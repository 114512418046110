import React from 'react';
import SignalWifi0BarRoundedIcon from '@mui/icons-material/SignalWifi0BarRounded';
import SignalWifi1BarRoundedIcon from '@mui/icons-material/SignalWifi1BarRounded';
import SignalWifi2BarRoundedIcon from '@mui/icons-material/SignalWifi2BarRounded';
import SignalWifi3BarRoundedIcon from '@mui/icons-material/SignalWifi3BarRounded';
import SignalWifi4BarRoundedIcon from '@mui/icons-material/SignalWifi4BarRounded';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import useStyles from '../../styles';

interface RSSIProps {
  RSSI: number | undefined;
  rssiTimeStamp?: string;
}

interface SignalProperty {
  limit: number;
  icon: typeof SvgIcon;
}

function RSSILookup({ RSSI, rssiTimeStamp }: RSSIProps): JSX.Element {
  const classes = useStyles();

  const signalProperty: Array<SignalProperty> = [
    { limit: -85, icon: SignalWifi0BarRoundedIcon },
    { limit: -75, icon: SignalWifi1BarRoundedIcon },
    { limit: -65, icon: SignalWifi2BarRoundedIcon },
    { limit: -55, icon: SignalWifi3BarRoundedIcon },
    { limit: Infinity, icon: SignalWifi4BarRoundedIcon },
  ];
  const filteredIcon =
    RSSI === undefined ? undefined : signalProperty.find((property) => property.limit >= RSSI);
  const Icon = filteredIcon?.icon ?? SignalWifiOffIcon;
  const tooltipText = `${RSSI === undefined ? 'No signal observed' : `${RSSI} dB`} ${
    rssiTimeStamp ? `\nLast seen: ${rssiTimeStamp}` : ''
  }`;
  return (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
      <Icon className={classes.icon} />
    </Tooltip>
  );
}

RSSILookup.defaultProps = {
  rssiTimeStamp: undefined,
};

export default RSSILookup;
