import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
const DEFAULT_EXPIRY_MINUTES = 30;

// Determine if given timestamp (in seconds) is older than threshold in minutes
export const isDataExpired = (
  timestampSeconds: number | null | undefined,
  threshold = DEFAULT_EXPIRY_MINUTES
): boolean => {
  const timestamp = (timestampSeconds ?? 0) * 1000;
  const date = new Date(timestamp).getTime();
  const now = new Date().getTime();
  return now - date > threshold * 60 * 1000;
};

export const ONE_DAY = 24 * 3600000; // 1 day in milliseconds
export const THREE_DAYS = 3 * ONE_DAY; // 3 days in milliseconds

export function getStartOfDay(d: Date): Date {
  const newDate = new Date(d);

  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
}

export function getEndOfDay(d: Date): Date {
  const newDate = new Date(d);

  newDate.setHours(23);
  newDate.setMinutes(59);
  newDate.setSeconds(59);
  newDate.setMilliseconds(999);

  return newDate;
}

export const getShortTimeStr = (time: number): string => {
  // takes time in seconds
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    relativeTime: {
      future: dayjs((time ?? 0) * 1000).format('H:mm'),
      past: dayjs((time ?? 0) * 1000).format('H:mm'),
      s: dayjs((time ?? 0) * 1000).format('H:mm'),
      m: dayjs((time ?? 0) * 1000).format('H:mm'),
      mm: dayjs((time ?? 0) * 1000).format('H:mm'),
      h: dayjs((time ?? 0) * 1000).format('H:mm'),
      hh: dayjs((time ?? 0) * 1000).format('H:mm'),
      d: dayjs((time ?? 0) * 1000).format('DD MMM'),
      dd: dayjs((time ?? 0) * 1000).format('DD MMM'),
      M: dayjs((time ?? 0) * 1000).format('DD MMM'),
      MM: dayjs((time ?? 0) * 1000).format('DD MMM'),
      y: dayjs((time ?? 0) * 1000).format('YYYY-MMM'),
      yy: dayjs((time ?? 0) * 1000).format('YYYY-MMM'),
    },
  });
  return dayjs(dayjs((time ?? 0) * 1000).format()).fromNow(true);
};
