/* eslint-disable react/no-danger */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { themeProps } from '../../styles/theme';

interface UserPolicyDialogProps {
  showUserPolicy: boolean;
  closeDialog: () => void;
}

function UserPolicyDialog({ showUserPolicy, closeDialog }: UserPolicyDialogProps): JSX.Element {
  const theme = useTheme();
  return (
    <Dialog
      open={showUserPolicy}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ textAlign: 'center' }}
      fullWidth
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '15px',
        }}
      >
        <Typography variant="h5" style={{ width: '100%' }}>
          Privacy Notice
        </Typography>
      </DialogTitle>
      <DialogContent>
        <hr style={{ border: 'solid 1px #f1f1f1' }} />
        We gather essential usage data to enhance your experience and refine our services.
        <br />
        <br />
        For detailed insights into our data practices, please review LightFi&apos;s
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.lightfi.io/privacy-policy/"
          style={{ color: theme.palette.text.primary }}
        >
          Privacy Policy
        </a>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant={themeProps.btnVariant.default} onClick={closeDialog}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserPolicyDialog;
