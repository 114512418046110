import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    backgroundColor: '#554e4e38',
    zIndex: '1',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  loader: {
    position: 'absolute',
    top: '40%',
    left: '45%',
    zIndex: '2',
  },
}));

function LoaderWithBackdrop(): JSX.Element {
  const styles = useStyle();
  return (
    <>
      <div className={styles.backdrop} />
      <div className={styles.loader}>
        <CircularProgress style={{ color: 'white' }} />
      </div>
    </>
  );
}

export default LoaderWithBackdrop;
