import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SettingsIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 181 181" {...props}>
      <path
        d="M 177.57,68.00
           C 175.83,66.39 173.45,65.58 171.06,65.77
             171.06,65.77 160.64,66.82 160.64,66.82
             159.92,64.74 159.10,62.69 158.16,60.64
             158.16,60.64 166.57,54.08 166.57,54.08
             168.51,52.58 169.71,50.34 169.86,47.96
             170.04,45.54 169.18,43.15 167.50,41.41
             167.50,41.41 142.86,15.77 142.86,15.77
             141.15,13.98 138.78,13.03 136.37,13.14
             133.94,13.23 131.65,14.36 130.09,16.25
             130.09,16.25 123.26,24.54 123.26,24.54
             120.71,23.27 118.10,22.15 115.43,21.19
             115.43,21.19 116.73,10.48 116.73,10.48
             117.02,8.06 116.26,5.62 114.64,3.80
             113.02,1.97 110.70,0.92 108.26,0.92
             108.26,0.92 72.66,0.81 72.66,0.81
             70.25,0.81 67.94,1.84 66.33,3.62
             64.71,5.41 63.92,7.82 64.16,10.24
             64.16,10.24 65.30,21.25 65.30,21.25
             63.27,21.98 61.30,22.79 59.36,23.70
             59.36,23.70 52.73,15.17 52.73,15.17
             51.23,13.24 48.96,12.04 46.44,11.88
             44.03,11.76 41.67,12.65 39.98,14.33
             39.98,14.33 14.62,39.27 14.62,39.27
             12.90,40.97 11.97,43.32 12.08,45.75
             12.19,48.16 13.32,50.42 15.18,51.95
             15.18,51.95 23.59,58.90 23.59,58.90
             22.75,60.62 22.00,62.35 21.31,64.11
             21.31,64.11 10.94,62.66 10.94,62.66
             8.49,62.29 6.01,62.99 4.14,64.59
             2.28,66.16 1.18,68.46 1.12,70.91
             1.12,70.91 0.31,106.48 0.31,106.48
             0.26,108.90 1.23,111.23 2.97,112.86
             4.56,114.38 6.65,115.21 8.86,115.21
             9.06,115.21 9.27,115.20 9.54,115.18
             9.54,115.18 19.96,114.35 19.96,114.35
             20.67,116.52 21.48,118.63 22.40,120.71
             22.40,120.71 14.30,126.77 14.30,126.77
             12.35,128.23 11.11,130.46 10.91,132.88
             10.70,135.31 11.54,137.72 13.22,139.49
             13.22,139.49 37.70,165.30 37.70,165.30
             39.31,166.99 41.56,167.96 43.95,167.96
             43.95,167.96 44.11,167.96 44.11,167.96
             46.52,167.90 48.80,166.82 50.39,164.98
             50.39,164.98 57.03,157.24 57.03,157.24
             59.67,158.58 62.38,159.76 65.15,160.77
             65.15,160.77 64.15,170.82 64.15,170.82
             63.91,173.23 64.70,175.64 66.33,177.43
             67.95,179.21 70.26,180.22 72.67,180.22
             72.67,180.22 72.68,180.22 72.68,180.22
             72.68,180.22 108.27,180.11 108.27,180.11
             110.71,180.10 113.03,179.05 114.63,177.23
             116.25,175.41 117.01,172.97 116.72,170.53
             116.72,170.53 115.54,160.75 115.54,160.75
             117.69,159.97 119.81,159.09 121.89,158.12
             121.89,158.12 128.30,166.01 128.30,166.01
             129.82,167.88 132.07,169.03 134.48,169.16
             134.63,169.17 134.79,169.17 134.94,169.17
             137.23,169.17 139.38,168.28 140.98,166.67
             140.98,166.67 166.15,141.55 166.15,141.55
             167.88,139.83 168.80,137.44 168.65,135.02
             168.52,132.58 167.35,130.32 165.43,128.80
             165.43,128.80 130.48,102.47 130.48,102.47
             131.90,98.33 132.63,94.00 132.63,89.53
             132.63,67.44 114.66,49.47 92.57,49.47
             70.48,49.47 52.51,67.44 52.51,89.53
             52.51,111.62 70.48,129.59 92.57,129.59
             96.63,129.59 100.64,128.98 104.48,127.79
             106.12,127.28 107.46,126.16 108.26,124.64
             109.06,123.12 109.22,121.38 108.71,119.74
             107.66,116.36 104.05,114.47 100.67,115.52
             98.06,116.33 95.34,116.74 92.57,116.74
             77.56,116.74 65.35,104.53 65.35,89.52
             65.35,74.51 77.56,62.30 92.57,62.30
             107.58,62.30 119.79,74.51 119.79,89.52
             119.79,93.81 118.82,97.91 116.91,101.72
             116.91,101.73 116.91,101.73 116.90,101.74
             116.72,102.10 116.58,102.47 116.48,102.85
             116.47,102.88 116.45,102.91 116.44,102.95
             116.34,103.31 116.29,103.68 116.26,104.05
             116.26,104.10 116.24,104.15 116.24,104.20
             116.22,104.57 116.24,104.94 116.28,105.30
             116.28,105.34 116.28,105.39 116.29,105.44
             116.34,105.82 116.43,106.20 116.55,106.57
             116.56,106.59 116.56,106.62 116.57,106.64
             116.68,106.98 116.84,107.31 117.01,107.63
             117.03,107.68 117.05,107.73 117.07,107.77
             117.21,108.02 117.38,108.24 117.56,108.47
             117.64,108.58 117.71,108.70 117.80,108.80
             117.94,108.96 118.10,109.10 118.26,109.24
             118.42,109.39 118.57,109.55 118.74,109.68
             118.76,109.69 118.77,109.71 118.79,109.73
             118.79,109.73 153.59,135.95 153.59,135.95
             153.59,135.95 135.28,154.22 135.28,154.22
             135.28,154.22 128.58,145.96 128.58,145.96
             126.60,143.52 123.17,142.87 120.44,144.42
             116.14,146.85 111.56,148.74 106.82,150.06
             103.73,150.92 101.78,153.84 102.16,157.02
             102.16,157.02 103.40,167.27 103.40,167.27
             103.40,167.27 77.41,167.35 77.41,167.35
             77.41,167.35 78.45,156.88 78.45,156.88
             78.76,153.78 76.81,150.92 73.81,150.07
             68.59,148.59 63.57,146.40 58.90,143.58
             56.20,141.94 52.75,142.50 50.70,144.89
             50.70,144.89 43.75,152.99 43.75,152.99
             43.75,152.99 25.88,134.14 25.88,134.14
             25.88,134.14 34.37,127.79 34.37,127.79
             36.90,125.90 37.68,122.37 36.17,119.59
             33.83,115.27 32.12,110.81 30.93,105.98
             30.18,102.91 27.33,100.85 24.18,101.11
             24.18,101.11 13.26,102.00 13.26,102.00
             13.26,102.00 13.85,76.03 13.85,76.03
             13.85,76.03 24.75,77.56 24.75,77.56
             27.92,78.01 30.86,76.11 31.79,73.06
             33.12,68.67 34.91,64.57 37.28,60.51
             38.87,57.78 38.25,54.34 35.82,52.33
             35.82,52.33 27.03,45.07 27.03,45.07
             27.03,45.07 45.54,26.84 45.54,26.84
             45.54,26.84 52.48,35.76 52.48,35.76
             54.42,38.25 57.95,38.96 60.71,37.41
             64.91,35.04 69.24,33.25 73.94,31.93
             76.97,31.09 78.93,28.21 78.60,25.08
             78.60,25.08 77.41,13.64 77.41,13.64
             77.41,13.64 103.38,13.72 103.38,13.72
             103.38,13.72 102.03,24.90 102.03,24.90
             101.65,28.08 103.61,31.00 106.69,31.86
             111.89,33.30 116.89,35.44 121.55,38.21
             124.30,39.85 127.76,39.24 129.80,36.77
             129.80,36.77 136.93,28.11 136.93,28.11
             136.93,28.11 154.93,46.84 154.93,46.84
             154.93,46.84 146.09,53.74 146.09,53.74
             143.59,55.69 142.88,59.13 144.42,61.91
             146.89,66.38 148.59,70.63 149.76,75.28
             150.53,78.35 153.50,80.42 156.63,80.10
             156.63,80.10 167.40,79.02 167.40,79.02
             167.40,79.02 166.89,109.76 166.89,109.76
             166.86,111.47 167.50,113.10 168.69,114.33
             169.88,115.56 171.48,116.26 173.10,116.28
             173.10,116.28 173.31,116.28 173.31,116.28
             176.79,116.28 179.67,113.45 179.73,109.96
             179.73,109.96 180.32,74.39 180.32,74.39
             180.37,71.99 179.36,69.64 177.57,68.00 Z"
      />
    </SvgIcon>
  );
}

export default SettingsIcon;
