import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl/maplibre';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
  getActiveMarker,
  getCurrentLocationChildLocations,
  getSelectedLocationVarValues,
} from '../../../state/selectors';
import { Marker as MarkerProps } from '../mapHelpers';
import useStyles from '../../../styles';
import DoughnutPlot from '../../Plots/DoughnutPlot';
import { setCurrentLocation } from '../../../state/actions';
import { VarName } from '../../../utils/varNames';
import MotionRingPlot from '../../Dashboard/MotionRingPlot';
import SensorSunburst from '../../Plots/SensorSunburst';
import { DataTreeItem } from '../../../services/api';

function CustomLocationMarker(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeMarker = useSelector(getActiveMarker);
  const childLocations = useSelector(getCurrentLocationChildLocations);
  const selectedVarValues = useSelector(getSelectedLocationVarValues);

  const hasFloorLevel = useMemo(() => {
    let floorExists = false;
    const floorLevel = childLocations.filter((item) => item.type === 'floorLevel');
    if (floorLevel.length > 0) {
      floorExists = true;
    }
    return floorExists;
  }, [childLocations]);

  const newLocationMarkers = useMemo(() => {
    const locMarkers = new Map<string, MarkerProps>();
    if (hasFloorLevel) {
      const floorData = childLocations.sort((a, b) => {
        const floorA = a?.shortName ?? a?.name ?? '';
        const floorB = b?.shortName ?? b?.name ?? '';
        if (floorA < floorB) return 1;
        if (floorA > floorB) return -1;
        return 0;
      });

      floorData.forEach((location) => {
        const { id } = location;
        if (id) {
          const locationMarkerData: MarkerProps = {
            name: location.name ?? location.id,
            id: location.id,
            location: location.id,
            coordinates: [location.position?.lng, location.position?.lat],
            availableData: [],
          };
          locMarkers.set(id, locationMarkerData as MarkerProps);
        }
      });
    } else {
      childLocations?.forEach((location) => {
        const { id } = location;
        if (id) {
          const locationMarkerData: MarkerProps = {
            name: location.name ?? location.id,
            id: location.id,
            location: location.id,
            coordinates: [location.position?.lng, location.position?.lat],
            availableData: [],
          };
          locMarkers.set(id, locationMarkerData as MarkerProps);
        }
      });
    }
    return locMarkers;
  }, [childLocations, hasFloorLevel]);

  const showLocRingContent = (locProps: MarkerProps) => {
    function showRingPlots() {
      switch (activeMarker) {
        case VarName.ClientsBle:
        case VarName.ClientsWiFi:
        case VarName.EnergyInkWh: {
          const allData: DataTreeItem[] = selectedVarValues.get(activeMarker) ?? [];
          const locData = allData.filter((item) => item.location?.startsWith(locProps.id));
          return <SensorSunburst data={locData} varName={activeMarker} locMarkerId={locProps.id} />;
        }
        case VarName.MotionEvent:
          return <MotionRingPlot locMarkerId={locProps.id} />;
        default:
          return <DoughnutPlot varName={activeMarker} locMarkerId={locProps.id} />;
      }
    }

    return (
      <Box
        className={classes.locationMarker}
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
      >
        {showRingPlots()} &nbsp; <Typography variant="body1">{locProps.name}</Typography>
      </Box>
    );
  };

  const showBuildingContent = () => (
    <>
      {Array.from(newLocationMarkers.values()).map((locationProps: MarkerProps) => {
        const lat = locationProps.coordinates[0];
        const lng = locationProps.coordinates[1];

        return (
          <Box key={locationProps.id}>
            {lat !== undefined && lng !== undefined && (
              <Marker
                longitude={lat}
                latitude={lng}
                anchor="center"
                style={{ zIndex: 5 }}
                onClick={() => dispatch(setCurrentLocation(locationProps.id))}
              >
                {showLocRingContent(locationProps)}
              </Marker>
            )}
          </Box>
        );
      })}
    </>
  );

  const showFloorContent = () => {
    const allFloors = Array.from(newLocationMarkers.values());
    const floorWithPos = allFloors.find((item) => item.coordinates[0] !== undefined);
    const lat = floorWithPos?.coordinates[0];
    const lng = floorWithPos?.coordinates[1];
    return (
      <Box>
        {lat !== undefined && lng !== undefined && (
          <Marker longitude={lat} latitude={lng} anchor="center" style={{ zIndex: 5 }} draggable>
            <List>
              {allFloors.map((floor) => (
                <ListItem
                  key={floor.id}
                  onClick={() => dispatch(setCurrentLocation(floor.id))}
                  sx={{ padding: '0 8px' }}
                >
                  {showLocRingContent(floor)}
                </ListItem>
              ))}
            </List>
          </Marker>
        )}
      </Box>
    );
  };

  return <Box>{hasFloorLevel ? showFloorContent() : showBuildingContent()};</Box>;
}

export default CustomLocationMarker;
