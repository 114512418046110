import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ParticulateMatterIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 51 49" {...props}>
      <path d="M28.8999 46.3C31.3852 46.3 33.3999 44.2853 33.3999 41.8C33.3999 39.3148 31.3852 37.3 28.8999 37.3C26.4146 37.3 24.3999 39.3148 24.3999 41.8C24.3999 44.2853 26.4146 46.3 28.8999 46.3Z" />
      <path d="M10.5 42.3C14.9183 42.3 18.5 38.7183 18.5 34.3C18.5 29.8818 14.9183 26.3 10.5 26.3C6.08172 26.3 2.5 29.8818 2.5 34.3C2.5 38.7183 6.08172 42.3 10.5 42.3Z" />
      <path d="M40.5982 2.80254L40.9703 2.31295L40.4151 2.04857C38.2443 1.01489 35.8704 0.5 33.4001 0.5C24.424 0.5 17.1001 7.82386 17.1001 16.8C17.1001 25.7761 24.424 33.1 33.4001 33.1C42.3762 33.1 49.7001 25.7761 49.7001 16.8C49.7001 14.5271 49.1842 12.2613 48.3609 10.306L48.0315 9.52352L47.5069 10.1911L45.3069 12.9911L45.1515 13.1889L45.2177 13.4316C45.5088 14.4988 45.7001 15.6538 45.7001 16.8C45.7001 23.6239 40.224 29.1 33.4001 29.1C26.5762 29.1 21.1001 23.6239 21.1001 16.8H21.1001L21.1 16.793C21.0041 9.98103 26.5697 4.5 33.4001 4.5C35.0442 4.5 36.6688 4.7905 38.0887 5.45309L38.4541 5.62365L38.6982 5.30254L40.5982 2.80254Z" />
      <path d="M47.9481 8.89084L48.1741 8.61344L47.9845 8.31C47.6305 7.74358 47.0576 7.5 46.498 7.5C45.958 7.5 45.4124 7.72333 45.0203 8.10821C44.489 8.57479 44.4584 9.21525 44.5271 9.75108C44.5622 10.0249 44.6277 10.3084 44.6915 10.5744C44.7 10.6101 44.7086 10.6456 44.7171 10.6808C44.7738 10.916 44.8278 11.1403 44.8686 11.3644L45.0627 12.4321L45.7481 11.5908L47.9481 8.89084Z" />
      <path d="M42.0756 2.53184L41.7271 2.40116L41.5019 2.69746L39.6019 5.19746L39.1655 5.77167L39.8563 5.97891C40.4085 6.14455 40.9897 6.20953 41.5119 6.11739C42.04 6.0242 42.5411 5.76084 42.8341 5.24807C43.0897 4.80078 43.1906 4.25355 43.0903 3.75194C42.9872 3.2367 42.6635 2.7523 42.0756 2.53184Z" />
    </SvgIcon>
  );
}

export default ParticulateMatterIcon;
