/* eslint-disable import/prefer-default-export */
import { PropLocationItem } from '../services/api';

export enum LocationNameType {
  name = 'name',
  shortName = 'shortName',
}

export const getLocationNameTree = (
  locId: string,
  locationsById: Map<string, PropLocationItem>
) => {
  const path = locId === '#' ? [] : locId?.slice(1).split('#');
  const breadCrumb = [];
  for (let i = 0; i <= path.length; ++i) {
    let id = '';
    for (let j = 0; j < i; j++) {
      id += `#${path[j]}`;
    }
    if (id === '') {
      id = '#';
    }
    const breadCrumbItem = { id, name: locationsById.get(id)?.name ?? id };
    breadCrumb.push(breadCrumbItem);
  }
  return breadCrumb;
};
