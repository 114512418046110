import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortNumericDescIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M164.106 162.131C154.911 171.164 144.168 175.681 131.88 175.681C116.174 175.681 103.6 170.31 94.1602 159.568C84.7201 148.744 80 134.177 80 115.866C80 104.229 82.5228 93.568 87.5684 83.884C92.6954 74.118 99.8161 66.55 108.931 61.179C118.045 55.726 128.299 53 139.692 53C151.411 53 161.828 55.93 170.942 61.789C180.057 67.649 187.137 76.072 192.183 87.058C197.228 98.044 199.792 110.617 199.873 124.778V137.839C199.873 167.461 192.508 190.736 177.778 207.663C163.049 224.59 142.175 233.624 115.156 234.763L106.489 234.885V205.466L114.302 205.344C144.982 203.961 161.584 189.556 164.106 162.131ZM140.547 148.703C146.244 148.703 151.126 147.238 155.195 144.309C159.346 141.379 162.479 137.839 164.595 133.689V119.162C164.595 107.199 162.316 97.922 157.759 91.33C153.202 84.738 147.098 81.443 139.448 81.443C132.368 81.443 126.55 84.698 121.992 91.208C117.435 97.637 115.156 105.735 115.156 115.5C115.156 125.184 117.354 133.16 121.748 139.426C126.224 145.611 132.49 148.703 140.547 148.703Z" />
      <path d="M158.392 444.979H123.114V308.992L81 322.053V293.367L154.608 267H158.392V444.979Z" />
      <path d="M427.875 246.5V381H376.125V246.5H333L402 112L471 246.5H427.875Z" />
    </SvgIcon>
  );
}

export default SortNumericDescIcon;
