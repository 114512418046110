/* tslint:disable */
/* eslint-disable */
/**
 * LightFi API
 *  <h2>Supplementary Documentation</h2> See also documentation at <a href=https://docs.lightfi.io/API/ >https://docs.lightfi.io/API/</a> for guidance.  <h2>Authorisation for the API and this test page</h2> You will need your OAuth `client_id` and `client_secret` as well as a valid user login to use the API (contact LightFi to obtain these).  You can test the API directly from this page:   - Click the \"Authorize\" button (below this text)   - Enter your `client_id` and `client_secret` and click \"Authorize\"   - You will be redirected to a login page to enter your LightFi username (the email address for your account) and password   - On successful login you will be redirected back to this page   - You can now test the API routes directly  Notes:   - Using this page to test queries returning large amounts of data will be slow, much slower than e.g. using the curl command   - You can obtain an access token from this page to use in another client for testing by copying it from the example curl request   - The `access_token` is only valid for a short period of time (`expiry [\"exp\"]` can be viewed in the **JWT** token itself using any JWT decoding tool/library e.g. <a href=https://jwt.io/>https://jwt.io/</a> ). When the access token expires you will need to logout/login again on the API documentation page.   - In order to allow programmatic access for a longer period of time you need the `refresh_token`, this can be obtained, for example, using Postman (see <a href=https://docs.lightfi.io/API/postman_refresh_token/>here</a>). 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    username?: string;
    password?: string;
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    basePath?: string;
    serverIndex?: number;
    baseOptions?: any;
    formDataCtor?: new () => any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * override server index
     *
     * @type {number}
     * @memberof Configuration
     */
    serverIndex?: number;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;
    /**
     * The FormData constructor that will be used to create multipart form data
     * requests. You can inject this here so that execution environments that
     * do not support the FormData class can still run the generated client.
     *
     * @type {new () => FormData}
     */
    formDataCtor?: new () => any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.serverIndex = param.serverIndex;
        this.baseOptions = param.baseOptions;
        this.formDataCtor = param.formDataCtor;
    }

    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    public isJsonMime(mime: string): boolean {
        const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
        return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    }
}
