import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import { ButtonVariant, themeProps } from '../../styles/theme';
import { getShortTimeStr } from '../../utils/functions';
import useGlobalStyles from '../../styles/index';
import SensorIcon from '../../styles/icons/SensorIcon';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import RSSILookup from '../SensorConfig/RSSILookup';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import { VisibleSubSensor } from '../../services/api';
import MobileAppIcon from '../../styles/icons/MobileAppIcon';

interface NewSensorsListProps {
  loading: boolean;
  newSensors: VisibleSubSensor[] | undefined;
  handleRefresh: () => void;
  setSelectedSensorId: (value: string) => void;
}

function NewSensorsList({
  loading,
  newSensors,
  handleRefresh,
  setSelectedSensorId,
}: NewSensorsListProps): JSX.Element {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <SensorIcon stroke={theme.palette.text.primary} style={{ alignSelf: 'center' }} />
          <Typography variant="h5" sx={{ marginLeft: '10px', alignSelf: 'center' }}>
            New Sensors
          </Typography>
        </Box>
        <Button
          startIcon={<RefreshIcon />}
          variant={ButtonVariant.default}
          onClick={handleRefresh}
          style={{ padding: '5px 10px', border: 'none' }}
        >
          Refresh
        </Button>
      </Box>
      {newSensors && newSensors.length > 0 ? (
        <>
          <span style={{ fontSize: themeProps.textSize.small }}>
            Make sure you are in right location and press to select sensor from list below
          </span>
          <List style={{ background: 'none', padding: '0' }}>
            {newSensors.map((sensor) => {
              const Icon = sensor?.type
                ? sensorTypeDetails[sensor.type]?.icon ?? SensorIcon
                : SensorIcon;
              return (
                <ListItem
                  key={sensor.suggested_id}
                  onClick={() => setSelectedSensorId(sensor.suggested_id)}
                  style={{
                    margin: '5px 0',
                    background: theme.palette.primary.main,
                    borderRadius: '8px',
                  }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item md={1} sm={1} xs={1}>
                      <Icon />
                    </Grid>
                    <Grid item md={4} sm={4} xs={7}>
                      {sensor.suggested_id}
                    </Grid>
                    <Grid item md={2} sm={2} xs={2} className={`${globalClasses.unixTime}`}>
                      {getShortTimeStr(sensor.last_seen)}
                    </Grid>
                    <Grid item md={2} sm={2} xs={2}>
                      <RSSILookup RSSI={sensor.rssi} />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        !loading && <Alert severity="error">Nearby sensors not available</Alert>
      )}
      <Button
        color="secondary"
        variant={ButtonVariant.default}
        onClick={() => {
          setSelectedSensorId('');
          appSwitchBtnHandler(dispatch);
        }}
        style={{ width: '100%', margin: '1rem auto', border: 'none' }}
        startIcon={<MobileAppIcon style={{ fontSize: '32px' }} />}
      >
        NearBy Sensors
      </Button>
    </>
  );
}

export default NewSensorsList;
