import React from 'react';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Plot from './PlotlyCustom';
import { VarName } from '../../utils/varNames';
import { getDataBandParams } from '../../utils/dataBandParams';
import { themeProps } from '../../styles/theme';
import { getMotionUtlBandParams } from '../CalendarView/helpers';

interface PlotProps {
  values: number[];
  sensorName: string;
  varName: VarName;
  xRange?: [number, number];
}

export function HistogramPlot({ values, sensorName, varName, xRange }: PlotProps): JSX.Element {
  const theme = useTheme();
  const inlinePlot = true;
  const isTransparentBg = true;

  const plotData: Plotly.Data[] = [];

  const bandValues: Map<string, number[]> = new Map();
  values.forEach((value) => {
    let band;
    if (varName === VarName.MotionEvent) band = getMotionUtlBandParams(value);
    else band = getDataBandParams(varName, value);

    const colourValues = bandValues.get(band.color);
    if (colourValues !== undefined) colourValues.push(value);
    else bandValues.set(band.color, [value]);
  });

  bandValues.forEach((valueArray, color) => {
    const trace: Plotly.Data = {
      x: valueArray,
      name: sensorName,
      type: 'histogram',
      marker: {
        color,
      },
    };
    plotData.push(trace);
  });

  return (
    <Plot
      data={plotData}
      layout={{
        barmode: 'stack',
        font: { family: themeProps.fontFamily.body },
        height: inlinePlot ? 40 : 200,
        autosize: true,
        // Set margins to maximise plot area (need to use automargin on axes)
        margin: {
          l: 5,
          r: 5,
          b: 5,
          t: 5,
          pad: 0,
        },
        showlegend: false,
        dragmode: 'pan',
        xaxis: {
          automargin: true,
          range: xRange,
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          tickfont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        yaxis: {
          automargin: true,
          fixedrange: true, // Disable pan/zoom
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          tickfont: {
            color: theme.palette.text.primary,
          },
          titlefont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        plot_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
        paper_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
      }}
      config={{ displayModeBar: false, staticPlot: true }}
      useResizeHandler
      style={{ width: '100%' }}
    />
  );
}

HistogramPlot.defaultProps = {
  xRange: undefined,
};

export default HistogramPlot;
