import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';
import CancelIcon from '@mui/icons-material/Cancel';
import useStyles from '../../styles/mapViewWrapper';
import globalStyles from '../../styles/index';
import MapViewIcon from '../../styles/icons/MapViewIcon';
import Help from '../../components/Help';
import Map from '../Map/Map';
import { SensorValueItem } from '../CalendarView/helpers';
import Header from '../../components/Header';

interface MapViewWrapperProps {
  children: React.ReactChild;
  sensorSummaryValues?: SensorValueItem[];
}

function MapViewWrapper({ children, sensorSummaryValues }: MapViewWrapperProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [showMapView, setShowMapView] = useState<boolean>(true);
  const isDesktopView = useMediaQuery(useTheme().breakpoints.up('lg'));

  useEffect(() => {
    if (isDesktopView) setShowMapView(true);
    else setShowMapView(false);
  }, [isDesktopView]);

  return (
    <div className={`${globalClasses.bodyContent} ${classes.bodyContent}`}>
      <Header />
      <div className={`${globalClasses.dashboard} ${classes.dashboard}`}>
        <Fab
          color="primary"
          size="large"
          className={globalClasses.floatingBtn}
          style={{ textTransform: 'inherit' }}
        >
          {showMapView ? (
            <CancelIcon style={{ color: 'white' }} onClick={() => setShowMapView(!showMapView)} />
          ) : (
            <Help helpText="Open the map" position="left">
              <MapViewIcon
                style={{ color: 'white' }}
                onClick={() => setShowMapView(!showMapView)}
              />
            </Help>
          )}
        </Fab>
        {showMapView && (
          <div className={`${globalClasses.dashboardTile} ${classes.dashboardTile}`}>
            <Map overrideData={sensorSummaryValues} />
          </div>
        )}
        <div
          style={{ display: 'block' }}
          className={`${globalClasses.dashboardTile} ${showMapView && classes.content} `}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

MapViewWrapper.defaultProps = {
  sensorSummaryValues: undefined,
};

export default MapViewWrapper;
