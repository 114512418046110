import { SvgIconProps } from '@mui/material/SvgIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HomeIcon from '../../styles/icons/HomeIcon';
import ReportsIcon from '../../styles/icons/ReportsIcon';
import SettingsIcon from '../../styles/icons/SettingsIcon';
import { UserAdminTypes } from '../../services/authenticationService';

export interface navListProps {
  id: number;
  label: NavListLabel;
  icon: (props: SvgIconProps) => JSX.Element;
  url: string;
  accessLevel?: UserAdminTypes;
  exact: boolean;
}

export enum NavListLabel {
  dashboard = 'Dashboard',
  compare = 'Compare',
  calendarView = 'Calendar View',
  config = 'Config',
  report = 'report',
  listViewDashboard = 'List View Dashboard',
}

const navList: navListProps[] = [
  { id: 1, label: NavListLabel.dashboard, icon: HomeIcon, url: '/', exact: true },
  { id: 2, label: NavListLabel.compare, icon: ReportsIcon, url: '/compare', exact: true },
  {
    id: 3,
    label: NavListLabel.calendarView,
    icon: CalendarMonthIcon,
    url: '/calendarView',
    exact: true,
  },
  {
    id: 4,
    label: NavListLabel.report,
    icon: SummarizeIcon,
    url: '/report',
    exact: true,
  },
  {
    id: 5,
    label: NavListLabel.config,
    icon: SettingsIcon,
    url: '/location',
    accessLevel: UserAdminTypes.admin,
    exact: false,
  },
];

export default navList;
