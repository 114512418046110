import React, { CSSProperties } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { showHelp } from '../state/actions';
import { getShowHelp } from '../state/selectors';
import { themeProps } from '../styles/theme';

interface HelpProps {
  children?: React.ReactChild;
  position?: 'top' | 'bottom' | 'left' | 'right';
  helpText: string;
}

function getFlexDirection(
  position: 'top' | 'bottom' | 'left' | 'right' | undefined
): 'column' | 'column-reverse' | 'row' {
  switch (position) {
    case 'top':
      return 'column-reverse';
    case 'left':
    case 'right':
      return 'row';
    case 'bottom':
    default:
      return 'column';
  }
}

function getTextPosition(
  position: 'top' | 'bottom' | 'left' | 'right' | undefined
): 'left' | 'right' | 'center' {
  switch (position) {
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    case 'top':
    case 'bottom':
    default:
      return 'center';
  }
}

function Help({ children, position, helpText }: HelpProps): JSX.Element {
  const showingHelp = useSelector(getShowHelp);
  const dispatch = useDispatch();

  const containerStyle: CSSProperties = {
    position: 'relative',
    cursor: 'pointer',
    display: 'block',
    alignSelf: 'center',
    fontFamily: themeProps.fontFamily.body,
  };
  const textStyle: CSSProperties = {
    fontSize: themeProps.textSize.default,
    fontWeight: 'normal',
    display: showingHelp ? 'flex' : 'none',
    flexDirection: getFlexDirection(position),
    alignItems: 'start',
    position: 'absolute',
    top: position !== 'bottom' ? '5px' : '100%',
    bottom: position === 'top' ? '100%' : 'auto',
    left:
      // eslint-disable-next-line no-nested-ternary
      position === 'right'
        ? 'calc(100% - 5px)'
        : position === 'left' // eslint-disable-next-line indent
        ? // eslint-disable-next-line indent
          'auto' // eslint-disable-next-line indent
        : '5px',
    right: position === 'left' ? 'calc(100% - 5px)' : 'auto',
    zIndex: 10000,
    textAlign: getTextPosition(position),
  };
  const textLabelStyle: CSSProperties = {
    color: '#333',
    background: '#fb0',
    padding: '3px 5px',
    borderRadius: '8px',
    display: 'inline-block',
    minWidth: '18ch',
  };
  const helpMarkerTop: CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderBottom: '10px solid #fb0',
    marginLeft: '8px',
  };
  const helpMarkerBottom: CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderTop: '10px solid #fb0',
    marginLeft: '8px',
  };
  const helpMarkerLeft: CSSProperties = {
    width: 0,
    height: 0,
    marginTop: '8px',
    borderTop: '7px solid transparent',
    borderBottom: '7px solid transparent',
    borderLeft: '10px solid #fb0',
  };
  const helpMarkerRight: CSSProperties = {
    width: 0,
    height: 0,
    marginTop: '8px',
    borderTop: '7px solid transparent',
    borderBottom: '7px solid transparent',
    borderRight: '10px solid #fb0',
  };

  const dismiss = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(showHelp(false));
  };

  // To ensure that dom validation is ok, as this may appear inside a P tag
  // (eg in a MaterialUI Typography tag) we have to use spans and style
  // accodinrgly. Divs are not allowed inside P tags.
  return (
    <span style={containerStyle} role="button" onKeyDown={dismiss} onClick={dismiss} tabIndex={0}>
      {children}
      {showingHelp && (
        <span style={textStyle}>
          {position === 'bottom' && <span style={helpMarkerTop} />}
          {position === 'right' && <span style={helpMarkerRight} />}
          {position === 'top' && <span style={helpMarkerBottom} />}
          <span style={textLabelStyle}>{helpText}</span>
          {position === 'left' && <div style={helpMarkerLeft} />}
        </span>
      )}
    </span>
  );
}

Help.defaultProps = {
  position: 'bottom',
  children: null,
};

export default Help;
