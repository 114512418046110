import React, { useState, useMemo } from 'react';
import { useTheme as useMuiTheme } from '@mui/material';
import { Auth } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import {
  useAuthenticator,
  Flex,
  TextField,
  PasswordField,
  Button,
  useTheme,
} from '@aws-amplify/ui-react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Loading from '../../components/Loading';
import { themeProps } from '../../styles/theme';

interface ChandePassWordDialog {
  showPassChangeDialog: boolean;
  closeDialog: () => void;
}

function ChandePassWordDialog({
  showPassChangeDialog,
  closeDialog,
}: ChandePassWordDialog): JSX.Element {
  const { tokens } = useTheme();
  const theme = useMuiTheme();

  const { user } = useAuthenticator((context) => [context.user]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newConfirmedPass, setNewConfirmedPass] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState<boolean>();

  // string to be atleast 8 character
  const validationRegex = /^.*(?=.{8,}).*$/;

  const newPassword = useMemo(() => {
    let newPassString = '';
    if (newPass === newConfirmedPass) newPassString = newPass;
    return newPassString;
  }, [newPass, newConfirmedPass]);

  const changePassHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAlertMsg('');
    setErrMsg('');
    setLoading(true);
    e.preventDefault();
    Auth.currentAuthenticatedUser()
      .then(() => Auth.changePassword(user, currentPassword, newPassword))
      .then((data) => {
        setLoading(false);
        if (data === 'SUCCESS') setAlertMsg('Password has been updated');
      })
      .catch((err) => {
        setErrMsg(err);
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={showPassChangeDialog}
      maxWidth="md"
      PaperProps={{
        style: {
          margin: '0',
          width: 'calc(100% - 20px)',
          background: themeProps.colors.white,
        },
      }}
    >
      <DialogTitle id="change-password" style={{ color: themeProps.colors.black }}>
        Change Password
      </DialogTitle>
      <DialogContent>
        {user && (
          <Flex as="form" direction="column" gap={tokens.space.medium}>
            <TextField
              disabled
              label="Username"
              name="username"
              autoComplete="username"
              value={user.attributes?.email}
            />
            <PasswordField
              label="Current password"
              name="current_password"
              autoComplete="current-password"
              isRequired
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <PasswordField
              label="New password"
              name="new_password"
              autoComplete="new-password"
              isRequired
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              hasError={!!(newPass && !validationRegex.test(newPass))}
              errorMessage="Password must be at least 8 characters (suggest to include uppercase, lowercase and number or special character)"
            />
            <PasswordField
              label="Confirm password"
              name="confirm_password"
              autoComplete="new-password"
              isRequired
              value={newConfirmedPass}
              onChange={(e) => setNewConfirmedPass(e.target.value)}
              hasError={!!(newPass && newConfirmedPass && newConfirmedPass !== newPass)}
              errorMessage="New passwords doesnot match"
            />
            <>
              {loading && <Loading />}
              {alertMsg && <Alert severity="success">{`${alertMsg}`}</Alert>}
              {errMsg && <Alert severity="error">{`${errMsg}`}</Alert>}
            </>
            <Button
              type="submit"
              onClick={(e) => changePassHandler(e)}
              disabled={!currentPassword || !newPassword}
              style={{ background: theme.palette.primary.main }}
            >
              Submit
            </Button>
            <Button
              onClick={closeDialog}
              style={{ background: theme.palette.error.main, color: 'white', border: 'none' }}
            >
              Cancel
            </Button>
          </Flex>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ChandePassWordDialog;
