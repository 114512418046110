import React from 'react';
import tealLogo from '../logo_512x512_teal.png';
import useGlobalStyles from '../styles';

export function Logo(): JSX.Element {
  const globalClasses = useGlobalStyles();
  return (
    <img
      src={tealLogo}
      alt="logo"
      height="35"
      width="35"
      className={globalClasses.navLogo}
      style={{ marginLeft: '10px', display: 'block' }}
      data-testid="brand-logo"
    />
  );
}

export function MobileLogo(): JSX.Element {
  return <img src={tealLogo} alt="logo" height="35" width="35" style={{ display: 'block' }} />;
}
