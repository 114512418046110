import { CSSProperties, ReactNode } from 'react';
import { DataTreeItem } from '../../services/api';
import { BandParamsType } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';

export interface DashSummary {
  data: DataTreeItem[];
  varName: VarName;
  firstItem: boolean;
  children?: ReactNode;
  valueF?: (value: number | undefined) => number | string | undefined;
}

export function gradientCalculator(colour1: string, colour2: string, split: number): string {
  const marginBetween = 5;
  let p1 = split * 100 - marginBetween;
  if (p1 < 0) {
    p1 = 0;
  }
  if (p1 > 100) {
    p1 = 100 - marginBetween;
  }
  const p2 = p1 + marginBetween;
  return `linear-gradient(to right, ${colour1} 0%, ${colour1} ${p1}%, ${colour2} ${p2}%, ${colour2} 100%`;
}

export type BandValues = {
  total: number; // Absolute value
  pct: number; // Pct in the 0-100 scale
  colour: string;
};

export function gradientGenerator(bands: BandValues[]): string {
  const BLEND = 5;
  let gradient = `linear-gradient(to right, ${bands[0].colour} 0%,`;
  let pct = 0;

  let i = 0;
  for (; i < bands.length - 1; i++) {
    const blend = pct > 0 ? BLEND : 1;

    pct += bands[i].pct;
    const p2 = pct;
    const p1 = pct - blend > 0 ? pct - blend : 0;
    const p3 = pct + blend < 100 ? pct + blend : 100;

    gradient += ` ${bands[i].colour} ${p1}%, ${bands[i].colour} ${p2}%, ${
      bands[i + 1].colour
    } ${p3}%,`;
  }

  gradient += ` ${bands[i].colour} 100%`;

  return gradient;
}

export const spacingStyles: CSSProperties = {
  marginLeft: '1rem',
};

export type MajorQualityData = {
  pct: number;
  label: string;
  expandedText: string;
};

function calculateMajorProps(
  perBandSums: BandValues[],
  bandDetails: BandParamsType[]
): MajorQualityData {
  // Calculation goals:
  //   - determine if good > bad
  //   - if >1 split e.g. badLow/good/badHigh determine which sub-group is higher
  //   - use percentage from biggest subgroup
  //   - pick message from sub-group non-empty band closest to split
  const goodBands: number[] = [];
  const badBands: number[] = [];

  for (let i = 0; i < bandDetails.length; i++) {
    if (bandDetails[i].desired === true) {
      goodBands.push(i);
    } else {
      badBands.push(i);
    }
  }

  const good = perBandSums
    .filter((item, index) => goodBands.indexOf(index) !== -1)
    .reduce((acc, val) => acc + val.pct, 0);
  const bad = perBandSums
    .filter((item, index) => badBands.indexOf(index) !== -1)
    .reduce((acc, val) => acc + val.pct, 0);

  // Pick from bigger of good/bad
  const bands = good > bad ? goodBands : badBands;

  // Find any sub-groups of bands in our good/bad bands
  const groups = [[bands[0]]];
  let currentGroup = 0;
  for (let i = 0; i < bands.length - 1; i++) {
    if (bands[i] + 1 !== bands[i + 1]) {
      groups.push([]);
      currentGroup++;
    }
    groups[currentGroup].push(bands[i + 1]);
  }

  // Find the highest sub-group
  let highestGroupIdx = 0;
  let highestGroupSum = 0;
  if (groups.length > 1) {
    // Process sub-groups to find largest
    for (let i = 0; i < groups.length; i++) {
      const groupSum = perBandSums
        .filter((item, index) => groups[i].indexOf(index) !== -1)
        .reduce((acc, val) => acc + val.pct, 0);
      if (groupSum >= highestGroupSum) {
        highestGroupIdx = i;
        highestGroupSum = groupSum;
      }
    }
  } else {
    // Don't have any sub-groups so use overall sum from above
    highestGroupSum = good > bad ? good : bad;
  }

  // Find our non-empty band closest to the split
  let selectedBand = groups[highestGroupIdx][0];
  if (groups[highestGroupIdx].length > 1) {
    // If group above good region iterate backwards to find group nearest split
    const isAbove = selectedBand > goodBands[0];
    const groupBands = isAbove ? groups[highestGroupIdx].reverse() : groups[highestGroupIdx];
    // Pick band nearest split with sensors in it
    groupBands.forEach((i) => {
      if (perBandSums[i].pct > 0) {
        selectedBand = i;
      }
    });
  }

  return {
    pct: highestGroupSum,
    label: bandDetails[selectedBand].text,
    expandedText:
      bandDetails[selectedBand].description ?? 'Click to see a breakdown of sensor readings',
  };
}

export const majorQualityCalculation: Record<
  VarName,
  ((perBandSums: BandValues[], bandDetails: BandParamsType[]) => MajorQualityData) | null
> = {
  unknown: null,
  BACnetReads: null,
  batteryLevelPct: null,
  CO2ppm: calculateMajorProps,
  clientsBLE: null,
  clientsWiFi: null,
  energyInkWh: null,
  illuminanceArb: null,
  motionEvent: null,
  occSignatures: null,
  onlineStatus: null,
  pressuremBar: calculateMajorProps,
  particulateMatter: calculateMajorProps,
  relativeHumidity: calculateMajorProps,
  temperatureC: calculateMajorProps,
  VOCppb: calculateMajorProps,
  frequencyHz: null,
  outputLevelPct: null,
};
