import React, { ReactNode, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from '../../styles';

import { VarNameDetailsItem } from '../../utils/varNames';
import { getDashboardPanels, getActiveMarker } from '../../state/selectors';
import { toggleDashboardPanel, setActiveMarker } from '../../state/actions';
import { persistState, StorageTypes } from '../../utils/persistentState';
import Help from '../../components/Help';
import BaseArraySortMenu from '../HelperComponents/BaseArraySortMenu';

interface ArrayRowProps {
  details: VarNameDetailsItem;
  children?: ReactNode;
  expanding: boolean;
  dataAvailable: boolean;
  firstItem: boolean;
}

function BaseArraySummary({
  children,
  details,
  expanding,
  dataAvailable,
  firstItem,
}: ArrayRowProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openPanels = useSelector(getDashboardPanels);
  const Icon = details.icon;
  const activeMarker = useSelector(getActiveMarker);
  const myRef = useRef<HTMLDivElement>(null);
  const matches = useMediaQuery(useTheme().breakpoints.up('md'));

  const expanded = openPanels.indexOf(details.id) !== -1;

  useEffect(() => {
    if (matches && activeMarker === details.id && myRef.current) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [activeMarker, details, matches]);

  const toggleExpanded = () => {
    dispatch(toggleDashboardPanel(details.id));
  };

  const activeMarkerHandler = () => {
    dispatch(setActiveMarker(details.id));
    persistState(details.id, StorageTypes.ActiveMarker);
    dispatch(toggleDashboardPanel(details.id));
  };

  return (
    <div className={classes.sensorArraySensorSummary} ref={myRef}>
      <div className={classes.sensorArraySensorSummaryContainer}>
        <Typography
          className={`${classes.sensorArraySensorSummaryMarkerButton} ${
            activeMarker === details.id ? classes.sensorArraySensorSummaryMarkerButtonActive : ''
          }`}
          variant="body1"
          display="inline"
          onClick={activeMarkerHandler}
        >
          <span className={classes.sensorArraySensorSummaryLabel}>
            {Icon && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon style={{ marginRight: '0.5rem' }} />
                <span className={classes.sensorArraySensorSummaryText}>{details.label}</span>
              </span>
            )}
            {!Icon && details.label}
          </span>
          {firstItem && <Help position="bottom" helpText="Show on map" />}
        </Typography>{' '}
      </div>
      {dataAvailable && children}
      {!dataAvailable && <Typography variant="body1">No data available</Typography>}
      <div className={classes.sensorArraySensorSummarySpacer} />
      {dataAvailable && expanded && <BaseArraySortMenu />}
      {expanding && dataAvailable && (
        <IconButton
          onClick={toggleExpanded}
          style={{ marginRight: 0 }}
          size="large"
          color="primary"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </div>
  );
}

BaseArraySummary.defaultProps = {
  children: undefined,
};

export default BaseArraySummary;
