import { ofType, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { StorageTypes, retrieveState } from '../../utils/persistentState';
import { fetchLocationFloorplan, fetchLocationDirectChildren } from '../../services/apiService';
import {
  goFetchLocationFloorplan,
  setCurrentLocation,
  setLocationDirectChildren,
  setLocationFloorplan,
  setSelectedSensors,
  setSensorVars,
  setSensorsById,
} from '../actions';
import { ActionTypes, ActionType } from '../actionTypes';
import { VarName, varNameDetails } from '../../utils/varNames';
import { LocationDirectChildren, GeoJSON as GeoJSONApiType } from '../../services/api/api';

const currentLocationChildrenEpic: Epic<ActionTypes> = (action$, state$) =>
  action$.pipe(
    filter(isOfType([ActionType.SET_CURRENT_LOCATION, ActionType.GO_FETCH_REFRESH])),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      let locationId = state.locations.currentLocation;
      if (action.type === ActionType.SET_CURRENT_LOCATION) {
        locationId = action.payload;
      }
      return from(
        // adding empty children manually for errors
        fetchLocationDirectChildren(locationId).catch(
          () => <LocationDirectChildren>{ locations: [], sensors: [] }
        )
      ).pipe(
        switchMap((response) => [
          setLocationDirectChildren(response.locations ?? []),
          setSensorsById(response.sensors ?? []),
        ])
      );
    })
  );

const currentLocationFloorplanEpic: Epic<ActionTypes> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(ActionType.SET_CURRENT_LOCATION)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      // Determine if we need to fetch the floorplan
      const actionMap = [] as ActionTypes[];
      const locationId = action.payload;
      if (!state.locations.floorplans.has(locationId)) {
        actionMap.push(goFetchLocationFloorplan(locationId));
      }
      return actionMap;
    })
  );

// This Epic is used to add the async API call for the goFetch action
// Should consider using createAsyncAction from typesafe-actions ?
const fetchLocationFloorplanEpic: Epic<ActionTypes> = (action$) =>
  action$.pipe(
    filter(isOfType(ActionType.GO_FETCH_LOCATION_FLOORPLAN)),
    mergeMap((action) => {
      const locationId = action.payload;
      return from(
        // error handlings are avoided for now to api calls that are linked with redux epic
        // need better error handling
        fetchLocationFloorplan(locationId).catch(
          () => <GeoJSONApiType>{ type: 'FeatureCollection', features: [] }
        )
      ).pipe(
        map(
          (response) =>
            setLocationFloorplan({
              locationId,
              floorplan: response,
            })
          // eslint-disable-next-line function-paren-newline
        )
      );
    })
  );

// When new available locations arrive restore the current location
const restoreCurrentLocationEpic: Epic<ActionTypes> = (action$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCATION_DATA),
    map(() => {
      const storedLocation: string = (retrieveState(StorageTypes.CurrentLocation) as string) ?? '#';
      return setCurrentLocation(storedLocation);
    })
  );

// When new available locations arrive restore the selected vars
const restoreSelectedVarsEpic: Epic<ActionTypes> = (action$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCATION_DATA),
    map(() => {
      const storedVars: VarName[] = (retrieveState(StorageTypes.SelectedVars) as VarName[]) ?? [];
      const validVars: VarName[] = [];
      // only allow valid varname to avoid error if varname naming has been changed
      for (let i = 0; i < storedVars.length; i++) {
        if (varNameDetails[storedVars[i]].id) validVars.push(storedVars[i]);
      }
      return setSensorVars(validVars);
    })
  );

const restoreSelectedPlotVarsEpic: Epic<ActionTypes> = (action$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCATION_DATA),
    map(() => {
      const storedVars: VarName[] =
        (retrieveState(StorageTypes.SelectedPlotSensors) as VarName[]) ?? [];
      return setSelectedSensors(storedVars);
    })
  );

export {
  currentLocationChildrenEpic,
  currentLocationFloorplanEpic,
  fetchLocationFloorplanEpic,
  restoreCurrentLocationEpic,
  restoreSelectedVarsEpic,
  restoreSelectedPlotVarsEpic,
};
