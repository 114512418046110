import React from 'react';
import {
  DPCalendar,
  DPDay,
  useContextCalendars,
  useContextDaysPropGetters,
} from '@rehookify/datepicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useStyles from '../../styles/datePicker';
import { CalendarPickerType } from './DateRangeContainer';

interface CalendarProps {
  prevButton?: JSX.Element;
  nextButton?: JSX.Element;
  calendar: DPCalendar;
  setPickerType: (value: CalendarPickerType) => void;
}

function Calendar({ prevButton, nextButton, calendar, setPickerType }: CalendarProps): JSX.Element {
  const classes = useStyles();
  const { weekDays } = useContextCalendars();
  const { dayButton } = useContextDaysPropGetters();
  const { days, month } = calendar;

  const getDayBtnClasses = (d: DPDay) => {
    const { selected, disabled, inCurrentMonth, now, range } = d;
    const allClasses = [classes.textStyle];
    if (!inCurrentMonth) allClasses.push(classes.invalidDate);
    else if (selected) allClasses.push(classes.selectedDate);
    else if (now) allClasses.push(classes.today);
    else if (range === 'in-range') allClasses.push(classes.inRange);
    else if (range === 'will-be-in-range') allClasses.push(classes.willBeInRange);
    else if (disabled) allClasses.push(classes.disabledDate);
    return allClasses.join(' ');
  };

  return (
    <Box sx={{ width: '14rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {prevButton || <div />}
        <Button
          className={classes.textStyle}
          onClick={() => setPickerType(CalendarPickerType.month)}
          sx={{ marginRight: prevButton ? '30px' : 0 }}
        >
          {month}
        </Button>

        {nextButton || <div />}
      </Box>
      <div className={classes.weekDaysContainer}>
        {weekDays.map((d) => (
          <p key={d} className={classes.textStyle}>
            {d}
          </p>
        ))}
      </div>
      <main className={classes.daysCol}>
        {days.map((d) => (
          <Button
            key={d.$date.toDateString()}
            style={{ minWidth: '20px' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dayButton(d)}
            className={getDayBtnClasses(d)}
            disabled={!d.inCurrentMonth}
          >
            {d.day}
          </Button>
        ))}
      </main>
    </Box>
  );
}

Calendar.defaultProps = {
  prevButton: undefined,
  nextButton: undefined,
};

export default Calendar;
