import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { setCurrentLocation } from '../../state/actions';
import { getCurrentLocation, getLocationsById } from '../../state/selectors';
import useStyles from '../../styles';

interface LocationIdentifierProps {
  location: string;
}

function LocationIdentifier({ location }: LocationIdentifierProps): JSX.Element | null {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentLocationId = useSelector(getCurrentLocation);
  const locationsById = useSelector(getLocationsById);
  const locationName = locationsById.get(location)?.name ?? location;
  const [hasMissingChildren, setHasMissingChildren] = useState<boolean>();
  const [parentLocId, setParentLocId] = useState('');

  useEffect(() => {
    const sensorLocPath = location.slice(1).split('#');
    const currentLocPath = currentLocationId.slice(1).split('#');
    // take next location from current location as parent location
    const parentLocPath = sensorLocPath.filter((path) => !currentLocPath.includes(path));
    if (parentLocPath.length > 1) {
      setParentLocId(`${currentLocationId}#${parentLocPath[0]}`);
    }
    setHasMissingChildren(parentLocPath.length > 2);
  }, [currentLocationId, locationsById, location]);

  const onLocationClick = (locationId: string | undefined) => {
    if (locationId) dispatch(setCurrentLocation(locationId));
  };

  // if we are in the location of the sensor we don't need a shortcut to go to it's location
  if (currentLocationId === location) return null;

  return (
    <>
      {parentLocId && parentLocId !== currentLocationId && (
        <Box style={{ display: 'flex' }}>
          <Button
            startIcon={<LocationOnIcon />}
            onClick={() => onLocationClick(parentLocId)}
            onKeyDown={() => onLocationClick(parentLocId)}
            className={classes.baseSummaryLocationBtn}
          >
            {locationsById.get(parentLocId)?.name}
          </Button>
          <ArrowRightIcon style={{ alignSelf: 'center' }} fontSize="small" />
        </Box>
      )}
      {hasMissingChildren && (
        <>
          ...
          <ArrowRightIcon style={{ alignSelf: 'center' }} fontSize="small" />
        </>
      )}
      <Button
        startIcon={!parentLocId && <LocationOnIcon />}
        onClick={() => onLocationClick(location)}
        onKeyDown={() => onLocationClick(location)}
        className={classes.baseSummaryLocationBtn}
      >
        {locationName}
      </Button>
    </>
  );
}

export default LocationIdentifier;
