import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';
import { reportColors } from '../Widgets/Report/constants';

const useStyles = makeStyles((theme: Theme) => ({
  report: {
    background: theme.palette.primary.main,
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  main: {
    width: '80vw',
    margin: '0 auto',
    maxWidth: '800px',
    padding: '70px 0 2rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      margin: '0',
      padding: '70px 1rem 2rem 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
    },
    '& aside': {
      fontSize: themeProps.textSize.h6,
    },
  },

  reportContainer: {
    '@media print': {
      width: '210mm',
    },
  },

  header: {
    background: theme.palette.primary.dark,
    flex: '0 0 auto',
  },
  headerButton: {
    color: theme.palette.text.primary,
    marginLeft: '0.5rem',
    transition: 'all 0.3s',
    '&:hover': {
      background: theme.palette.text.primary,
      color: theme.palette.primary.main,
    },
    'headerButton > &': {
      borderBottom: '2px solid #fff',
    },
  },
  summaryTable: {
    '& td': {
      padding: '0.25rem 0.5rem',
    },
  },
  summaryTableBandCell: {
    minWidth: '10ch',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
  },
  textField: {
    width: '100%',
    paddingTop: '20px',
  },
  formContainer: {
    marginTop: '30px',
    justifyContent: 'center',
  },
  bubbleTextArea: {
    position: 'absolute',
    background: '#ccf0ec00',
    border: 'none',
    color: themeProps.colors.grey,
    fontSize: '10px',
    lineHeight: 1.5,
    top: '35px',
    left: '25px',
    height: '150px',
    width: '150px',
  },

  reportContent: {
    padding: 0,
    color: themeProps.colors.grey,
    textAlign: 'left',
    margin: 'auto',
    width: '60%',
    background: '#fff',

    '& h3': {
      color: reportColors.blue,
      marginTop: '2rem',
      textAlign: 'center',
      fontWeight: themeProps.fontWeight.bold,
    },
    '& h4': {
      color: reportColors.blue,
      marginTop: '10px',
      fontWeight: themeProps.fontWeight.bold,
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        textAlign: 'center',
      },
    },
    '@media print': {
      overflow: 'hidden',
      width: '210mm',
    },
  },
  reportHeader: {
    display: 'none',
    justifyContent: 'space-between',
    height: '80px',
    width: '210mm',
    backgroundColor: '#cdf1ec',
    '@media print': {
      display: 'flex',
      position: 'fixed',
      top: 0,
      left: 0,
    },
  },
  page: {
    padding: '20px 50px 0 50px',
    marginTop: '50px',
    pageBreakAfter: 'always',
    [theme.breakpoints.down('lg')]: {
      marginTop: '80px',
      padding: 0,
    },
    '@media print': {
      padding: '20px 50px 0 50px',
      marginTop: '50px',
    },
  },
  startingPage: {
    padding: '0 50px',
    marginTop: '0 !important',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  headerText: {
    color: reportColors.lightGrey,
    position: 'absolute',
    top: '10px',
    left: '10px',
    textAlign: 'left',
  },
  reportFooter: {
    display: 'none',
    justifyContent: 'space-between',
    backgroundColor: '#cdf1ec',
    width: '210mm',
    height: '70px',
    '@media print': {
      display: 'flex',
      position: 'fixed',
      top: '280mm',
      left: 0,
    },
  },
  printBtn: {
    display: 'flex',
    justifyContent: 'end',
    '@media print': {
      display: 'none',
    },
  },
  pieLegendImg: {
    width: '25%',
    padding: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
  portalLinkImg: {
    width: '25%',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      marginTop: '10px',
    },
  },
}));

export default useStyles;
