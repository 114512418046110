import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { getActiveMarker } from '../../../state/selectors';
import {
  BandParamsType,
  getDataBandParams,
  varNameBandParams,
} from '../../../utils/dataBandParams';
import { themeProps } from '../../../styles/theme';
import { varNameDetails } from '../../../utils/varNames';

interface FeedbackProps {
  sensorValue: number;
  reset: () => void;
}

function Feedback({ sensorValue, reset }: FeedbackProps) {
  const theme = useTheme();
  const activeMarker = useSelector(getActiveMarker);
  const bands = varNameBandParams[activeMarker];
  const { label, color } = getDataBandParams(activeMarker, sensorValue);
  const [selectedBand, setSelectedBand] = useState<BandParamsType>();

  return (
    <>
      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        It is currently {sensorValue} {varNameDetails[activeMarker].metric} which is meant to be{' '}
        <span style={{ color }}>{label}</span>
      </Typography>
      <hr style={{ width: '50%', marginTop: '1rem' }} />
      <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        How are you feeling today?
      </Typography>
      <Box sx={{ marginTop: '2rem' }}>
        {bands &&
          bands.map((band) => (
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                marginTop: '1rem',
                borderRadius: '20px',
                background:
                  band === selectedBand ? themeProps.colors.green : theme.palette.primary.light,
                border: 'none',
                width: '100%',
                padding: '10px 0',
              }}
              onClick={() => setSelectedBand(band)}
            >
              {band.label}
            </Button>
          ))}

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.happyGreen,
              border: 'none',
              padding: '15px',
            }}
          >
            Submit
          </Button>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={reset}
          >
            Back
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Feedback;
