/* eslint-disable react/destructuring-assignment */
import React, { useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  BasicSensorType,
  GatewaySensorType,
  SensorLatest,
  SubSensorType,
} from '../../../services/api';
import {
  sensorProperties,
  SensorPropsType,
  sensorTypeDetails,
  SensorItem,
} from '../../../utils/sensorProperties';
import useStyles from '../../../styles';
import SensorInfoDialog from '../../Dialog/SensorInfoDialog';
import { getShortTimeStr } from '../../../utils/functions';
import { AlertMsg } from '../../LocationConfig/LocationProperties';
import { updateSensorProperties } from '../../../services/apiService';
import { setSensorsById } from '../../../state/actions';
import EditBtnIcon from '../../HelperComponents/EditBtnIcon';

const advancedDetailsItems = [
  'shortName',
  'type',
  'port',
  'timeZone',
  'hwVersion',
  'fwVersion',
  'latest',
];

interface AdvancedSensordetailsProps {
  sensorDetails: SensorLatest;
}

function AdvancedSensordetails({ sensorDetails }: AdvancedSensordetailsProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [showSensorInfo, setShowSensorInfo] = useState<boolean>(false);
  const [sensorItem, setSensorItem] = useState<SensorItem>();
  const [sensor, setSensor] = useState<SensorLatest>(sensorDetails);

  const metadata = sensor?.metadata as { [unit: string]: number };

  const handleUpdate = () => {
    setIsEditing(false);
    if (sensorItem) {
      updateSensorProperties(sensorDetails.id, sensorItem)
        .then((response) => {
          const mergedData = { ...sensorDetails, ...response };
          if (response?.id) {
            setAlertMsg({ success: true, msg: 'Update successful', alertType: 'success' });
            setSensor(mergedData);
            dispatch(setSensorsById([mergedData]));
            setSensorItem(undefined);
          } else {
            setAlertMsg({
              success: false,
              msg: response?.type,
              alertType: 'error',
            });
          }
        })
        .catch(() => setAlertMsg({ success: false, msg: 'Update Error', alertType: 'error' }));
    }
  };

  const handleEdit = (editing: boolean) => {
    setSensorItem(undefined);
    setIsEditing(editing);
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    const { value } = e.target;
    const item = { ...sensorItem };
    item[key] = value;
    setSensorItem(item);
  };

  return (
    <>
      {sensorDetails?.type && (
        <SensorInfoDialog
          sensorType={sensorDetails?.type}
          showSensorInfo={showSensorInfo}
          closeDialog={() => setShowSensorInfo(false)}
        />
      )}
      <ListItem>
        <Accordion style={{ width: '100%' }} expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <SettingsIcon className={classes.icon} />
                <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>
                  Advanced Details
                </Typography>
              </div>
              {expanded && (
                <EditBtnIcon
                  isEditing={isEditing}
                  handleEdit={handleEdit}
                  changedItem={sensorItem}
                  handleUpdate={handleUpdate}
                />
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {alertMsg?.msg && (
                <Alert
                  severity={alertMsg.alertType ?? 'info'}
                  onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
                  className={classes.alertMsg}
                >
                  {alertMsg?.msg}
                </Alert>
              )}
              <List style={{ width: '100%' }}>
                {sensor &&
                  Object.entries(sensor)
                    .filter(([sensorProps]) => advancedDetailsItems.includes(sensorProps))
                    .map((sensorProps) => {
                      const data = sensorProperties[sensorProps[0] as SensorPropsType];
                      const isSensorTypeProps =
                        data?.label === sensorProperties[SensorPropsType.type].label;
                      const sensorBrandName =
                        isSensorTypeProps &&
                        sensorTypeDetails[
                          sensorProps[1] as SubSensorType | GatewaySensorType | BasicSensorType
                        ].brandName;
                      return (
                        <ListItem key={sensorProps[0]}>
                          <Grid item sm={6} xs={6}>
                            <ListItemText primary={data?.label} />
                          </Grid>
                          <Grid item sm={6} xs={6}>
                            {data?.editable && isEditing ? (
                              <TextField
                                type="text"
                                defaultValue={
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (sensor as { [sensorProps: string]: any })[sensorProps[0]] ?? ''
                                }
                                onChange={(e) => onChange(e, sensorProps[0])}
                                style={{ width: '100%' }}
                                variant="standard"
                              />
                            ) : (
                              <ListItemText
                                primary={
                                  isSensorTypeProps ? (
                                    <Box
                                      onClick={() => setShowSensorInfo(!!isSensorTypeProps)}
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                    >
                                      {sensorBrandName}
                                    </Box>
                                  ) : (
                                    sensorProps[1]
                                  )
                                }
                              />
                            )}
                            <p className={classes.updatedTime}>
                              {metadata &&
                                metadata[sensorProps[0]] &&
                                getShortTimeStr(metadata[sensorProps[0]])}
                            </p>
                          </Grid>
                        </ListItem>
                      );
                    })}
              </List>
            </>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </>
  );
}

export default AdvancedSensordetails;
