import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Typography from '@mui/material/Typography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CalendarViewSensorArray from './CalendarViewSensorArray';
import {
  getActiveMarker,
  getCurrentLocation,
  getHighlightedItem,
  getLocationsById,
  getSelectedEndDate,
  getSelectedHours,
  getSelectedSensorIds,
  getSelectedStartDate,
  getSensorsById,
} from '../../state/selectors';
import { VarName } from '../../services/api';
import {
  ValueType,
  HeatmapHoverSelection,
  CalendarSelectionType,
  SensorFilteredStats,
  SensorAllStats,
  PlotType,
} from './helpers';
import { ActiveItem } from '../../state/types';
import { setSelectedSensors } from '../../state/actions';
import CalendarViewPlot from '../Plots/HeatMap/CalendarViewPlot';
import WeekViewPlot from '../Plots/HeatMap/WeekViewPlot';
import { varNameDetails } from '../../utils/varNames';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';
import { combineStats, Stats } from '../../utils/statistics';
import ActiveMarkerSelection from '../HelperComponents/ActiveMarkerSelection';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

interface CalendarViewDataContainerProps {
  sensorStats: Map<string, SensorAllStats>;
  sensorFilteredStats: Map<string, SensorFilteredStats>;
  heatmapHoverSelection: HeatmapHoverSelection | undefined;
  setHeatmapHoverSelection: (value: HeatmapHoverSelection | undefined) => void;
  selectionType: CalendarSelectionType;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  loading: boolean;
}

function CalendarViewDataContainer({
  sensorStats,
  sensorFilteredStats,
  heatmapHoverSelection,
  setHeatmapHoverSelection,
  selectionType,
  downloadData,
  setDownloadData,
  loading,
}: CalendarViewDataContainerProps): JSX.Element {
  const dispatch = useDispatch();
  const theme = useTheme();
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const selectedSensors = useSelector(getSelectedSensorIds);
  const [hoveredSensor, setHoveredSensor] = useState<ActiveItem>();
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const hoursSelection = useSelector(getSelectedHours);

  const sensorIdsWithData = Array.from(sensorStats.keys());

  useEffect(() => {
    setHoveredSensor(undefined);
    setHeatmapHoverSelection(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation]);

  // selectedSensors that we actually have data for
  const addedSensors = useMemo(() => {
    const activeSensors = selectedSensors.filter((selectedId) =>
      sensorIdsWithData.some((id) => id === selectedId)
    );
    return activeSensors;
  }, [sensorIdsWithData, selectedSensors]);

  useEffect(() => {
    if (highlightedItem.id && highlightedItem.varName)
      setHoveredSensor({ id: highlightedItem.id, varName: highlightedItem.varName });
    else setHoveredSensor(undefined);
  }, [highlightedItem]);

  const heatmapSensorIds = useMemo(() => {
    let sensors: string[] = [];
    if (addedSensors.length > 0) sensors = addedSensors;
    else if (hoveredSensor) sensors = [hoveredSensor.id];
    return sensors;
  }, [hoveredSensor, addedSensors]);

  const highlightedItemStats = sensorStats.get(highlightedItem.id ?? 'NONE')?.stats ?? undefined;
  const selectedSensorStats = useMemo(() => {
    const statsArray: Stats[] = [];
    let output: Stats = {
      bandTimes: new Map<string, number>(),
      totalTime: 0,
      maxMinAvg: { max: NaN, min: NaN, mean: NaN },
    };
    if (selectedSensors.length > 0) {
      selectedSensors.forEach((id) => {
        const s = sensorStats.get(id)?.stats;
        if (s !== undefined) statsArray.push(s);
      });
      output = combineStats(statsArray);
    }
    return output;
  }, [selectedSensors, sensorStats]);
  const totalSensorStats = useMemo(() => {
    let output: Stats = {
      bandTimes: new Map<string, number>(),
      totalTime: 0,
      maxMinAvg: { max: NaN, min: NaN, mean: NaN },
    };
    if (sensorStats.size > 0) {
      const statsArray = Array.from(sensorStats.values(), (value) => value.stats);
      output = combineStats(statsArray);
    }
    return output;
  }, [sensorStats]);

  let combinedSensorStats: Stats = totalSensorStats;
  if (addedSensors.length > 0) combinedSensorStats = selectedSensorStats;
  else if (highlightedItemStats !== undefined) combinedSensorStats = highlightedItemStats;

  const sensorSelectionLabel = useMemo(() => {
    let label = `${locationsById.get(currentLocation)?.name ?? ''} (${sensorStats.size} ${
      varNameDetails[activeMarker].label
    } sensors)`;
    if (heatmapSensorIds.length > 0) {
      if (heatmapSensorIds.length === 1)
        label = `${sensorsById.get(heatmapSensorIds[0])?.name ?? heatmapSensorIds[0]} (${
          varNameDetails[activeMarker].label
        })`;
      else
        label = `${heatmapSensorIds.length} selected ${varNameDetails[activeMarker].label} sensors`;
    }
    return label;
  }, [
    locationsById,
    currentLocation,
    heatmapSensorIds,
    sensorsById,
    activeMarker,
    sensorStats.size,
  ]);

  const clearSensorHandler = () => {
    dispatch(setSelectedSensors([]));
  };

  // XXX TODO Do we need the utl type? Could just be static?
  const valueType = activeMarker === VarName.MotionEvent ? ValueType.utl : ValueType.avg;

  const hasTotValue = Array.from(sensorStats.values()).some(
    (s) => s.stats.totalAdded !== undefined
  );

  const valuesLabel = useMemo(() => {
    const varNameLabel = varNameDetails[activeMarker].label;
    let label = `${varNameLabel} ${hasTotValue ? 'totals' : 'averages'} `;

    const selectedHoursStr = `${hoursSelection.startHour
      .toFixed()
      .padStart(2, '0')}:00-${hoursSelection.endHour.toFixed().padStart(2, '0')}:00`;
    const selectedHoursOnlyLabel = hoursSelection.selectHours ? ` (${selectedHoursStr})` : '';
    const selectedDaysStr =
      !hoursSelection.selectHours || hoursSelection.includeWeekends ? '' : ' Mon-Fri';
    const selectedDaysOnlyLabel =
      hoursSelection.selectHours && !hoursSelection.includeWeekends
        ? ` (${selectedDaysStr.trim()})`
        : '';
    const hoursSelectionLabel = hoursSelection.selectHours
      ? ` (${selectedHoursStr}${selectedDaysStr})`
      : '';

    if (heatmapHoverSelection) {
      const { date, weekWithStart, dayOfWeek, hour, summaryType } = heatmapHoverSelection;
      const hasHourValue = hour !== undefined;
      const hourLabel = hasHourValue
        ? `${hour.toFixed().padStart(2, '0')}:00-${(hour + 1).toFixed().padStart(2, '0')}:00`
        : '';
      if (summaryType) {
        if (summaryType === PlotType.min) label = `${varNameLabel} minimum values `;
        else if (summaryType === PlotType.max) label = `${varNameLabel} maximum values `;
        else if (summaryType === PlotType.avg) label = `${varNameLabel} averages `;
        else label = `${varNameLabel} ${summaryType}s `;
      }
      if (date) {
        return label
          .concat('for ')
          .concat(dayjs(date).format('MMM DD YYYY'))
          .concat(selectedHoursOnlyLabel); // includeWeekends not relevant
      }
      if (weekWithStart) {
        return label
          .concat('for week starting ')
          .concat(dayjs(date).format('MMM DD YYYY'))
          .concat(hoursSelectionLabel);
      }
      if (dayOfWeek !== undefined) {
        label = label
          .concat('for all ')
          .concat(dayjs(new Date()).day(dayOfWeek).format('dddd'))
          .concat('s ')
          .concat(hourLabel);
        if (!hasHourValue) label = label.concat(selectedHoursOnlyLabel); // includeWeekends not relevant
        return label;
      }
      if (hour !== undefined) {
        return label.concat(`${hourLabel} ${selectedDaysOnlyLabel}`);
      }
    }
    label = label.concat(
      `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format(
        'MMM DD YYYY'
      )}${hoursSelectionLabel}`
    );

    return label;
  }, [hasTotValue, heatmapHoverSelection, startDate, endDate, hoursSelection, activeMarker]);

  const getValuesLabelWithValue = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const plotLabel = parseFloat(heatmapHoverSelection?.hoverValue).toFixed(1);
    const metric = varNameDetails[activeMarker].metric ?? '';
    const label = heatmapHoverSelection ? `${valuesLabel} [${plotLabel}${metric}]` : valuesLabel;
    return label;
  };

  const valuesRange: [number, number] | undefined =
    totalSensorStats?.totalAdded !== undefined && totalSensorStats.values
      ? [0, Math.max(...totalSensorStats.values.totVals)]
      : [totalSensorStats.maxMinAvg.min, totalSensorStats.maxMinAvg.max];

  const summaryType = useMemo(() => {
    let summary = PlotType.avg;
    if (heatmapHoverSelection?.summaryType) summary = heatmapHoverSelection.summaryType;
    else if (hasTotValue) summary = PlotType.tot;
    return summary;
  }, [hasTotValue, heatmapHoverSelection?.summaryType]);

  return (
    <>
      <Box
        sx={{
          background: theme.palette.primary.main,
          marginTop: '10px',
          borderRadius: '8px',
          paddingBottom: '10px',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 20px 10px' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '10px',
            }}
          >
            <Typography variant="h6">{sensorSelectionLabel}</Typography>
            {addedSensors.length > 0 && (
              <IconButton
                onClick={() => clearSensorHandler()}
                style={{ padding: '0', marginLeft: '10px' }}
              >
                <Tooltip title="Remove all locked sensors">
                  <RemoveCircleIcon color="error" />
                </Tooltip>
              </IconButton>
            )}
          </Box>
          <ActiveMarkerSelection />
        </Box>

        <Box
          sx={{
            position: 'relative',
          }}
        >
          {loading && <LoaderWithBackdrop />}
          {selectionType === CalendarSelectionType.week ? (
            <WeekViewPlot
              combinedSensorStats={combinedSensorStats}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHeatmapHoverSelection}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sensorSelectionLabel.replaceAll(' ', '-')}
            />
          ) : (
            <CalendarViewPlot
              combinedSensorStats={combinedSensorStats}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHeatmapHoverSelection}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sensorSelectionLabel.replaceAll(' ', '-')}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.primary.main,
          padding: '10px 0',
          marginTop: '10px',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h6" style={{ margin: '10px 20px ', textAlign: 'left' }}>
          {getValuesLabelWithValue()}
        </Typography>

        {sensorFilteredStats.size === 0 && (
          <Alert severity="error" style={{ margin: '1rem 0' }}>
            No sensor data found
          </Alert>
        )}
        <div>
          <CalendarViewSensorArray
            sensorIds={sensorIdsWithData}
            sensorFilteredStats={sensorFilteredStats}
            valuesRange={valuesRange}
            summaryType={summaryType}
          />
        </div>
      </Box>
    </>
  );
}

export default CalendarViewDataContainer;
