import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function Co2Icon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 61 53" {...props}>
      <path d="M21.8097 33.2007C23.217 33.2007 24.3915 33.6296 25.3553 34.4708C25.7195 34.8506 26.0837 34.9301 26.3242 34.9301C26.6696 34.9301 26.9818 34.7896 27.2232 34.5262C27.4697 34.2609 27.5993 33.9226 27.5993 33.5474C27.5993 33.1656 27.4697 32.8236 27.1754 32.513C25.618 31.1284 23.8132 30.4259 21.8097 30.4259C19.4847 30.4259 17.4693 31.3318 15.8206 33.1203C14.1762 34.9015 13.3438 37.082 13.3438 39.6007C13.3438 40.8402 13.5621 42.0243 13.992 43.1178C14.4184 44.2001 15.03 45.1975 15.8317 46.1033C16.6462 46.9481 17.5657 47.6109 18.5644 48.073C19.5726 48.5389 20.6643 48.7755 21.8106 48.7755C22.7931 48.7755 23.7433 48.5999 24.6312 48.2523C25.5139 47.9067 26.3558 47.3992 27.1345 46.7429L27.2129 46.6662C27.4296 46.4167 27.5439 46.0913 27.5439 45.7271C27.5439 45.3454 27.4142 45.0043 27.1694 44.7409C26.731 44.2658 26.0496 44.1752 25.5063 44.5967C24.4401 45.5413 23.2324 46.0007 21.8123 46.0007C20.1628 46.0007 18.7964 45.3879 17.6339 44.129C16.4731 42.871 15.9085 41.3902 15.9085 39.6007C15.9085 37.8196 16.4731 36.3416 17.6339 35.0836C18.7998 33.8163 20.167 33.2007 21.8097 33.2007Z" />
      <path d="M27.5994 39.6026C27.5994 40.844 27.8293 42.0272 28.2812 43.1178C28.7286 44.1992 29.3727 45.1974 30.2171 46.1049C31.0741 46.9494 32.0416 47.6118 33.0936 48.0743C34.1536 48.5396 35.3026 48.7755 36.5082 48.7755C38.9615 48.7755 41.0798 47.8727 42.801 46.0928C44.5384 44.3038 45.4188 42.1206 45.4188 39.6017C45.4188 37.0818 44.5384 34.9014 42.8046 33.1243C41.0888 31.3334 38.9696 30.4259 36.5082 30.4259C34.062 30.4259 31.9419 31.3325 30.2054 33.1206C28.4752 34.9032 27.5994 37.0836 27.5994 39.6026ZM36.5082 33.2048C38.2303 33.2048 39.6649 33.8209 40.8966 35.0873C42.1246 36.3473 42.722 37.8246 42.722 39.6035C42.722 41.3889 42.1246 42.869 40.8957 44.1298C39.673 45.3889 38.2375 46.0012 36.5082 46.0012C34.778 46.0012 33.3406 45.3889 32.1117 44.1298C30.89 42.8708 30.2953 41.3907 30.2953 39.6035C30.2953 37.8237 30.89 36.3463 32.1099 35.0892C33.3532 33.8209 34.7924 33.2048 36.5082 33.2048Z" />
      <path d="M50.9098 44.1014L45.8221 48.5877C45.5569 48.8245 45.4185 49.1099 45.4185 49.4121C45.4185 49.739 45.5766 50.0435 45.8613 50.2668C46.1402 50.4884 46.5095 50.6104 46.9023 50.6104H52.8532C53.248 50.6104 53.6192 50.486 53.8971 50.2604C54.175 50.034 54.3282 49.7334 54.3282 49.4121C54.3282 49.0932 54.1769 48.7926 53.8951 48.5614C53.6172 48.3373 53.247 48.2138 52.8522 48.2138H50.2627L53.0123 45.7868C53.8853 45.0828 54.3282 44.2178 54.3282 43.2172C54.3282 42.2205 53.8882 41.3595 53.026 40.665C52.1668 39.9586 51.1062 39.6007 49.8738 39.6007C48.6601 39.6007 47.6005 39.9563 46.7127 40.6658C45.8535 41.3738 45.4185 42.2325 45.4185 43.218C45.4185 43.5456 45.5775 43.851 45.8613 44.0727C46.424 44.5207 47.405 44.5096 47.9442 44.0679C48.224 43.8422 48.3772 43.5401 48.3772 43.218C48.3772 42.8743 48.5167 42.6001 48.8034 42.3625C49.4132 41.8737 50.3344 41.8697 50.9344 42.3625C51.2358 42.604 51.3753 42.8759 51.3753 43.2188C51.3753 43.3838 51.339 43.5321 51.2633 43.6764C51.1848 43.8279 51.0738 43.9626 50.9098 44.1014Z" />
      <path d="M43.9258 16.6849C44.257 16.6842 44.579 16.5768 44.8434 16.3787C45.1078 16.1807 45.3004 15.9027 45.3922 15.5866C45.7258 14.4334 45.8275 13.2263 45.6915 12.034C45.5556 10.8418 45.1846 9.68788 44.5998 8.6381C43.4188 6.51798 41.4384 4.95086 39.0944 4.28146C36.7504 3.61207 34.2348 3.89525 32.101 5.06871C29.9671 6.24216 28.3898 8.20977 27.7161 10.5387C27.6523 10.7328 27.6286 10.9377 27.6463 11.1411C27.664 11.3446 27.7228 11.5424 27.8192 11.7227C27.9156 11.9031 28.0476 12.0623 28.2073 12.1908C28.367 12.3194 28.5511 12.4146 28.7487 12.4708C28.9462 12.5271 29.1532 12.5432 29.3572 12.5182C29.5612 12.4932 29.758 12.4276 29.9359 12.3253C30.1137 12.2231 30.2691 12.0862 30.3925 11.923C30.516 11.7598 30.6051 11.5735 30.6545 11.3752C31.1768 9.90779 32.244 8.6942 33.6375 7.98293C35.031 7.27165 36.6454 7.11648 38.1502 7.54918C39.6551 7.98188 40.9366 8.96973 41.7324 10.3105C42.5282 11.6513 42.7781 13.2435 42.4309 14.7614C42.3665 14.9887 42.3566 15.2279 42.4018 15.4597C42.4471 15.6916 42.5463 15.9097 42.6916 16.0966C42.8369 16.2835 43.0242 16.4341 43.2385 16.5362C43.4528 16.6384 43.6882 16.6893 43.9258 16.6849Z" />
      <path d="M13.1367 22.5739C13.3846 22.5759 13.6288 22.5091 13.8455 22.3799C14.0126 22.2784 14.1595 22.1427 14.2776 21.9804C14.3957 21.8182 14.4828 21.6326 14.5338 21.4343C14.5848 21.236 14.5988 21.0288 14.575 20.8247C14.5512 20.6206 14.49 20.4236 14.3949 20.2448C13.4178 18.3448 13.1859 16.1071 13.75 14.0241C14.3142 11.941 15.6283 10.1831 17.4032 9.13718C19.1782 8.09125 21.2685 7.84293 23.2145 8.44684C25.1605 9.05075 26.8027 10.4574 27.7798 12.3574C27.9765 12.7034 28.2905 12.9547 28.6563 13.0587C29.022 13.1627 29.4111 13.1114 29.7424 12.9155C30.0736 12.7197 30.3213 12.3943 30.4337 12.0075C30.5462 11.6208 30.5146 11.2024 30.3457 10.8399C29.6818 9.52498 28.7809 8.36395 27.6953 7.42394C26.6096 6.48394 25.3606 5.78361 24.0206 5.36344C22.6805 4.94328 21.276 4.81161 19.8881 4.97605C18.5002 5.14048 17.1565 5.59777 15.9348 6.32146C14.713 7.04516 13.6373 8.02091 12.77 9.19231C11.9026 10.3637 11.2608 11.7075 10.8815 13.1461C10.5023 14.5846 10.3932 16.0894 10.5605 17.5733C10.7278 19.0573 11.1683 20.491 11.8564 21.7917C11.9855 22.0322 12.1722 22.2315 12.3972 22.3691C12.6223 22.5066 12.8775 22.5773 13.1367 22.5739Z" />
      <path d="M10.566 37.2962H10.6109C10.9912 37.2711 11.3482 37.0907 11.6093 36.7917C11.8703 36.4928 12.0156 36.0978 12.0156 35.6873C12.0156 35.2769 11.8703 34.8819 11.6093 34.5829C11.3482 34.284 10.9912 34.1035 10.6109 34.0784H10.5716C9.70539 34.0806 8.85031 33.8677 8.0728 33.4562C7.29528 33.0447 6.61625 32.4458 6.0884 31.7058C5.5627 30.9884 5.19894 30.1493 5.02583 29.2545C4.85272 28.3598 4.87498 27.4339 5.09088 26.5501C5.09088 26.5018 5.09088 26.4474 5.13011 26.3931C5.55745 24.797 6.55504 23.4487 7.90401 22.644C9.25299 21.8393 10.8432 21.6439 12.3257 22.1006C12.5181 22.1712 12.7218 22.1987 12.9244 22.1813C13.127 22.1639 13.3241 22.1021 13.5037 21.9997C13.6833 21.8973 13.8416 21.7563 13.9688 21.5856C14.096 21.4149 14.1894 21.2179 14.2434 21.0068C14.2973 20.7958 14.3107 20.575 14.2825 20.3582C14.2544 20.1414 14.1854 19.9331 14.0798 19.746C13.9742 19.559 13.8343 19.3972 13.6685 19.2705C13.5027 19.1439 13.3147 19.0551 13.1159 19.0096C10.8747 18.3235 8.4724 18.6218 6.43486 19.8392C4.39731 21.0567 2.89043 23.0941 2.24401 25.5056C2.24401 25.5841 2.20478 25.6626 2.18237 25.7411C1.85154 27.1018 1.8175 28.5266 2.08292 29.9038C2.34833 31.2811 2.906 32.5735 3.71228 33.68C4.52058 34.8088 5.55917 35.7221 6.74766 36.3492C7.93616 36.9763 9.24266 37.3003 10.566 37.2962Z" />
      <path d="M49.0822 37.2963C51.0537 37.2952 52.9801 36.6805 54.6155 35.5308C56.2509 34.381 57.5211 32.7484 58.2639 30.8412C59.0719 28.6924 59.2955 26.3521 58.9099 24.0789C58.5242 21.8057 57.5444 19.6881 56.0786 17.96C54.6128 16.2319 52.7181 14.9606 50.6039 14.2865C48.4896 13.6124 46.2381 13.5618 44.098 14.1402C43.8976 14.1818 43.7075 14.2658 43.5393 14.3869C43.3712 14.508 43.2284 14.6637 43.1199 14.8444C43.0113 15.0252 42.9393 15.2272 42.9082 15.438C42.877 15.6489 42.8874 15.8642 42.9388 16.0707C42.9901 16.2772 43.0813 16.4706 43.2068 16.6389C43.3322 16.8073 43.4893 16.9472 43.6683 17.05C43.8473 17.1527 44.0446 17.2162 44.248 17.2365C44.4513 17.2569 44.6565 17.2336 44.851 17.1682C46.4566 16.7326 48.1463 16.7693 49.7332 17.2745C51.32 17.7796 52.7421 18.7334 53.842 20.0303C54.942 21.3272 55.6769 22.9167 55.9654 24.6228C56.2539 26.3288 56.0848 28.085 55.4768 29.6969C54.9567 31.0212 54.0707 32.1537 52.9318 32.9501C51.793 33.7465 50.4528 34.1707 49.0822 34.1685H49.0541C48.6728 34.1929 48.3148 34.3683 48.053 34.6589C47.7913 34.9495 47.6456 35.3334 47.6456 35.7324C47.6456 36.1314 47.7913 36.5153 48.053 36.8059C48.3148 37.0965 48.6728 37.2719 49.0541 37.2963H49.0822Z" />
    </SvgIcon>
  );
}

export default Co2Icon;
