import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { getSelectedVars, getActivePlotVars } from '../../state/selectors';
import { varNameDetails } from '../../utils/varNames';
import { toggleActivePlotVar } from '../../state/actions';
import SourcesMenu from '../../components/SourcesMenu';
import SensorIcon from '../../styles/icons/SensorIcon';

function SensorDataPicker(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedVars = useSelector(getSelectedVars);
  const activePlotVars = useSelector(getActivePlotVars);

  return (
    <Box sx={{ display: 'flex', overflowX: 'scroll', marginTop: '10px' }}>
      <Box sx={{ margin: '0 5px' }}>
        <SourcesMenu iconOnlyBtn helpPosition="bottom" />
      </Box>
      {selectedVars.map((varName) => {
        const { id, icon } = varNameDetails[varName];
        const SourceIcon = icon ?? SensorIcon;

        return (
          <Box sx={{ margin: '0 5px' }} key={varName}>
            <Tooltip title={varNameDetails[varName].label}>
              <IconButton
                onClick={() => dispatch(toggleActivePlotVar(varName))}
                style={{
                  color:
                    activePlotVars.indexOf(id) !== -1
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  background:
                    activePlotVars.indexOf(id) !== -1
                      ? theme.palette.text.primary
                      : theme.palette.primary.main,
                }}
              >
                <SourceIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
}

export default SensorDataPicker;
