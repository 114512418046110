import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { VarName, varNameDetails } from '../../utils/varNames';
import {
  getPlotCommonLayout,
  SensorHistoryPlotItem,
  TimeRange,
  transformSimplePositiveDerivative,
} from './plotCommon';
import { hexToRgb } from '../Map/mapHelpers';
import { getShowStackedTraces, getShowPlotGrid, getShowPlotLegend } from '../../state/selectors';
import { MetricOptions } from '../../components/DateRangePicker';
import StackedPlot from './StackedPlot';

/*
 * Helper functions
 */

/* Allow different plotly styles for different varNames */
const getPlotType = (
  varName: VarName,
  showStackedTraces: boolean,
  color?: string,
  ghost?: boolean
): Partial<Plotly.Data> => {
  switch (varName) {
    case varNameDetails.clientsWiFi.id:
    case varNameDetails.clientsBLE.id:
    case varNameDetails.energyInkWh.id:
      return {
        type: 'scatter',
        line: { width: showStackedTraces ? 1 : 2, shape: 'hv', ...(ghost && { dash: 'dash' }) },
        fill: showStackedTraces ? 'tonexty' : 'tozeroy',
        fillpattern: { shape: ghost ? '/' : '' },
        ...(color && {
          fillcolor: `rgba(${hexToRgb(color, ghost ? 0.6 : 0.3).join(', ')})`,
        }),
      };
    default:
      return { type: 'scatter', line: { ...(ghost && { dash: 'dash' }) } };
  }
};

interface MinMax {
  max: number;
  min: number;
}

// Shapes the data for Plotly
const formatPlotData = (
  sensorHistoryItem: SensorHistoryPlotItem,
  yAxisIndex: number,
  showStackedTraces: boolean
): Plotly.Data => {
  const { sensorName, varName, color, ghost } = { ...sensorHistoryItem };
  let { history } = { ...sensorHistoryItem };
  let { label, metric } = { ...varNameDetails[varName] };
  // Transform energy meter history data to simple average power for easier viewing
  if (varName === VarName.EnergyInkWh) {
    label = 'Average Power';
    metric = 'kW';
    history = transformSimplePositiveDerivative(history);
  }
  const plotType = getPlotType(varName, showStackedTraces, color, ghost);
  const plotData = {
    ...plotType,
    x: history.time.map((i: number) => new Date(i * 1000)),
    y: history.value,
    name: `${sensorName} - ${label} ${metric || ''}`,
    hovertemplate: `%{y} ${metric || ''} - %{x} <br> ${sensorName} <extra></extra>`,
    yaxis: `y${yAxisIndex > 0 ? yAxisIndex + 1 : ''}`,
  } as Plotly.Data;
  // Indicate which data should be stacked
  if (showStackedTraces && varNameDetails[varName].stackable) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.stackgroup = sensorHistoryItem.ghost ? `stack-${varName}-ghost` : `stack-${varName}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.stackgaps = 'interpolate';
  }
  if (color) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.marker = { color };
  }
  return plotData;
};

/*
 * Plot Component
 */

export interface SensorHistoricDataPlotProps {
  dataItems: SensorHistoryPlotItem[];
  unselectedTimes?: TimeRange[];
}

export function StackedHistoryPlot({
  dataItems,
  unselectedTimes,
}: SensorHistoricDataPlotProps): JSX.Element {
  const theme = useTheme();
  const showStackedTraces = useSelector(getShowStackedTraces);
  const showPlotGrid = useSelector(getShowPlotGrid);
  const showPlotLegend = useSelector(getShowPlotLegend);
  // Make a set of the unique varNames we have
  const varNames = dataItems.reduce((acc, dataItem) => {
    let out = acc;
    if (!acc.includes(dataItem.varName)) {
      out = [...acc, dataItem.varName];
    }
    return out;
  }, [] as VarName[]);

  const varNameMinMax = new Map<VarName, MinMax>();
  // Format the data
  const plotData = dataItems.reduce((previous, dataItem) => {
    let next = previous;
    const varIdx = varNames.indexOf(dataItem.varName);
    // Only add if the varName is in the allowed list
    if (varIdx > -1) {
      const plotDataItem = formatPlotData(dataItem, varIdx, showStackedTraces);
      next = [...previous, plotDataItem];
    }
    const max = Math.max(...dataItem.history.value);
    const min = Math.min(...dataItem.history.value);
    const varMinMax = varNameMinMax.get(dataItem.varName);
    if (varMinMax) {
      if (max > varMinMax.max || min < varMinMax.min) {
        varNameMinMax.set(dataItem.varName, {
          min: Math.min(min, varMinMax.min),
          max: Math.max(max, varMinMax.max),
        });
      }
    } else {
      varNameMinMax.set(dataItem.varName, {
        min,
        max,
      });
    }
    return next;
  }, [] as Plotly.Data[]);

  // Make the layout from the varNames and data ranges
  const layout = getPlotCommonLayout(
    varNames,
    theme,
    unselectedTimes,
    showStackedTraces,
    MetricOptions.Raw,
    varNameMinMax,
    showPlotGrid,
    showPlotLegend
  );

  return <StackedPlot plotData={plotData} layout={layout} varNames={varNames} />;
}

StackedHistoryPlot.defaultProps = {
  unselectedTimes: undefined,
};

export default StackedHistoryPlot;
