/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Position as DeckGlPosition } from '@deck.gl/core';
import { PolygonLayer, PolygonLayerProps } from '@deck.gl/layers';
import intersect from '@turf/intersect';
import { GeoJSON } from '../../../services/api';
import { VarName } from '../../../utils/varNames';
import { getMarkerColour, getCirclePolygon, PolygonMarker, MarkerDataProps } from '../mapHelpers';

const getSensorCoverageLayer = (
  activeVarName: VarName,
  activeMarkers: MarkerDataProps[],
  floorPlan: GeoJSON | undefined
): PolygonLayer<unknown, PolygonLayerProps<unknown>> | null => {
  const sensorPolygons = new Map<string, PolygonMarker>();

  if (activeMarkers) {
    activeMarkers?.forEach((marker) => {
      const { id, name, value, varName, time, position } = marker;
      const circleData = getCirclePolygon(activeMarkers, marker);
      const sensorPolygon = floorPlan?.features.find(
        (polygonInfo) =>
          position?.polygon !== undefined &&
          polygonInfo.properties.id === parseInt(position?.polygon, 10)
      );

      const showPerimeterOutsideWall =
        activeVarName === VarName.ClientsWiFi || activeVarName === VarName.ClientsBle;
      let intersectData;
      if (circleData) {
        // show perimeter accross walls only for wifi and BLE sensors
        if (showPerimeterOutsideWall) {
          intersectData = circleData;
        } else if (sensorPolygon && circleData) {
          // for others, show perimeter intersected within the polygon
          intersectData = intersect(circleData, sensorPolygon);
        }
      }
      if (intersectData) {
        const sensorPolygonMarkerData: PolygonMarker = {
          id,
          contours: intersectData?.geometry.coordinates[0] as DeckGlPosition[],
          name,
          value,
          varName,
          time,
        };
        sensorPolygons.set(id, sensorPolygonMarkerData as PolygonMarker);
      }
    });
  }

  const newSensorPolygons = Array.from(sensorPolygons.values()).filter((d: PolygonMarker) => {
    if (activeVarName === d.varName) {
      return true;
    }
    return false;
  });

  return new PolygonLayer({
    id: 'polygon-layer',
    data: newSensorPolygons,
    stroked: true,
    filled: true,
    extruded: false,
    wireframe: true,
    lineWidthMinPixels: 1,
    // @ts-ignore
    getPolygon: (x: PolygonMarker) => x.contours,
    getLineColor: (d) =>
      getMarkerColour(
        (d as PolygonMarker).value as number,
        (d as PolygonMarker).time as number,
        true,
        activeVarName
      ),
    getFillColor: (d) =>
      getMarkerColour(
        (d as PolygonMarker).value as number,
        (d as PolygonMarker).time as number,
        true,
        activeVarName
      ),
    getLineWidth: 0,
  });
};

export default getSensorCoverageLayer;
