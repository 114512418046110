import React from 'react';
import Typography from '@mui/material/Typography';

import { ReportOutput } from '../../services/api';
import summaryImg from '../../styles/images/report/summary.svg';
import visitPortalBtn from '../../styles/images/report/visitPortalBtn.svg';
import feedbackBtn from '../../styles/images/report/feedbackBtn.svg';
import { reportColors } from './constants';
import useStyles from '../../styles/report';

interface SummaryEndReportProps {
  reportData: ReportOutput;
}

function SummaryEndReport({ reportData }: SummaryEndReportProps): JSX.Element {
  const classes = useStyles();
  const { period, hours } = reportData;

  return (
    <div className={classes.page} style={{ pageBreakAfter: 'unset' }}>
      <Typography variant="h3">Your Report</Typography>
      <Typography variant="h4">About LightFi</Typography>
      <p>
        LightFi are an IoT and building automation specialist that offer sensors with integrated
        digital solutions to monitor & improve building ventilation and energy performance. Our
        solutions reduce energy consumption and improve comfort & productivity of occupiers.
        LightFi’s keystone technology includes patented IoT occupancy & environment sensor that
        integrate with building control systems to enable automated on-demand ventilation control.
      </p>
      <Typography variant="h4">About your report</Typography>
      <p>
        Using continuous monitoring data from multiple sensors distributed throughout occupied
        areas, we can assess the performance of building systems and policy. This analysis can be
        used ensure the right environment is being provided to occupants in all areas and under all
        conditions of use, insight that cannot be gained from spot checks alone. The factors
        measured can have a dramatic effect on comfort, productivity and the risk of viral
        transmission, this analysis can demonstrate the building health or, if necessary, identify
        areas that can be improved.
      </p>
      <p style={{ color: reportColors.blue }}>
        {`This report is for the period between ${period.start} ${hours && hours[0]}:00 to ${
          period.end
        } ${
          hours && hours[hours.length - 1]
        }:59. Operational hours analysed are between 9:00 and 17:00.
        Weekends are not included. Detailed live and historic data, as well as sensor location, are
        available on LightFi’s portal.`}
      </p>
      <div style={{ textAlign: 'center' }}>
        <img style={{ width: '80%' }} src={summaryImg} alt="summary" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-12%' }}>
        <div style={{ width: '25%', padding: '10px' }}>
          <img style={{ width: '80%' }} src={visitPortalBtn} alt="visitPortalBtn" />
        </div>
        <div style={{ width: '25%', padding: '10px' }}>
          <img style={{ width: '80%' }} src={feedbackBtn} alt="feedbackBtn" />
        </div>
      </div>
    </div>
  );
}

export default SummaryEndReport;
