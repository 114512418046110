import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportBandsData, VarName } from '../../services/api';
import getSensorBarChartProp from './helpers/sensorBarChart';
import { BarChartProperties, PieChartProperties, reportColors } from './constants';
import { varNameDetails } from '../../utils/varNames';
import PieChartPlot from './PieChartPlot';
import BarChartPlot from './BarChartPlot';
import bubbleBg from '../../styles/images/report/bubble-bg.svg';
import co2Range from '../../styles/images/report/co2Range.svg';
import airQuality from '../../styles/images/report/AirQuality.svg';
import detailBtn from '../../styles/images/report/detailBtn.svg';
import useStyles from '../../styles/report';

interface CO2ReportProps {
  co2Data: ReportBandsData;
  lastMonthDesiredPct: number | undefined;
}

function CO2Report({ co2Data, lastMonthDesiredPct }: CO2ReportProps): JSX.Element {
  const classes = useStyles();
  const { summary, weekdays, sensors } = co2Data;
  const { desiredPct } = summary;

  const sortedSensors = [...sensors].sort((a, b) => b.desiredPct - a.desiredPct);

  const goodSensorReadings = [...sortedSensors].slice(0, 5).reverse();
  const goodSensorBarProps = getSensorBarChartProp(
    VarName.Co2ppm,
    goodSensorReadings,
    false,
    false
  );

  const badSensorReadings = [...sortedSensors]
    .slice(sortedSensors.length - 5, sortedSensors.length)
    .reverse();
  const badSensorBarProps = getSensorBarChartProp(VarName.Co2ppm, badSensorReadings, true);

  const co2ThisMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: desiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: ' This Month',
    values: [100 - desiredPct, desiredPct],
  };

  const co2LastMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: lastMonthDesiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'Last Month',
    values: lastMonthDesiredPct ? [100 - lastMonthDesiredPct, lastMonthDesiredPct] : [],
  };

  const co2EachDayBar: BarChartProperties = {
    traces: [
      {
        name: 'max',
        value: weekdays.map((x) => x.max - 400).flat(2),
        color: reportColors.red,
        orientation: 'v',
        shape: '/',
      },
      {
        name: 'average',
        value: weekdays.map((x) => x.avg - 400).flat(2),
        color: reportColors.green,
        orientation: 'v',
      },
    ],
    yAxisTitle: `${varNameDetails[VarName.Co2ppm]?.label} (${
      varNameDetails[VarName.Co2ppm]?.metric
    })`,
    source: VarName.Co2ppm,
    values: weekdays?.map((x) => x.day).flat(2),
  };

  return (
    <>
      <div className={classes.page}>
        <Typography variant="h3">
          Carbon Dioxide (CO<sub>2</sub>)
        </Typography>
        <Typography variant="h4">
          Your monthly CO<sub>2</sub> stats
        </Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <PieChartPlot pieProps={co2ThisMonthPie} />
            <PieChartPlot pieProps={co2LastMonthPie} />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This percentage value tells us how often the CO₂ levels in your space are in 'ideal' conditions, which have been shown to improve productivity and wellbeing. Maximizing this number can help improve performance."
            />
          </Box>
        </Box>

        <Box sx={{ display: { sm: 'flex', xs: 'block' } }}>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              The primary source of carbon dioxide (CO<sub>2</sub>) indoors is typically due to
              people breathing. Elevated indoor CO<sub>2</sub> levels have been shown to impair
              cognitive function and productivity, raise presenteeism and absenteeism rates, and
              increase the risk of viral infection transmission, all of which can impact health and
              profitability.
            </p>
          </Box>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Continuous monitoring of CO<sub>2</sub> levels can help identify areas with inadequate
              ventilation and related risks. As per HSE and SAGE guidelines, spaces that regularly
              reach levels above 1,500 ppm should be identified and prioritized for improvement,
              e.g. via improved automation.
            </p>
          </Box>
        </Box>
        <img style={{ width: '100%' }} src={co2Range} alt="co2 range" />
        <Typography variant="h4">Better air quality equates to</Typography>
        <img style={{ width: '100%' }} src={airQuality} alt="Air Quality" />
      </div>
      <div className={classes.page}>
        <Typography variant="h3">
          Carbon Dioxide (CO<sub>2</sub>)
        </Typography>
        <Typography variant="h4">
          CO<sub>2</sub> levels for each day of the week
        </Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center' }}>
            <BarChartPlot barProps={co2EachDayBar} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The graph displays both the average CO2 levels for all sensors and the maximum CO2 levels reached by any sensor for each day of the week."
            />
          </Box>
        </Box>
        <Typography variant="h4">
          Best performing areas and those with potential for enhancement
        </Typography>
        <p>
          The table below shows how often each sensor spends in each CO2 range during working hours.
          For example, if a sensor spends 1 of every 4 working hours in a particular range and 3 of
          4 in another, those ranges will occupy 25% and 75% of the length of the bar.
        </p>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ display: 'grid' }}>
            <BarChartPlot barProps={goodSensorBarProps} />
            <BarChartPlot barProps={badSensorBarProps} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The table showcases the top 5 best-performing and bottom 5 performing areas. It is advisable to investigate those areas that frequently fall within the 'unhealthy' range for extended periods."
            />
          </Box>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <a href="https://portal.lightfi.io/">
            <img className={classes.portalLinkImg} src={detailBtn} alt="portal link" />
          </a>
        </div>
      </div>
    </>
  );
}

export default CO2Report;
