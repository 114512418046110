import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortNumericAscIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M154.604 371.955C145.409 380.988 134.666 385.505 122.378 385.505C106.672 385.505 94.0983 380.134 84.6582 369.392C75.2181 358.568 70.498 344.001 70.498 325.69C70.498 314.053 73.0208 303.392 78.0664 293.708C83.1934 283.942 90.3141 276.374 99.4287 271.003C108.543 265.55 118.797 262.824 130.19 262.824C141.909 262.824 152.326 265.754 161.44 271.613C170.555 277.473 177.635 285.896 182.681 296.882C187.726 307.868 190.29 320.441 190.371 334.602V347.663C190.371 377.285 183.006 400.56 168.276 417.487C153.547 434.414 132.673 443.448 105.654 444.587L96.9873 444.709V415.29L104.8 415.168C135.48 413.785 152.082 399.38 154.604 371.955ZM131.045 358.527C136.742 358.527 141.624 357.062 145.693 354.133C149.844 351.203 152.977 347.663 155.093 343.513V328.986C155.093 317.023 152.814 307.746 148.257 301.154C143.7 294.562 137.596 291.267 129.946 291.267C122.866 291.267 117.048 294.522 112.49 301.032C107.933 307.461 105.654 315.559 105.654 325.324C105.654 335.008 107.852 342.984 112.246 349.25C116.722 355.435 122.988 358.527 131.045 358.527Z" />
      <path d="M156.778 232H121.5V96.0137L79.3857 109.075V80.3887L152.994 54.0215H156.778V232Z" />
      <path d="M376.125 246.5V112H427.875V246.5H471L402 381L333 246.5H376.125Z" />
    </SvgIcon>
  );
}

export default SortNumericAscIcon;
