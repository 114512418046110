import React, { useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertMsg } from '../../LocationConfig/LocationProperties';
import useStyles from '../../../styles';
import { SensorPositionData } from '../../Map/types';
import { SensorLatest, SubSensorType, GeoJSON } from '../../../services/api';
import { getShortTimeStr } from '../../../utils/functions';
import PositioningMap from '../../Map/PositioningMap';
import { updateSensorProperties } from '../../../services/apiService';
import { setSensorsById } from '../../../state/actions';
import EditBtnIcon from '../../HelperComponents/EditBtnIcon';

interface SensorPositionProps {
  sensorDetails: SensorLatest;
  floorPlan: GeoJSON | undefined;
}

function SensorPosition({ sensorDetails, floorPlan }: SensorPositionProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [position, setPosition] = useState<SensorPositionData | undefined>(sensorDetails?.position);
  const [positionValid, setPositionValid] = useState(true);

  const handleEdit = (editing: boolean) => {
    setIsEditing(editing);
    setPosition(position);
  };

  const handleUpdate = () => {
    setIsEditing(false);
    updateSensorProperties(sensorDetails.id, { position })
      .then((response) => {
        const mergedData = { ...sensorDetails, ...response };
        setAlertMsg({ success: true, msg: 'Update successful', alertType: 'success' });
        setPosition(response?.position);
        dispatch(setSensorsById([mergedData]));
      })
      .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
  };

  const formPositionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? '';
    const values = newValue.split(',');
    let valid = false;
    if (values?.length === 2) {
      const latitude = Number.parseFloat(values[0]);
      const longitude = Number.parseFloat(values[1]);
      if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
        valid = true;
        setPosition({
          ...position,
          lat: latitude,
          lng: longitude,
        });
      }
    }
    setPositionValid(valid);
  };

  const mapPositionChangeHandler = (posData: SensorPositionData) => {
    const newPosition = { ...position };
    newPosition.lat = posData?.lat ?? 0;
    newPosition.lng = posData?.lng ?? 0;
    newPosition.polygon = posData?.polygon ?? '';
    if (sensorDetails?.type === SubSensorType.OccSignatures) {
      newPosition.azimuth = position?.azimuth ?? 0;
      newPosition.height = position?.height ?? 2.4;
    }
    setPosition(newPosition);
  };

  const metadata = sensorDetails?.metadata as { [unit: string]: number };
  const positionMetadata = metadata?.position as unknown as { [unit: string]: number };

  return (
    <ListItem>
      <Accordion style={{ width: '100%' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
              <SettingsIcon className={classes.icon} />
              <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>
                Position Details
              </Typography>
            </div>
            {expanded && (
              <EditBtnIcon
                isEditing={isEditing}
                handleEdit={handleEdit}
                changedItem={position}
                handleUpdate={handleUpdate}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '5px' }}>
          <>
            {alertMsg?.msg && (
              <Alert
                severity={alertMsg.alertType ?? 'info'}
                onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
                className={classes.alertMsg}
              >
                {alertMsg?.msg}
              </Alert>
            )}
            <List>
              <ListItem>
                <Grid item sm={5} xs={5}>
                  <ListItemText primary="Polygon" />
                </Grid>
                <Grid item sm={7} xs={5}>
                  {isEditing ? (
                    <TextField
                      type="text"
                      value={position?.polygon ?? ''}
                      style={{ width: '100%' }}
                      onChange={(e) =>
                        setPosition({
                          ...position,
                          polygon: e.target.value,
                        })
                      }
                      variant="standard"
                    />
                  ) : (
                    <ListItemText primary={position?.polygon ?? ''} />
                  )}
                  {positionMetadata?.polygon ? (
                    <p className={classes.updatedTime}>
                      last updated: {getShortTimeStr(positionMetadata?.polygon)}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>
              </ListItem>
              <ListItem>
                <Grid item sm={5} xs={5}>
                  <ListItemText primary="Position" />
                </Grid>
                <Grid item sm={7} xs={5}>
                  {isEditing ? (
                    <TextField
                      type="text"
                      value={`${position?.lat},${position?.lng}` ?? ''}
                      style={{ width: '100%' }}
                      error={!positionValid}
                      helperText={!positionValid ? 'Incorrect position entry' : undefined}
                      onChange={formPositionChangeHandler}
                      variant="standard"
                    />
                  ) : (
                    <ListItemText primary={`${position?.lat},${position?.lng}` ?? ''} />
                  )}
                  {positionMetadata?.lat ? (
                    <p className={classes.updatedTime}>
                      last updated: {getShortTimeStr(positionMetadata?.lat)}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>
              </ListItem>
              {(sensorDetails.type === SubSensorType.OccSignatures ||
                sensorDetails?.position?.azimuth !== undefined) && (
                <ListItem>
                  <Grid item sm={5} xs={5}>
                    <ListItemText primary="Azimuth" />
                  </Grid>
                  <Grid item sm={7} xs={5}>
                    {isEditing ? (
                      <TextField
                        type="number"
                        value={position?.azimuth ?? ''}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setPosition({
                            ...position,
                            azimuth: parseInt(e.target.value, 10),
                          })
                        }
                      />
                    ) : (
                      <ListItemText primary={position?.azimuth} />
                    )}
                  </Grid>
                </ListItem>
              )}
              {(sensorDetails.type === SubSensorType.OccSignatures ||
                sensorDetails?.position?.height !== undefined) && (
                <ListItem>
                  <Grid item sm={5} xs={5}>
                    <ListItemText primary="Height" />
                  </Grid>
                  <Grid item sm={7} xs={5}>
                    {isEditing ? (
                      <TextField
                        type="number"
                        value={position?.height ?? ''}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setPosition({
                            ...position,
                            height: parseFloat(e.target.value),
                          })
                        }
                      />
                    ) : (
                      <ListItemText primary={position?.azimuth} />
                    )}
                  </Grid>
                </ListItem>
              )}
              {sensorDetails?.position?.azimuth && (
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="Azimuth" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary={sensorDetails?.position?.azimuth} />
                  </Grid>
                </ListItem>
              )}
              {sensorDetails?.position?.height && (
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="Height" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary={sensorDetails?.position?.height} />
                  </Grid>
                </ListItem>
              )}
              <Grid item sm={12} xs={12}>
                <PositioningMap
                  floorPlan={
                    floorPlan?.features?.length && floorPlan?.features?.length > 0
                      ? floorPlan
                      : undefined
                  }
                  onChange={mapPositionChangeHandler}
                  position={position}
                  mapName="sensorEdit"
                />
              </Grid>
            </List>
          </>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

export default SensorPosition;
