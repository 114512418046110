import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CalendarViewDataContainer from '../Widgets/CalendarView/CalendarViewDataContainer';
import {
  getActiveMarker,
  getCurrentLocation,
  getSelectedHours,
  getSelectedStartDate,
  getSelectedEndDate,
  getSensorsByVarName,
  getSensorsByLocId,
} from '../state/selectors';
import {
  CalendarSelectionType,
  getHourSet,
  HeatmapHoverSelection,
  processRawData,
  PlotType,
  SensorAllStats,
  SensorFilteredStats,
} from '../Widgets/CalendarView/helpers';
import MapViewWrapper from '../Widgets/HelperComponents/MapViewWrapper';
import { DailyMetricItem } from '../services/api';
import DataStore from '../services/dataStore';
import { calculateMetricStats, combineStats, Stats } from '../utils/statistics';
import DateRangePicker from '../components/DateRangePicker';
import CalendarBtnGroup from '../Widgets/HelperComponents/CalendarBtnGroup';
import useStyles from '../styles/calendarView';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

function CalendarView(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const hoursSelection = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const dataStore = DataStore.getInstance();

  const sensorIdsInLoc = useSelector(getSensorsByLocId).get(currentLocation) || [];
  const sensorIdsWithVar = useSelector(getSensorsByVarName).get(activeMarker) || [];
  const activeSensorIds = sensorIdsInLoc.filter((id) => sensorIdsWithVar.includes(id));

  const [loading, setLoading] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<boolean>(false);
  const [locDailyData, setlocDailyData] = useState<DailyMetricItem[]>([]);
  const [heatmapHoverSelection, setHeatmapHoverSelection] = useState<HeatmapHoverSelection>();
  const [selectionType, setSelectionType] = useState<CalendarSelectionType>(
    CalendarSelectionType.week
  );

  // fetch the data from api and update data store
  useEffect(() => {
    setlocDailyData([]);
    setLoading(true);
    dataStore
      .getLocDailyMetricHistory(
        currentLocation,
        activeMarker,
        new Date(startDate).getTime(),
        new Date(endDate).getTime()
      )
      .then((history) => {
        if (history) {
          // Limit only to selectedHours (update max/min/tot)
          const { selectHours, startHour, endHour } = hoursSelection;
          const allHours = Array.from(new Set(getHourSet(selectHours, startHour, endHour)));
          if (allHours.length === 24) setlocDailyData(history);
          else setlocDailyData(processRawData(history, allHours));
          setLoading(false);
        }
      })
      .catch(() => {
        setlocDailyData([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation, startDate, endDate, hoursSelection, activeSensorIds.length]);

  // stats (per sensor id) for selected time range and hours
  const allSensorStats = useMemo(() => {
    const perSensorStats: Map<string, SensorAllStats> = new Map();
    activeSensorIds.forEach((id) => {
      const allMetrics = locDailyData.filter((item) => item.id === id);
      const stats = calculateMetricStats(
        activeMarker,
        allMetrics,
        [
          hoursSelection.selectHours ? hoursSelection.startHour : 0,
          hoursSelection.selectHours ? hoursSelection.endHour : 24,
        ],
        !hoursSelection.selectHours || hoursSelection.includeWeekends
      );
      perSensorStats.set(id, { stats, allMetrics });
    });
    return perSensorStats;
  }, [activeSensorIds, locDailyData, hoursSelection, activeMarker]);

  // stats (per sensor id) for specific heatmap hover selection
  const hoverFilterSensorStats = useMemo(() => {
    const hoverStatsMap: Map<string, SensorFilteredStats> = new Map();
    allSensorStats.forEach((senStat, sensorId) => {
      let filteredStats = senStat.stats; // Initially unfiltered
      if (heatmapHoverSelection) {
        const emptyStats: Stats = {
          bandTimes: new Map<string, number>(),
          totalTime: 0,
          maxMinAvg: { max: NaN, min: NaN, mean: NaN },
        };
        const {
          date: filterDate,
          weekWithStart: filterWeek,
          dayOfWeek: filterDay,
          hour: filterHour,
        } = heatmapHoverSelection;

        // Work out what we are hovering over and extract stats for only that selection
        // Check hover in order of most specific first, just in case there are multiple
        // partially redundant filters given (if no hover filter will stay as overall stats)
        if (filterDate !== undefined) {
          // Hovering over a specific calendar date box, get the stats for just that date
          filteredStats =
            senStat.stats.heatMapStats?.byDate.get(dayjs(filterDate).format('YYYY-MM-DD')) ??
            emptyStats;
        } else if (filterDay !== undefined && filterHour !== undefined) {
          // Hovering over a specific day of the week and hour of the day box
          filteredStats =
            senStat.stats.heatMapStats?.byDayAndHour.get(filterDay)?.get(filterHour) ?? emptyStats;
        } else if (filterDay !== undefined) {
          // Hovering over summary for specific day of the week (for all hours of the day)
          filteredStats = senStat.stats.heatMapStats?.byDayOfWeek.get(filterDay) ?? emptyStats;
        } else if (filterHour !== undefined) {
          // Hovering over summary for specific hour of the day (for all days of the week)
          filteredStats = senStat.stats.heatMapStats?.byHourOfDay.get(filterHour) ?? emptyStats;
        } else if (filterWeek !== undefined) {
          // Hovering over summary for specific week of the year starting at given date
          // get all the stats for the dates in that week and combine them into overall summary
          const weekStats: Stats[] = [];
          for (let i = 0; i < 7; i++) {
            const dateStr = new Date(filterWeek);
            dateStr.setDate(filterWeek.getDate() + i);
            const dateStrFormatted = dayjs(dateStr).format('YYYY-MM-DD');
            const dayStats = senStat.stats.heatMapStats?.byDate.get(dateStrFormatted);
            if (
              dayStats !== undefined &&
              (!hoursSelection.selectHours || hoursSelection.includeWeekends || ![0, 6].includes(i))
            )
              weekStats.push(dayStats);
          }
          filteredStats = combineStats(weekStats);
        }
      }

      // Create summaryValue
      let summaryValue = filteredStats.maxMinAvg.mean;
      if (filteredStats.totalAdded !== undefined) summaryValue = filteredStats.totalAdded;
      else if (filteredStats.utl !== undefined) summaryValue = Math.round(filteredStats.utl * 100);
      else if (heatmapHoverSelection?.summaryType === PlotType.min)
        summaryValue = filteredStats.maxMinAvg.min;
      else if (heatmapHoverSelection?.summaryType === PlotType.max)
        summaryValue = filteredStats.maxMinAvg.max;
      hoverStatsMap.set(sensorId, { stats: filteredStats, summaryValue });
    });
    return hoverStatsMap;
  }, [allSensorStats, heatmapHoverSelection, hoursSelection]);

  const sensorSummaryValues = Array.from(hoverFilterSensorStats.entries()).map(([id, stats]) => ({
    id,
    value: stats.summaryValue,
  }));

  return (
    <MapViewWrapper sensorSummaryValues={sensorSummaryValues}>
      <Box sx={{ paddingLeft: { sm: '10px' } }}>
        <Box
          sx={{
            padding: '8px',
            background: theme.palette.primary.main,
            borderRadius: '8px',
            display: { sm: 'flex' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <DateRangePicker />
          </Box>
          <Box className={classes.timeSelectionContainer}>
            <Box sx={{ display: 'flex' }}>
              <CalendarBtnGroup selectionType={selectionType} setSelectionType={setSelectionType} />
              <Tooltip title="Download data in .csv format">
                <IconButton
                  color="primary"
                  aria-label="Download data"
                  onClick={() => setDownloadData(true)}
                  size="small"
                  style={{ marginLeft: '10px' }}
                >
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <CalendarViewDataContainer
          sensorStats={allSensorStats}
          sensorFilteredStats={hoverFilterSensorStats}
          heatmapHoverSelection={heatmapHoverSelection}
          setHeatmapHoverSelection={setHeatmapHoverSelection}
          selectionType={selectionType}
          downloadData={downloadData}
          setDownloadData={setDownloadData}
          loading={loading}
        />
      </Box>
    </MapViewWrapper>
  );
}

export default CalendarView;
