import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import useGlobalStyles from '../styles';
import useStyles from '../styles/report';
import ReportContent from '../Widgets/Report/ReportContent';
import { getReportOutput } from '../services/apiService';
import Loading from '../components/Loading';
import { AlertMsg } from '../Widgets/LocationConfig/LocationProperties';
import { ReportOutput } from '../services/api';
import DateRangePicker from '../components/DateRangePicker';
import {
  getSelectedStartDate,
  getSelectedEndDate,
  getSelectedHours,
  getCurrentLocation,
} from '../state/selectors';
import SelectHoursPicker from '../components/SelectHoursPicker';
import { getHourSet } from '../Widgets/CalendarView/helpers';
import { setSelectedEndDate, setSelectedStartDate } from '../state/actions';
import Header from '../components/Header';
import LocationSwitcher from '../components/LocationSwitcher';
import { themeProps } from '../styles/theme';

function Report(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const selectedHours = useSelector(getSelectedHours);
  const currentLocation = useSelector(getCurrentLocation);
  const { selectHours, startHour, endHour, includeWeekends } = selectedHours;

  const globalClasses = useGlobalStyles();
  const [fetchingReportData, setFetchingReportData] = useState<boolean>(false);
  const [fetchingLastMonthData, setFetchingLastMonthData] = useState<boolean>(false);
  const [selectCustomDate, setSelectCustomDate] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [reportData, setReportData] = useState<ReportOutput | undefined>();
  const [lastMonthData, setLastMonthData] = useState<ReportOutput | undefined>();
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs().subtract(1, 'month'));

  useEffect(() => {
    const dStart = dayjs(currentMonth).startOf('month').format('YYYY-MM-DD');
    const dEnd = dayjs(currentMonth).endOf('month').format('YYYY-MM-DD');
    dispatch(setSelectedStartDate(new Date(dStart)));
    dispatch(setSelectedEndDate(new Date(dEnd)));
  }, [currentMonth, dispatch]);

  const hourSet = useMemo(
    () => new Set(getHourSet(selectHours, startHour, endHour)),
    [endHour, selectHours, startHour]
  );

  const fetchSelectedDateData = () => {
    setReportData(undefined);
    setFetchingReportData(true);
    const dStart = dayjs(startDate).format('YYYY-MM-DD');
    const dEnd = dayjs(endDate).format('YYYY-MM-DD');

    getReportOutput(currentLocation, dStart, dEnd, hourSet, includeWeekends)
      .then((res) => {
        setFetchingReportData(false);
        setReportData(res);
      })
      .catch((err) => {
        setFetchingReportData(false);
        setAlertMsg({ success: false, msg: err.cause, alertType: 'error' });
      });
  };

  const fetchLastMonthData = () => {
    const dStart = dayjs(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const dEnd = dayjs(startDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

    setLastMonthData(undefined);
    setFetchingLastMonthData(true);
    getReportOutput(currentLocation, dStart, dEnd, hourSet, includeWeekends)
      .then((res) => {
        setFetchingLastMonthData(false);
        setLastMonthData(res);
      })
      .catch((err) => {
        setFetchingLastMonthData(false);
        setAlertMsg({ success: false, msg: err.cause, alertType: 'error' });
      });
  };

  const fetchReportData = () => {
    setAlertMsg(undefined);
    if (startDate && endDate) {
      fetchSelectedDateData();
      fetchLastMonthData();
    }
  };

  return (
    <div className={`${globalClasses.bodyContent}`} style={{ height: '100%', width: '100%' }}>
      <Dialog
        fullScreen
        open={!!(reportData && lastMonthData)}
        onClose={() => setReportData(undefined)}
        style={{ zIndex: 10000 }}
      >
        {reportData && lastMonthData && (
          <ReportContent
            reportData={reportData}
            lastMonthData={lastMonthData}
            closeReport={() => setReportData(undefined)}
          />
        )}
      </Dialog>
      {!reportData && (
        <div>
          <Header />
          <Grid container className={classes.formContainer}>
            <Grid item xs={12} md={8}>
              <Paper elevation={0} sx={{ padding: '30px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                  Generate Report
                </Typography>
                <hr style={{ width: '150px', margin: 'unset' }} />
                <InputLabel
                  id="location-id"
                  style={{
                    textAlign: 'left',
                    fontSize: themeProps.textSize.small,
                    marginTop: '1rem',
                  }}
                >
                  Selected Location*
                </InputLabel>
                <Box
                  sx={{
                    alignSelf: 'center',
                    width: '100%',
                    overflowX: 'scroll',
                    padding: '5px 0',
                  }}
                >
                  <LocationSwitcher />
                </Box>
                <Grid container style={{ display: 'flex' }}>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={<Checkbox checked={selectCustomDate} color="primary" />}
                      label={<Typography>Select Custom Date</Typography>}
                      onChange={() => setSelectCustomDate(!selectCustomDate)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ alignSelf: 'center' }}>
                    {selectCustomDate ? (
                      <DateRangePicker showHoursSelection={false} />
                    ) : (
                      <Box sx={{ display: 'flex' }}>
                        <IconButton
                          color="primary"
                          aria-label="Download data"
                          onClick={() => setCurrentMonth(dayjs(currentMonth).subtract(1, 'month'))}
                          size="large"
                        >
                          <ArrowLeftIcon />
                        </IconButton>
                        <Typography variant="body2" style={{ alignSelf: 'center' }}>
                          {dayjs(currentMonth).format('MMM YYYY')}
                        </Typography>
                        <IconButton
                          color="primary"
                          aria-label="Download data"
                          onClick={() => setCurrentMonth(dayjs(currentMonth).add(1, 'month'))}
                          size="large"
                          disabled={dayjs(currentMonth).month() === dayjs().month()}
                        >
                          <ArrowRightIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectHoursPicker />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2">
                    Reports can be printed to PDF using the browser, there can be minor formatting
                    issues with some browsers. For best formatting we recommend using an up to date
                    Chrome based browser (tested on version 123) and choosing &quot;Margins -
                    None&quot;
                  </Typography>
                </Grid>

                {(fetchingReportData || fetchingLastMonthData) && <Loading />}
                {alertMsg && (
                  <Alert severity="error" className={globalClasses.alertMsg}>
                    {alertMsg.msg}
                  </Alert>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={fetchReportData}
                  style={{ marginTop: '10px' }}
                >
                  Show Report
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Report;
