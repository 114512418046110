import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/dashboard';
import { VarName, varNameDetails } from '../../utils/varNames';
import ColouredLinePlotWrapper from '../HelperComponents/ColouredLinePlotWrapper';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getMotionThreshold,
  getSelectedLocationVarValues,
  getSensorsById,
} from '../../state/selectors';
import { DataTreeItem } from '../../services/api';
import { getDataBandParams } from '../../utils/dataBandParams';
import SensorIcon from '../../styles/icons/SensorIcon';
import DataValueString, { getDataValueNumber } from '../HelperComponents/DataValueString';
import { MotionSlider } from '../SensorArrayWidgets/MotionEventsSensor';
import SelectedVarsIconBtn from '../../components/SelectedVarsIconBtn';
import { setSelectedBand } from '../../state/actions';

function SelectedSensorPlot(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeMarker = useSelector(getActiveMarker);
  const selectedVarValues = useSelector(getSelectedLocationVarValues);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const rangeValue = useSelector(getMotionThreshold);
  const allSensors = useSelector(getSensorsById);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeMarkerData: DataTreeItem[] = selectedVarValues.get(activeMarker) ?? [];

  const ActiveMarkerIcon = varNameDetails[activeMarker]?.icon ?? SensorIcon;
  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const selectedSensorId = activeItem.id;
  const selectedSensorName = allSensors.get(selectedSensorId)?.name ?? undefined;

  // when sensor is selected, check if value is expired or not and return value
  const selectedSensorData = useMemo(() => {
    let data;
    const dataItem = activeMarkerData.find((item) => item.id === activeItem.id);
    if (dataItem) {
      const { value } = dataItem;
      if (value !== undefined) data = dataItem;
    }
    return data;
  }, [activeItem.id, activeMarkerData]);

  const dataItemValue = selectedSensorData && getDataValueNumber(selectedSensorData, rangeValue);

  // null if there is no data else show band or default band where labels are 'Unknown'
  const selectedSensorBand =
    dataItemValue !== undefined ? getDataBandParams(activeMarker, dataItemValue ?? 0) : null;

  const handleBackBtn = () => {
    navigate('/');
    dispatch(setSelectedBand(null));
  };

  return (
    <div style={{ padding: '10px' }} className={classes.floatingBox}>
      <Box
        sx={{
          display: { lg: 'flex', xs: 'block' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBackBtn}
            style={{ background: theme.palette.primary.light, height: 'fit-content' }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ marginLeft: '5px' }}>
            <ActiveMarkerIcon />
          </Box>
          <Typography variant="h5" style={{ marginLeft: '10px' }}>
            {varNameDetails[activeMarker].label}
          </Typography>
        </div>
        <SelectedVarsIconBtn />
      </Box>

      <div
        style={{
          padding: '10px',
          background: theme.palette.primary.light,
          borderRadius: '8px',
          minHeight: activeMarker === VarName.MotionEvent ? '330px' : '280px', // 50px spacing for motion slider
        }}
      >
        <Box sx={{ minHeight: '40px' }}>
          {/* band will be null only if there is no data */}
          <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <FiberManualRecordIcon
                style={{
                  fontSize: '10px',
                  color: selectedSensorBand?.color,
                  alignSelf: 'center',
                }}
              />
              {selectedSensorData?.value && (
                <Typography variant="h4" style={{ alignSelf: 'center', marginLeft: '10px' }}>
                  <DataValueString
                    value={selectedSensorData?.value ?? 0}
                    varName={activeMarker}
                    time={selectedSensorData?.time}
                  />
                </Typography>
              )}
              <Typography variant="h6" style={{ alignSelf: 'center', marginLeft: '10px' }}>
                {selectedSensorName}
              </Typography>
            </Box>
            {selectedSensorBand?.label && selectedSensorBand.label !== 'Unknown' && (
              <Typography
                variant="body2"
                className={classes.pillBandLabel}
                style={{
                  borderColor: 'transparent',
                  textAlign: 'center',
                  background: `${selectedSensorBand?.color}84`,
                }}
              >
                {selectedSensorBand?.label}
              </Typography>
            )}
          </Box>
        </Box>
        {activeMarker === VarName.MotionEvent && (
          <Box sx={{ padding: '0 20px' }}>
            <MotionSlider />
          </Box>
        )}
        {selectedSensorId && (
          <div
            className={globalClasses.sensorGraph}
            style={{ marginTop: '10px', border: 'none', boxShadow: 'none' }}
          >
            <ColouredLinePlotWrapper
              varName={activeMarker}
              sensorId={selectedSensorId}
              transparentBg
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectedSensorPlot;
