/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import DeckGL from '@deck.gl/react';
import { IconLayer, GeoJsonLayer } from '@deck.gl/layers';
import { RGBAColor, Position as DeckGlPosition } from '@deck.gl/core';
import bbox from '@turf/bbox';
import { DeckGLViewPort, SensorPositionData } from './types';
import {
  createTileLayer,
  defaultLocation,
  FLOORPLAN_ZOOM,
  getCentreOfObject,
  getFloorPlanItemElevation,
  getFloorPlanItemFillColour,
  getFloorPlanItemLineColour,
  ICON_ATLAS,
  ICON_MAPPING,
  LocationStateProps,
} from './mapHelpers';
import MapToolbar from './MapToolbar';
import { GeoJSON, Position } from '../../services/api';
import useStyles from '../../styles';
import { getThemeMode, getUserPosition } from '../../state/selectors';
import getTargetPosLayer, { TargetPosIconType } from './MapLayers/targetPosLayer';

interface PositioningMapProps {
  floorPlan: GeoJSON | undefined;
  onChange: (newPosition: SensorPositionData) => void;
  position: SensorPositionData | undefined;
  mapName: string;
  locationState?: LocationStateProps;
  gatewayPosition?: Position;
}

function PositioningMap({
  floorPlan,
  onChange,
  position, // sensor pin position
  mapName,
  locationState,
  gatewayPosition, // position of highstrength gateway that shows sensor wifi pin
}: PositioningMapProps): JSX.Element {
  const classes = useStyles();
  const [hoveredPolygon, setHoveredPolygon] = useState('');
  const [mapLocationState, setMapLocationState] = useState(defaultLocation);
  const themeMode = useSelector(getThemeMode);
  const userPosition = useSelector(getUserPosition);

  // change view state to zoom in based on nearby ble sensors
  useEffect(() => {
    if (locationState) setMapLocationState(locationState);
  }, [locationState]);

  const deckGLLayers: any[] = [];
  const tileLayer = useRef(
    createTileLayer(themeMode, mapName, (obj: any) => {
      if (obj?.picked) {
        const coords = obj.coordinate;
        if (coords) {
          onChange({
            ...position,
            lng: Math.round(coords[0] * 1e7) / 1e7,
            lat: Math.round(coords[1] * 1e7) / 1e7,
          });
        }
      }
    })
  );
  const [showLabels, setShowLabels] = useState(true);
  deckGLLayers.push(tileLayer.current);

  const fitToView = () => {
    if (!tileLayer.current?.context || !floorPlan?.features?.length) {
      return;
    }

    const currentLocationCoordinates = {
      minLat: 90,
      minLon: 180,
      maxLat: -90,
      maxLon: -180,
    };

    const [minLng, minLat, maxLng, maxLat] = bbox(floorPlan); // Turf.js

    if (minLng < currentLocationCoordinates.minLon) {
      currentLocationCoordinates.minLon = minLng;
    }
    if (maxLng > currentLocationCoordinates.maxLon) {
      currentLocationCoordinates.maxLon = maxLng;
    }
    if (minLat < currentLocationCoordinates.minLat) {
      currentLocationCoordinates.minLat = minLat;
    }
    if (maxLat > currentLocationCoordinates.maxLat) {
      currentLocationCoordinates.maxLat = maxLat;
    }

    const { viewport } = tileLayer.current.context;
    // @ts-ignore
    const { longitude, latitude, zoom } = viewport.fitBounds([
      [currentLocationCoordinates.minLon, currentLocationCoordinates.minLat],
      [currentLocationCoordinates.maxLon, currentLocationCoordinates.maxLat],
    ]);

    const newViewState = {
      ...mapLocationState,
      latitude,
      longitude,
      zoom: zoom - 0.5,
    };
    setMapLocationState({ ...newViewState });
  };

  useEffect(() => {
    fitToView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorPlan]);

  // @ts-ignore
  const viewStateUpdateHandler = ({ viewState }) => {
    const { longitude, latitude, zoom, pitch, bearing } = viewState as DeckGLViewPort;
    const newViewState = {
      ...mapLocationState,
      longitude,
      latitude,
      zoom,
      pitch,
      bearing,
    };

    setMapLocationState(newViewState);
  };

  const floorPlanLayer = useMemo(() => {
    if (floorPlan && floorPlan.features.length > 0) {
      return new GeoJsonLayer({
        id: 'geojson-layer',
        // @ts-ignore
        data: floorPlan,
        pickable: true,
        stroked: true,
        filled: true,
        wireframe: true,
        extruded: true,
        onClick: (obj: any) => {
          if (obj?.picked) {
            const objData = obj.object;

            let coords: DeckGlPosition;
            let polygonId;
            switch (objData?.properties?.type) {
              case 'area.room':
              case 'area.toilet':
              case 'area.toilet.m':
              case 'area.toilet.w':
              case 'area.toilet.d':
                coords = obj.coordinate;
                polygonId = objData.id ?? objData.properties?.id;
                break;
              case 'furniture.desk':
                coords = getCentreOfObject(objData.geometry);
                polygonId = objData.id ?? objData.properties?.id;
                break;
              default:
                coords = obj.coordinate;
                polygonId = '';
                break;
            }

            if (coords) {
              onChange({
                ...position,
                lng: Math.round(coords[0] * 1e7) / 1e7,
                lat: Math.round(coords[1] * 1e7) / 1e7,
                polygon: polygonId.toString(),
              });
            }
          }
        },
        onHover: (obj: any) => {
          let polygonId = '';

          if (obj?.picked) {
            const objData = obj.object;
            switch (objData?.properties?.type) {
              case 'area.room':
              case 'furniture.desk':
              case 'area.toilet':
              case 'area.toilet.m':
              case 'area.toilet.w':
              case 'area.toilet.d':
                polygonId = objData.id ?? objData.properties?.id;
                break;
              default:
                break;
            }
          }

          setHoveredPolygon(polygonId);
        },
        getFillColor: (d: any): RGBAColor => {
          const polygonId: string | number = d.id ?? d.properties?.id;

          if (position?.polygon) {
            if (polygonId?.toString() === position.polygon) {
              return [52, 95, 145, 164];
            }
          }

          if (hoveredPolygon) {
            if (polygonId === hoveredPolygon) {
              return [52, 95, 145, 200];
            }
          }

          return getFloorPlanItemFillColour(d?.properties?.type ?? '');
        },
        getLineColor: (d: any): RGBAColor => getFloorPlanItemLineColour(d?.properties?.type ?? ''),
        getElevation: (d: any) => getFloorPlanItemElevation(d?.properties?.type ?? ''),
        lineWidthMinPixels: 2,
        lineWidthMaxPixels: 2,
        updateTriggers: {
          getFillColor: [hoveredPolygon, position?.polygon],
          getLineColor: [hoveredPolygon],
        },
      });
    }

    return null;
  }, [floorPlan, hoveredPolygon, onChange, position]);

  if (floorPlanLayer !== null) {
    deckGLLayers.push(floorPlanLayer);
  }

  const labelLayer = useMemo(() => {
    if (
      showLabels &&
      floorPlan &&
      floorPlan.features.length > 0 &&
      mapLocationState.zoom >= FLOORPLAN_ZOOM
    ) {
      const labelData = floorPlan.features.filter(({ geometry, properties }) => {
        const coordinates = geometry?.coordinates;
        const polygonType = properties?.type;
        if (coordinates) {
          switch (polygonType) {
            case 'area.toilet.m':
            case 'area.toilet.w':
            case 'area.toilet.d':
            case 'area.toilet':
            case 'area.lift':
            case 'area.stairs':
              return true;
            default:
              return false;
          }
        }
        return false;
      });
      if (labelData.length > 0) {
        return new IconLayer({
          id: 'label-layer',
          data: labelData,
          pickable: false,
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          iconAtlas: ICON_ATLAS,
          iconMapping: ICON_MAPPING,
          // @ts-ignore
          getIcon: (d: any) => {
            switch (d?.properties?.type) {
              case 'area.toilet.m':
                return 'toilet.m';
              case 'area.toilet.w':
                return 'toilet.f';
              case 'area.toilet.d':
                return 'toilet.d';
              case 'area.toilet':
                return 'toilet';
              case 'area.lift':
                return 'lift';
              case 'area.stairs':
                return 'stairs';
              default:
                return 'unknown';
            }
          },
          sizeScale: 3.5,
          getPosition: ({ geometry }: any) => getCentreOfObject(geometry),
          getSize: (d: any) => {
            switch (d?.properties?.type) {
              case 'area.toilet.m':
              case 'area.toilet.w':
              case 'area.toilet.d':
              case 'area.lift':
              case 'area.stairs':
              default:
                return 5;
            }
          },
          getColor: () => [255, 255, 255, 175],
        });
      }
    }

    return null;
  }, [floorPlan, mapLocationState.zoom, showLabels]);

  if (labelLayer !== null) {
    deckGLLayers.push(labelLayer);
  }

  if (position) {
    deckGLLayers.push(
      new IconLayer({
        id: 'marker-layer',
        data: [{ data: 0, position: [position.lng, position.lat, position.height ?? 0] }],
        pickable: true,
        iconAtlas: ICON_ATLAS,
        iconMapping: ICON_MAPPING,
        getAngle: () => (typeof position.azimuth === 'number' ? -position.azimuth : 0),
        getIcon: () => (typeof position.azimuth === 'number' ? 'sensor' : 'marker'),
        sizeScale: 15,
        // @ts-ignore
        getPosition: (d: Position) => d.position,
        getSize: 4,
        getColor: () => [52, 95, 145, 255], // is always default to blue
        updateTriggers: {
          getIcon: [position.azimuth],
          getAngle: [position.azimuth],
        },
      })
    );
  }

  const resetOrientation = () => {
    const newViewState = { ...mapLocationState, pitch: 0, bearing: 0 };
    setMapLocationState({ ...newViewState });
  };

  const toggleShowLabels = () => {
    const newState = !showLabels;
    setShowLabels(newState);
  };

  // Auto-zoom on initial load
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const afterRenderHandler = () => {
    // Auto-zoom on intial map load
    if (isInitialLoad) {
      setIsInitialLoad(false);
      fitToView();
    }
  };
  // End of Auto-zoom handling

  const gatewayPosLayer = useMemo(() => {
    let dataLayer = null;
    if (gatewayPosition) {
      dataLayer = getTargetPosLayer(gatewayPosition, TargetPosIconType.sensor);
    }
    return dataLayer;
  }, [gatewayPosition]);

  const userPosLayer = useMemo(() => {
    let dataLayer = null;
    if (userPosition) {
      dataLayer = getTargetPosLayer(userPosition);
    }
    return dataLayer;
  }, [userPosition]);

  if (userPosLayer !== null) {
    deckGLLayers.push(userPosLayer);
  }

  if (gatewayPosLayer !== null) {
    deckGLLayers.push(gatewayPosLayer);
  }

  return (
    <div className={classes.mapContainer}>
      <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <DeckGL
          initialViewState={mapLocationState}
          controller
          id="deckgl-map"
          layers={deckGLLayers}
          parameters={{ depthMask: false }}
          onViewStateChange={viewStateUpdateHandler}
          onAfterRender={afterRenderHandler}
        />
        <MapToolbar
          fitToView={fitToView}
          resetOrientation={resetOrientation}
          pitch={mapLocationState.pitch}
          bearing={mapLocationState.bearing}
          showLabels={showLabels}
          toggleShowLabels={toggleShowLabels}
        />
      </div>
    </div>
  );
}

PositioningMap.defaultProps = {
  locationState: defaultLocation,
  gatewayPosition: undefined,
};

export default PositioningMap;
