import React from 'react';
import { useSelector } from 'react-redux';
import { VarName, varNameDetails } from '../../utils/varNames';
import { getDataBandParams } from '../../utils/dataBandParams';
import { getMotionThreshold } from '../../state/selectors';
import { getMotionOccupancyValue } from '../../utils/motionEvents';
import { DataItem, DataTreeItem } from '../../services/api';

export const getDataValueNumber = (
  dataItem: DataTreeItem | DataItem,
  motionThreshold: [number, number]
) => {
  const { varName, value, time } = dataItem;
  let dataItemValue = value;
  if (varName === VarName.MotionEvent) {
    dataItemValue = getMotionOccupancyValue(motionThreshold, time, value);
  }
  return dataItemValue;
};

export const getDataValueString = (
  value: number,
  varName: VarName,
  time?: number,
  motionThreshold?: [number, number],
  showMetric = true,
  isRawValue = true
): string => {
  switch (varName) {
    case VarName.OnlineStatus:
      return getDataBandParams(varName, value)?.label ?? '';
    case VarName.MotionEvent:
      if (value !== undefined && time !== undefined && motionThreshold) {
        const motionValue = isRawValue
          ? getMotionOccupancyValue(motionThreshold, time, value)
          : value;
        return getDataBandParams(VarName.MotionEvent, motionValue).text;
      }
      return 'Unknown';
    default:
      return `${parseFloat(value.toFixed(1))}${
        showMetric ? ` ${varNameDetails[varName].metric ?? ''}` : ''
      }`;
  }
};

interface DataValueStringProps {
  value: number;
  varName: VarName;
  time?: number;
  showMetric?: boolean;
  isRawValue?: boolean; // For MotionEvent if already turned to occupancy value
}

function DataValueString({
  value,
  varName,
  time,
  showMetric,
  isRawValue,
}: DataValueStringProps): JSX.Element {
  const motionThreshold = useSelector(getMotionThreshold); // Only needed for VarName.MotionEvent

  return (
    <span>{getDataValueString(value, varName, time, motionThreshold, showMetric, isRawValue)}</span>
  ); // Render a string value
}

DataValueString.defaultProps = {
  time: undefined,
  showMetric: true,
  isRawValue: true,
};

export default DataValueString;
