import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import Map from '../Map/Map';
import useStyles from '../../styles/location';
import useGlobalStyles from '../../styles';
import { GeoJSON } from '../../services/api/api';
import {
  setLocationFloorPlan,
  fetchLocationFloorplan,
  deleteLocationFloorplan,
} from '../../services/apiService';
import {
  setLocationFloorplan,
  setLocationFloorplan as setStateFloorplan,
} from '../../state/actions';
import PositioningMap from '../Map/PositioningMap';
import { AlertMsg } from './LocationProperties';
import RemoveDialog from '../Dialog/RemoveDialog';
import { themeProps } from '../../styles/theme';

interface FloorPlanConfigProps {
  locationId: string;
}

function FloorPlanConfig({ locationId }: FloorPlanConfigProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const [file, setFile] = useState<File | undefined>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [floorMap, setFloorMap] = useState<GeoJSON>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();

  useEffect(() => {
    setFloorMap(undefined);
    setAlertMsg(undefined);
  }, [locationId]);

  useEffect(() => {
    if (file) {
      const r = new FileReader();
      r.onload = (e: ProgressEvent<FileReader>) => {
        const contents = e?.target?.result as string;
        const floorplanJson = contents && JSON.parse(contents);
        const floorPlanType = { type: floorplanJson?.type };
        const floorPlanFeatures = { features: floorplanJson?.features };
        const floorPlan: GeoJSON = { ...floorPlanType, ...floorPlanFeatures };
        setFloorMap(floorPlan);
      };
      r.readAsText(file);
    } else {
      fetchLocationFloorplan(locationId)
        .then((res) => {
          setFloorMap(res);
          dispatch(setLocationFloorplan({ locationId, floorplan: res }));
        })
        .catch((error) => setAlertMsg({ success: false, msg: error.cause, alertType: 'error' }));
    }
  }, [dispatch, file, locationId]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(undefined);
    setAlertMsg(undefined);
    const { files } = event.target;
    const fileExtension = files && files[0].name.split('.').pop();
    const uploadedFile = files && files[0];
    if (
      (uploadedFile !== null && uploadedFile.type === 'application/json') ||
      fileExtension === 'json' ||
      fileExtension === 'geojson'
    ) {
      setFile(uploadedFile as File);
    } else {
      setAlertMsg({ success: false, msg: 'File is not in correct format', alertType: 'error' });
    }
  };

  const uploadHandler = () => {
    setAlertMsg(undefined);
    if (floorMap) {
      setLocationFloorPlan(locationId, floorMap as GeoJSON)
        .then(() => {
          setAlertMsg({ success: true, msg: 'Upload successful', alertType: 'success' });
          dispatch(setStateFloorplan({ locationId, floorplan: floorMap }));
          setFile(undefined);
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
  };

  const removeFloorPlan = () => {
    setShowDialog(true);
    setAlertMsg(undefined);
    deleteLocationFloorplan(locationId)
      .then(() => {
        setAlertMsg({
          success: true,
          msg: 'Floorplan has been successfully removed',
          alertType: 'success',
        });
        dispatch(setStateFloorplan({ locationId, floorplan: { type: '', features: [] } }));
        setFloorMap(undefined);
        setShowDialog(false);
      })
      .catch((err) => {
        setAlertMsg({ success: false, msg: err.cause, alertType: 'error' });
        setShowDialog(false);
      });
  };

  return (
    <ListItem>
      <RemoveDialog
        showDialog={showDialog}
        title="floorplan"
        removeHandler={removeFloorPlan}
        cancelHandler={() => setShowDialog(false)}
      />
      <Accordion style={{ width: '100%' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
              <SettingsIcon className={classes.icon} />
              <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>Floorplan</Typography>
            </div>
            {expanded && (
              <IconButton onClick={() => setIsEditing(!isEditing)} size="large">
                {isEditing ? (
                  <CancelIcon className={globalClasses.closeBtnIcon} />
                ) : (
                  <EditIcon className={classes.icon} data-testid="floorplan-edit" />
                )}
              </IconButton>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '5px' }}>
          <>
            {alertMsg?.msg && (
              <Alert
                severity={alertMsg.alertType ?? 'info'}
                onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
                className={classes.alertMsg}
              >
                {alertMsg?.msg}
              </Alert>
            )}
            {isEditing ? (
              <Box style={{ textAlign: 'center' }}>
                <Typography>Please upload floor plan.</Typography>
                <Typography variant="caption">File must be json or geojson</Typography>
                <div style={{ margin: '1rem 0' }}>
                  <input
                    className={`${classes.uploadInput} form-control`}
                    type="file"
                    name="file"
                    id="choose_file"
                    onChange={(e) => onInputChange(e)}
                    data-testid="choose_file"
                  />
                  <label htmlFor="choose_file">
                    <Button component="span" size="small" variant="outlined">
                      {!file ? 'Choose your file' : file.name}
                    </Button>
                  </label>
                  {floorMap && floorMap.features.length > 0 && !file && (
                    <Button
                      color="secondary"
                      variant={themeProps.btnVariant.default}
                      className={classes.listSensorBtn}
                      onClick={() => setShowDialog(true)}
                    >
                      Remove Floorplan
                    </Button>
                  )}
                </div>
                {floorMap && floorMap.features.length > 0 && (
                  <div className={globalClasses.dashboardTile}>
                    {file && (
                      <Button
                        color="primary"
                        variant={themeProps.btnVariant.default}
                        className={classes.listSensorBtn}
                        onClick={uploadHandler}
                        endIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    )}
                    <PositioningMap
                      floorPlan={floorMap}
                      onChange={() => undefined}
                      position={undefined}
                      mapName="floorplanUpload"
                    />
                  </div>
                )}
              </Box>
            ) : (
              <Map />
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

export default FloorPlanConfig;
