import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SelectHoursPicker from './SelectHoursPicker';
import DateRangeContainer from './DatePicker/DateRangeContainer';

dayjs.extend(localizedFormat);

export enum MetricOptions {
  Raw = 'Raw',
  Hourly = 'Hourly',
  Daily = 'Daily',
}

interface DateRangePickerProps {
  isRawDataAllowed?: boolean;
  metricSelection?: MetricOptions;
  setMetricSelection?: React.Dispatch<React.SetStateAction<MetricOptions | undefined>>;
  showHoursSelection?: boolean;
}

function DateRangePicker({
  isRawDataAllowed,
  metricSelection,
  setMetricSelection,
  showHoursSelection,
}: DateRangePickerProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'space-between' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        <DateRangeContainer />
        {showHoursSelection && <SelectHoursPicker />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: { xs: 'center' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        {metricSelection && setMetricSelection && (
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={metricSelection}
            exclusive
            onChange={(e, value) => setMetricSelection(value)}
            aria-label="data-metric-source"
          >
            <ToggleButton
              sx={{ textTransform: 'inherit' }}
              value={MetricOptions.Raw}
              disabled={!isRawDataAllowed}
            >
              {MetricOptions.Raw}
            </ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value={MetricOptions.Hourly}>
              {MetricOptions.Hourly}
            </ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value={MetricOptions.Daily}>
              {MetricOptions.Daily}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
    </Box>
  );
}

DateRangePicker.defaultProps = {
  isRawDataAllowed: undefined,
  metricSelection: null,
  setMetricSelection: null,
  showHoursSelection: true,
};

export default DateRangePicker;
