import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportBandsData, VarName } from '../../services/api';
import getSensorBarChartProp from './helpers/sensorBarChart';
import { BarChartProperties, PieChartProperties, reportColors } from './constants';
import { varNameDetails } from '../../utils/varNames';
import PieChartPlot from './PieChartPlot';
import BarChartPlot from './BarChartPlot';
import bubbleBg from '../../styles/images/report/bubble-bg.svg';
import humRange from '../../styles/images/report/humRange.svg';
import humidity from '../../styles/images/report/humidity.svg';
import detailBtn from '../../styles/images/report/detailBtn.svg';
import useStyles from '../../styles/report';

interface HumidityReportProps {
  humData: ReportBandsData;
  lastMonthDesiredPct: number | undefined;
}

function HumidityReport({ humData, lastMonthDesiredPct }: HumidityReportProps): JSX.Element {
  const classes = useStyles();
  const { summary, weekdays, sensors } = humData;
  const { desiredPct } = summary;

  const sortedSensors = [...sensors].sort((a, b) => b.desiredPct - a.desiredPct);

  const goodSensorReadings = [...sortedSensors].slice(0, 5).reverse();
  const goodSensorBarProps = getSensorBarChartProp(
    VarName.RelativeHumidity,
    goodSensorReadings,
    false,
    false
  );

  const badSensorReadings = [...sortedSensors]
    .slice(sortedSensors.length - 5, sortedSensors.length)
    .reverse();
  const badSensorBarProps = getSensorBarChartProp(
    VarName.RelativeHumidity,
    badSensorReadings,
    true
  );

  const humThisMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: desiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'This Month',
    values: [100 - desiredPct, desiredPct],
  };

  const humLastMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: lastMonthDesiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'Last Month',
    values: lastMonthDesiredPct ? [100 - lastMonthDesiredPct, lastMonthDesiredPct] : [],
  };

  const humEachDayBar: BarChartProperties = {
    traces: [
      {
        name: 'max',
        value: weekdays.map((x) => x.max).flat(2),
        orientation: 'v',
      },
      {
        name: 'min',
        value: weekdays.map((x) => x.min).flat(2),
        orientation: 'v',
      },
      {
        name: 'avg',
        value: weekdays.map((x) => x.avg).flat(2),
        orientation: 'v',
      },
    ],
    yAxisTitle: `${varNameDetails[VarName.RelativeHumidity]?.label} (${
      varNameDetails[VarName.RelativeHumidity]?.metric
    })`,
    source: VarName.RelativeHumidity,
    values: weekdays?.map((x) => x.day).flat(2),
  };

  return (
    <>
      <div className={classes.page}>
        <Typography variant="h3">Relative Humidity</Typography>
        <Typography variant="h4">Your monthly Humidity stats</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <PieChartPlot pieProps={humThisMonthPie} />
            <PieChartPlot pieProps={humLastMonthPie} />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This percentage value indicates the proportion of time the relative humidity in your space is in the 'optimal' range. Maintaining optimal humidity can help reduce fatigue, enabling you to boost performance and decrease tiredness."
            />
          </Box>
        </Box>

        <Box sx={{ display: { sm: 'flex', xs: 'block' } }}>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Humidity is the moisture content of the air, which impacts both the comfort and
              healthiness of an environment. A relative humidity range of 40% to 60% is considered
              the most comfortable and provides the most protection against respiratory infections.
              Values outside of this range can contribute to Sick Building Syndrome, increase the
              risk of viral transmission, and lead to increased absenteeism related to respiratory
              problems.
            </p>
          </Box>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Low relative humidity (dry air) can cause dry eyes and irritation, and if air
              pollutants are present, this may lead to skin rash, itching, headaches, and mental
              fatigue. Both too low and too high humidity can increase the severity of asthma,
              dermatitis, nonallergic rhinitis, dehydration, and heat exhaustion. Humidifiers and
              dehumidifiers can help mitigate these effects.
            </p>
          </Box>
        </Box>
        <img style={{ width: '100%' }} src={humRange} alt="co2 range" />
        <Typography variant="h4">Optimal humidity levels equate to</Typography>
        <img style={{ width: '50%', marginTop: '1rem' }} src={humidity} alt="Air Quality" />
      </div>
      <div className={classes.page}>
        <Typography variant="h3">Relative Humidity</Typography>
        <Typography variant="h4">Relative humidity range for each day of the week</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center' }}>
            <BarChartPlot barProps={humEachDayBar} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The graph displays the daily average relative humidity from all sensors, along with the minimum and maximum relative humidity recorded by any single sensor for each day of the week."
            />
          </Box>
        </Box>
        <Typography variant="h4">Best and least optimal areas for humidity </Typography>
        <p>
          The table below shows how often each sensor spends in each relative humidity range during
          working hours. For example, if a sensor spends 1 of every 4 working hours in a particular
          range and 3 of 4 in another, those ranges will occupy 25% and 75% of the length of the
          bar.
        </p>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ display: 'grid' }}>
            <BarChartPlot barProps={goodSensorBarProps} />
            <BarChartPlot barProps={badSensorBarProps} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The table showcases the top 5 best-performing and bottom 5 performing areas.  Consider investigating areas that are often too dry or too humid."
            />
          </Box>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <a href="https://portal.lightfi.io/">
            <img className={classes.portalLinkImg} src={detailBtn} alt="portal link" />
          </a>
        </div>
      </div>
    </>
  );
}

export default HumidityReport;
