import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportBandsData, VarName } from '../../services/api';
import getSensorBarChartProp from './helpers/sensorBarChart';
import { BarChartProperties, PieChartProperties, reportColors } from './constants';
import { varNameDetails } from '../../utils/varNames';
import PieChartPlot from './PieChartPlot';
import BarChartPlot from './BarChartPlot';
import bubbleBg from '../../styles/images/report/bubble-bg.svg';
import pmRange from '../../styles/images/report/pmRange.svg';
import pm from '../../styles/images/report/pm.svg';
import detailBtn from '../../styles/images/report/detailBtn.svg';
import useStyles from '../../styles/report';

interface PmReportProps {
  pmData: ReportBandsData;
  lastMonthDesiredPct: number | undefined;
}

function PmReport({ pmData, lastMonthDesiredPct }: PmReportProps): JSX.Element {
  const classes = useStyles();
  const { summary, weekdays, sensors } = pmData;
  const { desiredPct } = summary;

  const sortedSensors = [...sensors].sort((a, b) => b.desiredPct - a.desiredPct);

  const goodSensorReadings = [...sortedSensors].slice(0, 5).reverse();
  const goodSensorBarProps = getSensorBarChartProp(
    VarName.ParticulateMatter,
    goodSensorReadings,
    false,
    false
  );

  const badSensorReadings = [...sortedSensors]
    .slice(sortedSensors.length - 5, sortedSensors.length)
    .reverse();
  const badSensorBarProps = getSensorBarChartProp(
    VarName.ParticulateMatter,
    badSensorReadings,
    true
  );

  const pmThisMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: desiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'This Month',
    values: [100 - desiredPct, desiredPct],
  };

  const pmLastMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: lastMonthDesiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'Last Month',
    values: lastMonthDesiredPct ? [100 - lastMonthDesiredPct, lastMonthDesiredPct] : [],
  };

  const pmEachDayBar: BarChartProperties = {
    traces: [
      {
        name: 'max',
        value: weekdays.map((x) => x.max).flat(2),
        color: reportColors.red,
        orientation: 'v',
        shape: '/',
      },
      {
        name: 'average',
        value: weekdays.map((x) => x.min).flat(2),
        color: reportColors.green,
        orientation: 'v',
      },
    ],
    yAxisTitle: `${varNameDetails[VarName.ParticulateMatter]?.label} (${
      varNameDetails[VarName.ParticulateMatter]?.metric
    })`,
    source: VarName.ParticulateMatter,
    values: weekdays?.map((x) => x.day).flat(2),
  };

  return (
    <>
      <div className={classes.page}>
        <Typography variant="h3">Particulate Matter (PM2.5)</Typography>
        <Typography variant="h4">Your monthly PM2.5 stats</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <PieChartPlot pieProps={pmThisMonthPie} />
            <PieChartPlot pieProps={pmLastMonthPie} />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This percentage value tells us how often the PM2.5 levels in your space are in 'clean' conditions. Keeping these numbers high can help improve general health and wellbeing."
            />
          </Box>
        </Box>
        <Box sx={{ display: { sm: 'flex', xs: 'block' } }}>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Particulate matter indicates the presence of air pollution that poses a significant
              health risk. In 2016, it was estimated that ambient air pollution led to 4.2 million
              premature deaths per year worldwide, affecting both urban and rural areas. This
              mortality rate is primarily due to exposure to fine particulate matter with a diameter
              of 2.5 microns or smaller (PM2.5), which can contribute to cardiovascular and
              respiratory
            </p>
          </Box>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              diseases, as well as cancers. Studies have shown that for every 10 μg/m3 increase in
              PM2.5 levels, overall mortality, cardiopulmonary disease mortality, and lung cancer
              mortality rates rise by 4%, 6%, and 8%, respectively. PM2.5 levels are heavily
              influenced by external factors such as vehicle emissions and weather patterns. Air
              filtration can generally help reduce PM levels.
            </p>
          </Box>
        </Box>
        <img style={{ width: '100%' }} src={pmRange} alt="co2 range" />
        <Typography variant="h4">Clean PM2.5 readings equate to</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box sx={{ alignSelf: 'center', padding: { sm: 0, xs: '10px 30px' } }}>
            <img style={{ width: '100%' }} src={pm} alt="Particulate matter" />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="If PM2.5 readings are too high, consider that your filters may need to be changed."
            />
          </Box>
        </Box>
      </div>
      <div className={classes.page}>
        <Typography variant="h3">Particulate Matter (PM2.5)</Typography>
        <Typography variant="h4">PM2.5 levels for each day of the week</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center' }}>
            <BarChartPlot barProps={pmEachDayBar} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The graph displays the daily average PM2.5 levels from all sensors, along with the highest PM2.5 levels recorded by any single sensor for each day of the week."
            />
          </Box>
        </Box>
        <Typography variant="h4">Best and worst performing areas </Typography>
        <p>
          The table below shows how often each sensor spends in each particulate matter range during
          working hours. For example, if a sensor spends 1 of every 4 working hours in a particular
          range and 3 of 4 in another, those ranges will occupy 25% and 75% of the length of the
          bar.
        </p>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ display: 'grid' }}>
            <BarChartPlot barProps={goodSensorBarProps} />
            <BarChartPlot barProps={badSensorBarProps} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This table displays the 5 bestperforming and 5 leastperforming areas. Those that spend an increased time in the 'unhealthy' range should be investigated."
            />
          </Box>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <a href="https://portal.lightfi.io/">
            <img className={classes.portalLinkImg} src={detailBtn} alt="portal link" />
          </a>
        </div>
      </div>
    </>
  );
}

export default PmReport;
