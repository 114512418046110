import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WifiIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 79 72" {...props}>
      <path d="M66.2 56.2001C70.3 55.4001 73.8 52.9001 75.9 49.5L70.2 49.8C68.3 51.4 65.9 52.4001 63.3 52.4001H62.3L49.9001 39.8C50 39.8 50 39.7 50.1 39.6C50.9 38.8 50.9 37.5 50.1 36.7001C48.1 34.7001 45.6 33.3 42.8 32.7001L39.3 29.2001C44 29.1 48.7001 30.9 52.3 34.5C52.7001 34.9001 53.2001 35.1 53.8 35.1C54.3 35.1 54.9 34.9001 55.3 34.5C56.1 33.7001 56.1 32.4 55.3 31.5C49.9 26.1 42.5 24.1 35.5 25.3L27.3 17.1C30.5 14.1 34.8 12.3 39.5 12.3C49 12.3 56.9001 19.8 57.5 29.3C57.5 29.9 57.9001 30.5 58.4001 30.9C58.9001 31.3 59.6001 31.4001 60.2001 31.2001C65.1001 29.7001 69.7 31.8001 72.2 35.4001L76.8 35.2001C74.4 30.1 69.3 26.5 63.3 26.5C62.7001 26.5 62 26.5 61.4001 26.6C59.6001 16 50.4 8.00005 39.5 8.00005C33.7001 8.00005 28.3 10.3 24.4 14L11.6 1.40005C10.8 0.600049 9.50005 0.600049 8.70005 1.40005C7.90005 2.20005 7.90005 3.50005 8.70005 4.30005L21.6 17.3C19.6 20 18.2001 23.2001 17.6 26.7001C16.9 26.6 16.3 26.6 15.7 26.6C7.50005 26.6 0.800049 33.3 0.800049 41.6C0.800049 49.9 7.50005 56.6 15.7 56.6H60.7001L74.8 70.7C75.2001 71.1 75.7001 71.3 76.3 71.3C76.8 71.3 77.4 71.1 77.8 70.7C78.6001 69.9 78.6001 68.6001 77.8 67.8L66.2 56.2001ZM15.7 52.3C9.80005 52.3 5.00005 47.5 5.00005 41.5C5.00005 35.5 9.80005 30.7001 15.7 30.7001C16.7 30.7001 17.8 30.9001 18.8 31.2001C19.4 31.4001 20.1 31.3 20.6 30.9C21.1 30.5 21.5 29.9 21.5 29.3C21.7001 25.9 22.8001 22.8001 24.6 20.2001L30.9 26.6C28.3 27.7001 25.9001 29.3 23.7001 31.4C22.9001 32.2 22.9001 33.5 23.7001 34.3C24.5 35.1 25.8001 35.1 26.6 34.3C28.8001 32.1 31.3 30.6 34.1 29.8L36.7001 32.4001C33.8 32.9001 31 34.3 28.9 36.5C28.1 37.3 28.1 38.6001 28.9 39.4001C29.3 39.8001 29.8 40 30.4 40C30.9 40 31.5 39.8001 31.9 39.4001C33.9 37.4001 36.6 36.2001 39.5 36.2001C39.9001 36.2001 40.4 36.2001 40.8 36.3L56.5 52.1H15.7V52.3Z" />
      <path d="M75.7 49.8H70.2C71.6 48.2 73.2 45.6 74.9 46.4C76.4 47.1 76.9 48.4 75.7 49.8Z" />
      <path d="M76.8 35.1L72.2 35.3C73 36.8 73.7 38.4 75 38C76.3 37.7 77.4 36.4 76.8 35.1Z" />
    </SvgIcon>
  );
}

export default WifiIcon;
