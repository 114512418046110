/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, ChangeEvent } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';

import {
  BacnetSettings,
  BacnetSettingsUpdate,
  BacnetSettingsCreate,
  SensorLatest,
} from '../../../services/api';
import {
  updateSensorBacnetConfig,
  createSensorBacnetConfig,
  getSensorBacnetConfig,
} from '../../../services/apiService';
import { AlertMsg } from '../../LocationConfig/LocationProperties';
import Loading from '../../../components/Loading';
import RemoveBacnetDialog from './RemoveBacnetDialog';
import useStyles from '../../../styles';
import BacnetConfigForm from './BacnetConfigForm';

interface SensorBacnetConfigProps {
  sensorDetails: SensorLatest;
  bacnetConfig: BacnetSettings | undefined;
  isBacnetEditable: boolean | undefined;
  bacnetAlertMsg: string | undefined;
}

function SensorBacnetConfig({
  sensorDetails,
  bacnetConfig,
  isBacnetEditable,
  bacnetAlertMsg,
}: SensorBacnetConfigProps): JSX.Element {
  const classes = useStyles();

  const [isEditingBacnet, setIsEditingBacnet] = useState(false);
  const [bacnetItem, setBacnetItem] = useState<BacnetSettingsUpdate | BacnetSettingsCreate>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [pageLoading, setPageLoading] = useState<boolean>();
  const [bacnetSetting, setBacnetSetting] = useState<BacnetSettings>();
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [isSettingEditable, setIsSettingEditable] = useState<boolean>();

  const sensorId = sensorDetails.id;

  useEffect(() => {
    if (bacnetAlertMsg) setAlertMsg({ success: true, msg: bacnetAlertMsg, alertType: 'error' });
    if (bacnetConfig) setBacnetSetting(bacnetConfig);
    setIsSettingEditable(isBacnetEditable);
  }, [bacnetConfig, isBacnetEditable, bacnetAlertMsg]);

  const getBacnetConfig = () => {
    setPageLoading(true);
    setBacnetSetting(undefined);
    setIsSettingEditable(false);
    if (sensorId) {
      getSensorBacnetConfig(sensorId)
        .then((details) => {
          setBacnetSetting(details);
          setPageLoading(false);
          setIsSettingEditable(true);
        })
        .catch((e) => {
          setIsSettingEditable(false);
          const resStatus = e?.cause?.status ?? undefined;
          if (resStatus && resStatus === 402) setIsSettingEditable(false);
          else if (resStatus && resStatus === 404) setIsSettingEditable(true);
          setAlertMsg({
            success: true,
            msg: e?.cause?.msg ?? 'Bacnet config not available',
            alertType: 'error',
          });
          setPageLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!bacnetConfig) getBacnetConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bacnetConfig]);

  const handleConfigChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
    configType: string
  ) => {
    const configSettings = { ...bacnetItem };
    const { value } = e.target;
    if (configType === 'sensor') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { sensor } = configSettings;
      if (sensor?.bacnet_id) {
        sensor.bacnet_id = value;
      } else {
        Object.assign(configSettings, { sensor: { bacnet_id: value } });
      }
    } else if (configType === 'gateway') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { gateway } = configSettings;
      if (key === 'building_network_no') {
        if (gateway?.building_network_no) {
          gateway.building_network_no = value;
        }
        if (gateway) {
          Object.assign(gateway, { building_network_no: value });
        } else Object.assign(configSettings, { gateway: { building_network_no: value } });
      }
      if (key === 'vlan_network_no') {
        if (gateway?.vlan_network_no) {
          gateway.vlan_network_no = value;
        }
        if (gateway) {
          Object.assign(gateway, { vlan_network_no: value });
        } else Object.assign(configSettings, { gateway: { vlan_network_no: value } });
      }
    }
    setBacnetItem(configSettings as BacnetSettings);
  };

  const createBacnetConfig = (settings: BacnetSettingsCreate) => {
    if (sensorId) {
      createSensorBacnetConfig(sensorId, settings)
        .then(() => {
          getBacnetConfig();
          setBacnetItem(undefined);
          setIsEditingBacnet(false);
          setAlertMsg({
            success: true,
            msg: 'Successfully created',
            alertType: 'success',
          });
        })
        .catch((e) => {
          setAlertMsg({
            success: true,
            msg: e.cause,
            alertType: 'error',
          });
        });
    }
  };

  const updateBacnetConfig = (settings: BacnetSettingsUpdate) => {
    if (sensorId) {
      updateSensorBacnetConfig(sensorId, settings)
        .then(() => {
          getBacnetConfig();
          setBacnetItem(undefined);
          setIsEditingBacnet(false);
          setAlertMsg({
            success: true,
            msg: 'Successfully updated',
            alertType: 'success',
          });
        })
        .catch((e) => {
          setAlertMsg({
            success: true,
            msg: e.cause,
            alertType: 'error',
          });
        });
    }
  };

  const requestConfigChange = () => {
    if ((!bacnetSetting || (bacnetSetting && !bacnetSetting?.sensor)) && bacnetItem)
      createBacnetConfig(bacnetItem as BacnetSettingsCreate);
    else if (bacnetItem) updateBacnetConfig(bacnetItem);
  };

  const handleEdit = () => {
    setAlertMsg(undefined);
    if (isEditingBacnet) {
      setBacnetItem(undefined);
      setIsEditingBacnet(false);
    } else setIsEditingBacnet(true);
  };

  const sensorBacnetInfo = bacnetSetting?.gateway?.sensors.find((config) => config.id === sensorId);

  return (
    <ListItem>
      {showRemoveDialog && sensorId && (
        <RemoveBacnetDialog
          showDialog={showRemoveDialog}
          sensorDetails={sensorDetails}
          hideDialog={() => setShowRemoveDialog(false)}
          refreshConfig={getBacnetConfig}
          setSuccessAlert={() =>
            setAlertMsg({
              success: true,
              msg: 'Bacnet config has been removed',
              alertType: 'success',
            })
          }
        />
      )}
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Badge
            badgeContent={sensorBacnetInfo?.bacnet_id && 'b'}
            invisible={!sensorBacnetInfo}
            color="primary"
            classes={{ badge: classes.customBadge }}
          >
            <DeviceHubIcon className={classes.icon} />
          </Badge>
          <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>Bacnet Config</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{ width: '100%' }}>
            {alertMsg && (
              <Alert
                severity={alertMsg.alertType}
                className={classes.alertMsg}
                style={{ marginBottom: '20px' }}
              >
                {alertMsg.msg}
              </Alert>
            )}
            {pageLoading && <Loading />}
            {isSettingEditable && (
              <BacnetConfigForm
                sensorId={sensorId}
                bacnetConfig={bacnetSetting}
                sensorType={sensorDetails?.type}
                isEditingBacnet={isEditingBacnet}
                bacnetItem={bacnetItem}
                showRemoveDialog={() => setShowRemoveDialog(true)}
                handleEdit={handleEdit}
                handleConfigChange={handleConfigChange}
                requestConfigChange={requestConfigChange}
              />
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

export default SensorBacnetConfig;
