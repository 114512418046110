import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Position, PropLocationItemIn } from '../../services/api';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/location';

interface EditBtnIconProps {
  isEditing: boolean;
  handleEdit: (value: boolean) => void;
  changedItem: PropLocationItemIn | Position | undefined;
  handleUpdate: () => void;
}

function EditBtnIcon({
  isEditing,
  handleEdit,
  changedItem,
  handleUpdate,
}: EditBtnIconProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Box sx={{ alignSelf: 'center' }}>
      {isEditing ? (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton
            className={`${changedItem ? classes.primaryBtn : classes.disabledBtn}`}
            onClick={handleUpdate}
            disabled={!changedItem}
            style={{ padding: '5px' }}
            size="large"
          >
            <CheckCircleIcon data-testid="update" />
          </IconButton>
          <IconButton onClick={() => handleEdit(false)} style={{ padding: '5px' }} size="large">
            <CancelIcon className={globalClasses.closeBtnIcon} data-testid="cancel" />
          </IconButton>
        </Box>
      ) : (
        <IconButton onClick={() => handleEdit(true)} size="large">
          <EditIcon className={classes.icon} data-testid="edit" />
        </IconButton>
      )}
    </Box>
  );
}

export default EditBtnIcon;
