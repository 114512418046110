import { HoursSelection, SortType, TempUser, ThemeMode } from '../state/types';
import { DeckGLViewPort } from '../Widgets/Map/types';
import { VarName } from './varNames';

export enum StorageTypes {
  CurrentLocation = 'current_location',
  SelectedVars = 'selected_vars',
  MapLocation = 'map_location',
  DashboardPanels = 'dashboard_panels',
  PlotVars = 'plot_vars',
  SelectedPlotSensors = 'selected_plot_sensors',
  ActiveMarker = 'active_marker',
  ShowingHelp = 'showing_help',
  SortBy = 'sort_by',
  SelectedHours = 'selected_hours',
  MotionThreshold = 'motion_threshold',
  TemporaryUser = 'temporary_user',
  ThemeType = 'theme_type',
}

export const retrieveState = (
  stateName: StorageTypes
):
  | string
  | string[]
  | [number, number]
  | DeckGLViewPort
  | undefined
  | SortType
  | HoursSelection
  | TempUser
  | ThemeMode => {
  try {
    let serializedState;
    const params = new URLSearchParams(window.location.search);
    serializedState = params.get(stateName);

    if (serializedState) {
      serializedState = decodeURIComponent(serializedState);
    } else {
      serializedState = localStorage.getItem(stateName);
    }

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const persistState = (
  state:
    | string
    | string[]
    | VarName[]
    | [number, number]
    | DeckGLViewPort
    | SortType
    | HoursSelection
    | TempUser
    | ThemeMode,
  stateName: StorageTypes
): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateName, serializedState);

    // eslint-disable-next-line no-restricted-globals
    if (history) {
      const params = new URLSearchParams(window.location.search);
      params.set(stateName, encodeURIComponent(serializedState));
      // Update params in active URL (TODO - filter only desired params?)
      // eslint-disable-next-line no-restricted-globals
      // history.pushState(null, '', `?${params.toString()}`);
    }
  } catch (err) {
    // console.warn("Can't save changes in local storage");
  }
};

export const clearState = (stateName: StorageTypes): void => {
  try {
    localStorage.removeItem(stateName);
  } catch (err) {
    // console.warn("Can't clear state in local storage");
  }
};

export const constructURLToShare = (): string => {
  const location = JSON.stringify(retrieveState(StorageTypes.CurrentLocation));
  const selectedVars = JSON.stringify(retrieveState(StorageTypes.SelectedVars));

  const params = new URLSearchParams();
  params.set(StorageTypes.CurrentLocation, location);
  params.set(StorageTypes.SelectedVars, selectedVars);

  const { origin, pathname } = window.location;
  const url = encodeURI(
    `${origin}${pathname}${pathname.length > 1 ? '/' : ''}?${params.toString()}`
  );

  return url;
};
