import { PropLocationItem } from '../../services/api';
import { ActionTypes, ActionType } from '../actionTypes';
import { LocationState } from '../types';

const initialState: LocationState = {
  currentLocation: '#',
  currentLocationChildren: [],
  floorplans: new Map(),
  locationsById: new Map(),
  locationsByParent: new Map(),
};

export function updateFlatMap(
  locationsById: Map<string, PropLocationItem>,
  locationsByParent: Map<string, string[]>,
  item: PropLocationItem
) {
  locationsById.set(item.id, { ...locationsById.get(item.id), ...item });

  if (item.location !== undefined) {
    const parentItem = locationsByParent.get(item.location) || [];
    if (!parentItem.includes(item.id)) {
      parentItem.push(item.id);
      locationsByParent.set(item.location, parentItem);
    }
  }
}

// eslint-disable-next-line default-param-last
export default function setLocationState(state = initialState, action: ActionTypes): LocationState {
  switch (action.type) {
    case ActionType.SET_LOCATION_DATA: {
      const locationsRaw = action.payload;
      const { locationsById, locationsByParent } = state;
      locationsRaw?.locations?.forEach((loc) =>
        updateFlatMap(locationsById, locationsByParent, loc)
      );
      return {
        ...state,
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.UPDATE_LOCATION_DATA: {
      const newDetails = action.payload;
      const { locationsById, locationsByParent } = state;
      updateFlatMap(locationsById, locationsByParent, newDetails);

      return {
        ...state,
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.REMOVE_LOCATION_DATA: {
      const locationId = action.payload;
      const { locationsById, locationsByParent } = state;
      const parentLocId = locationsById.get(locationId)?.location;
      if (parentLocId !== undefined) {
        const locArray = locationsByParent.get(parentLocId);
        // Remove the locationId from the parent array
        locArray?.splice(locArray.indexOf(locationId), 1);
      }
      locationsById.delete(locationId);

      return {
        ...state,
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.SET_CURRENT_LOCATION: {
      const currentLocation = action.payload;
      return {
        ...state,
        currentLocation,
      };
    }
    case ActionType.SET_CURRENT_LOCATION_CHILDREN: {
      const locations = action.payload;
      const { locationsById, locationsByParent } = state;
      locations.forEach((loc) => updateFlatMap(locationsById, locationsByParent, loc));
      return {
        ...state,
        currentLocationChildren: locations,
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.SET_LOCATION_FLOORPLAN: {
      const { locationId, floorplan } = action.payload;
      const floorplans = new Map(state.floorplans);
      floorplans.set(locationId, floorplan);
      return {
        ...state,
        floorplans,
      };
    }
    case ActionType.CLEAR_REDUX_STORE: {
      return {
        ...state,
        currentLocation: '#',
        currentLocationChildren: [],
        floorplans: new Map(),
        locationsById: new Map(),
        locationsByParent: new Map(),
      };
    }
    default: {
      return state;
    }
  }
}
