import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortLocAlphaAscIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M114.688 413.581H204.287V443H68.9111V421.516L156.802 294.929H69.0332V265.266H202.334V286.262L114.688 413.581Z" />
      <path d="M175.211 195.379H111.002L98.7949 232H59.8545L126.017 54.2656H159.952L226.48 232H187.54L175.211 195.379ZM120.89 165.716H165.323L142.984 99.1875L120.89 165.716Z" />
      <path d="M397.5 49C356.9 49 323.994 81.9063 323.994 122.506C323.994 136.064 326.66 150.118 334.277 160.211L397.5 244L460.723 160.211C467.654 151.032 471.006 134.808 471.006 122.506C471.006 81.9063 438.1 49 397.5 49ZM397.5 91.5801C414.562 91.5801 428.426 105.443 428.426 122.506C428.426 139.568 414.562 153.432 397.5 153.432C380.438 153.432 366.574 139.568 366.574 122.506C366.574 105.443 380.438 91.5801 397.5 91.5801Z" />
      <path d="M379.688 350V257H415.312V350H445L397.5 443L350 350H379.688Z" />
    </SvgIcon>
  );
}

export default SortLocAlphaAscIcon;
