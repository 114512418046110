import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortLocAlphaDescIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M120.777 202.315H210.376V231.734H75V210.25L162.891 83.663H75.1221V54H208.423V74.996L120.777 202.315Z" />
      <path d="M175.356 410.113H111.147L98.9404 446.734H60L126.162 269H160.097L226.625 446.734H187.685L175.356 410.113ZM121.035 380.45H165.468L143.129 313.922L121.035 380.45Z" />
      <path d="M397.5 49C356.9 49 323.994 81.9063 323.994 122.506C323.994 136.064 326.66 150.118 334.277 160.211L397.5 244L460.723 160.211C467.654 151.032 471.006 134.808 471.006 122.506C471.006 81.9063 438.1 49 397.5 49ZM397.5 91.5801C414.562 91.5801 428.426 105.443 428.426 122.506C428.426 139.568 414.562 153.432 397.5 153.432C380.438 153.432 366.574 139.568 366.574 122.506C366.574 105.443 380.438 91.5801 397.5 91.5801Z" />
      <path d="M415.312 350V443H379.688V350H350L397.5 257L445 350H415.312Z" />
    </SvgIcon>
  );
}

export default SortLocAlphaDescIcon;
