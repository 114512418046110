import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/location';
import { PropLocationItem, PropSensorItem } from '../../services/api';
import { LocationContent } from './LocationProperties';
import SensorsInfoIcon from '../../styles/icons/SensorsInfoIcon';
import Header from '../../components/Header';

interface LocationHeaderProps {
  childLocations: PropLocationItem[] | undefined;
  sensorsInLocation: PropSensorItem[] | undefined;
  currentContent: LocationContent | undefined;
  setCurrentContent: (value: LocationContent) => void;
  isDesktopView: boolean;
}

function LocationHeader({
  childLocations,
  sensorsInLocation,
  currentContent,
  setCurrentContent,
  isDesktopView,
}: LocationHeaderProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const contentMenu = [
    { id: LocationContent.info, title: 'Info', icon: InfoIcon, disabled: false },
    {
      id: LocationContent.subLocations,
      title: 'Sub Locations',
      icon: childLocations && childLocations?.length > 0 ? LocationOnIcon : AddLocationIcon,
      disabled: false,
    },
    { id: LocationContent.users, title: 'Users', icon: AccountCircleIcon, disabled: false },
    {
      id: LocationContent.subSensors,
      title: 'Sub Sensors',
      icon: SensorsInfoIcon,
      disabled: sensorsInLocation && sensorsInLocation?.length === 0,
    },
  ];

  return (
    <div className={classes.locationHeaderContainer}>
      <Header autoSwitchLoc={false} />
      {!isDesktopView && (
        <div className={classes.activityIcons}>
          {contentMenu.map((menu) => (
            <Tooltip title={menu.title} key={menu.id}>
              <IconButton
                className={globalClasses.icon}
                onClick={() => setCurrentContent(menu.id)}
                disabled={menu.disabled}
                size="large"
              >
                <menu.icon
                  className={`${currentContent === menu.id ? classes.activeIcon : ''} ${
                    menu.disabled ? globalClasses.disabledIcon : globalClasses.icon
                  }`}
                />
              </IconButton>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
}

export default LocationHeader;
