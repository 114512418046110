import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import useStyles from '../styles';
import { getMapSize, getSelectedVars, getSelectedLocationVarValues } from '../state/selectors';
import { WidgetConfig } from '../utils/widgetConfig';
import { varNameDetails } from '../utils/varNames';
import LocationSensorArray from '../Widgets/LocationSensorArray';
import Header from '../components/Header';
import SourcesMenu from '../components/SourcesMenu';

const Map = lazy(() => import('../Widgets/Map/Map'));
const SensorGraph = lazy(() => import('../Widgets/SensorGraph'));

function ListViewDashboard(): JSX.Element {
  const classes = useStyles();
  const mapSize = useSelector(getMapSize);
  const [widgetConfiguration, setWidgetConfiguration] = useState<WidgetConfig[]>([]);
  const varsCurrentlySelected = useSelector(getSelectedVars);
  const selectedVarValues = useSelector(getSelectedLocationVarValues);

  useEffect(() => {
    const newConfig: WidgetConfig[] = [];
    for (let i = 0; i < varsCurrentlySelected.length; i++) {
      const varName = varsCurrentlySelected[i];
      const widgetConfigEntry: WidgetConfig = {
        id: `widget${i}-${varName}`,
        data: selectedVarValues.get(varName) ?? [],
        varName,
      };
      newConfig.push(widgetConfigEntry);
    }

    setWidgetConfiguration(
      newConfig.sort((a, b) => {
        const va = a.varName ? varNameDetails[a.varName].label : '';
        const vb = b.varName ? varNameDetails[b.varName].label : '';

        if (va < vb) {
          return -1;
        }
        if (va > vb) {
          return 1;
        }
        return 0;
      })
    );
  }, [varsCurrentlySelected, selectedVarValues]);

  return (
    <div className={classes.bodyContent} style={{ overflowY: 'hidden' }}>
      <Header />
      <div className={classes.dashboard} data-testid="dashboard">
        {mapSize !== 'collapsed' && (
          <div className={classes.dashboardTile} style={{ flex: '40%' }}>
            <Suspense
              fallback={
                <Skeleton variant="rectangular" style={{ height: '100%', width: '100%' }} />
              }
            >
              <Map />
            </Suspense>
          </div>
        )}
        {mapSize !== 'full' && (
          <div className={`${classes.dashboardTile} ${classes.dashboardTileContainer}`}>
            <div style={{ flex: '0 0 auto' }}>
              <div className={classes.sensorGraphContainer}>
                <Suspense
                  fallback={<Skeleton variant="rectangular" className={classes.sensorGraph} />}
                >
                  <SensorGraph />
                </Suspense>
              </div>
            </div>
            <div
              className={classes.dashboardTileScrollable}
              style={{ flex: '1 1 auto', marginTop: '1rem' }}
            >
              {widgetConfiguration.length === 0 && (
                <Typography variant="h5" style={{ textAlign: 'center', padding: '1rem' }}>
                  No data found for this location
                </Typography>
              )}
              <ul className={classes.locationSensorArrayList}>
                {widgetConfiguration.map((widgetConfig, index) => (
                  <LocationSensorArray
                    key={widgetConfig.id}
                    config={widgetConfig}
                    firstItem={index === 0}
                  />
                ))}
                <li
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <SourcesMenu />
                  <Typography variant="body1" display="inline">
                    - Add/Remove data from display
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListViewDashboard;
