import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import useStyles from '../styles';
import { getBleWebViewUrl } from '../state/selectors';
import { showHelp } from '../state/actions';

interface MainWrapperProps {
  routeComponent: JSX.Element;
  helmetTitle: string;
}

function MainWrapper({ routeComponent, helmetTitle }: MainWrapperProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bleWebViewUrl = useSelector(getBleWebViewUrl);

  posthog.capture('$pageview');

  useEffect(() => {
    if (bleWebViewUrl) {
      navigate(bleWebViewUrl);
      dispatch(showHelp(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleWebViewUrl]);

  return (
    <main className={classes.mainContent}>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      {routeComponent}
    </main>
  );
}

export default MainWrapper;
