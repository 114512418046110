import React from 'react';
import { PieChartProperties, reportColors } from './constants';
import Plot from '../Plots/PlotlyCustom';

interface PieChartPlotProps {
  pieProps: PieChartProperties;
}

function PieChartPlot({ pieProps }: PieChartPlotProps): JSX.Element {
  const {
    values,
    colors,
    childRingColor,
    childRingValue,
    titleText,
    annotationColor,
    annotationText,
    showMaxTitle,
  } = pieProps;

  const plotData = [
    {
      values,
      domain: { column: 0 },
      hole: 0.85,
      type: 'pie',
      marker: {
        colors,
        line: {
          color: reportColors.lightGrey,
          width: [1, 0],
        },
      },
      textinfo: 'none',
      direction: 'clockwise',
      sort: false,
    },
    {
      values: childRingValue ?? [],
      domain: { x: [0.15, 0.85], y: [0.15, 0.85] },
      hole: 0.8,
      type: 'pie',
      marker: {
        colors: childRingColor ?? [],
      },
      textinfo: 'none',
      direction: 'clockwise',
      sort: false,
    },
  ] as Plotly.Data[];

  const plotLayout = {
    title: {
      text: titleText,
      x: 0.5,
      y: showMaxTitle ? 0.12 : 0.015,
      font: {
        size: 15,
        color: '#979BA6',
        family: "'Poppins-Medium', sans-serif",
      },
    },
    annotations: [
      {
        font: {
          size: 26,
          color: annotationColor,
          family: "'Poppins-Bold', sans-serif",
        },
        showarrow: false,
        text: annotationText ? `${Math.trunc(annotationText)}%` : '',
        x: 0.5,
        y: 0.5,
      },
    ],
    height: showMaxTitle ? 250 : 220,
    width: 160,
    margin: {
      l: 10,
      r: 10,
      b: 10,
      t: 0,
      pad: 30,
    },
    showlegend: false,
  } as Partial<Plotly.Layout>;

  return <Plot data={plotData} layout={plotLayout} config={{ staticPlot: true }} />;
}

export default PieChartPlot;
