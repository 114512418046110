import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import UpdateIcon from '@mui/icons-material/Update';
import AdjustIcon from '@mui/icons-material/Adjust';
import { BaseAccessPoint, WiFiConfig } from '../../services/api/api';
import useStyles from '../../styles';

interface AccessPointsIconProps {
  wifiConfig: WiFiConfig;
  accessPoint: BaseAccessPoint;
  addressPattern: string;
  listening: boolean;
  type: string;
  parentAP: string;
  changeStatus: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    enabled: boolean
  ) => void;
}

function AccessPointsIcon({
  wifiConfig,
  accessPoint,
  addressPattern,
  listening,
  type,
  changeStatus,
  parentAP,
}: AccessPointsIconProps): JSX.Element {
  const classes = useStyles();

  const inReported = !!wifiConfig?.reported?.address_allowlist?.includes(addressPattern);
  const inDesired = !!wifiConfig?.desired?.address_allowlist?.includes(addressPattern);
  const inProgress = (!inReported && inDesired) || (inReported && !inDesired);
  const enabled = inReported && inDesired;
  const includesEnabledSSID = accessPoint.aps.some((ssid) =>
    wifiConfig?.reported?.address_allowlist?.includes(ssid.address)
  );
  const listeningSSID = accessPoint.aps.filter((ssid) => ssid.listening === true);
  const includesListeningSSID = listeningSSID.length > 0;
  const includesNonListeningSSID =
    accessPoint.aps.filter((ssid) => ssid.listening === false).length > 0;
  const enabledParentAP = !!wifiConfig?.reported?.address_allowlist?.includes(parentAP);

  let icon;
  let toolTipText;

  // for access point when all ssid's are listening
  if (enabled && includesListeningSSID && !includesNonListeningSSID) {
    icon = (
      <CheckCircleIcon
        className={`${classes.enabledIcon} ${type === 'ssid' && classes.ssidIconBtn}`}
      />
    );
    toolTipText = 'Enabled Address';
    // for access point when partial ssid's are listening
  } else if (enabled && includesListeningSSID && includesNonListeningSSID) {
    icon = (
      <AdjustIcon
        className={`${type === 'ssid' && classes.ssidIconBtn}`}
        style={{ color: '#f50357' }}
      />
    );
    toolTipText = 'Partially Enabled Address';
    // for access point, if status is in progress for change
  } else if (inProgress) {
    icon = (
      <UpdateIcon className={`${classes.progressIcon} ${type === 'ssid' && classes.ssidIconBtn}`} />
    );
    toolTipText = 'Address is in Progress';
    // for access point, if it has enabled ssid from allowlist
  } else if (includesEnabledSSID && type === 'AP') {
    icon = <CheckCircleIcon className={`${classes.icon}`} />;
    toolTipText = 'Address has enabled SSID';
    // for ssid if parent AP is enabled and is listening
  } else if (enabledParentAP && type === 'ssid' && listening) {
    icon = (
      <CheckCircleIcon className={`${classes.icon} ${type === 'ssid' && classes.ssidIconBtn}`} />
    );
    toolTipText = 'Address has enabled parent AccessPoint';
    // for access point if it has listening ssid
  } else if (includesListeningSSID && type === 'AP') {
    icon = <CheckCircleIcon className={`${classes.listeningGreyIcon}`} />;
    toolTipText = 'Address has listening SSID';
  } else if (listening) {
    icon = (
      <CheckCircleIcon
        className={`${classes.listeningGreyIcon} ${type === 'ssid' && classes.ssidIconBtn}`}
      />
    );
    toolTipText = 'Address is listening';
  } else {
    icon = (
      <RadioButtonUncheckedIcon
        className={`${classes.icon} ${type === 'ssid' && classes.ssidIconBtn}`}
      />
    );
    toolTipText = 'Disabled Address';
  }

  return (
    <Tooltip title={toolTipText}>
      <IconButton
        style={{ width: 'fit-content' }}
        onClick={(e) => changeStatus(e, addressPattern, enabled)}
        size="large"
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default AccessPointsIcon;
