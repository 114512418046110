import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getActiveMarker, getSelectedVars } from '../../state/selectors';
import { varNameDetails } from '../../utils/varNames';
import SensorIcon from '../../styles/icons/SensorIcon';
import { setActiveMarker } from '../../state/actions';

function ActiveMarkerSelection(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedVars = useSelector(getSelectedVars);
  const activeMarker = useSelector(getActiveMarker);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ActiveMarkerIcon = varNameDetails[activeMarker].icon ?? SensorIcon;

  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Button
        onClick={handleClick}
        startIcon={<ActiveMarkerIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {varNameDetails[activeMarker].label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {selectedVars
          .sort((a, b) => {
            const va = varNameDetails[a].label;
            const vb = varNameDetails[b].label;
            if (va < vb) return -1;
            if (va > vb) return 1;
            return 0;
          })
          .map((varName) => {
            const { label, icon } = varNameDetails[varName];
            const VarIcon = icon ?? SensorIcon;

            return (
              <MenuItem
                onClick={() => {
                  dispatch(setActiveMarker(varName));
                  handleClose();
                }}
                selected={varName === activeMarker}
              >
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  <VarIcon />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
}

export default ActiveMarkerSelection;
