import React, { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import useStyles from '../styles';
import { getCurrentLocationFloorplan } from '../state/selectors';
import { SensorPositionData } from '../Widgets/Map/types';
import GatewayProvisioningForm from '../components/GatewayProvisioningForm';
import PositioningMap from '../Widgets/Map/PositioningMap';
import ProvisionInitialData from '../Widgets/ProvisionConfig/ProvisionInitialData';
import Header from '../components/Header';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProvisionSensor(): JSX.Element {
  const classes = useStyles();
  const { sensorId } = useParams() as { sensorId: string };
  const query = useQuery();
  const callbackUrl = query.get('callback');
  const hwVer = query.get('hwVer');
  const fwVer = query.get('fwVer');
  const floorPlan = useSelector(getCurrentLocationFloorplan);
  const [position, setPosition] = useState<SensorPositionData>({});
  const [initialDataCollected, setInitialDataCollected] = useState<boolean>(false);
  const [sensorName, setSensorName] = useState('Base Sensor');
  const [sensorShortName, setShortName] = useState('Base');

  const positionChangeHandler = (newPosition: SensorPositionData) => {
    setPosition(newPosition);
  };

  const onFormElevationChange = (newValue: number) => {
    setPosition({
      ...position,
      height: newValue,
    });
  };

  const onFormPolygonChange = (newValue: string) => {
    setPosition({
      ...position,
      polygon: newValue,
    });
  };

  const onFormPositionChange = (newValue: [number, number]) => {
    setPosition({
      ...position,
      lat: newValue[0],
      lng: newValue[1],
    });
  };

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>, param: string) => {
    const { value } = event.target;
    if (param === 'name') {
      const trimmedShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      const capitalizedShortName =
        trimmedShortName.charAt(0).toUpperCase() + trimmedShortName.slice(1);
      setSensorName(value);
      setShortName(capitalizedShortName);
    } else if (param === 'shortName') {
      const fixedLengthShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      setShortName(fixedLengthShortName);
    }
  };

  return (
    <div className={`${classes.bodyContent} ${classes.provisionContainer} `}>
      <Header />
      {!callbackUrl || !hwVer || !fwVer ? (
        <h1>Required parameters not found</h1>
      ) : (
        <div className={`${classes.dashboard} ${classes.provisionFormContainer}`}>
          <div className={classes.dashboardTileContainer}>
            {initialDataCollected ? (
              <GatewayProvisioningForm
                sensorId={sensorId}
                hwVer={hwVer}
                fwVer={fwVer}
                callbackUrl={callbackUrl}
                position={position}
                sensorName={sensorName}
                sensorShortName={sensorShortName}
                nameChangeHandler={nameChangeHandler}
                onElevationChange={onFormElevationChange}
                onPositionChange={onFormPositionChange}
                onPolygonChange={onFormPolygonChange}
              />
            ) : (
              <ProvisionInitialData
                sensorName={sensorName}
                sensorShortName={sensorShortName}
                nameChangeHandler={nameChangeHandler}
                setInitialDataCollected={setInitialDataCollected}
                position={position}
                onPositionChange={onFormPositionChange}
              />
            )}
          </div>
          <div style={{ flex: '40%' }}>
            <PositioningMap
              floorPlan={floorPlan}
              onChange={positionChangeHandler}
              position={position}
              mapName="provisioning"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProvisionSensor;
