import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportOutput, VarName } from '../../services/api';
import PieChartPlot from './PieChartPlot';
import {
  getCo2SummaryPie,
  getTempSummaryPie,
  getHumSummaryPie,
  getPmSummaryPie,
  getUtlSummaryPie,
  getPeakUtlSummaryPie,
} from './helpers/summaryHelpers';
import pieLegend from '../../styles/images/report/pieLegend.svg';
import useStyles from '../../styles/report';

interface SummaryStartReportProps {
  reportData: ReportOutput;
  lastMonthData: ReportOutput;
}

function SummaryStartReport({ reportData, lastMonthData }: SummaryStartReportProps): JSX.Element {
  const classes = useStyles();
  const { bands, utl } = reportData;
  const lastMonthBands = lastMonthData.bands;

  const hasCo2Data = bands.find((data) => data.varName === VarName.Co2ppm);
  const hasTempData = bands.find((data) => data.varName === VarName.TemperatureC);
  const hasHumData = bands.find((data) => data.varName === VarName.RelativeHumidity);
  const hasPmData = bands.find((data) => data.varName === VarName.ParticulateMatter);
  const hasUtlData = utl.find((data) => data.varName === VarName.ClientsWiFi);

  return (
    // assign separate padding for initial page to be compatible with other browsers
    <div className={`${classes.page} ${classes.startingPage} `}>
      <Typography variant="h3">How did you do this month?</Typography>
      <Typography variant="h4">Indoor Air Quality</Typography>
      <div style={{ width: '100%', textAlign: 'end' }}>
        <img className={classes.pieLegendImg} src={pieLegend} alt="pieLegend" />
      </div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', sm: 'start' },
        }}
      >
        {hasCo2Data && <PieChartPlot pieProps={getCo2SummaryPie(bands, lastMonthBands)} />}
        {hasTempData && <PieChartPlot pieProps={getTempSummaryPie(bands, lastMonthBands)} />}
        {hasHumData && <PieChartPlot pieProps={getHumSummaryPie(bands, lastMonthBands)} />}
        {hasPmData && <PieChartPlot pieProps={getPmSummaryPie(bands, lastMonthBands)} />}
      </Box>

      <p>
        This summary shows the percentage of time your space exhibited good conditions during
        operational hours. The criteria for good indoor air quality are derived from scientific
        research papers, the World Health Organisation (WHO), national agencies and building
        regulations (see LightFi Portal for detail).
      </p>
      <Typography variant="h4">Space Utilisation</Typography>
      {hasUtlData && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'start' },
          }}
        >
          <PieChartPlot pieProps={getUtlSummaryPie(utl)} />
          <PieChartPlot pieProps={getPeakUtlSummaryPie(utl)} />
        </Box>
      )}
      <p>
        The summary shows the total average Utilisation rate, which is calculated as the average
        percentage occupancy vs capacity. The Peak Utilisation rate is the total maximum occupancy
        at any time during the period vs capacity. The analysis assumes each area reaches maximum at
        least once during analysis period.
      </p>
    </div>
  );
}

export default SummaryStartReport;
