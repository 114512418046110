/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IconLayer, IconLayerProps } from '@deck.gl/layers';
import { OccSignaturesDetail } from '../../../services/api';
import { isDataExpired } from '../../../utils/functions';
import {
  Marker,
  ICON_ATLAS,
  ICON_MAPPING,
  MAX_FLOOR_OBJECT_ELEVATION,
  MarkerDataProps,
} from '../mapHelpers';

const getOccSignaturesLayer = (
  activeMarkers: MarkerDataProps[]
): IconLayer<unknown, IconLayerProps<unknown>> | null => {
  const personMarkers: Marker[] = [];
  if (activeMarkers) {
    activeMarkers.forEach((sensor) => {
      const { id, detail, time } = sensor;
      if (!isDataExpired(time) && detail?.positions) {
        // eslint-disable-next-line prefer-destructuring
        const occdetail: OccSignaturesDetail = detail;
        occdetail.positions.forEach((point) => {
          personMarkers.push({
            id,
            location: 'location',
            name: 'name',
            coordinates: [point.lng, point.lat, point.height],
            availableData: [],
          });
        });
      }
    });
  }

  return new IconLayer({
    id: 'sensor-layer',
    data: personMarkers,
    pickable: true,
    iconAtlas: ICON_ATLAS,
    iconMapping: ICON_MAPPING,

    getIcon: () => 'person',
    sizeScale: 30,
    // @ts-ignore
    getPosition: (d: Marker) => [
      d.coordinates[0],
      d.coordinates[1],
      MAX_FLOOR_OBJECT_ELEVATION - (d?.coordinates[2] ?? 0),
    ],
    getIconSize: () => 2,
    getIconColor: (d: Marker) => d?.color ?? [52, 95, 145, 128],
  });
};

export default getOccSignaturesLayer;
