import React, { useState, useEffect, ChangeEvent } from 'react';
import Alert from '@mui/material/Alert';
import PageviewIcon from '@mui/icons-material/Pageview';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import useStyles from '../../styles/location';
import useGlobalStyles from '../../styles';
import { UserListItem } from '../../services/api/api';
import UsersList from './UsersList';
import CreateNewUser from './CreateNewUser';
import Loading from '../../components/Loading';
import { AlertMsg, LocationContent } from './LocationProperties';
import { getUsersInLocation } from '../../services/apiService';

interface UsersInLocationProps {
  locationId: string;
  currentContent: LocationContent;
  isDesktopView: boolean;
  setHasUsers: (value: boolean) => void;
}

function UsersInLocation({
  locationId,
  currentContent,
  isDesktopView,
  setHasUsers,
}: UsersInLocationProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [users, setUsers] = useState<UserListItem[]>();
  const [fetchingUsers, setFetchingUsers] = useState<boolean>();
  const [usersInLocation, setUsersInLocation] = useState<UserListItem[]>();
  const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState('all');
  const [searchParam, setSearchParam] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<UserListItem[]>();
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();

  const getUsers = () => {
    setFetchingUsers(true);
    getUsersInLocation(locationId)
      .then((response) => {
        setUsers(response.users);
        setFetchingUsers(false);
        setHasUsers(response.users.length > 0);
      })
      .catch((err) => {
        setAlertMsg({ success: true, msg: err.cause, alertType: 'error' });
        setFetchingUsers(false);
      });
  };

  useEffect(() => {
    setAlertMsg({ success: true, msg: '', alertType: 'info' });
    if (!isDesktopView) {
      if (currentContent === LocationContent.users) {
        getUsers();
      }
    } else getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, currentContent, isDesktopView]);

  useEffect(() => {
    if (users !== undefined) {
      const currentLocationUsers = users.filter((user) => user.location === locationId);
      setUsersInLocation(currentLocationUsers);
      if (showAllUsers) {
        setFilteredUsers(users);
      } else {
        setFilteredUsers(currentLocationUsers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const createUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsCreatingUser(!isCreatingUser);
  };

  useEffect(() => {
    const allUsers = showAllUsers ? users : usersInLocation;
    let selectionFiltration;
    if (selectionType === 'all') {
      selectionFiltration = allUsers;
    } else {
      selectionFiltration = allUsers?.filter((user) => user.status === selectionType);
    }
    if (!searchParam) {
      setFilteredUsers(selectionFiltration);
    } else {
      const regex = new RegExp(searchParam, 'i');
      const searchFilteredItem = selectionFiltration?.filter(
        (user) =>
          (user?.email && user.email.match(regex)) ||
          user.location.match(regex) ||
          (user?.access_level && user.access_level.match(regex))
      );
      setFilteredUsers(searchFilteredItem);
    }
  }, [searchParam, selectionType, showAllUsers, users, usersInLocation]);

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    setSearchParam(value);
  };

  const onSelectChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    setSelectionType(value);
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <List>
        <ListItem>
          <Grid item sm={8} xs={8} style={{ display: 'flex' }}>
            <PersonIcon className={classes.icon} />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>
              Users
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} style={{ textAlign: 'right' }}>
            {!isCreatingUser && (
              <>
                <Tooltip title="Add New User">
                  <IconButton onClick={createUser} data-testid="create-new-user" size="large">
                    <PersonAddIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show filter options">
                  <IconButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    data-testid="show-filter-options"
                    size="large"
                  >
                    <PageviewIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </ListItem>
        {showFilterOptions && (
          <ListItem style={{ paddingTop: '0' }}>
            <Grid item sm={4} xs={4} style={{ display: 'flex' }}>
              <FormControlLabel
                control={<Checkbox checked={showAllUsers} color="primary" />}
                label={<Typography>Include Sub-Locations</Typography>}
                onChange={() => setShowAllUsers(!showAllUsers)}
              />
            </Grid>
            <Grid item sm={6} xs={5} style={{ display: 'flex' }}>
              <PageviewIcon className={classes.icon} />
              <TextField
                placeholder="Search"
                onChange={onTextChange}
                style={{ width: '100%' }}
                variant="standard"
              />
            </Grid>
            <Grid item sm={2} xs={3} style={{ display: 'flex', justifyContent: 'end' }}>
              <Select
                labelId="Sensor type"
                value={selectionType}
                onChange={(e) =>
                  onSelectChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
                }
                className={classes.selectBox}
                variant="standard"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="access_downgraded">Access Downgraded</MenuItem>
                <MenuItem value="temporary_access">Temporary Access</MenuItem>
              </Select>
            </Grid>
          </ListItem>
        )}
      </List>
      <div style={{ padding: '5px 1rem' }}>
        {fetchingUsers && <Loading />}
        {!fetchingUsers && filteredUsers?.length === 0 && !alertMsg?.msg && (
          <Alert severity="error" className={globalClasses.alertMsg}>
            Users not found
          </Alert>
        )}
        {alertMsg?.msg && (
          <Alert severity={alertMsg?.alertType} className={globalClasses.alertMsg}>
            {alertMsg.msg}
          </Alert>
        )}
        {isCreatingUser && (
          <CreateNewUser
            locationId={locationId}
            setIsCreatingUser={setIsCreatingUser}
            refreshUsers={getUsers}
          />
        )}
        {filteredUsers && filteredUsers?.length > 0 && !fetchingUsers && !isCreatingUser && (
          <UsersList allUsers={filteredUsers} refreshUsers={getUsers} />
        )}
      </div>
    </div>
  );
}

export default UsersInLocation;
