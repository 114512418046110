import React, { useEffect, useState, useMemo, ChangeEvent } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import {
  BacnetSettings,
  BacnetSettingsCreate,
  BacnetSettingsUpdate,
  BasicSensorType,
  GatewaySensorType,
  SubSensorType,
} from '../../../services/api';
import { themeProps } from '../../../styles/theme';

interface BacnetConfigFormProps {
  sensorId: string;
  bacnetConfig: BacnetSettings | undefined;
  sensorType: GatewaySensorType | SubSensorType | BasicSensorType | undefined;
  isEditingBacnet: boolean;
  bacnetItem: BacnetSettingsUpdate | BacnetSettingsCreate | undefined;
  showRemoveDialog: () => void;
  handleEdit: () => void;
  requestConfigChange: () => void;
  handleConfigChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
    configType: string
  ) => void;
}

function BacnetConfigForm({
  sensorId,
  bacnetConfig,
  sensorType,
  isEditingBacnet,
  bacnetItem,
  showRemoveDialog,
  handleEdit,
  requestConfigChange,
  handleConfigChange,
}: BacnetConfigFormProps): JSX.Element {
  const [sensorIsGateway, setSensorIsGateway] = useState<boolean>();

  useEffect(() => {
    const isGateway = Object.values(GatewaySensorType).includes(sensorType as GatewaySensorType);
    if (isGateway) {
      setSensorIsGateway(true);
    } else {
      setSensorIsGateway(false);
    }
  }, [sensorType]);

  const editBtnLabel = useMemo(() => {
    let label = 'Edit';
    if (isEditingBacnet) label = 'Cancel';
    else if (!bacnetConfig || (bacnetConfig && !bacnetConfig?.sensor)) label = 'Enable';
    return label;
  }, [bacnetConfig, isEditingBacnet]);

  const sensorBacnetInfo = bacnetConfig?.gateway?.sensors.find((config) => config.id === sensorId);
  const bacnetSensorId = sensorBacnetInfo?.bacnet_id;
  const gatewayBuildingNetNo = bacnetConfig?.gateway?.building_network_no;
  const gatewayVlanNetNo = bacnetConfig?.gateway?.vlan_network_no;
  const showDeleteBtn =
    bacnetConfig && (bacnetConfig?.sensor || sensorIsGateway) && !isEditingBacnet;
  const defaultBacnetId = bacnetItem?.sensor?.bacnet_id
    ? bacnetItem?.sensor?.bacnet_id
    : bacnetSensorId;
  const defaultBuildingNetNum = bacnetItem?.gateway?.building_network_no
    ? bacnetItem?.gateway?.building_network_no
    : gatewayBuildingNetNo;
  const defaultVlanNetNum = bacnetItem?.gateway?.vlan_network_no
    ? bacnetItem?.gateway?.vlan_network_no
    : gatewayVlanNetNo;

  return (
    <>
      <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>Bacnet Sensor</Typography>
      <Divider />
      <ListItem>
        <Grid item sm={6} xs={6}>
          <ListItemText primary="Bacnet id" />
        </Grid>
        <Grid item sm={6} xs={6}>
          {isEditingBacnet ? (
            <TextField
              type="text"
              defaultValue={defaultBacnetId ?? ''}
              onChange={(e) => handleConfigChange(e, 'bacnet_id', 'sensor')}
              style={{ width: '100%' }}
              variant="standard"
            />
          ) : (
            <ListItemText primary={defaultBacnetId ?? '-'} />
          )}
        </Grid>
      </ListItem>
      <ListItem>
        <Grid item sm={6} xs={6}>
          <ListItemText primary="VLAN Device Address" />
        </Grid>
        <Grid item sm={6} xs={6}>
          <ListItemText primary={sensorBacnetInfo?.vlan_device_address ?? '-'} />
        </Grid>
      </ListItem>
      {sensorIsGateway && (
        <>
          <Typography style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '10px' }}>
            Bacnet Gateway
          </Typography>

          <Divider />
          <ListItem>
            <Grid item sm={6} xs={6}>
              <ListItemText primary="Building Network No." />
            </Grid>
            <Grid item sm={6} xs={6}>
              {isEditingBacnet ? (
                <TextField
                  type="text"
                  defaultValue={defaultBuildingNetNum ?? ''}
                  onChange={(e) => handleConfigChange(e, 'building_network_no', 'gateway')}
                  style={{ width: '100%' }}
                  variant="standard"
                />
              ) : (
                <ListItemText primary={defaultBuildingNetNum ?? '-'} />
              )}
            </Grid>
          </ListItem>
          <ListItem>
            <Grid item sm={6} xs={6}>
              <ListItemText primary="VLAN Network No." />
            </Grid>
            <Grid item sm={6} xs={6}>
              {isEditingBacnet ? (
                <TextField
                  type="text"
                  defaultValue={defaultVlanNetNum ?? ''}
                  onChange={(e) => handleConfigChange(e, 'vlan_network_no', 'gateway')}
                  style={{ width: '100%' }}
                  variant="standard"
                />
              ) : (
                <ListItemText primary={defaultVlanNetNum ?? '-'} />
              )}
            </Grid>
          </ListItem>
        </>
      )}
      {isEditingBacnet && (
        <Button
          color="primary"
          variant={themeProps.btnVariant.default}
          onClick={requestConfigChange}
          style={{ marginLeft: '10px' }}
          startIcon={<CheckCircleIcon />}
        >
          Submit
        </Button>
      )}
      {editBtnLabel && (
        <Button
          color={isEditingBacnet ? 'error' : 'primary'}
          variant={themeProps.btnVariant.default}
          onClick={handleEdit}
          style={{ marginLeft: '10px' }}
          startIcon={isEditingBacnet ? <CancelIcon /> : <EditIcon />}
        >
          {editBtnLabel}
        </Button>
      )}
      {showDeleteBtn && (
        <Button
          color="error"
          variant={themeProps.btnVariant.default}
          onClick={showRemoveDialog}
          style={{ marginLeft: '10px' }}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}
    </>
  );
}

export default BacnetConfigForm;
