import React, { ChangeEvent, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchTerm } from '../state/actions';
import { getSearchTerm } from '../state/selectors';
import useStyles from '../styles';

function SearchBox(): JSX.Element {
  const classes = useStyles();
  const searchTerm = useSelector(getSearchTerm);
  const [currentValue, setCurrentValue] = useState(searchTerm.term);
  const dispatch = useDispatch();

  const onChangeDebounced = debounce((value: string) => {
    dispatch(setSearchTerm({ term: value, source: 'searchComponent' }));
  }, 500);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDebounced(value);
    setCurrentValue(value);
  };

  const onClearSearchTerm = () => {
    dispatch(setSearchTerm({ term: '', source: undefined }));
    setCurrentValue('');
  };

  return (
    <TextField
      onChange={onChange}
      id="search-input-box"
      placeholder="Search"
      value={currentValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="secondary" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              visibility: currentValue.length > 0 ? 'visible' : 'hidden',
            }}
          >
            <IconButton size="small" onClick={onClearSearchTerm}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      type="text"
      size="small"
      className={classes.searchBox}
      variant="standard"
    />
  );
}

export default SearchBox;
