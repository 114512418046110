import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SvgIconProps } from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import useGlobalStyles from '../../styles';
import { SortType } from '../../state/types';
import { getSortBy } from '../../state/selectors';
import { setSortBy } from '../../state/actions';
import SortAlphaAscIcon from '../../styles/icons/SortAlphaAscIcon';
import SortAlphaDescIcon from '../../styles/icons/SortAlphaDescIcon';
import SortNumericDescIcon from '../../styles/icons/SortNumericDescIcon';
import SortNumericAscIcon from '../../styles/icons/SortNumericAscIcon';
import SortLocAlphaAscIcon from '../../styles/icons/SortLocAlphaAscIcon';
import SortTimeOldestFirst from '../../styles/icons/SortTimeOldestFirst';
import SortTimeNewestFirst from '../../styles/icons/SortTimeNewestFirst';
import SortLocAlphaDescIcon from '../../styles/icons/SortLocAlphaDescIcon';

export enum SortPropertyType {
  name = 'name',
  location = 'location',
  value = 'value',
  time = 'time',
}

const sortOptions: (SortType & {
  description: string;
  icon: (props: SvgIconProps) => JSX.Element;
})[] = [
  {
    property: SortPropertyType.name,
    ascending: true,
    description: 'Name (alphabetically)',
    icon: SortAlphaAscIcon,
  },
  {
    property: SortPropertyType.name,
    ascending: false,
    description: 'Name (reverse alpha)',
    icon: SortAlphaDescIcon,
  },
  {
    property: SortPropertyType.location,
    ascending: true,
    description: 'Location (alphabetically)',
    icon: SortLocAlphaAscIcon,
  },
  {
    property: SortPropertyType.location,
    ascending: false,
    description: 'Location (reverse alpha)',
    icon: SortLocAlphaDescIcon,
  },
  {
    property: SortPropertyType.value,
    ascending: true,
    description: 'Value (lowest first)',
    icon: SortNumericAscIcon,
  },
  {
    property: SortPropertyType.value,
    ascending: false,
    description: 'Value (highest first)',
    icon: SortNumericDescIcon,
  },
  {
    property: SortPropertyType.time,
    ascending: true,
    description: 'Time (oldest first)',
    icon: SortTimeOldestFirst,
  },
  {
    property: SortPropertyType.time,
    ascending: false,
    description: 'Time (newest first)',
    icon: SortTimeNewestFirst,
  },
];

interface BaseArraySortMenuProps {
  sortProperties?: SortPropertyType[];
  iconOnly?: boolean;
}
function BaseArraySortMenu({ sortProperties, iconOnly }: BaseArraySortMenuProps): JSX.Element {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const sortBy = useSelector(getSortBy);

  const sortMenu = useMemo(() => {
    let sortMenuItem = sortOptions;
    if (sortProperties) {
      const menuItem = [];
      for (let i = 0; i < sortProperties.length; i++) {
        menuItem.push(sortOptions.filter((option) => option.property === sortProperties[i]));
      }
      sortMenuItem = menuItem.flat();
    }
    return sortMenuItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortByHandler = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    for (let i = 0; i < sortMenu.length; i++) {
      const so = sortMenu[i];
      if (newValue === `${so.property}-${so.ascending}`) {
        dispatch(setSortBy(so));
        return;
      }
    }
  };

  return (
    <Select
      onChange={sortByHandler}
      variant="standard"
      value={`${sortBy?.property ?? 'name'}-${sortBy.ascending ?? true}`}
    >
      {sortMenu.map(({ property, ascending, description, icon }) => {
        const SortIcon = icon as (props: SvgIconProps) => JSX.Element;
        return (
          <MenuItem
            key={`sortBy-${Math.random()}-${property}-${ascending}`}
            value={`${property}-${ascending}`}
          >
            <Box style={{ display: 'flex' }}>
              <SortIcon className={globalClasses.sortIcon} />
              <Box
                className={globalClasses.hideInSmallScreen}
                sx={{ display: { xs: iconOnly ? 'none' : '' } }}
              >
                <span>Sort by {description}</span>
              </Box>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
}

BaseArraySortMenu.defaultProps = {
  sortProperties: undefined,
  iconOnly: false,
};

export default BaseArraySortMenu;
