import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ButtonTooltip from '@mui/material/Tooltip';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AbcIcon from '@mui/icons-material/Abc';
import useStyles from '../../styles';
import { setMapSize, toggleShowSensorLabels } from '../../state/actions';

interface MapToolbarProps {
  mapSize?: 'collapsed' | 'normal' | 'full';
  allowChangeMapSize?: boolean;
  fitToView?: () => void;
  resetOrientation?: () => void;
  toggleShowLabels?: () => void;
  showLabels?: boolean;
  pitch: number;
  bearing: number;
}

function MapToolbar({
  mapSize,
  allowChangeMapSize,
  fitToView,
  resetOrientation,
  toggleShowLabels,
  showLabels,
  pitch,
  bearing,
}: MapToolbarProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const changeMapSize = () => {
    if (mapSize === 'normal') {
      dispatch(setMapSize('full'));
    } else {
      dispatch(setMapSize('normal'));
    }
  };

  return (
    <div className={` ${classes.mapToolbar}`}>
      {allowChangeMapSize && (
        <ButtonTooltip title="Toggle map size" placement="left">
          <IconButton onClick={changeMapSize} color="inherit" size="large">
            {mapSize === 'full' ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </ButtonTooltip>
      )}
      {fitToView && (
        <ButtonTooltip title="Go to location" placement="left">
          <IconButton onClick={fitToView} color="inherit" size="large">
            <MyLocationIcon />
          </IconButton>
        </ButtonTooltip>
      )}
      {resetOrientation && (
        <ButtonTooltip title="Reset orientation" placement="left">
          <IconButton onClick={resetOrientation} color="inherit" size="large">
            {pitch !== 0 || bearing !== 0 ? (
              /* eslint-disable indent */
              <ExploreOffIcon />
            ) : (
              <ExploreIcon />
            )}
            {/* eslint-enable indent */}
          </IconButton>
        </ButtonTooltip>
      )}
      {toggleShowLabels && (
        <ButtonTooltip title="Toggle floor labels" placement="left">
          <IconButton onClick={toggleShowLabels} color="inherit" size="large">
            {showLabels ? <InfoIcon /> : <InfoOutlinedIcon />}
          </IconButton>
        </ButtonTooltip>
      )}
      <ButtonTooltip title="Show Sensor labels" placement="left">
        <IconButton onClick={() => dispatch(toggleShowSensorLabels())} color="inherit" size="large">
          <AbcIcon />
        </IconButton>
      </ButtonTooltip>
    </div>
  );
}

MapToolbar.defaultProps = {
  allowChangeMapSize: true,
  fitToView: undefined,
  resetOrientation: undefined,
  toggleShowLabels: undefined,
  mapSize: 'normal',
  showLabels: true,
};

export default MapToolbar;
