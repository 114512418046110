import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import QRCode from 'qrcode.react';
import { AlertMsg } from '../LocationConfig/LocationProperties';
import Loading from '../../components/Loading';

interface MfaTotpDialogProps {
  openDialog: boolean;
  closeDialog: () => void;
}

function MfaTotpDialog({ openDialog, closeDialog }: MfaTotpDialogProps): JSX.Element {
  const [qrCode, setQrCode] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [isTotpVerified, setIsTotpVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [token, setToken] = useState('');
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    const username = user?.attributes?.email;
    if (username) {
      Auth.setupTOTP(user)
        .then((code) => {
          setTotpCode(code);
          const str = `otpauth://totp/LightFi-Portal:${username}?secret=${code}&issuer:LightFi-Portal`;
          setQrCode(str);
        })
        .catch((e) => {
          setAlertMsg({ success: true, msg: e, alertType: 'error' });
        });
    }
  }, [user]);

  const handleSubmit = () => {
    setIsVerifying(true);
    Auth.verifyTotpToken(user, token)
      .then(() => {
        Auth.setPreferredMFA(user, 'TOTP').then(() => {
          setAlertMsg({ success: true, msg: 'Token has been verified', alertType: 'success' });
          setIsTotpVerified(true);
          setIsVerifying(false);
        });
      })
      .catch(() => {
        setAlertMsg({ success: true, msg: 'Token is not verified', alertType: 'error' });
        setIsVerifying(false);
      });
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          textAlign: 'center',
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Two Step Verification</DialogTitle>
      <DialogContent>
        {!isTotpVerified && (
          <>
            {isVerifying && <Loading />}
            <Typography variant="body1">
              Scan the image below with the two-factor authentication app. If you cannot use a QR
              code, enter the text code instead.
            </Typography>
            <br />
            <QRCode value={qrCode} />
            <Typography variant="h6">{totpCode}</Typography>
            <br />
            <Typography variant="body1">
              After scanning the QR code image, the app will display a code that you can enter below
            </Typography>
            <TextField
              margin="dense"
              id="name"
              label="Verify Token"
              type="text"
              fullWidth
              onChange={(e) => setToken(e.target.value)}
            />
          </>
        )}
        {isTotpVerified && (
          <Typography variant="body1">
            The next time you login, you will need to provide a two-factor authentication code
          </Typography>
        )}
        {alertMsg && <Alert severity={alertMsg.alertType}>{alertMsg.msg}</Alert>}
      </DialogContent>
      <DialogActions>
        {!isVerifying && !isTotpVerified && (
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        )}
        <Button onClick={closeDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MfaTotpDialog;
