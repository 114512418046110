import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import chunk from 'lodash/chunk';
import Plot from '../PlotlyCustom';
import { VarName } from '../../../services/api';
import {
  getCalendarPlotLayout,
  getColorScaleValue,
  getZmaxValue,
  getSubPlotResult,
  getHighlightedItems,
  getMainPlotData,
  getMinMaxAvgPerDayData,
  getAvgPerColumnData,
  getMinMaxAvgPerColumnData,
  getCsvData,
  getDatesArray,
} from './calendarViewPlotHelpers';
import {
  getSelectedStartDate,
  getSelectedEndDate,
  getSelectedHours,
} from '../../../state/selectors';
import {
  CalendarSelectionType,
  HoveredPlot,
  HeatmapHoverSelection,
  PlotType,
  ValueType,
} from '../../CalendarView/helpers';
import { varNameBandParams } from '../../../utils/dataBandParams';
import { combineStats, Stats } from '../../../utils/statistics';
import { varNameDetails } from '../../../utils/varNames';

interface CalendarViewPlotProps {
  combinedSensorStats: Stats;
  varName: VarName;
  valueType: ValueType | undefined;
  showGradient?: boolean;
  setHoveredPlot?: ((value: HeatmapHoverSelection | undefined) => void) | undefined;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  sourceLabel: string;
}

function CalendarViewPlot({
  combinedSensorStats,
  varName,
  valueType,
  showGradient,
  setHoveredPlot,
  downloadData,
  setDownloadData,
  sourceLabel,
}: CalendarViewPlotProps): JSX.Element | null {
  const theme = useTheme();
  const { selectHours, includeWeekends } = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  // extend range to make sure we have value (even empty data) for every day of the week
  const allDates = getDatesArray(dayjs(startDate).day(0).toDate(), dayjs(endDate).day(6).toDate());
  const isTot = combinedSensorStats.totalAdded !== undefined;
  const isUtl = combinedSensorStats.utl !== undefined;

  const mainPlotXWeekStart = allDates.filter((_, index) => index % 7 === 0);
  const mainPlotYDayOfWeek = allDates.slice(0, 7);

  const highlightTimes = getHighlightedItems(mainPlotYDayOfWeek, !selectHours || includeWeekends);

  const initialMainPlotZvalues = useMemo(() => {
    const zValues: number[] = [];
    allDates.forEach((date) => {
      const dateStr = dayjs(date).format('YYYY-MM-DD');
      const stats = combinedSensorStats.heatMapStats?.byDate.get(dateStr);
      if (stats === undefined) {
        // Use -Infinity to indicate missing data
        zValues.push(-Infinity);
      } else if (isTot) {
        zValues.push(stats.totalAdded ?? -Infinity);
      } else if (isUtl) {
        const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
        zValues.push(utlPct);
      } else if (valueType === ValueType.min) {
        zValues.push(stats.maxMinAvg.min);
      } else if (valueType === ValueType.max) {
        zValues.push(stats.maxMinAvg.max);
      } else {
        zValues.push(Math.round(stats.maxMinAvg.mean * 10) / 10);
      }
    });
    return zValues;
  }, [allDates, combinedSensorStats, valueType, isTot, isUtl]);

  // If the data has bands this will extend to the next band (for colour scale)
  const zmax = useMemo(() => {
    const maxData = isTot ? Math.max(...initialMainPlotZvalues) : combinedSensorStats.maxMinAvg.max;
    return getZmaxValue([maxData], varName);
  }, [combinedSensorStats, varName, isTot, initialMainPlotZvalues]);

  // If the data has bands this will extend to the previous band (for colour scale)
  const zmin = useMemo(() => {
    if (isTot) return 0;
    if (isUtl) return 0;
    const bands = varNameBandParams[varName];
    const bandMinValue = bands ? bands[0]?.upperBound : 0;
    return Math.min(bandMinValue, combinedSensorStats.maxMinAvg.min);
  }, [isTot, isUtl, combinedSensorStats, varName]);

  const colorscaleValue = useMemo(
    () => getColorScaleValue(varName, zmin, zmax, showGradient),
    [varName, zmin, zmax, showGradient]
  );

  const mainPlotZValues = useMemo(() => {
    const nWeeks = Math.ceil(initialMainPlotZvalues.length / 7);
    const shaped = chunk(initialMainPlotZvalues, nWeeks);
    const rotated = shaped.map((row, rowIndex) =>
      row.map((_, colIndex) => initialMainPlotZvalues[colIndex * 7 + rowIndex])
    );
    return rotated;
  }, [initialMainPlotZvalues]);

  // zValues for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDay = useMemo(() => {
    const zValues: number[] = [];
    mainPlotYDayOfWeek.forEach((date) => {
      const day = date.getDay();
      const stats = combinedSensorStats.heatMapStats?.byDayOfWeek.get(day);
      if (stats === undefined) {
        zValues.push(-Infinity);
        zValues.push(-Infinity);
        zValues.push(-Infinity);
      } else if (isTot) {
        zValues.push(stats.totalAdded ?? -Infinity);
      } else if (isUtl) {
        const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
        zValues.push(utlPct);
        zValues.push(utlPct);
        zValues.push(utlPct);
      } else {
        zValues.push(
          Number.isNaN(stats.maxMinAvg.min) ? -Infinity : stats.maxMinAvg.min,
          Number.isNaN(stats.maxMinAvg.mean)
            ? -Infinity
            : Math.round(stats.maxMinAvg.mean * 10) / 10,
          Number.isNaN(stats.maxMinAvg.max) ? -Infinity : stats.maxMinAvg.max
        );
      }
    });
    return chunk(zValues, isTot ? 1 : 3);
  }, [mainPlotYDayOfWeek, combinedSensorStats, isTot, isUtl]);

  // zValues for subplot that shows just the avg from the main plot for each hours column
  const avgPerWeekColumn = useMemo(() => {
    const zValues: number[] = [];
    mainPlotXWeekStart.forEach((weekStartDate) => {
      const weekStats: Stats[] = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(weekStartDate);
        date.setDate(weekStartDate.getDate() + i);
        const dateStrFormatted = dayjs(date).format('YYYY-MM-DD');
        const dayStats = combinedSensorStats.heatMapStats?.byDate.get(dateStrFormatted);
        if (dayStats !== undefined && (!selectHours || includeWeekends || ![0, 6].includes(i))) {
          weekStats.push(dayStats);
        }
      }
      let stats;
      if (weekStats.length > 0) stats = combineStats(weekStats);
      if (stats === undefined) {
        zValues.push(-Infinity);
      } else if (isTot) {
        zValues.push(stats.totalAdded ?? -Infinity);
      } else if (isUtl) {
        const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
        zValues.push(utlPct);
      } else {
        zValues.push(
          Number.isNaN(stats.maxMinAvg.mean)
            ? -Infinity
            : Math.round(stats.maxMinAvg.mean * 10) / 10
        );
      }
    });
    return [zValues];
  }, [mainPlotXWeekStart, combinedSensorStats, isTot, isUtl, selectHours, includeWeekends]);

  // zValues for subplot that shows actual min, max and avg of all time
  const minMaxAvgPerColumn = useMemo(() => {
    const stats = combinedSensorStats;
    const zValues: number[] = [];
    if (stats === undefined) {
      zValues.push(-Infinity);
      zValues.push(-Infinity);
      zValues.push(-Infinity);
    } else if (isTot) {
      zValues.push(stats.totalAdded ?? -Infinity);
    } else if (isUtl) {
      const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
      zValues.push(utlPct);
      zValues.push(utlPct);
      zValues.push(utlPct);
    } else {
      zValues.push(
        Number.isNaN(stats.maxMinAvg.min) ? -Infinity : stats.maxMinAvg.min,
        Number.isNaN(stats.maxMinAvg.mean) ? -Infinity : Math.round(stats.maxMinAvg.mean * 10) / 10,
        Number.isNaN(stats.maxMinAvg.max) ? -Infinity : stats.maxMinAvg.max
      );
    }
    return [zValues];
  }, [combinedSensorStats, isTot, isUtl]);

  const mainPlotData = getMainPlotData(
    colorscaleValue,
    mainPlotXWeekStart,
    mainPlotYDayOfWeek,
    mainPlotZValues,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric ?? ''
  );

  // plotly data for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDayData = getMinMaxAvgPerDayData(
    colorscaleValue,
    minMaxAvgPerDay,
    mainPlotYDayOfWeek,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  // plotly data for subplot that shows just the avg from the main plot
  const avgPerWeekColumnData = getAvgPerColumnData(
    colorscaleValue,
    avgPerWeekColumn,
    mainPlotXWeekStart,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  // plotly data for subplot that shows avg of min/max/avg
  const minMaxAvgPerColumnData = getMinMaxAvgPerColumnData(
    colorscaleValue,
    minMaxAvgPerColumn,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  const totSubPlot = [
    {
      x: [PlotType.tot],
      y: mainPlotYDayOfWeek,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXWeekStart, y: [PlotType.tot], z: avgPerWeekColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.tot],
      y: [PlotType.tot],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const minMaxAvgSubPlot = [
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: mainPlotYDayOfWeek,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXWeekStart, y: [PlotType.avg], z: avgPerWeekColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: [PlotType.avg],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const subPlots = isTot ? totSubPlot : minMaxAvgSubPlot;

  const layout = useMemo(() => {
    let plotlyLayout;
    const isLongRange = dayjs(endDate).diff(dayjs(startDate), 'days') > 180;
    if (mainPlotXWeekStart && mainPlotYDayOfWeek && mainPlotZValues) {
      plotlyLayout = getCalendarPlotLayout(
        mainPlotXWeekStart,
        mainPlotYDayOfWeek,
        mainPlotZValues,
        isLongRange,
        highlightTimes,
        valueType,
        theme
      );
    }
    for (let i = 0; i < subPlots.length; i++) {
      const { x, y, z, xRef, yRef } = subPlots[i];
      const subPlotResult = getSubPlotResult(x, y, z, xRef, yRef, isLongRange, valueType);
      if (subPlotResult && plotlyLayout?.annotations) {
        const updatedLayout = plotlyLayout?.annotations.concat(subPlotResult);
        plotlyLayout.annotations = updatedLayout;
      }
    }
    return plotlyLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmin, highlightTimes, valueType]);

  const hasAllPlotData =
    mainPlotData && minMaxAvgPerDayData && avgPerWeekColumnData && minMaxAvgPerColumnData;

  const handleHover = (plotDatum: HoveredPlot | undefined) => {
    if (setHoveredPlot === undefined) return;
    if (plotDatum === undefined) {
      setHoveredPlot(undefined);
      return;
    }
    const filter: HeatmapHoverSelection = { hoverValue: plotDatum.z as number };
    if (plotDatum.y !== PlotType.avg && plotDatum.y !== PlotType.tot) {
      // Is a value for a specific day of the week (i.e. not summary of all days)
      filter.dayOfWeek = dayjs(plotDatum.y).get('day');
    }
    if (plotDatum.x === PlotType.min) {
      filter.summaryType = PlotType.min;
    } else if (plotDatum.x === PlotType.avg) {
      filter.summaryType = PlotType.avg;
    } else if (plotDatum.x === PlotType.max) {
      filter.summaryType = PlotType.max;
    } else if (plotDatum.x === PlotType.tot) {
      filter.summaryType = PlotType.tot;
    } else {
      // Is a specific week
      filter.weekWithStart = new Date(dayjs(plotDatum.x).format('YYYY-MM-DD'));
    }
    if (filter.dayOfWeek !== undefined && filter.weekWithStart !== undefined) {
      const start = dayjs(filter.weekWithStart);
      // Get the day of the week for the start date
      const startDay = start.day();
      // Calculate the difference in days
      let diff = filter.dayOfWeek - startDay;
      // If the difference is negative, add 7 to get the date in the next week
      if (diff < 0) {
        diff += 7;
      }
      // Calculate the specific date by adding the difference in days
      const specificDate = start.add(diff, 'day');
      // Return the specific date as a JavaScript Date object
      filter.date = specificDate.toDate();
      delete filter.dayOfWeek;
      delete filter.weekWithStart;
    }
    setHoveredPlot(filter);
  };

  const handleDownload = () => {
    setDownloadData(false);
    const csvData = getCsvData(
      zmin,
      mainPlotXWeekStart,
      mainPlotYDayOfWeek,
      mainPlotZValues,
      minMaxAvgPerDay,
      avgPerWeekColumn,
      minMaxAvgPerColumn,
      CalendarSelectionType.calendar
    );
    if (csvData) {
      const csvFile = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = `${sourceLabel}-${varName}-${dayjs(startDate).format('LL')}-${dayjs(
        endDate
      ).format('LL')}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    return null;
  };

  if (hasAllPlotData && layout) {
    return (
      <>
        {downloadData && handleDownload()}
        <Plot
          data={[mainPlotData, minMaxAvgPerDayData, avgPerWeekColumnData, minMaxAvgPerColumnData]}
          layout={layout}
          style={{ width: '100%', height: '100%' }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onHover={(e) => handleHover({ x: e.points[0].x, y: e.points[0].y, z: e.points[0].z })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onUnhover={() => handleHover(undefined)}
          config={{ displayModeBar: false, showAxisDragHandles: false }}
          useResizeHandler
        />
      </>
    );
  }
  return null;
}

CalendarViewPlot.defaultProps = {
  showGradient: true,
  setHoveredPlot: undefined,
};

export default CalendarViewPlot;
