import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortTimeNewestFirst(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M157.5 93C77.134 93 12 158.134 12 238.5C12 318.866 77.134 384 157.5 384C237.866 384 303 318.866 303 238.5C303 158.134 237.866 93 157.5 93ZM157.5 359.333C90.7746 359.333 36.6668 305.225 36.6668 238.5C36.6668 171.775 90.7746 117.667 157.5 117.667C224.225 117.667 278.333 171.775 278.333 238.5C278.333 305.225 224.225 359.333 157.5 359.333Z" />
      <path d="M214.222 279.592L167.901 246.116V165.75C167.901 164.329 166.707 163.136 165.287 163.136H149.657C148.236 163.136 147.042 164.329 147.042 165.75V255.21C147.042 256.062 147.44 256.858 148.122 257.313L201.832 296.473C203.026 297.325 204.617 297.041 205.47 295.904L214.734 283.23C215.643 282.036 215.416 280.445 214.222 279.592Z" />
      <path d="M468.875 249.5V384H417.125V249.5L374 249.5L443 115L512 249.5H468.875Z" />
    </SvgIcon>
  );
}

export default SortTimeNewestFirst;
