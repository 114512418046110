import { ReportBandsData, ReportUtlData, VarName } from '../../../services/api';
import { varNameDetails } from '../../../utils/varNames';
import { PieChartProperties, reportColors } from '../constants';

const getDesiredPct = (dataBands: ReportBandsData[], varName: VarName) =>
  dataBands.find((data) => data.varName === varName)?.summary.desiredPct;

// generate co2 summary pie
export const getCo2SummaryPie = (
  reportDataBands: ReportBandsData[],
  lastMonthBands: ReportBandsData[]
) => {
  const co2ThisMonthDesiredPct = getDesiredPct(reportDataBands, VarName.Co2ppm);
  const co2LastMonthDesiredPct = getDesiredPct(lastMonthBands, VarName.Co2ppm);

  const co2SummaryPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: co2ThisMonthDesiredPct,
    childRingValue: co2LastMonthDesiredPct
      ? [100 - co2LastMonthDesiredPct, co2LastMonthDesiredPct]
      : [],
    childRingColor: [reportColors.white, reportColors.lightGreen],
    colors: [reportColors.white, reportColors.green],
    titleText: varNameDetails[VarName.Co2ppm]?.label,
    values: co2ThisMonthDesiredPct ? [100 - co2ThisMonthDesiredPct, co2ThisMonthDesiredPct] : [],
  };
  return co2SummaryPie;
};

// generate Temperature summary pie
export const getTempSummaryPie = (
  reportDataBands: ReportBandsData[],
  lastMonthBands: ReportBandsData[]
) => {
  const tempThisMonthDesiredPct = getDesiredPct(reportDataBands, VarName.TemperatureC);
  const tempLastMonthDesiredPct = getDesiredPct(lastMonthBands, VarName.TemperatureC);

  const tempSummaryPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: tempThisMonthDesiredPct,
    childRingValue: tempLastMonthDesiredPct
      ? [100 - tempLastMonthDesiredPct, tempLastMonthDesiredPct]
      : [],
    childRingColor: [reportColors.white, reportColors.lightGreen],
    colors: [reportColors.white, reportColors.green],
    titleText: varNameDetails[VarName.TemperatureC]?.label,
    values: tempThisMonthDesiredPct ? [100 - tempThisMonthDesiredPct, tempThisMonthDesiredPct] : [],
  };
  return tempSummaryPie;
};

// generate Humidity summary pie
export const getHumSummaryPie = (
  reportDataBands: ReportBandsData[],
  lastMonthBands: ReportBandsData[]
) => {
  const humThisMonthDesiredPct = getDesiredPct(reportDataBands, VarName.RelativeHumidity);
  const humLastMonthDesiredPct = getDesiredPct(lastMonthBands, VarName.RelativeHumidity);

  const humSummaryPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: humThisMonthDesiredPct,
    childRingValue: humLastMonthDesiredPct
      ? [100 - humLastMonthDesiredPct, humLastMonthDesiredPct]
      : [],
    childRingColor: [reportColors.white, reportColors.lightGreen],
    colors: [reportColors.white, reportColors.green],
    titleText: varNameDetails[VarName.RelativeHumidity]?.label,
    values: humThisMonthDesiredPct ? [100 - humThisMonthDesiredPct, humThisMonthDesiredPct] : [],
  };
  return humSummaryPie;
};

// generate Particulate Matter summary pie
export const getPmSummaryPie = (
  reportDataBands: ReportBandsData[],
  lastMonthBands: ReportBandsData[]
) => {
  const pmThisMonthDesiredPct = getDesiredPct(reportDataBands, VarName.ParticulateMatter);
  const pmLastMonthDesiredPct = getDesiredPct(lastMonthBands, VarName.ParticulateMatter);

  const pmSummaryPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: pmThisMonthDesiredPct,
    childRingValue: pmLastMonthDesiredPct
      ? [100 - pmLastMonthDesiredPct, pmLastMonthDesiredPct]
      : [],
    childRingColor: [reportColors.white, reportColors.lightGreen],
    colors: [reportColors.white, reportColors.green],
    titleText: varNameDetails[VarName.ParticulateMatter]?.label,
    values: pmThisMonthDesiredPct ? [100 - pmThisMonthDesiredPct, pmThisMonthDesiredPct] : [],
  };
  return pmSummaryPie;
};

// generate Utl summary pie
export const getUtlSummaryPie = (utl: ReportUtlData[]) => {
  const thisMonthUtl = utl.find((data) => data.varName === VarName.ClientsWiFi)?.summary.utl;
  const utlSummaryPie: PieChartProperties = {
    annotationColor: reportColors.blue,
    annotationText: thisMonthUtl && thisMonthUtl * 100,
    colors: [reportColors.white, reportColors.blue],
    titleText: 'Utilisation',
    values: thisMonthUtl ? [100 - thisMonthUtl * 100, thisMonthUtl * 100] : [],
  };
  return utlSummaryPie;
};

// generate Peak Utl summary pie
export const getPeakUtlSummaryPie = (utl: ReportUtlData[]) => {
  const thisMonthPeakUtl = utl.find((data) => data.varName === VarName.ClientsWiFi)?.summary
    .peakUtl;
  const utlSummaryPie: PieChartProperties = {
    annotationColor: reportColors.blue,
    annotationText: thisMonthPeakUtl && thisMonthPeakUtl * 100,
    colors: [reportColors.white, reportColors.blue],
    titleText: 'Peak Utilisation',
    values: thisMonthPeakUtl ? [100 - thisMonthPeakUtl * 100, thisMonthPeakUtl * 100] : [],
  };
  return utlSummaryPie;
};
