import React  from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/dashboard';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getSensorsById,
} from '../../state/selectors';
import { varNameDetails } from '../../utils/varNames';
import SensorIcon from '../../styles/icons/SensorIcon';
import SelectedVarsIconBtn from '../../components/SelectedVarsIconBtn';
import ColouredLinePlotWrapper from '../HelperComponents/ColouredLinePlotWrapper';

function VarNamePlotContainer(): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const activeMarker = useSelector(getActiveMarker);
  const clickedItem = useSelector(getClickedItem);
  const highlightedItem = useSelector(getHighlightedItem);
  const sensorDetails = useSelector(getSensorsById);

  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const selectedVarName = activeItem.varName ?? activeMarker;
  const ActiveMarkerIcon = varNameDetails[selectedVarName]?.icon ?? SensorIcon;

  return (
    <Card className={classes.dashboardCard} style={{ height: '300px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <ActiveMarkerIcon style={{ alignSelf: 'center' }} />
          <Typography variant="h6" sx={{ marginLeft: '5px', alignSelf: 'center' }}>
            {sensorDetails.get(activeItem.id)?.name ?? ''}
          </Typography>
        </Box>
        <SelectedVarsIconBtn />
      </Box>
      {activeItem && activeItem.varName === selectedVarName && (
        <div className={globalClasses.sensorGraph} style={{ marginTop: '10px' }}>
          <ColouredLinePlotWrapper varName={selectedVarName} sensorId={activeItem.id} />
        </div>
      )}
    </Card>
  );
}

export default VarNamePlotContainer;
