import { DailyMetricItem, SensorLatest } from '../../services/api';
import { themeProps } from '../../styles/theme';
import { getDataBandParams, motionUtilisationBands } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';
import { SortType } from '../../state/types';
import { getDataValueString } from '../HelperComponents/DataValueString';
import { Stats } from '../../utils/statistics';

export enum CalendarSelectionType {
  week = 'week',
  calendar = 'calendar',
}

export interface SensorValueItem {
  id: string;
  value: number | null;
}

export interface HoveredPlot {
  x: string | number | Date | null;
  y: string | number | Date | null;
  z: string | number | null;
}

export interface HeatmapHoverSelection {
  hour?: number;  // If we are hovering over a specific hour of the day e.g. 9
  dayOfWeek?: number;  // If we are hovering over a specific day of the week e.g. 0 (Sun)
  date?: Date  // If we are hovering over a specific calendar date;
  weekWithStart?: Date;  // If we are hovering over a specific week in the calendar;
  summaryType?: PlotType  // If we are hovering over e.g. min/max values
  hoverValue?: number;  // The number shown in the heatmap where we are hovering
}

export interface SensorAllStats {
  stats: Stats;
  allMetrics: DailyMetricItem[];
}

export interface SensorFilteredStats {
  stats: Stats;
  summaryValue: number;
}

export enum ValueType {
  min = 'Minimum',
  avg = 'Average',
  max = 'Maximum',
  occ = 'Occupancy',
  utl = ' Utilisation',
}

export enum FloorplanSpace {
  room = 'room',
  floor = 'floor',
  desk = 'desk',
  area = 'area',
}

export enum PlotType {
  avg = 'Avg',
  min = 'Min',
  max = 'Max',
  tot = 'Total',
}

export interface SpaceProperty {
  label: string;
  code: string;
}

export const spaceProperties: Record<FloorplanSpace, SpaceProperty> = {
  room: {
    label: 'Room',
    code: 'area.room',
  },
  floor: {
    label: 'Floor',
    code: 'area.floor',
  },
  desk: {
    label: 'Desk',
    code: 'furniture.desk',
  },
  area: {
    label: 'Area',
    code: 'area',
  },
};

export const getHourSet = (selectHours: boolean, startHour: number, endHour: number) => {
  let startH = 0;
  let endH = 23;
  if (selectHours) {
    startH = startHour;
    endH = endHour - 1;
  }
  const allHours = [];
  for (let i = startH; i <= endH; i++) allHours.push(i);
  return allHours;
};


export const getMotionUtlBandParams = (value: number) => {
  const bandParams = motionUtilisationBands
    .filter((params) => value <= params.upperBound)
    .sort((paramsA, paramsB) => paramsA.upperBound - paramsB.upperBound)[0];
  return bandParams;
};

export const getDataString = (value: number | null | undefined, varName: VarName) => {
  let dataValueString = '';
  // eslint-disable-next-line use-isnan
  if (value !== null && value !== undefined) {
    if (varName === VarName.MotionEvent) {
      const valueString = value.toString();
      dataValueString = `${valueString} %`;
    } else dataValueString = getDataValueString(value, varName, undefined, undefined, false);
  }
  return dataValueString;
};

export const getBandColor = (value: number | null | undefined, varName: VarName) => {
  let color = themeProps.colors.grey;
  if (value !== null && value !== undefined && !Number.isNaN(value)) {
    if (varName === VarName.MotionEvent) {
      color = getMotionUtlBandParams(value).color;
    } else color = getDataBandParams(varName, value)?.color;
  }
  return color;
};

export const sortSensors = (
  sensors: SensorLatest[],
  sortBy: SortType,
  sensorFilteredStats: Map<string, SensorFilteredStats>
) => {
  let sortedSensors = sensors;
  for (let i = 0; i < sensors.length; i++) {
    if (sortBy.property === 'name') {
      sortedSensors = sensors.sort((a, b) => {
        const nameA = a.name ?? a.id;
        const nameB = b.name ?? b.id;
        if (nameA > nameB && !sortBy.ascending) {
          return -1;
        }
        if (nameA < nameB && !sortBy.ascending) {
          return 1;
        }
        if (nameA > nameB && sortBy.ascending) {
          return 1;
        }
        if (nameA < nameB && sortBy.ascending) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy.property === 'value') {
      sortedSensors = sensors.sort((a, b) => {
        const avgValueA = sensorFilteredStats.get(a.id)?.summaryValue ?? 0;
        const avgValueB = sensorFilteredStats.get(b.id)?.summaryValue ?? 0;
        if (avgValueA !== undefined && avgValueB !== undefined) {
          if (avgValueA > avgValueB && sortBy.ascending) {
            return 1;
          }
          if (avgValueA < avgValueB && sortBy.ascending) {
            return -1;
          }
          if (avgValueA > avgValueB && !sortBy.ascending) {
            return -1;
          }
          if (avgValueA < avgValueB && !sortBy.ascending) {
            return 1;
          }
        }
        return 0;
      });
    }
  }
  return sortedSensors;
};

// update min/max/avg values based on selected hours and return new metric items
export const processRawData = (
  rawData: DailyMetricItem[],
  selectedHours: number[],
  hourly?: boolean,
  offsetWeeks?: number // for ghost comparison data
): DailyMetricItem[] => {
  let offsetData = rawData;
  if (offsetWeeks) {
    const offsetDays = offsetWeeks * 7;
    offsetData = rawData.map(({ date, ...rest }) => {
      if (date !== undefined) {
        const newDate = new Date(date);
        // Data is from offsetWeeks before so need to move forward to match comparison data
        newDate.setDate(newDate.getDate() + offsetDays);
        return {
          ...rest,
          date: newDate.toISOString().split('T')[0],
        }; // Update the date in ISO format
      }
      return { date, ...rest };
    });
  }
  // Create custom `DailyMetricItem`s where the `date`s are specific hours
  if (hourly) {
    const outputArray = offsetData.flatMap(
      (item) =>
        item.hours?.map(({ h, ...props }) => ({
          id: item.id,
          date: `${item.date} ${String(h).padStart(2, '0')}:00:00`,
          ...props,
        })) || [] // Return an empty array if hours is undefined
    );
    return outputArray;
  }
  const processedData: DailyMetricItem[] = [];
  offsetData.forEach((metricItem) => {
    const processedMetricItem: DailyMetricItem = { ...metricItem };
    const { avg, min, max, utl, tot, hours } = processedMetricItem;
    const hoursItem = hours?.filter((item) => selectedHours.includes(item.h)) ?? [];
    const totalPts = hoursItem.reduce((total, next) => total + (next.pts as number), 0);
    processedMetricItem.hours = hoursItem;
    processedMetricItem.pts = totalPts;

    if (min !== undefined)
      processedMetricItem.min = Math.min(...hoursItem.map((item) => item.min as number));

    if (max !== undefined)
      processedMetricItem.max = Math.max(...hoursItem.map((item) => item.max as number));

    if (utl !== undefined)
      processedMetricItem.utl =
        hoursItem.reduce((total, next) => total + (next.utl as number), 0) / hoursItem.length;

    if (tot !== undefined)
      processedMetricItem.tot = hoursItem.reduce((total, next) => total + (next.tot as number), 0);

    if (avg !== undefined)
      processedMetricItem.avg =
        hoursItem.reduce((total, next) => total + (next.avg as number) * (next.pts as number), 0) /
        totalPts;

    processedData.push(processedMetricItem);
  });
  return processedData;
};
