import { clearState, persistState, retrieveState, StorageTypes } from '../../utils/persistentState';
import { ActionTypes, ActionType } from '../actionTypes';
import { TempUser, UserAuthState } from '../types';

let initialTempUserState: TempUser | null = null;
try {
  initialTempUserState = (retrieveState(StorageTypes.TemporaryUser) as TempUser) ?? null;
} catch (err) {
  // console.warn(`Error attempting to initialise the user state ${err}`);
}

const initialState: UserAuthState = {
  tempUser: initialTempUserState,
  userDetails: null,
  userPosition: null,
};

// eslint-disable-next-line default-param-last
export default function setUserAuthState(state = initialState, action: ActionTypes): UserAuthState {
  switch (action.type) {
    case ActionType.SET_TEMP_USER: {
      const tempUser = action.payload;
      if (tempUser) {
        persistState(tempUser, StorageTypes.TemporaryUser);
      } else {
        clearState(StorageTypes.TemporaryUser);
      }
      return {
        ...state,
        tempUser,
      };
    }
    case ActionType.SET_USER_DETAILS: {
      const userDetails = action.payload;
      return {
        ...state,
        userDetails,
      };
    }
    case ActionType.SET_USER_POSITION: {
      const userPosition = action.payload;
      return {
        ...state,
        userPosition,
      };
    }
    default: {
      return state;
    }
  }
}
