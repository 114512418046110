/* eslint-disable no-nested-ternary */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import WifiIcon from '@mui/icons-material/Wifi';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import UpdateIcon from '@mui/icons-material/Update';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { BacnetSettings, SensorLatest, VisibleSubSensor } from '../../services/api/api';
import useStyles from '../../styles';
import SubSensorTitle from './SubSensorTitle';
import { fetchSensorLatest, updateSensorProperties } from '../../services/apiService';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import RSSILookup from './RSSILookup';
import SensorDetails from './SensorDetails/SensorDetails';
import CreateSensor from './CreateSensor';
import Loading from '../../components/Loading';
import { getShortTimeStr } from '../../utils/functions';
import { themeProps } from '../../styles/theme';
import { getSensorsById } from '../../state/selectors';
import { setSensorsById } from '../../state/actions';

interface SubSensorProps {
  sensorGatewayId: string;
  sensorGatewayDetails: SensorLatest;
  subSensors: VisibleSubSensor[];
  refreshVisibleItems: () => void;
  bacnetConfig: BacnetSettings | undefined;
}

interface UpdateResponse {
  success: boolean;
  msg: string | undefined;
  alertType: 'success' | 'error' | 'info' | 'warning' | undefined;
}

export interface SearchType {
  id?: number;
  searching?: boolean;
  keyword?: string;
}

function SubSensors({
  sensorGatewayId,
  sensorGatewayDetails,
  subSensors,
  refreshVisibleItems,
  bacnetConfig,
}: SubSensorProps): JSX.Element | null {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [search, setSearch] = useState<SearchType>({});
  const [sensor, setSensor] = useState<SensorLatest>();
  const [sensorExists, setSensorExists] = useState<boolean>(false);
  const [sensorFetching, setSensorFetching] = useState<boolean>(true);
  const [expandedSensorId, setExpandedSensorId] = useState<string>('');
  const [createSensor, setCreateSensor] = useState<boolean>(false);
  const [updateResponse, setUpdateResponse] = useState<UpdateResponse>();
  const [expanded, setExpanded] = useState(true);
  const sensorsById = useSelector(getSensorsById);

  const fetchSubSensorDetails = () => {
    if (expandedSensorId) {
      setSensorFetching(true);
      fetchSensorLatest(expandedSensorId)
        .then((details) => {
          setSensor(details);
          dispatch(setSensorsById([details]));
          setSensorExists(true);
          setSensorFetching(false);
        })
        .catch(() => {
          setSensorExists(false);
          setSensorFetching(false);
        });
    }
  };

  useEffect(() => {
    fetchSubSensorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedSensorId]);

  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    const item: SearchType = { ...search, [key]: e.target.value };
    setSearch(item);
  };

  const changeSensorStatus = (status: boolean) => {
    const gatewayStatus = {
      gateway: status ? sensorGatewayId : '',
    };

    updateSensorProperties(expandedSensorId, gatewayStatus)
      .then(() => {
        refreshVisibleItems();
        setUpdateResponse({
          success: true,
          msg: 'Update successful. Sensor status change in progress',
          alertType: 'success',
        });
      })
      .catch((err) => setUpdateResponse({ success: false, msg: err.cause, alertType: 'error' }));
  };

  const regex = new RegExp(search?.keyword ?? '', 'i');
  const filteredItem =
    subSensors &&
    Object.entries(subSensors)
      .filter((data) => data[1].suggested_id.match(regex))
      .sort((a, b) => {
        const va = a[1].rssi;
        const vb = b[1].rssi;
        if (va > vb) return -1;
        if (va < vb) return 1;
        return 0;
      });

  if (subSensors.length === 0) return null;

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <Typography variant="h6" style={{ marginLeft: '10px' }}>
          Visible Sub Sensors
        </Typography>{' '}
      </AccordionSummary>
      <AccordionDetails>
        <SubSensorTitle search={search} handleChange={handleSearch} setSearch={setSearch} />
        <div>
          {filteredItem && (
            <>
              {Object.entries(filteredItem).map(([, SubSensorDetail]) => {
                const subSensorData = SubSensorDetail[1];
                const sensorId = subSensorData.suggested_id;
                let iconClassName = classes.progressIcon;
                if (subSensorData.status === 'enabled') iconClassName = classes.enabledIcon;
                else if (subSensorData.status === 'disabled') iconClassName = classes.icon;
                const Icon = sensorTypeDetails[subSensorData.type]?.icon ?? WifiIcon;
                const sensorBacnetConfig = bacnetConfig?.gateway?.sensors.find(
                  (config) => config.id === sensorId
                );
                const sensorName = sensorsById.get(sensorId)?.name ?? '';

                return (
                  <Accordion
                    key={sensorId}
                    expanded={expandedSensorId === sensorId}
                    onClick={() =>
                      setExpandedSensorId(expandedSensorId === sensorId ? '' : sensorId)
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <IconButton
                          data-testid={`expand-${sensorId}`}
                          className={classes.expandBtn}
                          size="large"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.expansionSummary}
                    >
                      <div className={classes.subSensorSummary}>
                        <Badge
                          badgeContent={
                            sensorBacnetConfig &&
                            sensorBacnetConfig.id === sensorId && (
                              <DeviceHubIcon
                                className={classes.icon}
                                style={{ background: theme.palette.primary.main }}
                              />
                            )
                          }
                          invisible={!sensorBacnetConfig || typeof sensorBacnetConfig === 'string'}
                          color={undefined}
                          classes={{ badge: classes.customBadge }}
                          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        >
                          {subSensorData.status === 'enabled' ? (
                            <CheckCircleIcon className={iconClassName} />
                          ) : subSensorData.status === 'disabled' ? (
                            <RadioButtonUncheckedIcon className={iconClassName} />
                          ) : (
                            <UpdateIcon className={iconClassName} />
                          )}
                        </Badge>
                        <Typography className={classes.hideInSmallScreen}>
                          {sensorTypeDetails[subSensorData.type]?.label ?? subSensorData.type}
                        </Typography>
                        <p style={{ display: 'flex', color: theme.palette.text.primary }}>
                          <Icon className={`${classes.hideInBigScreen}`} />
                          <span style={{ alignSelf: 'center', marginLeft: '5px' }}>
                            {sensorName ? `(${sensorName}) - ` : ''} {subSensorData.address}
                          </span>
                        </p>
                        <RSSILookup
                          RSSI={subSensorData.rssi}
                          rssiTimeStamp={getShortTimeStr(subSensorData.last_seen)}
                        />
                        <p className={`${classes.unixTime} ${classes.hideInSmallScreen}`}>
                          {getShortTimeStr(subSensorData.last_seen)}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails onClick={(e) => e.stopPropagation()}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} className={classes.expansionGridContainer}>
                          {!sensorFetching ? (
                            <>
                              {createSensor ? (
                                <CreateSensor
                                  sensorGatewayDetails={sensorGatewayDetails}
                                  subSensorData={subSensorData}
                                  creatingSensor={setCreateSensor}
                                  refreshSubSensor={fetchSubSensorDetails}
                                  sensorNewId={sensorId}
                                  enableSensor={changeSensorStatus}
                                />
                              ) : sensorExists && expandedSensorId === sensorId ? (
                                <>
                                  <SensorDetails sensorId={sensorId} sensorDetails={sensor} />
                                  {subSensorData.status === 'enabled' ? (
                                    <Button
                                      variant={themeProps.btnVariant.default}
                                      color="secondary"
                                      onClick={() => changeSensorStatus(false)}
                                    >
                                      Disable Sensor
                                    </Button>
                                  ) : subSensorData.status === 'disabled' ? (
                                    <Button
                                      variant={themeProps.btnVariant.default}
                                      color="primary"
                                      onClick={() => changeSensorStatus(true)}
                                    >
                                      Enable Sensor
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                <Button
                                  variant={themeProps.btnVariant.default}
                                  color="primary"
                                  onClick={() => setCreateSensor(true)}
                                >
                                  Create Sensor
                                </Button>
                              )}
                              <Grid item xs={12} sm={12}>
                                {updateResponse?.msg && (
                                  <Alert
                                    severity={updateResponse.alertType ?? 'info'}
                                    onClose={() =>
                                      setUpdateResponse({
                                        success: true,
                                        msg: '',
                                        alertType: 'success',
                                      })
                                    }
                                    className={classes.alertMsg}
                                  >
                                    {updateResponse?.msg}
                                  </Alert>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <Loading />
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
export default SubSensors;
