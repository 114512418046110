import React from 'react';
import useStyles from '../styles';
import { VarName, varNameDetails } from '../utils/varNames';
import { WidgetConfig } from '../utils/widgetConfig';
import { DataTreeItem } from '../services/api';
import { BandSensor, ValueSensor } from './SensorArrayWidgets';
import BaseArraySummary from './SensorArrayWidgets/BaseArraySummary';
import MotionEventSensor from './SensorArrayWidgets/MotionEventsSensor';

interface LocationSensorArrayConfig {
  config: WidgetConfig;
  firstItem: boolean;
}

function summaryFactory(varName: VarName, data: DataTreeItem[], firstItem: boolean) {
  if (data.length === 0) {
    return (
      <BaseArraySummary
        firstItem={firstItem}
        details={varNameDetails[varName]}
        dataAvailable={false}
        expanding={false}
      />
    );
  }
  switch (varName) {
    case VarName.BaCnetReads:
    case VarName.Co2ppm:
    case VarName.VoCppb:
    case VarName.BatteryLevelPct:
    case VarName.IlluminanceArb:
    case VarName.OnlineStatus:
    case VarName.ParticulateMatter:
    case VarName.PressuremBar:
    case VarName.RelativeHumidity:
    case VarName.TemperatureC:
      return <BandSensor data={data} varName={varName} firstItem={firstItem} />;
    case VarName.ClientsBle:
    case VarName.ClientsWiFi:
    case VarName.EnergyInkWh:
    case VarName.OccSignatures:
      return <ValueSensor data={data} varName={varName} firstItem={firstItem} />;
    case VarName.MotionEvent:
      return <MotionEventSensor data={data} varName={varName} firstItem={firstItem} />;
    case VarName.Unknown:
    default:
      return (
        <BaseArraySummary
          firstItem={firstItem}
          details={varNameDetails[varName]}
          dataAvailable={false}
          expanding={false}
        />
      );
  }
}

function LocationSensorArray({ config, firstItem }: LocationSensorArrayConfig): JSX.Element {
  const classes = useStyles();

  return (
    <li className={classes.locationSensorArray}>
      {summaryFactory(config.varName ?? VarName.Unknown, config.data, firstItem)}
    </li>
  );
}

export default LocationSensorArray;
