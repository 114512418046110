import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ReportsAddCircleIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 65 67" {...props}>
      <path d="M33.8468 12.6716L19.5546 26.8733C18.8524 26.556 18.0721 26.3794 17.2503 26.3794C14.1742 26.3794 11.6837 28.8529 11.6837 31.9118C11.6837 32.1067 11.6938 32.2994 11.7136 32.4892L4.76146 35.9433V6.7941C4.76146 5.71949 3.88504 4.84998 2.80592 4.84998C1.7268 4.84998 0.850372 5.71949 0.850372 6.7941V39.0506C0.849845 39.0766 0.849843 39.1026 0.850372 39.1287V64.2059C0.850372 65.2805 1.7268 66.15 2.80592 66.15H60.5834C61.6625 66.15 62.5389 65.2805 62.5389 64.2059V33.7353C62.5389 33.7353 61.6625 31.7912 60.5834 31.7912H46.139C45.0599 31.7912 44.1835 32.6607 44.1835 33.7353V33.5559H31.6947C30.6155 33.5559 29.7391 34.4254 29.7391 35.5V44.3206H17.2503C16.1712 44.3206 15.2948 45.1901 15.2948 46.2647V62.2618H4.76146V40.29L13.462 35.9673C14.4552 36.884 15.7865 37.4441 17.2503 37.4441C20.3263 37.4441 22.8169 34.9707 22.8169 31.9118C22.8169 31.0953 22.6394 30.3201 22.3204 29.6225L36.6125 15.4208L33.8468 12.6716ZM17.2503 33.5559C16.6263 33.5559 16.0853 33.2163 15.803 32.7129C15.7928 32.6904 15.7821 32.6681 15.7709 32.6459C15.7599 32.6241 15.7486 32.6025 15.7369 32.5813C15.6455 32.3772 15.5948 32.1508 15.5948 31.9118C15.5948 31.0021 16.3325 30.2676 17.2503 30.2676C17.6881 30.2676 18.0846 30.4346 18.3799 30.708C18.3933 30.7221 18.407 30.7361 18.4209 30.7499C18.4348 30.7637 18.4489 30.7773 18.4631 30.7907C18.7381 31.084 18.9058 31.4776 18.9058 31.9118C18.9058 32.8215 18.1681 33.5559 17.2503 33.5559ZM29.7391 48.2088V62.2618H19.2058V48.2088H29.7391ZM44.1835 37.4441V62.2618H33.6502V37.4441H44.1835ZM58.6278 35.6794V62.2618H48.0946V35.6794H58.6278Z" />
      <path d="M43.5 0C31.6256 0 22 9.62555 22 21.5C22 33.3745 31.6256 43 43.5 43C55.3745 43 65 33.3745 65 21.5C65 9.62555 55.3745 0 43.5 0ZM52.1 23.65H45.65V30.1C45.65 31.2868 44.6868 32.25 43.5 32.25C42.3132 32.25 41.35 31.2868 41.35 30.1V23.65H34.9C33.7132 23.65 32.75 22.6868 32.75 21.5C32.75 20.3132 33.7132 19.35 34.9 19.35H41.35V12.9C41.35 11.7132 42.3132 10.75 43.5 10.75C44.6868 10.75 45.65 11.7132 45.65 12.9V19.35H52.1C53.2868 19.35 54.25 20.3132 54.25 21.5C54.25 22.6868 53.2868 23.65 52.1 23.65Z" />
    </SvgIcon>
  );
}

export default ReportsAddCircleIcon;
