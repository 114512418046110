import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTreeItem } from '../../services/api';
import { getMotionThreshold } from '../../state/selectors';
import { getMotionOccupancyValue } from '../../utils/motionEvents';

// avoiding hooks and can be used for conditional mapping
export const convertMotionMapping = (data: DataTreeItem[], motionThreshold: [number, number]) =>
  data.map((item) => ({
    ...item,
    value: getMotionOccupancyValue(motionThreshold, item.time, item.value),
  }));

// works with hook based on motion threshold change
export default function useMotionMapping(data: DataTreeItem[]): DataTreeItem[] {
  const motionThreshold = useSelector(getMotionThreshold);
  const [d, setD] = useState<DataTreeItem[]>([]);

  useEffect(() => {
    setD(convertMotionMapping(data, motionThreshold));
  }, [data, motionThreshold]);

  return d;
}
