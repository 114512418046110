import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function HumidityIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 42 43" {...props}>
      <path
        d="M 30.30,22.30
           C 29.50,22.90 29.30,23.40 29.90,24.60
             29.90,24.60 32.70,22.60 32.70,22.60
             32.20,21.70 31.20,21.60 30.30,22.30 Z
           M 26.50,13.20
           C 26.50,13.20 23.70,15.10 23.70,15.10
             24.60,16.10 25.10,16.10 26.00,15.60
             27.00,15.10 27.20,14.10 26.50,13.20 Z
           M 30.60,38.20
           C 34.40,33.80 35.20,27.90 32.70,22.60
             32.70,22.60 29.90,24.60 29.90,24.60
             31.50,28.60 30.90,32.90 28.10,36.10
             25.10,39.50 20.10,40.70 16.00,38.90
             11.70,37.00 9.10,32.70 9.40,28.10
             9.60,24.60 10.90,21.20 13.20,17.20
             15.30,13.60 18.00,10.30 21.60,6.90
             21.60,7.90 21.60,8.80 21.80,9.80
             22.10,11.60 22.70,13.40 23.80,15.20
             23.80,15.20 26.60,13.30 26.60,13.30
             25.80,11.90 25.40,10.60 25.10,9.30
             24.80,7.50 24.90,5.60 25.50,3.10
             25.60,2.50 25.40,1.80 24.90,1.40
             24.40,1.00 23.70,0.90 23.20,1.10
             23.20,1.10 23.10,1.10 23.10,1.10
             23.00,1.10 22.70,1.20 22.50,1.50
             17.10,6.20 13.30,10.60 10.30,15.50
             7.70,19.90 6.30,23.80 6.00,27.90
             6.00,28.30 6.00,28.60 6.00,29.00
             6.10,34.60 9.40,39.70 14.70,41.90
             16.50,42.60 18.30,43.00 20.10,43.00
             24.00,43.00 27.90,41.30 30.60,38.20 Z"
      />
    </SvgIcon>
  );
}
export default HumidityIcon;
