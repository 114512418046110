import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MapViewIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 23 22" {...props}>
      <path d="M20.7488 10.7071C20.652 10.316 20.4099 10.0227 20.071 9.876C19.248 11.4648 18.4491 13.0537 18.4007 13.1759C18.086 13.8114 17.4566 14.2025 16.7546 14.2025C16.0525 14.2025 15.4231 13.8114 15.1084 13.1759L13.3655 9.75378H3.46453C3.27087 9.75378 3.053 9.80267 2.88354 9.90044L22.7097 19.3112L20.7488 10.7071Z" />
      <path d="M2.20556 10.9027L0.0268668 20.4845C-0.0457564 20.8512 0.0268668 21.2423 0.268944 21.5356C0.511021 21.8289 0.849929 22 1.23725 22H3.19808L7.24077 13.2737L2.20556 10.9027Z" />
      <path d="M8.33015 13.8114L4.52954 22H10.7025L15.7135 17.3313L8.33015 13.8114Z" />
      <path d="M16.8997 17.8936L12.4697 22.0001H21.7655C22.1286 22.0001 22.4917 21.829 22.7338 21.5356C22.9033 21.3156 23.0001 21.0712 23.0001 20.8023L16.8997 17.8936Z" />
      <path d="M16.2218 12.6137C16.4397 13.0781 17.1175 13.0781 17.3354 12.6137C17.3354 12.6137 19.974 7.43166 20.5792 6.20949C21.1844 4.98731 21.2086 3.61848 20.5792 2.34741C19.514 0.245272 16.9722 -0.610251 14.8662 0.465263C12.7601 1.54078 11.9612 4.18068 12.978 6.20949C14.0189 8.2383 16.2218 12.6137 16.2218 12.6137ZM16.7786 2.32297C17.8921 2.32297 18.7878 3.22738 18.7878 4.35178C18.7878 5.47618 17.8921 6.38059 16.7786 6.38059C15.665 6.38059 14.7693 5.47618 14.7693 4.35178C14.7693 3.25182 15.665 2.32297 16.7786 2.32297Z" />
    </SvgIcon>
  );
}

export default MapViewIcon;
