import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DailyMetricItem, HistoricData } from '../services/api';
import { getMotionUtlBandParams } from '../Widgets/CalendarView/helpers';
import { getDataBandParams } from './dataBandParams';
import { VarName } from './varNames';

dayjs.extend(advancedFormat);

export type MaxMinAvg = {
  max: number;
  min: number;
  mean: number;
};

type HeatMapStats = {
  byHourOfDay: Map<number, Stats>;
  byDayOfWeek: Map<number, Stats>;
  byDayAndHour: Map<number, Map<number, Stats>>;
  byDate: Map<string, Stats>;
};

type StatsValues = {
  // Used for generation of Stats and histograms
  minVals: number[];
  maxVals: number[];
  avgVals: number[];
  totVals: number[];
  utlVals: number[];
  bandTimes: Map<string, number>;
};

export type Stats = {
  bandTimes: Map<string, number>;
  totalTime: number;
  maxMinAvg: MaxMinAvg;
  totalAdded?: number; // only for total type date e.g. EnergyInkWh and metrics with tot
  utl?: number; // utl (utilisation) is only for metrics data (motionEvent)
  values?: StatsValues; // Useful for histograms. Will include duplicate bandTimes
  heatMapStats?: HeatMapStats; // Used for heatmap (metrics only)
};

export type SensorStatsSummary = {
  sensorId: string;
  sensorName: string;
  stats: Stats;
};

function mergeStatsValues(obj1: StatsValues, obj2: StatsValues): StatsValues {
  const bandTimes = new Map<string, number>(obj1.bandTimes);
  obj2.bandTimes.forEach((value, band) => {
    bandTimes.set(band, (bandTimes.get(band) ?? 0) + value);
  });
  return {
    minVals: [...obj1.minVals, ...obj2.minVals],
    maxVals: [...obj1.maxVals, ...obj2.maxVals],
    avgVals: [...obj1.avgVals, ...obj2.avgVals],
    totVals: [...obj1.totVals, ...obj2.totVals],
    utlVals: [...obj1.utlVals, ...obj2.utlVals],
    bandTimes,
  };
}

// Take multiple Stats (e.g. from multiple sensors or multiple dates), combine into one summary Stats
export function combineStats(statsArray: Stats[]): Stats {
  if (statsArray.length === 0)
    return {
      bandTimes: new Map<string, number>(),
      totalTime: 0,
      maxMinAvg: { max: NaN, min: NaN, mean: NaN },
    };
  if (statsArray.length === 1) return statsArray[0];
  // Make a deep copy to avoid infinite loop / problems with modifying original heatmaps
  const combinedStats: Stats = structuredClone(statsArray[0]);
  const means = [combinedStats.maxMinAvg.mean];
  statsArray.slice(1).forEach((s) => {
    combinedStats.totalTime += s.totalTime;
    if (combinedStats.totalAdded !== undefined && s.totalAdded !== undefined) {
      combinedStats.totalAdded += s.totalAdded;
    }
    let { max, min } = combinedStats.maxMinAvg;
    // Math.max/min is weird about NaN so prefer non-NaN values
    if (Number.isNaN(max)) max = -Infinity;
    if (Number.isNaN(min)) min = Infinity;
    max = Number.isNaN(s.maxMinAvg.max) ? max : Math.max(max, s.maxMinAvg.max);
    min = Number.isNaN(s.maxMinAvg.min) ? min : Math.min(min, s.maxMinAvg.min);
    combinedStats.maxMinAvg.min = min;
    combinedStats.maxMinAvg.max = max;
    means.push(s.maxMinAvg.mean);
    if (combinedStats.values !== undefined && s.values !== undefined) {
      combinedStats.values = mergeStatsValues(combinedStats.values, s.values);
    }
  });
  // In most cases these should probably give the same result (top one maybe better for patchy data)
  if (combinedStats.values !== undefined) {
    combinedStats.maxMinAvg.mean =
      combinedStats.values.avgVals.reduce((a, b) => a + b, 0) / combinedStats.values.avgVals.length;
  } else {
    combinedStats.maxMinAvg.mean = means.reduce((a, b) => a + b, 0) / means.length;
  }
  if (combinedStats?.heatMapStats !== undefined) {
    const hours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    ];
    const days = [0, 1, 2, 3, 4, 5, 6];
    const dates = Array.from(
      new Set(statsArray.flatMap((s) => Array.from(s.heatMapStats?.byDate.keys() ?? [])))
    ); // All unique dates in the data
    let allSensorStats: Stats[] = [];
    const byDayOfWeek: Map<number, Stats> = new Map();
    const byHourOfDay: Map<number, Stats> = new Map();
    const byDayAndHour: Map<number, Map<number, Stats>> = new Map();
    const byDate: Map<string, Stats> = new Map();
    days.forEach((day) => {
      allSensorStats = [];
      statsArray.forEach((s) => {
        const d = s.heatMapStats?.byDayOfWeek.get(day);
        if (d !== undefined) allSensorStats.push(d);
      });
      if (allSensorStats.length > 0) {
        const dayStats = combineStats(allSensorStats);
        byDayOfWeek.set(day, dayStats);
      }
    });
    hours.forEach((hour) => {
      allSensorStats = [];
      statsArray.forEach((s) => {
        const h = s.heatMapStats?.byHourOfDay.get(hour);
        if (h !== undefined) allSensorStats.push(h);
      });
      if (allSensorStats.length > 0) {
        const hourStats = combineStats(allSensorStats);
        byHourOfDay.set(hour, hourStats);
      }
    });
    days.forEach((day) => {
      hours.forEach((hour) => {
        allSensorStats = [];
        statsArray.forEach((s) => {
          const h = s.heatMapStats?.byDayAndHour.get(day)?.get(hour);
          if (h !== undefined) allSensorStats.push(h);
        });
        if (allSensorStats.length > 0) {
          const dayHourStats = combineStats(allSensorStats);
          let dayMap = byDayAndHour.get(day);
          if (dayMap === undefined) {
            dayMap = new Map();
          }
          dayMap.set(hour, dayHourStats);
          byDayAndHour.set(day, dayMap);
        }
      });
    });
    dates.forEach((date) => {
      allSensorStats = [];
      statsArray.forEach((s) => {
        const d = s.heatMapStats?.byDate.get(date);
        if (d !== undefined) allSensorStats.push({ ...d });
      });
      if (allSensorStats.length > 0) {
        const dateStats = combineStats(allSensorStats);
        byDate.set(date, dateStats);
      }
    });

    combinedStats.heatMapStats = {
      byDayOfWeek,
      byHourOfDay,
      byDate,
      byDayAndHour,
    };
  }
  return combinedStats;
}

const isSelectedTime = (
  time: number,
  selectedHours: [number, number],
  includeWeekends: boolean
): boolean => {
  // Checks whether a given time matches our selection criteria
  const m = dayjs.unix(time);
  const hour = m.hour();
  const day = m.day();
  if (
    hour < selectedHours[0] ||
    hour >= selectedHours[1] ||
    (!includeWeekends && (day === 0 || day === 6))
  ) {
    return false;
  }
  return true;
};

// For raw history data
export const calculateStats = (
  varName: VarName,
  history: HistoricData,
  selectedHours: [number, number],
  includeWeekends: boolean
): Stats => {
  const bandTimes: Map<string, number> = new Map();
  let totalTime = 0;
  let totalAdded = 0;

  let max = -Infinity;
  let min = Infinity;
  const values: number[] = [];
  for (let i = 1; i < history.time.length; i++) {
    const lastTime = history.time[i - 1];
    const time = history.time[i];

    if (isSelectedTime(time, selectedHours, includeWeekends)) {
      const band = getDataBandParams(varName, history.value[i]);
      // Update the statistics to include this chunk of time
      //  (our chunk of time is the size of the gap between this data point and the last one)
      bandTimes.set(band.label, (bandTimes.get(band.label) ?? 0) + (time - lastTime));
      // Update our count of time we have done statistics for to include this chunk
      totalTime += time - lastTime;
      // Update max/min if needed
      max = Math.max(max, history.value[i]);
      min = Math.min(min, history.value[i]);
      values.push(history.value[i]);
      const added = history.value[i] - history.value[i - 1] ?? 0;
      if (added > 0) totalAdded += added;
    }
  }
  const sum = values?.reduce((a, b) => a + b, 0) ?? 0;
  const mean = sum / Math.max(values.length, 1);

  // Only add totalAdded if tot data. Add values for histograms?
  if (varName === VarName.EnergyInkWh) {
    return { bandTimes, totalTime, maxMinAvg: { max, min, mean }, totalAdded };
  }
  return { bandTimes, totalTime, maxMinAvg: { max, min, mean } };
};

interface GapInfo {
  start: DailyMetricItem;
  end: DailyMetricItem;
  gapHours: number;
}

// Look for gaps due to missing data,
// in some circumstances (totals for whole missing period) we can compute missing summary stats
function findGaps(data: DailyMetricItem[]): GapInfo[] {
  // Parse the date strings into Date objects and sort the array by date
  const sortedData = data
    .map((item) => ({
      ...item,
      parsedDate: new Date(item.date ?? 0),
    }))
    .sort((a, b) => a.parsedDate.getTime() - b.parsedDate.getTime());
  const gaps: GapInfo[] = [];
  for (let i = 1; i < sortedData.length; i++) {
    const previousItem = sortedData[i - 1];
    const currentItem = sortedData[i];
    // Calculate the difference in hours between the two dates
    const diffInMs = currentItem.parsedDate.getTime() - previousItem.parsedDate.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60);
    if (diffInHours > 1) {
      gaps.push({
        start: previousItem,
        end: currentItem,
        gapHours: diffInHours, // Note: e.g. a 2 hour gap is only missing 1 item
      });
    }
  }
  return gaps;
}

function updateMetricStatsValues(
  item: DailyMetricItem,
  varName: VarName,
  stats: StatsValues
): void {
  if (item.max !== undefined && item.min !== undefined && item.avg !== undefined) {
    const band = getDataBandParams(varName, item.avg);
    stats.bandTimes.set(band.label, (stats.bandTimes.get(band.label) ?? 0) + 1);
    stats.maxVals.push(item.max);
    stats.minVals.push(item.min);
    stats.avgVals.push(item.avg);
    if (item.tot !== undefined) stats.totVals.push(item.tot);
  }
  if (item.utl !== undefined) {
    stats.utlVals.push(item.utl);
    const band = getMotionUtlBandParams(item.utl * 100);
    stats.bandTimes.set(band.label, (stats.bandTimes.get(band.label) ?? 0) + 1);
  }
}

// For metric stats caluclations
function calculateOutputStatsFromValues(stats: StatsValues): Stats {
  const { avgVals, utlVals, maxVals, minVals, totVals, bandTimes } = stats;
  let mean = NaN;
  if (avgVals.length > 0) {
    const sum = avgVals?.reduce((a, b) => a + b, 0) ?? 0;
    mean = sum / Math.max(avgVals.length, 1);
  }
  let utlMean;
  if (utlVals && utlVals.length > 0) {
    const utlSum = utlVals?.reduce((a, b) => a + b, 0) ?? 0;
    // utl may have missing hours in selection if the sensor went offline
    // Would be fixed by more consistent onlineStatus tracking and backfill on backend ingest
    // or we could try to fill in missing hours with 0 and estimate?
    // utlMean = utlSum / Math.max(utlEstimateTime, 1);
    utlMean = utlSum / Math.max(utlVals.length, 1);
  }
  const max: number = maxVals.length > 0 ? Math.max(...maxVals) : NaN;
  const min: number = minVals.length > 0 ? Math.min(...minVals) : NaN;

  const output: Stats = {
    bandTimes,
    totalTime: Math.max(avgVals.length, utlVals.length),
    maxMinAvg: { max, min, mean },
  };
  if (utlMean !== undefined) output.utl = utlMean;
  if (totVals.length > 0) {
    output.totalAdded = totVals?.reduce((a, b) => a + b, 0) ?? 0;
  }
  return output;
}

export const calculateMetricStats = (
  varName: VarName,
  metrics: DailyMetricItem[],
  selectedHours: [number, number], // end hour is not inclusive, e.g whole day [0,24], 9-5 [9,17]
  includeWeekends: boolean,
  includeHeatmapStats = true, // could save minor cpu/memory if disabled when not needed
  includeValues = true // could save minor cpu/memory if disabled when not needed
): Stats => {
  let filteredMetrics: DailyMetricItem[] = [];
  const createInitialStats = (): StatsValues => ({
    minVals: [],
    maxVals: [],
    avgVals: [],
    totVals: [],
    utlVals: [],
    bandTimes: new Map(),
  });

  const overallStats = createInitialStats();
  const valuesByDayOfWeek: Map<number, StatsValues> = new Map();
  const valuesByHourOfDay: Map<number, StatsValues> = new Map();
  const valuesByDate: Map<string, StatsValues> = new Map();
  const valuesByDayAndHour: Map<number, Map<number, StatsValues>> = new Map();

  // Make one metric item per hour (of selected hours) for easier calculation
  if (metrics.length > 0 && 'hours' in metrics[0]) {
    // is daily data make one item per hour (filtered by selectedHours)
    filteredMetrics = metrics.flatMap(
      (item) =>
        item.hours
          ?.filter(({ h }) => item.date && h >= selectedHours[0] && h < selectedHours[1])
          .map(
            ({ h, ...props }) =>
              ({
                id: item.id,
                date: `${item.date} ${String(h).padStart(2, '0')}:00:00`,
                ...props,
              } as DailyMetricItem)
          ) || []
    );
  } else {
    // Is already metrics per hour rather than per day, filter by selectedHours
    filteredMetrics = metrics.filter((item) => {
      if (!item.date) return false;
      const hour = new Date(item.date).getHours();
      return hour >= selectedHours[0] && hour < selectedHours[1];
    });
  }

  filteredMetrics.forEach((item) => {
    if (!item.date) return;
    const date = new Date(item.date);
    const hour = date.getHours();
    const day = date.getDay();
    const dateStr = dayjs(date).format('YYYY-MM-DD');
    const isWeekend = day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
    if (includeWeekends || !isWeekend) {
      // These stats may cover whole weeks, only include weekend values in stats if specified
      updateMetricStatsValues(item, varName, overallStats);
      if (includeHeatmapStats) {
        let vH = valuesByHourOfDay.get(hour);
        if (vH === undefined) {
          vH = createInitialStats();
          valuesByHourOfDay.set(hour, vH);
        }
        updateMetricStatsValues(item, varName, vH);
      }
    }
    if (includeHeatmapStats) {
      let vDay = valuesByDayOfWeek.get(day);
      if (vDay === undefined) {
        vDay = createInitialStats();
        valuesByDayOfWeek.set(day, vDay);
      }
      updateMetricStatsValues(item, varName, vDay);

      let vDate = valuesByDate.get(dateStr);
      if (vDate === undefined) {
        vDate = createInitialStats();
        valuesByDate.set(dateStr, vDate);
      }
      updateMetricStatsValues(item, varName, vDate);

      let dayMap = valuesByDayAndHour.get(day);
      if (dayMap === undefined) {
        dayMap = new Map();
        valuesByDayAndHour.set(day, dayMap);
      }
      let vDH = dayMap.get(hour);
      if (vDH === undefined) {
        vDH = createInitialStats();
        dayMap.set(hour, vDH);
      }
      updateMetricStatsValues(item, varName, vDH);
    }
  });

  const output = calculateOutputStatsFromValues(overallStats);

  // For total data if there are gaps see if we can work out the missing total
  // If we are only considering specific times we can't work out a total from max/min
  // since don't know which specific hours/days the data occurs in
  if (
    output.totalAdded !== undefined &&
    selectedHours[0] === 0 &&
    selectedHours[1] === 24 &&
    includeWeekends
  ) {
    const gaps = findGaps(metrics);
    gaps.forEach((gap) => {
      const change = (gap.end.min ?? 0) - (gap.start.max ?? 0);
      // If meter reset during gap then number might be -ve and we don't know what to add
      if (change > 0) output.totalAdded = (output.totalAdded ?? 0) + change;
    });
  }

  if (includeValues) output.values = overallStats;

  if (includeHeatmapStats) {
    const byHourOfDay = new Map<number, Stats>();
    valuesByHourOfDay.forEach((values, hour) => {
      const hourStats = {
        ...calculateOutputStatsFromValues(values),
        ...(includeValues && { values }),
      };
      byHourOfDay.set(hour, hourStats);
    });
    const byDayOfWeek = new Map<number, Stats>();
    valuesByDayOfWeek.forEach((values, day) => {
      const dayStats = {
        ...calculateOutputStatsFromValues(values),
        ...(includeValues && { values }),
      };
      byDayOfWeek.set(day, dayStats);
    });
    const byDate = new Map<string, Stats>();
    valuesByDate.forEach((values, date) => {
      const dayStats = {
        ...calculateOutputStatsFromValues(values),
        ...(includeValues && { values }),
      };
      byDate.set(date, dayStats);
    });
    const byDayAndHour = new Map<number, Map<number, Stats>>();
    valuesByDayAndHour.forEach((byHourMap, day) => {
      byHourMap.forEach((values, hour) => {
        const hourStats = {
          ...calculateOutputStatsFromValues(values),
          ...(includeValues && { values }),
        };
        let dayStats = byDayAndHour.get(day);
        if (dayStats === undefined) {
          dayStats = new Map<number, Stats>();
          byDayAndHour.set(day, dayStats);
        }
        dayStats.set(hour, hourStats);
      });
    });
    output.heatMapStats = { byHourOfDay, byDayOfWeek, byDate, byDayAndHour };
  }

  return output;
};
