import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WifiIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 162 162" {...props}>
      <path
        d="M 81.20,36.32
           C 104.90,36.32 127.16,45.53 143.87,62.24
             147.45,65.81 153.27,65.81 156.84,62.24
             158.57,60.51 159.53,58.20 159.53,55.75
             159.53,53.30 158.58,51.00 156.84,49.26
             115.58,8.00 48.09,7.65 6.38,48.48
             6.38,48.48 5.87,49.10 5.87,49.10
             2.82,52.77 3.04,58.06 6.39,61.40
             9.74,64.75 15.03,64.97 18.70,61.92
             18.70,61.92 19.70,61.09 19.70,61.09
             36.22,45.13 58.08,36.32 81.20,36.32 Z
           M 80.66,83.62
           C 91.11,83.62 100.93,87.68 108.31,95.05
             110.04,96.78 112.35,97.74 114.80,97.74
             117.25,97.74 119.55,96.79 121.29,95.05
             123.02,93.32 123.98,91.02 123.98,88.56
             123.98,86.11 123.03,83.81 121.29,82.07
             98.89,59.67 62.45,59.68 40.06,82.07
             38.33,83.80 37.37,86.11 37.37,88.56
             37.37,91.01 38.32,93.31 40.06,95.05
             41.79,96.78 44.10,97.74 46.55,97.74
             49.00,97.74 51.30,96.79 53.04,95.05
             60.39,87.69 70.21,83.62 80.66,83.62 Z
           M 80.77,109.83
           C 76.85,109.83 73.17,111.35 70.41,114.11
             64.70,119.82 64.70,129.11 70.41,134.83
             76.12,140.54 85.42,140.54 91.12,134.83
             96.83,129.12 96.83,119.83 91.12,114.11
             88.36,111.35 84.68,109.83 80.77,109.83 Z"
      />
    </SvgIcon>
  );
}

export default WifiIcon;
