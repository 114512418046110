import { Auth } from 'aws-amplify';

const config = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID, // portal_lf-react

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: process.env.REACT_APP_OAUTH_DOMAIN,
    //   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
    //   redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
    //   responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  },
};

Auth.configure(config);

export enum AuthState {
  Idle = 'idle',
  SetUp = 'setup',
  SignIn = 'signIn',
  SignOut = 'signOut',
  SignUp = 'signUp',
  Authenticated = 'authenticated',
  ResetPassword = 'resetPassword',
  ConfirmResetPassword = 'confirmResetPassword',
  ForceNewPassword = 'forceNewPassword',
}

export enum UserAdminTypes {
  owner = 'owner',
  admin = 'admin',
  installer = 'installer',
}
