import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortAlphaAscIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M114.688 413.581H204.287V443H68.9111V421.516L156.802 294.929H69.0332V265.266H202.334V286.262L114.688 413.581Z" />
      <path d="M175.211 195.379H111.002L98.7949 232H59.8545L126.017 54.2656H159.952L226.48 232H187.54L175.211 195.379ZM120.89 165.716H165.323L142.984 99.1875L120.89 165.716Z" />
      <path d="M376.125 246.5V112H427.875V246.5H471L402 381L333 246.5H376.125Z" />
    </SvgIcon>
  );
}

export default SortAlphaAscIcon;
