import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getCurrentLocationFloorplan, getSensorsById } from '../../state/selectors';
import useGlobalStyles from '../../styles/index';
import { SensorLatest } from '../../services/api';

import PositioningMap from '../../Widgets/Map/PositioningMap';
import { setCurrentLocation, setSensorsById } from '../../state/actions';
import Header from '../../components/Header';
import { fetchSensorLatest } from '../../services/apiService';

enum DisplayType {
  sensorInfo = 'sensorInfo',
  sensorMap = 'sensorMap',
  sensorHistory = 'sensorHistory',
}

function AppSensorInfo(): JSX.Element {
  const navigate = useNavigate();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { displayType, selectedSensorId } = useParams() as {
    displayType: DisplayType;
    selectedSensorId: string;
  };
  const sensorsById = useSelector(getSensorsById);
  const floorPlan = useSelector(getCurrentLocationFloorplan);
  const [sensorDetails, setSensorDetails] = useState<SensorLatest>();
  // const [sensorData, setSensorData] = useState<SensorHistoryPlotItem>();

  // fetch history data
  // useEffect(() => {
  //   dataStore.getHistory(selectedSensorId, activeMarker).then((history) => {
  //     const dataItem: SensorHistoryPlotItem = {
  //       varName: activeMarker,
  //       sensorId: selectedSensorId,
  //       sensorName: sensorDetails?.name ?? '',
  //       history: {
  //         time: history.map(({ time }) => time),
  //         value: history.map(({ value }) => value),
  //       },
  //     };

  //     setSensorData(dataItem);
  //   });
  // }, [activeMarker, dataStore, selectedSensorId, sensorDetails?.name]);

  useEffect(() => {
    if (!sensorDetails || sensorDetails.id !== selectedSensorId) {
      const sensorDetail = sensorsById.get(selectedSensorId);
      if (sensorDetail) {
        dispatch(setCurrentLocation(sensorDetail?.location ?? '#'));
        setSensorDetails(sensorDetail);
      } else
        fetchSensorLatest(selectedSensorId).then((sensor) => {
          dispatch(setCurrentLocation(sensor?.location ?? '#'));
          setSensorDetails(sensor);
          dispatch(setSensorsById([sensor]));
        });
    }
  }, [dispatch, selectedSensorId, sensorDetails, sensorsById]);

  useEffect(() => {
    if (displayType === DisplayType.sensorInfo && sensorDetails?.id) {
      navigate(`/sensors/${sensorDetails?.id}`);
    } else if (displayType === DisplayType.sensorHistory && sensorDetails?.id) {
      navigate(`/appDashboard/${sensorDetails.id}`);
    }
  }, [displayType, navigate, sensorDetails?.id]);

  // display history graph
  // const showSensorHistoryContent = () => {
  //   if (sensorData)
  //     return (
  //       <div className={globalClasses.sensorGraph}>
  //         <ColouredLinePlot data={sensorData} />
  //       </div>
  //     );
  //   return <Loading />;
  // };

  return (
    <div className={globalClasses.bodyContent} style={{ padding: '10px' }}>
      <Header />
      {/* {displayType === DisplayType.sensorHistory && showSensorHistoryContent()} */}
      {displayType === DisplayType.sensorMap && floorPlan && (
        <PositioningMap
          onChange={() => null}
          floorPlan={floorPlan}
          position={sensorDetails?.position}
          mapName=""
        />
      )}
    </div>
  );
}

export default AppSensorInfo;
