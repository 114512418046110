import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MobileAppIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 48 48" {...props} fill="none">
      <circle cx="24" cy="24" r="24" fill="#10CDAE" fillOpacity="0.16" />
      <path
        d="M33.3334 11.3725C33.3334 9.92937 32.0267 8.66663 30.5334 8.66663H17.4667C15.9734 8.66663 14.6667 9.92937 14.6667 11.3725V36.6274C14.6667 38.0705 15.9734 39.3333 17.4667 39.3333H30.5334C32.0267 39.3333 33.3334 38.0705 33.3334 36.6274V30.5714"
        stroke="#10CDAE"
        strokeWidth="2"
        strokeLinecap="round"
        fillOpacity="0.16"
      />
      <path
        d="M25 34.2993C25 34.8516 24.5523 35.2993 24 35.2993C23.4477 35.2993 23 34.8516 23 34.2993C23 33.747 23.4477 33.2993 24 33.2993C24.5523 33.2993 25 33.747 25 34.2993Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.7621 16.6259H33.3335M33.3335 16.6259V25.1973M33.3335 16.6259L21.3335 28.6259"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default MobileAppIcon;
