/* tslint:disable */
/* eslint-disable */
/**
 * LightFi API
 *  <h2>Supplementary Documentation</h2> See also documentation at <a href=https://docs.lightfi.io/API/ >https://docs.lightfi.io/API/</a> for guidance.  <h2>Authorisation for the API and this test page</h2> You will need your OAuth `client_id` and `client_secret` as well as a valid user login to use the API (contact LightFi to obtain these).  You can test the API directly from this page:   - Click the \"Authorize\" button (below this text)   - Enter your `client_id` and `client_secret` and click \"Authorize\"   - You will be redirected to a login page to enter your LightFi username (the email address for your account) and password   - On successful login you will be redirected back to this page   - You can now test the API routes directly  Notes:   - Using this page to test queries returning large amounts of data will be slow, much slower than e.g. using the curl command   - You can obtain an access token from this page to use in another client for testing by copying it from the example curl request   - The `access_token` is only valid for a short period of time (`expiry [\"exp\"]` can be viewed in the **JWT** token itself using any JWT decoding tool/library e.g. <a href=https://jwt.io/>https://jwt.io/</a> ). When the access token expires you will need to logout/login again on the API documentation page.   - In order to allow programmatic access for a longer period of time you need the `refresh_token`, this can be obtained, for example, using Postman (see <a href=https://docs.lightfi.io/API/postman_refresh_token/>here</a>). 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: RawAxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath ?? basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}

interface ServerMap {
    [key: string]: {
        url: string,
        description: string,
    }[];
}

/**
 *
 * @export
 */
export const operationServerMap: ServerMap = {
}
