import React, { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import PageviewIcon from '@mui/icons-material/Pageview';
import CancelIcon from '@mui/icons-material/Cancel';
import useStyles from '../../styles';
import { SearchType } from './SubSensors';

interface SubSensorTitleProps {
  search: SearchType;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void;
  setSearch: (value: SearchType) => void;
}

const ListHeaderItem = [
  { id: 1, label: 'Active', icon: false, hideInSmallScreen: false },
  { id: 2, label: 'Type', icon: false, hideInSmallScreen: true },
  { id: 3, label: 'Address', icon: true, hideInSmallScreen: false },
  { id: 4, label: 'Signal Strength Indicator', icon: false, hideInSmallScreen: false },
  { id: 5, label: 'Last Seen', icon: false, hideInSmallScreen: true },
];

function SubSensorTitle({ search, handleChange, setSearch }: SubSensorTitleProps): JSX.Element {
  const classes = useStyles();
  return (
    <List className={classes.subSensorGridHeader}>
      {ListHeaderItem.map((title) => (
        <ListItem
          key={title.id}
          className={title.hideInSmallScreen ? classes.hideInSmallScreen : ''}
        >
          {title.icon &&
            (!search.searching ? (
              <PageviewIcon
                className={classes.icon}
                onClick={() => setSearch({ id: title.id, searching: true, keyword: '' })}
              />
            ) : (
              <CancelIcon
                className={classes.closeBtnIcon}
                onClick={() => setSearch({ id: title.id, searching: false, keyword: '' })}
              />
            ))}{' '}
          <Typography variant="body1" color="secondary" component="span">
            {search?.searching && search.id === title?.id ? (
              <TextField
                type="text"
                value={search.keyword}
                placeholder="Search"
                onChange={(e) => handleChange(e, 'keyword')}
                variant="standard"
              />
            ) : (
              title?.label
            )}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}

export default SubSensorTitle;
