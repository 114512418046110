import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportBandsData, VarName } from '../../services/api';
import getSensorBarChartProp from './helpers/sensorBarChart';
import { BarChartProperties, PieChartProperties, reportColors } from './constants';
import { varNameDetails } from '../../utils/varNames';
import PieChartPlot from './PieChartPlot';
import BarChartPlot from './BarChartPlot';
import bubbleBg from '../../styles/images/report/bubble-bg.svg';
import tempRange from '../../styles/images/report/tempRange.svg';
import temperature from '../../styles/images/report/temperature.svg';
import detailBtn from '../../styles/images/report/detailBtn.svg';
import useStyles from '../../styles/report';

interface TempReportProps {
  tempData: ReportBandsData;
  lastMonthDesiredPct: number | undefined;
}

function TempReport({ tempData, lastMonthDesiredPct }: TempReportProps): JSX.Element {
  const classes = useStyles();
  const { summary, weekdays, sensors } = tempData;
  const { desiredPct } = summary;

  const sortedSensors = [...sensors].sort((a, b) => b.desiredPct - a.desiredPct);

  const goodSensorReadings = [...sortedSensors].slice(0, 5).reverse();
  const goodSensorBarProps = getSensorBarChartProp(
    VarName.TemperatureC,
    goodSensorReadings,
    false,
    false
  );

  const badSensorReadings = [...sortedSensors]
    .slice(sortedSensors.length - 5, sortedSensors.length)
    .reverse();
  const badSensorBarProps = getSensorBarChartProp(VarName.TemperatureC, badSensorReadings, true);

  const tempThisMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: desiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: ' This Month',
    values: [100 - desiredPct, desiredPct],
  };

  const tempLastMonthPie: PieChartProperties = {
    annotationColor: reportColors.green,
    annotationText: lastMonthDesiredPct,
    colors: [reportColors.white, reportColors.green],
    titleText: 'Last Month',
    values: lastMonthDesiredPct ? [100 - lastMonthDesiredPct, lastMonthDesiredPct] : [],
  };

  const tempEachDayBar: BarChartProperties = {
    traces: [
      {
        name: 'max',
        value: weekdays.map((x) => x.max).flat(2),
        orientation: 'v',
      },
      {
        name: 'min',
        value: weekdays.map((x) => x.min).flat(2),
        orientation: 'v',
      },
      {
        name: 'avg',
        value: weekdays.map((x) => x.avg).flat(2),
        orientation: 'v',
      },
    ],
    yAxisTitle: `${varNameDetails[VarName.TemperatureC]?.label} (${
      varNameDetails[VarName.TemperatureC]?.metric
    })`,
    source: VarName.TemperatureC,
    values: weekdays?.map((x) => x.day).flat(2),
  };

  return (
    <>
      <div className={classes.page}>
        <Typography variant="h3">Temperature</Typography>
        <Typography variant="h4">Your monthly temperature stats</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <PieChartPlot pieProps={tempThisMonthPie} />
            <PieChartPlot pieProps={tempLastMonthPie} />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This percentage value tells us how often the temperature in your space is in 'comfortable' range. Maximizing this number can help improve performance and reduce complaints."
            />
          </Box>
        </Box>

        <Box sx={{ display: { sm: 'flex', xs: 'block' } }}>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Temperature affects people’s comfort and performance differently. Specific preferences
              can be a function of the body’s metabolic rate. CIBSE guidance is to maintain air
              temperatures are maintained between 19°C - 23°C in the Winter
            </p>
          </Box>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              and 22°C – 25°C in the Summer. Research indicates that productivity begins to decrease
              for temperatures below 19°C and above 25°C. High air velocity and drafts may also be a
              factor in perceived thermal comfort.
            </p>
          </Box>
        </Box>
        <img style={{ width: '100%' }} src={tempRange} alt="co2 range" />
        <Typography variant="h4">Optimal temperature equates to</Typography>
        <img style={{ width: '25%' }} src={temperature} alt="Air Quality" />
      </div>
      <div className={classes.page}>
        <Typography variant="h3">Temperature</Typography>
        <Typography variant="h4">Temperature range for each day of the week</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center' }}>
            <BarChartPlot barProps={tempEachDayBar} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The graph displays the daily average tempearture from all sensors, along with the minimum and maximum temperatures recorded by any single sensor for each day of the week."
            />
          </Box>
        </Box>
        <Typography variant="h4">
          Most comfortable areas and those with potential to improve
        </Typography>
        <p>
          The table below shows how often each sensor spends in each temperature range during
          working hours. For example, if a sensor spends 1 of every 4 working hours in a particular
          range and 3 of 4 in another, those ranges will occupy 25% and 75% of the length of the
          bar.
        </p>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ display: 'grid' }}>
            <BarChartPlot barProps={goodSensorBarProps} />
            <BarChartPlot barProps={badSensorBarProps} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The table showcases the top 5 best-performing and bottom 5 performing areas.  Consider investigating areas that are often too cold or too hot."
            />
          </Box>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <a href="https://portal.lightfi.io/">
            <img className={classes.portalLinkImg} src={detailBtn} alt="portal link" />
          </a>
        </div>
      </div>
    </>
  );
}

export default TempReport;
