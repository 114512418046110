import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SensorsInfoIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 72 34" {...props}>
      <path d="M50.1882 13.1116C49.7465 12.5386 48.9137 12.4219 48.3257 12.8523C47.7377 13.2802 47.618 14.0944 48.0597 14.6649C49.1213 16.0496 49.1213 17.9503 48.0597 19.335C47.618 19.9081 47.7377 20.7197 48.3257 21.1475C48.9164 21.578 49.7465 21.4613 50.1882 20.8882C51.9602 18.5856 51.9602 15.4169 50.1882 13.1116Z" />
      <path d="M54.3096 9.96608C53.9088 9.39023 53.153 9.27298 52.6218 9.70552C52.0882 10.1354 51.9795 10.9536 52.3779 11.5295C54.6332 14.7709 54.6332 19.2291 52.3779 22.4706C51.9795 23.049 52.0882 23.8646 52.6218 24.2945C53.1554 24.727 53.9088 24.6098 54.3096 24.0339C57.2072 19.8675 57.2072 14.1351 54.3096 9.96608Z" />
      <path d="M58.1471 6.81887C57.7257 6.24194 56.9312 6.12446 56.3702 6.55781C55.8092 6.99116 55.695 7.80826 56.1164 8.38519C59.8402 13.4888 59.8402 20.5111 56.1164 25.6147C55.695 26.1917 55.8092 27.0088 56.3702 27.4421C56.9312 27.8755 57.7257 27.758 58.1471 27.1811C62.5487 21.1481 62.5487 12.8518 58.1471 6.81887Z" />
      <path d="M62.5711 3.67275C62.1377 3.0925 61.3131 2.9775 60.7327 3.40876C60.1523 3.84264 60.0341 4.66073 60.47 5.23836C65.7227 12.2091 65.7227 21.791 60.47 28.7617C60.0341 29.3394 60.1523 30.1574 60.7327 30.5913C61.3131 31.0226 62.1377 30.9076 62.5711 30.3273C68.5223 22.4339 68.5223 11.5714 62.5711 3.67275Z" />
      <path d="M66.7882 0.524168C66.3762 -0.0538855 65.5994 -0.171588 65.0509 0.25999C64.4999 0.694184 64.3883 1.51287 64.8002 2.09093C71.0867 10.9265 71.0867 23.0735 64.8002 31.9091C64.3883 32.4871 64.4999 33.3058 65.0509 33.74C65.5994 34.1716 66.3762 34.0539 66.7882 33.4758C73.7373 23.7143 73.7373 10.2909 66.7882 0.524168Z" />
      <path d="M38.3367 9.09847H25.5578V15.682H20.9651C19.9811 15.682 19.0125 15.4134 18.1588 14.9078C15.0535 13.0591 11.1381 13.5174 8.51331 16.0349C8.28073 16.2587 8.03282 16.4589 7.76702 16.638C5.17546 18.3865 1.69959 17.6413 0 14.971V18.6868C3.15639 20.9699 7.43221 20.6671 10.2563 17.9626C12.0607 16.2324 14.7494 15.919 16.8835 17.1883C18.1256 17.9257 19.5338 18.3154 20.9676 18.3154H25.5578V24.899H38.3367C39.267 24.899 40.1232 25.4178 40.573 26.2578C40.9819 27.0215 41.9148 27.3007 42.656 26.8741C43.1416 26.5975 43.4483 26.0656 43.4483 25.4941V8.50595C43.4483 7.63429 42.7608 6.9259 41.9148 6.9259C41.3551 6.9259 40.8414 7.23665 40.573 7.74226C40.1232 8.57969 39.267 9.09847 38.3367 9.09847Z" />
    </SvgIcon>
  );
}

export default SensorsInfoIcon;
