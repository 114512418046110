/* eslint-disable @typescript-eslint/no-explicit-any */
import { GeoJsonLayer, GeoJsonLayerProps } from '@deck.gl/layers';
import { RGBAColor } from '@deck.gl/core';
import { GeoJSON, VarName } from '../../../services/api';
import {
  FLOORPLAN_ZOOM,
  getFloorPlanItemElevation,
  getFloorPlanItemFillColour,
  getFloorPlanItemLineColour,
  hexToRgb,
  MotionMapRecord,
} from '../mapHelpers';
import { ActiveItem } from '../../../state/types';

const getFloorplanLayer = (
  floorPlan: GeoJSON | undefined,
  mapStateZoom: number,
  showMotion: boolean,
  highlightedItem: ActiveItem,
  motionMap: Map<string, MotionMapRecord | null> | null,
  motionThreshold: [number, number],
  showOverrideData: boolean | undefined,
  onSensorHover: (item: ActiveItem) => void
): GeoJsonLayer<unknown, GeoJsonLayerProps<unknown>> | null => {
  let hasFloorPlan = false;
  if (floorPlan) {
    const { features } = floorPlan;
    if (features?.length > 0) hasFloorPlan = true;
  }

  if (hasFloorPlan && mapStateZoom >= FLOORPLAN_ZOOM) {
    return new GeoJsonLayer({
      id: 'geojson-layer',
      data: floorPlan,
      pickable: showMotion, // Only pick up hover events if we are showing motion
      stroked: true,
      filled: true,
      wireframe: true,
      extruded: true,
      onHover: (obj: any) => {
        if (obj?.picked) {
          const objData = obj.object;
          const polygonId: string = objData.id ?? objData.properties?.id;
          if (polygonId) {
            const sensorId = motionMap?.get(`${polygonId}`)?.id;
            if (sensorId) onSensorHover({ id: sensorId, varName: VarName.MotionEvent });
          }
        } else {
          onSensorHover({ id: '' });
        }
      },
      getFillColor: (d: any): RGBAColor => {
        if (!showMotion) {
          return getFloorPlanItemFillColour(d?.properties?.type ?? '');
        }
        const id = d.id ?? d?.properties.id;
        const motionData = motionMap?.get(`${id}`);
        if (!motionData) {
          return getFloorPlanItemFillColour(d?.properties?.type ?? '');
        }
        if (!showOverrideData && !motionData.online) {
          return [0, 0, 0];
        }

        const motionColour = motionData.color;
        const eventId = motionData?.id ?? undefined;
        const highlightedId = highlightedItem.id;
        return hexToRgb(motionColour, eventId === highlightedId ? 50 : 200);
      },
      getLineColor: (d: any): RGBAColor => {
        if (!showMotion) {
          return getFloorPlanItemLineColour(d?.properties?.type ?? '');
        }
        const id = d.id ?? d?.properties.id;
        const motionData = motionMap?.get(`${id}`);
        if (motionData) {
          return [0, 0, 0];
        }
        return getFloorPlanItemLineColour(d?.properties?.type ?? '');
      },
      getElevation: (d: any) => getFloorPlanItemElevation(d?.properties?.type ?? ''),
      lineWidthMinPixels: 2,
      lineWidthMaxPixels: 2,
      updateTriggers: {
        getFillColor: [showMotion, motionThreshold, motionMap, highlightedItem],
        getLineColor: [showMotion, motionThreshold, motionMap],
      },
    });
  }

  return null;
};

export default getFloorplanLayer;
