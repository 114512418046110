import React from 'react';
import Box from '@mui/material/Box';
import { SensorStatsSummary } from '../../utils/statistics';
import useStyles from '../../styles/report';
import {
  getDataBandParams,
  motionUtilisationBands,
  varNameBandParams,
} from '../../utils/dataBandParams';
import { VarName, varNameDetails } from '../../utils/varNames';

interface StatsBoxProps {
  stats: Map<VarName, SensorStatsSummary[]>;
}

function StatsBox({ stats }: StatsBoxProps): JSX.Element {
  const classes = useStyles();

  const showGhostContent = (
    varStats: SensorStatsSummary[],
    varName: VarName,
    s: SensorStatsSummary
  ) => {
    const ghost = varStats.find((g) => g.sensorId === `${s.sensorId}-GHOST`);
    return ghost && ghost.stats.totalAdded !== undefined && s.stats.totalAdded !== undefined ? (
      <>
        <td
          key={`added-${s.sensorId}-GHOST`}
          className={classes.summaryTableBandCell}
          style={{ background: 'grey' }}
        >
          {`${ghost.stats.totalAdded?.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
        </td>
        <td
          key={`addedPct-${s.sensorId}-GHOST`}
          className={classes.summaryTableBandCell}
          style={{ background: 'grey' }}
        >
          {`${
            ((s.stats.totalAdded - ghost.stats.totalAdded) / ghost.stats.totalAdded) * 100 > 0
              ? '+'
              : ''
          }${(
            ((s.stats.totalAdded - ghost.stats.totalAdded) / ghost.stats.totalAdded) *
            100
          ).toFixed(0)} %`}
        </td>
      </>
    ) : null;
  };

  return (
    <>
      {Array.from(stats.keys())
        .reverse()
        .map((varName) => {
          let bandDetails = varNameBandParams[varName];
          const isUtl =
            varName === VarName.MotionEvent &&
            stats.get(varName)?.some((s) => s.stats.utl !== undefined);
          if (isUtl) {
            bandDetails = motionUtilisationBands;
          }
          let max = -Infinity;
          let min = Infinity;
          const means: number[] = [];
          const varStats = stats.get(varName);
          // Data with defined bands
          if (bandDetails && varStats) {
            const bandAnalysis = bandDetails.map((band) => {
              let totalTime = 0;
              let totalValue = 0;

              varStats.forEach(({ stats: sensorStats }) => {
                if (!sensorStats) return;
                totalTime += sensorStats.totalTime;
                totalValue += sensorStats.bandTimes.get(band.label) ?? 0;
                max = Math.max(max, sensorStats.maxMinAvg.max);
                min = Math.min(min, sensorStats.maxMinAvg.min);
                means.push(sensorStats.maxMinAvg.mean);
              });

              return {
                band,
                totalValue,
                totalTime,
                pct: (100 * totalValue) / totalTime,
              };
            });

            return (
              <div key={varName}>
                <br />
                <table className={classes.summaryTable}>
                  <tbody>
                    <tr key={`${varName}-headerrow`}>
                      <th key={`${varName}-header`}>{varNameDetails[varName].label} </th>
                      {bandDetails.map((b) => (
                        <th key={`${varName}-header-${b.label}`}>{b.label}</th>
                      ))}
                    </tr>
                    <tr key={`${varName}-AllSensorsRow`}>
                      <td key={`${varName}-AllSensors`}>
                        <b>All sensors</b>
                      </td>
                      {bandAnalysis.map((b) => (
                        <td
                          key={`${varName}-AllSensors-${b.band.label}`}
                          className={classes.summaryTableBandCell}
                          style={{ background: b.band.color }}
                        >{`${b.pct.toFixed(1)}%`}</td>
                      ))}
                    </tr>
                    {varStats
                      .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                      .map((s) => (
                        <tr key={`${varName}-${s.sensorId}-row`}>
                          <td key={`${varName}-${s.sensorId}`}>{s.sensorName}</td>
                          {bandDetails &&
                            bandDetails.map((b) => {
                              const pct =
                                (100 * (s.stats.bandTimes.get(b.label) ?? 0)) / s.stats.totalTime;
                              return (
                                <td
                                  key={`${varName}-${s.sensorId}-${b.label}`}
                                  className={classes.summaryTableBandCell}
                                  style={
                                    pct > 0 ? { background: b.color } : { background: 'lightgrey' }
                                  }
                                >
                                  {pct.toFixed(1)}%
                                </td>
                              );
                            })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            );
          }
          if (varName === VarName.EnergyInkWh && varStats) {
            // Energy data will have totalAdded values in stats
            const totals: number[] = [];
            const totalsGhost: number[] = [];
            varStats.forEach(({ stats: sensorStats, sensorId }) => {
              if (sensorId.endsWith('-GHOST')) {
                totalsGhost.push(sensorStats.totalAdded ?? 0);
              } else {
                totals.push(sensorStats.totalAdded ?? 0);
              }
            });

            const sum = totals.reduce((a, b) => a + b);
            const hasGhosts = totalsGhost.length > 0;
            const sumGhost = hasGhosts ? totalsGhost.reduce((a, b) => a + b) : 0;
            return (
              <div key={`${varName}-GHOST`}>
                <br />
                <table className={classes.summaryTable}>
                  <tbody>
                    <tr key={`${varName}-vals-header-row`}>
                      <th key={`${varName}-vals-header-label`}>{varNameDetails[varName].label} </th>
                      <th key={`${varName}-vals-header-Total`}>Total</th>
                      {hasGhosts && <th>Previous</th>}
                      {hasGhosts && <th>Change</th>}
                    </tr>
                    <tr key={`${varName}-AllSensors-row`}>
                      <td key={`${varName}-AllSensors`}>
                        <b>All sensors</b>
                      </td>
                      <td
                        key={`${varName}-AllSensors-sum`}
                        className={classes.summaryTableBandCell}
                        style={{ background: getDataBandParams(varName, sum).color }}
                      >
                        {`${sum.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
                      </td>
                      {hasGhosts && (
                        <>
                          <td
                            key={`${varName}-AllSensors-sum-ghost`}
                            className={classes.summaryTableBandCell}
                            style={{ background: getDataBandParams(varName, sumGhost).color }}
                          >
                            {`${sumGhost.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
                          </td>
                          <td
                            key={`${varName}-AllSensors-sum-ghost-pct`}
                            className={classes.summaryTableBandCell}
                            style={{ background: 'grey' }}
                          >
                            {`${((sum - sumGhost) / sumGhost) * 100 > 0 ? '+' : ''}${(
                              ((sum - sumGhost) / sumGhost) *
                              100
                            ).toFixed(0)} %`}
                          </td>
                        </>
                      )}
                    </tr>
                    {varStats
                      .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                      .filter((s) => !s.sensorId.endsWith('-GHOST'))
                      .map((s) => (
                        <tr key={`${varName}-${s.sensorId}-row`}>
                          <td key={`${varName}-${s.sensorId}`}>{s.sensorName}</td>
                          <td
                            key={`${varName}-${s.sensorId}-added`}
                            className={classes.summaryTableBandCell}
                            style={{ background: 'grey' }}
                          >
                            {`${s.stats.totalAdded?.toFixed(1)} ${
                              varNameDetails[varName].metric ?? ''
                            }`}
                          </td>
                          {hasGhosts ? showGhostContent(varStats, varName, s) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            );
          }
          // No band details, do a basic summary
          if (varStats) {
            varStats.forEach(({ stats: sensorStats }) => {
              max = Math.max(max, sensorStats.maxMinAvg.max);
              min = Math.min(min, sensorStats.maxMinAvg.min);
              means.push(sensorStats.maxMinAvg.mean);
            });

            const sum = means.reduce((a, b) => a + b);
            const mean = sum / means.length;
            const maxMinAvg = { max, min, mean };
            return (
              <Box key={varName}>
                <br />
                <table className={classes.summaryTable}>
                  <tbody>
                    <tr key={`${varName}-vals-header`}>
                      <th key={`${varName}-vals-header-label`}>{varNameDetails[varName].label} </th>
                      <th key={`${varName}-vals-header-min`}>Minimum</th>
                      <th key={`${varName}-vals-header-avg`}>Average</th>
                      <th key={`${varName}-vals-header-max`}>Maximum</th>
                    </tr>
                    <tr key={`${varName}-AllSensors-row`}>
                      <td key={`${varName}-AllSensors-label`}>
                        <b>All sensors</b>
                      </td>
                      <td
                        key={`${varName}-AllSensors-min`}
                        className={classes.summaryTableBandCell}
                        style={{ background: getDataBandParams(varName, maxMinAvg.min).color }}
                      >
                        {`${maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                      </td>
                      <td
                        key={`${varName}-AllSensors-avg`}
                        className={classes.summaryTableBandCell}
                        style={{ background: getDataBandParams(varName, maxMinAvg.mean).color }}
                      >
                        {`${maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
                      </td>
                      <td
                        key={`${varName}-AllSensors-max`}
                        className={classes.summaryTableBandCell}
                        style={{ background: getDataBandParams(varName, maxMinAvg.max).color }}
                      >
                        {`${maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                      </td>
                    </tr>
                    {varStats
                      .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                      .map((s) => (
                        <tr key={`${varName}-${s.sensorId}-row`}>
                          <td key={`${varName}-${s.sensorId}-name`}>{s.sensorName}</td>
                          <td
                            key={`${varName}-${s.sensorId}-min`}
                            className={classes.summaryTableBandCell}
                            style={{ background: 'grey' }}
                          >
                            {`${s.stats.maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                          </td>
                          <td
                            key={`${varName}-${s.sensorId}-avg`}
                            className={classes.summaryTableBandCell}
                            style={{ background: 'grey' }}
                          >
                            {`${s.stats.maxMinAvg.mean.toFixed(0)} ${
                              varNameDetails[varName].metric ?? ''
                            }`}
                          </td>
                          <td
                            key={`${varName}-${s.sensorId}-max`}
                            className={classes.summaryTableBandCell}
                            style={{ background: 'grey' }}
                          >
                            {`${s.stats.maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>
            );
          }

          return varName;
        })}
    </>
  );
}

export default StatsBox;
