import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  locationGrid: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
  },
  locationInfoDialog: {
    width: '100%',
  },
  locationHeaderContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  headerTypography: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  activityIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 1',
    width: '100%',
    marginTop: '1rem',
  },
  locationSensorsHeader: {
    margin: '20px',
  },
  primaryBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelBtn: {
    color: 'white',
    backgroundColor: '#ab2e2e',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#ab2e2e',
    },
  },
  listSensorBtn: {
    margin: '15px',
    width: 'fit-content',
  },
  sensorContainer: {
    padding: '15px',
  },
  sensorList: {
    cursor: 'pointer',
  },
  headerIconContainer: {
    textAlign: 'right',
    '& > *': {
      marginRight: '10px',
      cursor: 'pointer',
    },
  },
  createLocationTextField: {
    width: '50%',
    padding: '10px',
    display: 'inline-grid',
  },
  activeIcon: {
    color: 'white',
    background: '#fdce14',
    borderRadius: '50%',
    padding: '3px',
  },
  uploadInput: {
    display: 'none',
  },
  alertMsg: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: themeProps.textSize.h4,
  },
  cancelIcon: {
    color: theme.palette.error.main,
    fontSize: themeProps.textSize.h4,
  },
  successIcon: {
    color: theme.palette.success.main,
  },

  childLocationListItem: {
    marginBottom: '5px',
    boxShadow: '2px 2px 2px 2px #80808040',
    padding: '5px',
    borderRadius: '2px',
    cursor: 'pointer',
    display: 'flex',
    color: theme.palette.text.primary,
    width: '100%',
    justifyContent: 'left',
    textTransform: 'capitalize',
  },
  disabledBtn: {
    color: `${theme.palette.primary.contrastText} !important`,
  },
  usersTable: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '500px',
      backgroundColor: 'white',
    },
  },
  createUserContainer: {
    width: '50%',
    marginLeft: '50px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
    },
  },
  newUserTextField: {
    width: '100%',
    paddingTop: '10px',
  },
  userActionBtn: {
    padding: '0',
  },
  usersListHeader: {
    '& span': {
      fontWeight: 600,
    },
    paddingBottom: '5px',
  },
  usersListItem: {
    padding: '5px 0',
  },
  accordionDetails: {
    display: 'block',
  },
  selectBox: {
    width: '50%',
  },
  sensorGridItem: {
    overflow: 'hidden',
  },
  rootLocationContainer: {
    marginTop: '20px',
  },
  configureText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  breadCrumbBtn: {
    padding: '0',
    minWidth: '0',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    '&:hover': {
      background: 'none',
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  configureDialogTitle: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
  configureDialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
  dialogPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0',
    },
  },
  locationDetailActions: {
    display: 'flex',
    justifyContent: 'end',
    '& button': {
      margin: '0 5px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  fabInfoBtn: {
    position: 'fixed',
    bottom: '15px',
    right: '15px',
    zIndex: 1,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  desktopConfigContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileConfigContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
