import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { themeProps } from '../../styles/theme';

interface RemoveDialogProps {
  showDialog: boolean;
  title: string;
  removeHandler: () => void;
  cancelHandler: () => void;
  helperText?: string;
}

function RemoveDialog({
  showDialog,
  title,
  removeHandler,
  cancelHandler,
  helperText,
}: RemoveDialogProps): JSX.Element {
  return (
    <Dialog
      open={showDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Remove {title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="secondary">
          Are you sure, you want to delete {title}? {helperText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant={themeProps.btnVariant.default} onClick={removeHandler}>
          Remove
        </Button>
        <Button color="secondary" variant={themeProps.btnVariant.default} onClick={cancelHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RemoveDialog.defaultProps = {
  helperText: '',
};

export default RemoveDialog;
