import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SensorIcon(props: SvgIconProps): JSX.Element {
  // eslint-disable-next-line react/destructuring-assignment
  const color = props.stroke ?? '#fff';
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <circle cx="11.9998" cy="12.027" r="2.27027" stroke={color} strokeWidth="2" fill="none" />
      <path
        d="M7.05025 17C4.31658 14.2386 4.31658 9.76142 7.05025 7M16.9497 7C19.6834 9.76142 19.6834 14.2386 16.9497 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M4.22183 20.0541C-0.0739416 15.6208 -0.0739419 8.4332 4.22183 4M19.7782 4C24.0739 8.4332 24.0739 15.6208 19.7782 20.0541"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path d="M12 14.5V19.5" stroke={color} strokeWidth="2" strokeLinecap="round" fill="none" />
    </SvgIcon>
  );
}

export default SensorIcon;
