import React from 'react';
import { Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  loadingWrapper: {
    width: '100%',
    minHeight: theme.spacing(10),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Loading(): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.loadingWrapper}>
      <CircularProgress color="inherit" />
    </div>
  );
}

export default Loading;
