/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { MapStyle } from 'react-map-gl/maplibre';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import distance from '@turf/distance';
import midpoint from '@turf/midpoint';
import destination from '@turf/destination';
import bearing from '@turf/bearing';
import intersect from '@turf/intersect';
import { Feature, polygon as turfPolygon, Polygon, Units } from '@turf/helpers';
// eslint-disable-next-line import/no-unresolved
import { TileLayer } from '@deck.gl/geo-layers';
import { RGBAColor, Position as DeckGlPosition } from '@deck.gl/core';
import { BitmapLayer } from '@deck.gl/layers';
import centerOfMass from '@turf/center-of-mass';
import WebMercatorViewport from 'viewport-mercator-project';
import {
  DataItem,
  PropLocationItem,
  SensorLatest,
  GeoJSON,
  Position,
  Detail,
} from '../../services/api';
import { getDataBandParams } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';
import ICON_ATLAS from './icon-atlas.png';
import { isDataExpired } from '../../utils/functions';
import { getMotionUtlBandParams } from '../CalendarView/helpers';
import { ThemeMode } from '../../state/types';
import { DeckGLViewPort } from './types';

export const MAX_FLOOR_OBJECT_ELEVATION = 1; // in metres
export const MAX_SENSOR_ELEVATION = 5; // in metres
export const FLOORPLAN_ZOOM = 5;
export const MIN_ZOOM = 0;
export const MAX_ZOOM = 21;

export { ICON_ATLAS }; // = './icon-atlas.png';
export const ICON_MAPPING = {
  marker: {
    x: 128,
    y: 0,
    width: 128,
    height: 128,
    mask: true,
    anchorY: 128,
  },
  sensor: {
    x: 512,
    y: 0,
    width: 128,
    height: 128,
    mask: true,
    anchorY: 110,
    anchorX: 60,
  },
  'toilet.m': {
    x: 0,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  'toilet.f': {
    x: 0,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  'toilet.d': {
    x: 0,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  toilet: {
    x: 0,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  lift: {
    x: 128,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  stairs: {
    x: 256,
    y: 128,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 64,
  },
  unknown: {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    mask: false,
    anchorY: 0,
  },
  person: {
    x: 256,
    y: 0,
    width: 128,
    height: 128,
    mask: true,
    anchorY: 128,
  },
  building: {
    x: 384,
    y: 0,
    width: 128,
    height: 128,
    mask: false,
    anchorY: 90,
    anchorX: 140,
  },
};

// Align floorplan on pages, for instance active Source has floorplan aligned to right side
export enum MapCentrePosition {
  default = 'default',
  right = 'right',
  left = 'left',
}

export interface LocationStateProps {
  latitude: number;
  longitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  transitionDuration: number;
  maxZoom: number;
}

export interface MarkerDataProps {
  id: string;
  name: string;
  shortName: string;
  position: Position;
  value: number;
  time: number;
  varName: VarName;
  isOnline: boolean;
  detail: Detail | null;
}

export const defaultLocation: LocationStateProps = {
  latitude: 53,
  longitude: -1,
  zoom: 4,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  maxZoom: MAX_ZOOM,
};

export enum FloorPlanItemType {
  floor = 'area.floor',
  room = 'area.room',
  desk = 'furniture.desk',
  chair = 'furniture.chair',
  wall = 'wall',
  window = 'window',
}

export function getFloorPlanItemFillColour(item: string): RGBAColor {
  switch (item as FloorPlanItemType) {
    // Try to avoid opacity 255 as it hides everything infront/behind it
    case FloorPlanItemType.wall:
    case FloorPlanItemType.floor:
      return [249, 246, 239, 80];
    case FloorPlanItemType.window:
      return [52, 183, 235, 50];
    case FloorPlanItemType.room:
      return [0, 0, 0, 0];
    case FloorPlanItemType.desk:
    case FloorPlanItemType.chair:
    default:
      return [179, 179, 179, 200];
  }
}

export function getFloorPlanItemLineColour(item: string): RGBAColor {
  switch (item as FloorPlanItemType) {
    case FloorPlanItemType.wall:
    case FloorPlanItemType.room:
      return [128, 128, 128, 255];
    case FloorPlanItemType.window:
      return [52, 183, 235, 50];
    case FloorPlanItemType.floor:
      return [0, 0, 0, 255];
    case FloorPlanItemType.desk:
    case FloorPlanItemType.chair:
    default:
      return [179, 179, 179, 255];
  }
}

export function getFloorPlanItemElevation(item: string): number {
  switch (item as FloorPlanItemType) {
    case FloorPlanItemType.desk:
    case FloorPlanItemType.chair:
      return 1;
    case FloorPlanItemType.wall:
    case FloorPlanItemType.window:
      return 2.5;
    case FloorPlanItemType.floor:
      return 0;
    case FloorPlanItemType.room:
      return 0.9;
    default:
      return 0.5;
  }
}

export interface PolygonMarker {
  id: string;
  contours: DeckGlPosition[];
  name: string;
  value: number;
  varName: VarName;
  time: number;
}

export type Marker = {
  name: string;
  id: string;
  location: string;
  coordinates: Array<number | undefined>;
  availableData: DataItem[];
  color?: RGBAColor;
};

export function hexToRgb(hex: string, alpha?: number): RGBAColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      alpha ?? 128,
    ];
  }

  return [52, 95, 145, alpha ?? 128];
}

export function getMarkerColour(
  value: number,
  time: number,
  addTransparency: boolean,
  activeMarker: VarName
): RGBAColor {
  let alpha = 255;

  if (addTransparency) {
    alpha = 128;
  }

  // Highlight items with no recent data (likely offline)
  if (
    activeMarker !== VarName.OnlineStatus &&
    activeMarker !== VarName.MotionEvent &&
    time &&
    isDataExpired(time)
  ) {
    return [151, 155, 166, alpha];
  }
  // Can we find a colour
  const c = getDataBandParams(activeMarker, value ?? NaN);
  return hexToRgb(c?.color, alpha); // if needed a default can be returned as [52, 95, 145, alpha]
}

const mapTilesLight = [
  'https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
  'https://b.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
  'https://c.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
  'https://d.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
];

const mapTilesDark = [
  'https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  'https://b.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  'https://c.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  'https://d.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
];

export const getMapLibreStyle = (themeMode: ThemeMode): MapStyle => ({
  version: 8,
  sources: {
    osm: {
      type: 'raster',
      tiles: themeMode === ThemeMode.dark ? mapTilesDark : mapTilesLight,
      tileSize: 256,
      maxzoom: 19,
    },
  },
  layers: [
    {
      id: 'osm',
      type: 'raster',
      source: 'osm', // This must match the source key above
    },
  ],
});
// tile layer is only used in positioning map now, should be updated to react-map-gl at some point
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTileLayer = (
  themeMode: ThemeMode,
  ref: string,
  onClick?: (o?: unknown, e?: unknown) => unknown | undefined
) => {
  const mapTiles = themeMode === ThemeMode.dark ? mapTilesDark : mapTilesLight;
  const newLayer = new TileLayer({
    id: `tile-layer-${ref}`,
    data: mapTiles,
    pickable: onClick !== undefined,
    // onViewportLoad: onTilesLoad,
    autoHighlight: false,
    highlightColor: [60, 60, 60, 40],
    minZoom: 0,
    tileSize: 256,
    onClick,
    renderSubLayers: (props) => {
      const {
        // eslint-disable-next-line react/prop-types
        bbox: { west, south, east, north },
        // eslint-disable-next-line react/prop-types
      } = props.tile;

      return [
        new BitmapLayer(props, {
          data: undefined,
          image: props.data,
          bounds: [west, south, east, north],
        }),
      ];
    },
  });

  return newLayer;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCentreOfObject = (geometry: any): DeckGlPosition => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  let coords = geometry.coordinates;
  // For some reason Turfjs centerOfMass doesn't work with MultiPolygon
  if (geometry.type === 'MultiPolygon') {
    [coords] = coords;
  }
  const poly = turfPolygon(coords);
  const center = centerOfMass(poly);

  return [
    center.geometry.coordinates[0],
    center.geometry.coordinates[1],
    MAX_FLOOR_OBJECT_ELEVATION,
  ];
};

export const calculateViewSize = (
  childLocations: PropLocationItem[],
  childSensors: SensorLatest[],
  currentLocation: string,
  floorPlan: GeoJSON | undefined,
  viewState: DeckGLViewPort
): DeckGLViewPort | undefined => {
  const currentLocationCoordinates = {
    minLat: 90,
    minLon: 180,
    maxLat: -90,
    maxLon: -180,
  };

  let processed = false;
  let newViewState;

  // Determine max extent of location and sensor markers
  const childLocationsAndSensors = [...childLocations, ...childSensors];
  childLocationsAndSensors.forEach((item) => {
    const loc = item.location ?? '';

    if (loc.indexOf(currentLocation) !== -1) {
      if (item.position?.lat !== undefined && item.position?.lng !== undefined) {
        const lng = item.position.lng as number;
        const lat = item.position.lat as number;
        if (lng < currentLocationCoordinates.minLon) {
          currentLocationCoordinates.minLon = lng;
        }
        if (lng > currentLocationCoordinates.maxLon) {
          currentLocationCoordinates.maxLon = lng;
        }
        if (lat < currentLocationCoordinates.minLat) {
          currentLocationCoordinates.minLat = lat;
        }
        if (lat > currentLocationCoordinates.maxLat) {
          currentLocationCoordinates.maxLat = lat;
        }

        processed = true;
      }
    }
  });

  // Determine extent of floorplan
  if (floorPlan && floorPlan.features.length > 0) {
    const [minLng, minLat, maxLng, maxLat] = bbox(floorPlan); // Turf.js
    if (minLng < currentLocationCoordinates.minLon) {
      currentLocationCoordinates.minLon = minLng;
    }
    if (maxLng > currentLocationCoordinates.maxLon) {
      currentLocationCoordinates.maxLon = maxLng;
    }
    if (minLat < currentLocationCoordinates.minLat) {
      currentLocationCoordinates.minLat = minLat;
    }
    if (maxLat > currentLocationCoordinates.maxLat) {
      currentLocationCoordinates.maxLat = maxLat;
    }

    processed = true;
  }

  if (processed) {
    const { longitude, latitude, zoom } = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds([
      [currentLocationCoordinates.minLon, currentLocationCoordinates.minLat],
      [currentLocationCoordinates.maxLon, currentLocationCoordinates.maxLat],
    ]);
    newViewState = {
      ...viewState,
      latitude,
      longitude,
      zoom,
    };
  }
  return newViewState;
};

export interface MotionMapRecord {
  occupancyValue: number;
  id: string;
  online: boolean;
  color: string;
}

export const getMotionMap = (
  activeMarkers: MarkerDataProps[],
  isUtlData: boolean
): Map<string, MotionMapRecord | null> | null => {
  const map = new Map<string, MotionMapRecord | null>();
  activeMarkers?.forEach((marker) => {
    const { isOnline, position, id, value, varName } = marker;
    const polygon = position?.polygon;
    // if there is more than one sensor for the polygon ignore the data (use coverage layer)
    if (polygon && map.has(polygon)) {
      map.set(polygon, null);
    } else if (varName === VarName.MotionEvent && polygon) {
      map.set(polygon, {
        occupancyValue: value,
        id,
        online: isOnline,
        color: isUtlData
          ? getMotionUtlBandParams(value).color
          : getDataBandParams(VarName.MotionEvent, value).color,
      });
    }
  });

  return map;
};

export const getCirclePolygon = (activeMarkers: MarkerDataProps[], marker: MarkerDataProps) => {
  let radius = 5;
  const { id, position, varName } = marker;
  if (varName === VarName.ClientsBle || varName === VarName.ClientsWiFi) radius = 10;
  const targetPoint = [position?.lng ?? 0, position?.lat ?? 0];
  const options = { steps: 50, units: 'meters' as Units };
  let circlePolygon = circle(targetPoint, radius, options);
  const overlappingSensors: SensorLatest[] = [];
  activeMarkers?.forEach((markerData) => {
    const center = [markerData.position?.lng ?? 0, markerData.position?.lat ?? 0];
    const targetDistance = distance(targetPoint, center, { units: 'meters' });
    if (targetDistance < radius * 2 && markerData.id !== id) {
      if (varName === VarName.ClientsBle || varName === VarName.ClientsWiFi) {
        overlappingSensors.push(markerData);
      } else if (markerData.position?.polygon === position?.polygon)
        overlappingSensors.push(markerData);
    }
  });
  if (overlappingSensors.length > 0) {
    for (let i = 0; i < overlappingSensors.length; i++) {
      const center = [
        overlappingSensors[i].position?.lng ?? 0,
        overlappingSensors[i].position?.lat ?? 0,
      ];
      const midPoint = midpoint(center, targetPoint).geometry.coordinates;
      const bearingAngle = bearing(targetPoint, center);
      const bearingOptions = { units: 'meters' as Units };
      const destination1 = destination(midPoint, radius, bearingAngle + 90, bearingOptions);
      const destination2 = destination(
        destination1.geometry.coordinates,
        radius * 2,
        bearingAngle + 180,
        bearingOptions
      );
      const destination3 = destination(
        destination2.geometry.coordinates,
        radius * 2,
        bearingAngle + 270,
        bearingOptions
      );
      const destination4 = destination(
        destination3.geometry.coordinates,
        radius * 2,
        bearingAngle + 360,
        bearingOptions
      );
      const destinationPolygon = turfPolygon([
        [
          destination1.geometry.coordinates,
          destination2.geometry.coordinates,
          destination3.geometry.coordinates,
          destination4.geometry.coordinates,
          destination1.geometry.coordinates,
        ],
      ]);
      circlePolygon = intersect(circlePolygon, destinationPolygon) as Feature<Polygon>;
    }
  }
  return circlePolygon;
};
