import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TemperatureIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 69 68" {...props}>
      <path
        d="M 36.00,1.80
           C 36.00,1.80 36.00,5.80 36.00,5.80
             35.00,5.00 33.90,4.50 34.00,3.50
             34.10,2.40 34.90,1.50 36.00,1.80 Z
           M 29.10,1.70
           C 29.10,1.70 29.10,5.30 29.10,5.30
             30.00,4.80 31.20,3.90 31.10,2.90
             31.00,1.90 30.00,1.40 29.10,1.70 Z
           M 40.90,41.00
           C 40.90,41.00 40.90,9.50 40.90,9.50
             40.90,6.10 38.90,3.10 36.00,1.80
             36.00,1.80 36.00,5.80 36.00,5.80
             37.10,6.80 37.70,8.20 37.70,9.70
             37.70,9.70 37.70,42.70 37.70,42.70
             37.70,42.70 38.90,43.70 38.90,43.70
             41.80,45.80 43.40,49.20 43.40,52.80
             43.40,55.80 42.20,58.70 40.10,60.80
             37.90,62.90 35.10,64.10 32.10,64.10
             26.50,64.10 22.00,60.10 21.10,55.20
             20.40,50.70 22.10,46.30 25.50,43.80
             25.50,43.80 26.70,42.80 26.70,42.80
             26.70,42.80 26.70,9.80 26.70,9.80
             26.70,8.00 27.60,6.40 29.00,5.40
             29.00,5.40 29.00,1.60 29.00,1.60
             26.00,2.90 23.80,6.00 23.80,9.50
             23.80,9.50 23.80,41.00 23.80,41.00
             19.50,44.20 17.00,49.50 18.30,55.60
             19.40,61.10 23.90,65.60 29.60,66.70
             38.80,68.50 46.80,61.50 46.80,52.50
             46.80,47.90 44.50,43.60 40.90,41.00 Z
           M 54.00,28.50
           C 54.00,27.70 53.30,27.00 52.50,27.00
             52.50,27.00 44.60,27.00 44.60,27.00
             44.60,27.00 44.60,30.20 44.60,30.20
             44.60,30.20 52.50,30.20 52.50,30.20
             53.30,30.10 54.00,29.30 54.00,28.50 Z
           M 50.80,20.20
           C 50.80,19.40 50.10,18.70 49.30,18.70
             49.30,18.70 44.60,18.70 44.60,18.70
             44.60,18.70 44.60,21.90 44.60,21.90
             44.60,21.90 49.30,21.90 49.30,21.90
             50.10,21.80 50.80,21.10 50.80,20.20 Z
           M 52.30,10.30
           C 52.30,10.30 44.40,10.30 44.40,10.30
             44.40,10.30 44.40,13.50 44.40,13.50
             44.40,13.50 52.30,13.50 52.30,13.50
             53.10,13.50 53.80,12.80 53.80,12.00
             54.00,11.00 53.30,10.30 52.30,10.30 Z
           M 32.40,60.70
           C 34.60,60.70 36.70,59.90 38.20,58.40
             39.70,56.90 40.50,54.80 40.50,52.60
             40.50,47.10 35.80,45.30 34.60,44.20
             34.60,44.20 34.60,35.50 34.60,35.50
             34.60,35.50 30.00,35.50 30.00,35.50
             30.00,35.50 30.00,44.20 30.00,44.20
             29.00,45.30 23.00,47.50 24.40,54.20
             25.10,57.80 28.20,60.70 32.40,60.70 Z"
      />
    </SvgIcon>
  );
}

export default TemperatureIcon;
