import { VarName } from '../../utils/varNames';
import { themeProps } from '../../styles/theme';

export const reportColors = {
  green: themeProps.colors.spearmint,
  lightGreen: themeProps.colors.lightGreen,
  white: themeProps.colors.white,
  blue: themeProps.colors.cobalt,
  lightBlue: themeProps.colors.infoBlue,
  red: themeProps.colors.warningRed,
  lightRed: themeProps.colors.lightRed,
  orange: themeProps.colors.alertYellow,
  lightGrey: themeProps.colors.lightGrey,
  grey: themeProps.colors.grey,
};

export interface BarChartTraces {
  name?: string;
  value: number[];
  color?: string;
  orientation: string;
  base?: number[];
  shape?: string;
}

export interface PieChartProperties {
  annotationColor: string;
  annotationText: number | undefined;
  childRingValue?: [number | string, number | string] | [];
  childRingColor?: [string, string];
  colors: [string, string];
  titleText: string;
  values: [number, number] | [];
  showMaxTitle?: boolean;
}

export interface BarChartProperties {
  traces: BarChartTraces[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  source: VarName;
  values: (string | number)[];
  range?: number[];
  xAxisVisibility?: boolean;
  showLegend?: boolean;
}
