import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BackBtnIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <rect x="5.40991" y="19.2937" width="30" height="2" rx="1" />
      <rect x="4" y="20.2334" width="20" height="2" rx="1" transform="rotate(-45.3707 4 20.2334)" />
      <rect
        x="5.4707"
        y="18.9226"
        width="20"
        height="2"
        rx="1"
        transform="rotate(45.4684 5.4707 18.9226)"
      />
    </SvgIcon>
  );
}

export default BackBtnIcon;
