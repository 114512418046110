import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReportUtlData, VarName } from '../../services/api';
import getSensorBarChartProp from './helpers/sensorBarChart';
import { BarChartProperties, PieChartProperties, reportColors } from './constants';
import { varNameDetails } from '../../utils/varNames';
import PieChartPlot from './PieChartPlot';
import BarChartPlot from './BarChartPlot';
import bubbleBg from '../../styles/images/report/bubble-bg.svg';
import rightAnchorBubbleBg from '../../styles/images/report/rightAnchorBubble.svg';
import sensorDevice from '../../styles/images/report/sensorDevice.svg';
import detailBtn from '../../styles/images/report/detailBtn.svg';
import useStyles from '../../styles/report';

interface PmReportProps {
  utlData: ReportUtlData;
  lastMonthUtlData: ReportUtlData | undefined;
}

function PmReport({ utlData, lastMonthUtlData }: PmReportProps): JSX.Element {
  const classes = useStyles();
  const { summary, weekdays, sensors } = utlData;
  const { utl, peakUtl, maxTheory } = summary;

  const lastMonthUtl = lastMonthUtlData?.summary.utl;
  const lastMonthPeakUtl = lastMonthUtlData?.summary.peakUtl;
  const lastMonthMaxTheory = lastMonthUtlData?.summary.maxTheory;
  const sortedSensors = [...sensors].sort((a, b) => b.utl * b.relativeMax - a.utl * a.relativeMax);

  const goodSensorReadings = [...sortedSensors].slice(0, 5).reverse();
  const goodSensorBarProps = getSensorBarChartProp(
    VarName.ClientsWiFi,
    goodSensorReadings,
    false,
    false
  );

  const badSensorReadings = [...sortedSensors]
    .slice(sortedSensors.length - 5, sortedSensors.length)
    .reverse();
  const badSensorBarProps = getSensorBarChartProp(VarName.ClientsWiFi, badSensorReadings, true);

  const utlThisMonthPie: PieChartProperties = {
    annotationColor: reportColors.blue,
    annotationText: utl * 100,
    childRingColor: [reportColors.white, reportColors.blue],
    childRingValue: [100 - utl * 100, utl * 100],
    colors: [reportColors.white, reportColors.green],
    titleText: `This Month <br> (${maxTheory} max value )`,
    values: [100 - peakUtl * 100, peakUtl * 100],
    showMaxTitle: true,
  };

  const utlLastMonthPie: PieChartProperties = {
    annotationColor: reportColors.blue,
    annotationText: lastMonthUtl ? lastMonthUtl * 100 : undefined,
    childRingColor: [reportColors.white, reportColors.blue],
    childRingValue: lastMonthUtl ? [100 - lastMonthUtl * 100, lastMonthUtl * 100] : [],
    colors: [reportColors.white, reportColors.green],
    titleText: `Last Month <br> (${lastMonthMaxTheory} max value )`,
    values: lastMonthPeakUtl ? [100 - lastMonthPeakUtl * 100, lastMonthPeakUtl * 100] : [],
    showMaxTitle: true,
  };

  const utlEachDayBar: BarChartProperties = {
    traces: [
      {
        name: 'max',
        value: weekdays?.map((x) => x.peakUtl * 100).flat(2),
        orientation: 'v',
        color: reportColors.green,
      },
      {
        name: 'average',
        value: weekdays?.map((x) => x.utl * 100).flat(2),
        orientation: 'v',
        color: reportColors.blue,
      },
    ],
    yAxisTitle: `${varNameDetails[VarName.ClientsWiFi]?.label}(${
      varNameDetails[VarName.ClientsWiFi]?.metric
    })`,
    source: VarName.ClientsWiFi,
    values: weekdays?.map((x) => x.day).flat(2),
  };

  return (
    <>
      <div className={classes.page}>
        <Typography variant="h3">Workspace Utilisation</Typography>
        <Typography variant="h4">Your monthly space utilisation</Typography>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <PieChartPlot pieProps={utlThisMonthPie} />
            <PieChartPlot pieProps={utlLastMonthPie} />
          </Box>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              style={{ top: '15px', height: '160px', width: '175px' }}
              defaultValue="We gauge space utilisation through a proxy occupancy metric, considering the quantity of phones, laptops, watches, and other wireless devices individuals carry. Blue signifies utilisation; green denotes peak utilisation."
            />
          </Box>
        </Box>

        <Box sx={{ display: { sm: 'flex', xs: 'block' } }}>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              Space utilisation is a measure of the number of people occupying a space relative to
              its capacity, averaged over working hours. This metric reveals the occupancy patterns
              of different areas, identifying popular or quiet days, times or locations.
              Understanding how a space is being utilised can help improve design, productivity
            </p>
          </Box>
          <Box sx={{ padding: { sm: '25px', xs: '5px 10px' } }}>
            <p>
              and flexibility, as well as better allocate and manage resources such as rent, energy,
              and assets. Underutilised space can lead to unnecessary costs and a sense of
              disconnection, while overutilisation can decrease comfort, increase stress, and
              accelerate the wear and tear of building infrastructure.
            </p>
          </Box>
        </Box>
        <Typography variant="h4">Understanding your space utilisation data</Typography>
        <p>
          The “wireless occupancy” count is a long-range occupancy counting method, covering a 10 to
          20 meter radius, that is able to detect percentage occupancy levels (±10%). It has been
          specifically designed for building control automation. For our analysis in this report, we
          assume that each sensor saw maximum occupancy at least once during the measurement period.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ position: 'relative', alignSelf: 'center' }}>
            <img src={rightAnchorBubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              style={{ top: '10px' }}
              defaultValue="For more granular occupancy analysis of your space, please look at the data from our granular (motion/PIR/ToF) occupancy sensors for desks and meeting rooms."
            />
          </div>
          <div>
            <img style={{ width: '120%' }} src={sensorDevice} alt="sensor info" />
          </div>
        </div>
      </div>
      <div className={classes.page}>
        <Typography variant="h3">Workspace Utilisation</Typography>
        <Typography variant="h4">Utilisation for each day of the week</Typography>

        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center' }}>
            <BarChartPlot barProps={utlEachDayBar} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="The graph displays the average utilisation (blue) and peak utilisation (green), defined as the maximum observed utilisation compared with estimated capacity, for each day of the week."
            />
          </Box>
        </Box>
        <Typography variant="h4">Busiest and quietest areas </Typography>
        <p>
          The table below shows how utilised each area is (blue), during working hours. It also
          shows the relative maximum occupancy (green) for each space, which is the maximum
          occupancy reached in each area, relative to the highest maximum occupancy of any space
          during the period.
        </p>
        <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around' }}>
          <div style={{ display: 'grid' }}>
            <BarChartPlot barProps={goodSensorBarProps} />
            <BarChartPlot barProps={badSensorBarProps} />
          </div>
          <Box
            sx={{ position: 'relative', alignSelf: 'center', margin: { sm: 0, xs: '10px 20%' } }}
          >
            <img src={bubbleBg} style={{ width: '200px' }} alt="bubble-text" />
            <textarea
              className={classes.bubbleTextArea}
              defaultValue="This table displays the 5 most occupied and 5 least occupied areas. Average utilisation (blue) and maximum occupancy (green) are both scaled to the largest recorded occupancy number in any single area."
            />
          </Box>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <a href="https://portal.lightfi.io/">
            <img className={classes.portalLinkImg} src={detailBtn} alt="portal link" />
          </a>
        </div>
      </div>
    </>
  );
}

export default PmReport;
