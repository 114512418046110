import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/dashboard';
import MapGL from '../Map/Map';
import Header from '../../components/Header';
import {
  getActiveMarker,
  getCurrentLocation,
} from '../../state/selectors';
import SensorIcon from '../../styles/icons/SensorIcon';
import { setClickedItem } from '../../state/actions';
import SensorsList from './SensorsList';
import MapViewIcon from '../../styles/icons/MapViewIcon';
import SelectedSensorPlot from './SelectedSensorPlot';
import { MapCentrePosition } from '../Map/mapHelpers';

function ActiveSource(): JSX.Element {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkFirstRender = useRef(true);
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const isDesktopView = useMediaQuery(useTheme().breakpoints.up('lg'));

  const floatingElementRef = useRef(null);
  const [showMap, setShowMap] = useState(false);

  // reset clicked item on location or active marker change
  useEffect(() => {
    // avoid reset on first render where sensor might have been clicked to land here
    if (checkFirstRender.current) {
      checkFirstRender.current = false;
    } else {
      dispatch(setClickedItem({ id: '' }));
    }
  }, [currentLocation, activeMarker, dispatch]);

  const FloatingIcon = showMap ? SensorIcon : MapViewIcon;

  const showMapContent = () => {
    let mapContent;
    if (isDesktopView)
      mapContent = <MapGL showSourceToolbar={false} mapCentrePosition={MapCentrePosition.right} />;
    // for small screen show map only when map button is clicked
    if (showMap) mapContent = <MapGL />;
    return mapContent;
  };

  return (
    <div
      style={{ overflowY: 'hidden', height: '100%', padding: '0' }}
      className={globalClasses.bodyContent}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          padding: { xs: '10px', lg: 0 },
        }}
      >
        <Box className={classes.floatingHeader}>
          <Header showSearchField={false} />
        </Box>
        <Box
          ref={floatingElementRef}
          sx={{ display: { lg: 'block', xs: showMap ? 'none' : 'block' } }}
          className={classes.floatingContainer}
        >
          <SelectedSensorPlot />
          <SensorsList floatingElementRef={floatingElementRef} />
        </Box>
        {showMapContent()}
        <Fab
          color="error"
          size="medium"
          className={`${globalClasses.floatingBtn}`}
          style={{ bottom: '20px' }}
          sx={{ display: { lg: 'none' } }}
        >
          <FloatingIcon onClick={() => setShowMap(!showMap)} />
        </Fab>
      </Box>
    </div>
  );
}

export default ActiveSource;
