import { SensorReading } from '../services/dataStore';
import { MOTION_LIGHT_USAGE_MAX_VAL, MOTION_OCCUPIED_VALUE_RESCALE } from './dataBandParams';

export const motionTimeTicks = [
  {
    value: 3,
    time: 10,
    label: "10'",
  },
  {
    value: 5,
    time: 30,
    label: "30'",
  },
  {
    value: 7,
    time: 60,
    label: '1h',
  },
  {
    value: 9,
    time: 120,
    label: '2h',
  },
  {
    value: 11,
    time: 180,
    label: '3h',
  },
  {
    value: 13,
    time: 240,
    label: '4h',
  },
  {
    value: 15,
    time: 360,
    label: '6h',
  },
  {
    value: 17,
    time: 480,
    label: '8h',
  },
  {
    value: 19,
    time: 720,
    label: '12h',
  },
  {
    value: 23,
    time: 1440,
    label: '1 day',
  },
];

export const mapRangeValueToTime = (value: number): number => {
  for (let i = 0; i < motionTimeTicks.length; i++) {
    if (motionTimeTicks[i].value === value) {
      return motionTimeTicks[i].time;
    }
  }

  return NaN;
};

export const occupiedValue = (value: number): number => 1 + value * MOTION_OCCUPIED_VALUE_RESCALE;
export const recentUseValue = (value: number): number =>
  value < MOTION_LIGHT_USAGE_MAX_VAL ? 0.4 : 0.6;
export const UNOCCUPIED_VALUE = 0;

export const motionValueToOccupancyValue = (
  motionSliderRange: [number, number],
  data: SensorReading,
  refTime: number
): number => {
  const [occThresh, recentThresh] = [
    mapRangeValueToTime(motionSliderRange[0]) * 60,
    mapRangeValueToTime(motionSliderRange[1]) * 60,
  ];

  // Occupied
  if (refTime - data.time <= occThresh) {
    return occupiedValue(data.value);
  }
  // Recent use
  if (refTime - data.time <= recentThresh) {
    return recentUseValue(data.value);
  }
  // Unoccupied
  return UNOCCUPIED_VALUE;
};

/**
 *
 * @param range The time bands, see motionTimeTicks
 * @param time The motionEvent data timestamp in seconds
 * @param value The motionEvent data value
 * @param refTime Reference time to compare (default is now)
 */
export const getMotionOccupancyValue = (
  range: [number, number],
  time?: number,
  value?: number,
  refTime?: number
): number => {
  if (!time || !value) {
    return -1; // Shouldn't happen?
  }

  let refT = refTime;
  if (!refT) {
    refT = Date.now() / 1000;
  }
  return motionValueToOccupancyValue(range, { time, value }, refT);
};

export const transformHistoricMotionOccupancy = (
  values: SensorReading[],
  motionSliderRange: [number, number],
  requestedEndTime: number
): SensorReading[] => {
  const tranformedValues = [];

  const [occThresh, recentThresh] = [
    mapRangeValueToTime(motionSliderRange[0]) * 60,
    mapRangeValueToTime(motionSliderRange[1]) * 60,
  ];
  for (let i = 0; i < values.length; i++) {
    const { time, value } = values[i];

    // Add extra points for unoccupied times between data
    if (i > 1) {
      const lastTime = values[i - 1].time;
      const lastValue = values[i - 1].value;

      if (time - lastTime > occThresh) {
        let lastAddedTime = lastTime;
        while (time - lastAddedTime > occThresh) {
          if (lastAddedTime - lastTime < recentThresh) {
            lastAddedTime += occThresh;
            tranformedValues.push({ time: lastAddedTime, value: recentUseValue(lastValue) });
          } else {
            lastAddedTime += occThresh;
            tranformedValues.push({ time: lastAddedTime, value: UNOCCUPIED_VALUE });
          }
        }
      }
    }

    tranformedValues.push({ time, value: occupiedValue(value) });
  }

  // Add any extra points to fill to requestedEndTime
  if (values.length >= 1) {
    const lastData = values[values.length - 1];
    let lastAddedTime = lastData.time;
    while (requestedEndTime - lastAddedTime > occThresh) {
      if (lastAddedTime - lastData.time < recentThresh) {
        lastAddedTime += occThresh;
        tranformedValues.push({ time: lastAddedTime, value: recentUseValue(lastData.value) });
      } else {
        lastAddedTime += occThresh;
        tranformedValues.push({ time: lastAddedTime, value: UNOCCUPIED_VALUE });
      }
    }
  }

  return tranformedValues;
};
