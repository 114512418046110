import React, { ChangeEvent, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GatewaySensorType, ProvisionSensorClaimOut, ProvisionSensorIn } from '../services/api';
import { getCurrentLocation, getLocationsById } from '../state/selectors';
import { fetchLocationProperties, provisionSensor } from '../services/apiService';
import { MAX_SENSOR_ELEVATION } from '../Widgets/Map/mapHelpers';
import useStyles from '../styles';
import { SensorPositionData } from '../Widgets/Map/types';
import { themeProps } from '../styles/theme';
import { updateLocationData } from '../state/actions';

interface PassedFormParams {
  sensorId: string;
  fwVer: string;
  hwVer: string;
  callbackUrl: string;
  sensorName: string;
  sensorShortName: string;
  nameChangeHandler: (e: ChangeEvent<HTMLInputElement>, param: string) => void;
  onElevationChange?: (newValue: number) => void;
  onPositionChange?: (newValue: [number, number]) => void;
  onPolygonChange?: (newValue: string) => void;
  position: SensorPositionData;
}

function GatewayProvisioningForm({
  sensorId,
  fwVer,
  hwVer,
  callbackUrl,
  position,
  sensorName,
  sensorShortName,
  nameChangeHandler,
  onElevationChange,
  onPositionChange,
  onPolygonChange,
}: PassedFormParams): JSX.Element {
  const dispatch = useDispatch();
  const currentLocation = useSelector(getCurrentLocation);
  const locationsById = useSelector(getLocationsById);
  const [timeZone, setTimeZone] = useState<string>('Europe/London');
  const [port, setPort] = useState<string>('62221');
  const sensorType = GatewaySensorType.LfWiFiSensor;
  const classes = useStyles();
  const [provisionClaim, setProvisionClaim] = useState<ProvisionSensorClaimOut>();
  const [positionElevation, setPositionElevation] = useState(0);
  const [positionPolygon, setPositionPolygon] = useState('');
  const [positionValid, setPositionValid] = useState(true);
  const [currentPosition, setCurrentPosition] = useState('');
  const [errMsg, setErrMsg] = useState<string>();
  const [activeStep, setActiveStep] = useState(0);
  const getSteps = () => ['Confirm', 'Launch'];
  const steps = getSteps();

  useEffect(() => {
    const { lat, lng, polygon } = position;
    if (lat !== undefined && lng !== undefined) {
      setCurrentPosition(`${lat},${lng}`);
      setPositionValid(true);
    }
    setPositionPolygon(polygon ?? '');
  }, [position]);

  useEffect(() => {
    const locationDetails = locationsById.get(currentLocation);
    if (locationDetails && locationDetails.timeZone) {
      setTimeZone(locationDetails.timeZone);
    } else
      fetchLocationProperties(currentLocation)
        .then((res) => {
          dispatch(updateLocationData(res));
          if (res.timeZone) {
            setTimeZone(res?.timeZone);
          }
        })
        .catch((err) => setErrMsg(err.cause));
  }, [currentLocation, dispatch, locationsById]);

  const handleAPIRequest = () => {
    setErrMsg(undefined);
    const provisionData: ProvisionSensorIn = {
      location: currentLocation,
      name: sensorName,
      shortName: sensorShortName,
      type: sensorType,
      hwVersion: hwVer,
      fwVersion: fwVer,
      timeZone,
    };

    if (port !== '62221') {
      provisionData.port = port;
    }

    if (Object.keys(position).length !== 0) {
      provisionData.position = position;
    }

    provisionSensor(sensorId, provisionData)
      .then((data) => {
        setProvisionClaim(data);
        if (data?.claim) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((err) => setErrMsg(err.cause));
  };

  const onElevationChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseFloat(event.target.value ?? 0);
    setPositionElevation(newValue);
    if (onElevationChange !== undefined) {
      onElevationChange(newValue);
    }
  };

  const onPositionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? '';
    setCurrentPosition(newValue);

    const values = newValue.split(',');
    let valid = false;

    if (values?.length === 2) {
      const lat = Number.parseFloat(values[0]);
      const lng = Number.parseFloat(values[1]);
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        valid = true;
        if (onPositionChange !== undefined) {
          onPositionChange([lat, lng]);
        }
      }
    }

    setPositionValid(valid);
  };

  const onPolygonChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPositionPolygon(newValue);
    if (onPolygonChange !== undefined) {
      onPolygonChange(newValue);
    }
  };

  return (
    <Card variant="outlined" className={classes.provisioningForm} style={{ overflow: 'auto' }}>
      <Typography variant="h5">Confirm and create sensor</Typography>
      <Stepper activeStep={activeStep} className={classes.provisioningFormStepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form noValidate autoComplete="off">
        <div className={`${classes.provisioningFormRow} ${classes.provisionFormAutoField}`}>
          <TextField
            required
            disabled
            id="id"
            label="Sensor id"
            value={sensorId}
            variant="standard"
          />
          <TextField
            required
            disabled
            id="hwVer"
            label="Hardware version"
            value={hwVer}
            variant="standard"
          />
          <TextField
            required
            disabled
            id="fwVer"
            label="FW version"
            value={fwVer}
            variant="standard"
          />
          <TextField
            required
            disabled
            id="type"
            label="Type"
            value={sensorType}
            variant="standard"
          />
        </div>
        <div className={classes.provisioningFormRow}>
          <TextField
            disabled
            required
            style={{ width: 'calc(100% - 1rem' }}
            id="location"
            label="Location (selected above)"
            value={currentLocation}
            variant="standard"
          />
        </div>
        <div className={classes.provisioningFormRow}>
          <TextField
            disabled={provisionClaim?.claim !== undefined}
            required
            id="name"
            label="Name"
            value={sensorName}
            onChange={(e) => nameChangeHandler(e as React.ChangeEvent<HTMLInputElement>, 'name')}
            style={{ width: 'calc(50% - 1rem' }}
            variant="standard"
          />
          <TextField
            disabled={provisionClaim?.claim !== undefined}
            required
            id="shortName"
            label="Short Name"
            value={sensorShortName}
            onChange={(e) => {
              nameChangeHandler(e as React.ChangeEvent<HTMLInputElement>, 'shortName');
            }}
            style={{ width: 'calc(50% - 1rem)' }}
            variant="standard"
          />
        </div>

        <Accordion>
          <AccordionSummary
            expandIcon={
              <IconButton data-testid="expand" size="large">
                <ExpandMoreIcon />
              </IconButton>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h5">Advanced Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <div className={classes.provisioningFormRow}>
                <InputLabel shrink id="position">
                  Click on map to select sensor position
                </InputLabel>
                <TextField
                  id="position-coordinates"
                  placeholder="Postion"
                  error={!positionValid}
                  helperText={!positionValid ? 'Incorrect position entry' : undefined}
                  value={currentPosition}
                  onChange={onPositionChangeHandler}
                  style={{ width: 'calc(100% - 1rem)' }}
                  variant="standard"
                />
              </div>
              <div className={classes.provisioningFormRow}>
                <TextField
                  id="position-elevation"
                  label="Elevation (m)"
                  type="number"
                  inputProps={{
                    min: 0,
                    max: MAX_SENSOR_ELEVATION,
                    step: 0.1,
                  }}
                  style={{
                    width: 'calc(50% - 1rem)',
                  }}
                  data-testid="elevation-form"
                  value={positionElevation}
                  onChange={onElevationChangeHandler}
                  variant="standard"
                />
                <TextField
                  id="position-polygon"
                  label="Polygon id"
                  type="text"
                  style={{
                    width: 'calc(50% - 1rem)',
                  }}
                  data-testid="polygon-id"
                  value={positionPolygon}
                  onChange={onPolygonChangeHandler}
                  variant="standard"
                />
              </div>
              <div className={classes.provisioningFormRow}>
                <TextField
                  required
                  disabled
                  id="timeZone"
                  label="Time Zone"
                  value={timeZone}
                  style={{
                    width: 'calc(50% - 1rem)',
                  }}
                  variant="standard"
                />
                <TextField
                  disabled={provisionClaim?.claim !== undefined}
                  id="port"
                  label="Port"
                  type="number"
                  onChange={(e) => {
                    setPort(e.target.value);
                  }}
                  style={{
                    width: 'calc(50% - 1rem)',
                  }}
                  variant="standard"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </form>
      {errMsg && (
        <Alert severity="error" className={classes.alertMsg}>
          {errMsg}
        </Alert>
      )}
      {!provisionClaim?.claim ? (
        <Button variant={themeProps.btnVariant.default} onClick={handleAPIRequest}>
          Confirm Details
        </Button>
      ) : (
        <>
          <p>
            (To complete the process you will be redirected to your sensor at {callbackUrl}, you may
            need to allow this connection in your browser.)
          </p>
          <form action={`${callbackUrl}`} method="post" encType="text/plain">
            <input name="claim" value={JSON.stringify(provisionClaim)} type="hidden" />
            <Button
              variant={themeProps.btnVariant.default}
              type="submit"
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
            >
              Create Sensor
            </Button>
          </form>
        </>
      )}
    </Card>
  );
}

GatewayProvisioningForm.defaultProps = {
  onElevationChange: undefined,
  onPositionChange: undefined,
  onPolygonChange: undefined,
};

export default GatewayProvisioningForm;
