import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useStyles from '../../styles/dashboard';
import useGlobalStyles from '../../styles';
import { VarName, varNameDetails } from '../../utils/varNames';
import SensorIcon from '../../styles/icons/SensorIcon';
import {
  getActiveMarker,
  getClickedItem,
  getCurrentLocation,
  getHighlightedItem,
  getMotionThreshold,
  getLocSensorStatusData,
  getSelectedBand,
  getSensorsById,
} from '../../state/selectors';
import BaseArraySortMenu from '../HelperComponents/BaseArraySortMenu';
import { getDataBandParams } from '../../utils/dataBandParams';
import DataValueString, { getDataValueNumber } from '../HelperComponents/DataValueString';
import {
  setClickedItem,
  setCurrentLocation,
  setHighlightedItem,
  setSelectedBand,
} from '../../state/actions';
import useDataFiltering from '../SensorArrayWidgets/useDataFiltering';
import { themeProps } from '../../styles/theme';
import { getShortTimeStr } from '../../utils/functions';

function DashboardSensorsList(): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeMarker = useSelector(getActiveMarker);
  const sensorsById = useSelector(getSensorsById);
  const locSensorStatusData = useSelector(getLocSensorStatusData);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const motionThreshold = useSelector(getMotionThreshold);
  const currentLocation = useSelector(getCurrentLocation);
  const selectedBand = useSelector(getSelectedBand);

  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const selectedVarName = selectedBand ? selectedBand.varName : activeItem.varName ?? activeMarker;
  const ActiveMarkerIcon = varNameDetails[selectedVarName]?.icon ?? SensorIcon;

  const onlineData = locSensorStatusData.onlineData.get(selectedVarName) ?? [];
  const offlineData = locSensorStatusData.offlineData.get(selectedVarName) ?? [];

  const allVarValues = [...onlineData, ...offlineData];

  const filteredTree = useMemo(() => {
    const bandFilter = [selectedBand?.band];
    let valueTree = onlineData;
    if (selectedVarName === VarName.OnlineStatus) valueTree = allVarValues;
    const bandData = valueTree.filter(
      (dataItem) =>
        bandFilter.length === 0 ||
        bandFilter.indexOf(
          getDataBandParams(
            selectedVarName,
            getDataValueNumber(dataItem, motionThreshold) ?? Infinity
          )
        ) !== -1
    );
    return bandData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motionThreshold, onlineData, selectedBand, selectedVarName]);

  const initialDataTree = useMemo(
    () => (selectedBand ? filteredTree : allVarValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBand, locSensorStatusData, selectedVarName]
  );

  const sortedVarValues = useDataFiltering(initialDataTree);

  const onSensorClick = (id: string, loc: string | undefined) => {
    dispatch(setClickedItem({ id, varName: activeMarker }));
    if (loc) dispatch(setCurrentLocation(loc));
    navigate('/activeSource');
    dispatch(setSelectedBand(null));
  };

  useEffect(() => {
    dispatch(setClickedItem({ id: '' }));
  }, [activeMarker, dispatch]);

  useEffect(() => {
    const sortedOnlineValues = sortedVarValues.filter((item) =>
      onlineData.some((el) => el.id === item.id)
    );
    if (sortedOnlineValues.length > 0) {
      const { id, varName } = sortedOnlineValues[0];
      if (varName === activeMarker) dispatch(setClickedItem({ id, varName: activeMarker }));
      else if (selectedBand) dispatch(setHighlightedItem({ id, varName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation, dispatch, sortedVarValues, selectedBand]);

  return (
    <Card className={classes.dashboardCard} style={{ overflowY: 'scroll', height: '300px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignSelf: 'center' }}>
          <ActiveMarkerIcon />
          <Typography variant="h6" style={{ marginLeft: '5px' }}>
            {varNameDetails[selectedVarName].label}
          </Typography>
        </Box>
        <BaseArraySortMenu iconOnly />
      </Box>
      <List dense>
        {sortedVarValues.map((dataItem) => {
          const { id, varName, value, location, time } = dataItem;
          const isOnline =
            varName !== VarName.OnlineStatus ? onlineData.some((item) => item.id === id) : true;
          const dataItemValue = getDataValueNumber(dataItem, motionThreshold);
          const dataColor =
            dataItemValue !== undefined
              ? getDataBandParams(selectedVarName, dataItemValue).color
              : themeProps.colors.grey;

          return (
            <ListItem
              key={id}
              sx={{
                background:
                  id === activeItem.id
                    ? `${isOnline ? dataColor : themeProps.colors.lightGrey}84`
                    : theme.palette.primary.light,
                margin: '5px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onMouseEnter={() => dispatch(setClickedItem({ id, varName }))}
              // onMouseLeave={() => dispatch(setHighlightedItem({ id: '' }))}
              onClick={() => onSensorClick(id, location)}
            >
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{
                    color: isOnline ? dataColor : themeProps.colors.lightGrey,
                    width: '12px',
                    height: '12px',
                    alignSelf: 'center',
                  }}
                />
                <ActiveMarkerIcon
                  style={{
                    color: theme.palette.text.primary,
                    height: '16px',
                    width: '16px',
                    marginLeft: '5px',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ flex: 'none' }}>
                <DataValueString value={value ?? 0} varName={varName} time={time} />
              </ListItemText>
              <p
                className={globalClasses.updatedTime}
                style={{ fontSize: themeProps.textSize.small, margin: '0 10px' }}
              >
                {time && getShortTimeStr(time)}
              </p>
              <ListItemText primary={sensorsById.get(id)?.name ?? id} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

export default DashboardSensorsList;
