import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import useGlobalStyles from '../../styles/index';
import {
  getActiveMarker,
  getCurrentLocation,
  getLocationsById,
  getSelectedEndDate,
  getSelectedStartDate,
  getSensorsById,
  getSensorsByLocId,
  getSensorsByVarName,
} from '../../state/selectors';

import { VarName, varNameDetails } from '../../utils/varNames';
import DateRangePicker from '../../components/DateRangePicker';
import Header from '../../components/Header';
import { SensorHistoryPlotItem } from '../../Widgets/Plots/plotCommon';
import DataStore from '../../services/dataStore';
import ColouredLinePlot from '../../Widgets/Plots/ColouredLinePlot';
import { themeProps } from '../../styles/theme';
import Feedback from './Feedback/Feedback';
import { getDataValueString } from '../../Widgets/HelperComponents/DataValueString';
import { getDataBandParams } from '../../utils/dataBandParams';
import WeatherService, { WeatherData } from '../../services/weatherService';
import { setSelectedStartDate, setSelectedEndDate } from '../../state/actions';
import { DataItem } from '../../services/api';
import { getEndOfDay, getStartOfDay } from '../../utils/functions';

function AppDashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dataStore = DataStore.getInstance();
  const globalClasses = useGlobalStyles();
  const { sensorId } = useParams() as { sensorId: string };

  const activeMarker = useSelector(getActiveMarker);
  const [sensorData, setSensorData] = useState<SensorHistoryPlotItem>();
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const currentLocation = useSelector(getCurrentLocation);
  const sensorsByVarName = useSelector(getSensorsByVarName).get(activeMarker);
  const locSensors = useSelector(getSensorsByLocId).get(currentLocation);

  useEffect(() => {
    dispatch(setSelectedStartDate(dayjs().startOf('d').toDate()));
    dispatch(setSelectedEndDate(new Date(Date.now())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const weatherService = WeatherService.getInstance();

  const [showFeedbackPage, setShowFeedbackPage] = useState(false);
  const [outsideWeather, setOutsideWeather] = useState<number>();

  useEffect(() => setOutsideWeather(undefined), [currentLocation]);

  const showOutsideWeather =
    activeMarker === VarName.TemperatureC || activeMarker === VarName.RelativeHumidity;

  const selectedSensorValue =
    sensorsById.get(sensorId)?.data?.find((item) => item.varName === activeMarker)?.value ?? 0;

  // band details for selected sensor
  const { color, description } = getDataBandParams(activeMarker, selectedSensorValue);

  const avgReading = useMemo(() => {
    let avgValue;
    if (showOutsideWeather) {
      if (outsideWeather) avgValue = outsideWeather;
    } else {
      const locVarValues: DataItem[] = [];
      const activeVarLocSensors =
        locSensors ?? [].filter((item) => sensorsByVarName ?? [].includes(item));
      activeVarLocSensors.forEach((id) => {
        const dataItem = sensorsById.get(id)?.data?.find((item) => item.varName === activeMarker);
        if (dataItem) locVarValues.push(dataItem);
      });

      avgValue =
        locVarValues.reduce((acc, curr) => acc + (curr?.value ?? 0), 0) / locVarValues.length;
    }
    return avgValue;
  }, [activeMarker, locSensors, outsideWeather, sensorsById, sensorsByVarName, showOutsideWeather]);

  const updateOutsideWeather = (data: WeatherData) => {
    if (activeMarker === VarName.TemperatureC && data.main?.temp)
      setOutsideWeather(Math.round(data.main?.temp));
    else if (activeMarker === VarName.RelativeHumidity && data.main?.humidity)
      setOutsideWeather(Math.round(data.main?.humidity));
  };

  useEffect(() => {
    if (showOutsideWeather && outsideWeather === undefined) {
      let position;
      const locPos = locationsById.get(currentLocation)?.position;
      const sensorPos = sensorsById.get(sensorId)?.position;

      if (locPos) position = locPos;
      else if (sensorPos) position = sensorPos;
      else if (locSensors) {
        for (let i = 0; i < locSensors.length; i++) {
          const locSensorPos = sensorsById.get(locSensors[i])?.position;
          if (locSensorPos) {
            position = locSensorPos;
            break;
          }
        }
      }

      if (position && position.lat && position.lng) {
        weatherService
          .getCurrentWeather(currentLocation, position.lat, position.lng)
          .then((res) => {
            updateOutsideWeather(res);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLocation,
    locSensors,
    locationsById,
    outsideWeather,
    sensorId,
    sensorsById,
    showOutsideWeather,
  ]);

  useEffect(() => {
    dataStore
      .getHistory(
        sensorId,
        activeMarker,
        new Date(startDate).getTime(),
        new Date(endDate).getTime(),
        true
      )
      .then((history) => {
        const dataItem: SensorHistoryPlotItem = {
          varName: activeMarker,
          sensorId,
          sensorName: sensorsById.get(sensorId)?.name ?? '',
          history: {
            time: history.map(({ time }) => time),
            value: history.map(({ value }) => value),
          },
        };
        setSensorData(dataItem);
      });
  }, [activeMarker, dataStore, endDate, sensorId, sensorsById, startDate]);

  function handleRelayout(event: Plotly.PlotRelayoutEvent) {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      let newStart = new Date(event['xaxis.range[0]']);
      let newEnd = new Date(event['xaxis.range[1]']);
      // Don't dispatch if it is a zoom into current data, only if the new range is larger (pan)
      if (dayjs(newStart).isBefore(dayjs(startDate)) || dayjs(newEnd).isAfter(dayjs(endDate))) {
        // If dragged to the future limit to now and keep range from drag
        const now = new Date();
        if (newEnd > now) {
          const diff = Math.max(newEnd.getTime() - now.getTime(), 24 * 3600 * 1000);
          newEnd = getEndOfDay(now);
          newStart = getStartOfDay(new Date(newStart.getTime() - diff));
        }
        dispatch(setSelectedStartDate(newStart));
        dispatch(setSelectedEndDate(newEnd));
      }
    }
  }

  return (
    <div className={globalClasses.bodyContent} style={{ padding: '10px' }}>
      <Header />
      {!showFeedbackPage && (
        <Box>
          <Typography variant="h4" sx={{ textAlign: 'center', margin: '10px 0' }}>
            {varNameDetails[activeMarker].label}
          </Typography>

          <Grid container spacing={2} sx={{ textAlign: 'center', marginTop: '10px' }}>
            <Grid item xs={6}>
              <span>Sensor reading</span>
              <Typography
                variant="h3"
                sx={{
                  background: theme.palette.primary.light,
                  borderRadius: '8px',
                  padding: '20px 0',
                  margin: '15px 0 15px 10px',
                  color,
                }}
              >
                {getDataValueString(selectedSensorValue, activeMarker, undefined, undefined, false)}
                <span style={{ fontSize: themeProps.textSize.h4 }}>
                  {' '}
                  {varNameDetails[activeMarker].metric}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <span>{showOutsideWeather ? 'Outside' : 'Average'} reading</span>
              <Typography
                variant="h3"
                sx={{
                  background: theme.palette.primary.light,
                  borderRadius: '8px',
                  padding: '20px 0',
                  margin: '15px 10px 15px 0',
                  color:
                    avgReading !== undefined
                      ? getDataBandParams(activeMarker, avgReading)?.color
                      : theme.palette.text.primary,
                }}
              >
                {avgReading !== undefined ? (
                  <>
                    {getDataValueString(avgReading, activeMarker, undefined, undefined, false)}
                    <span style={{ fontSize: themeProps.textSize.h4 }}>
                      {' '}
                      {varNameDetails[activeMarker].metric}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" sx={{ textAlign: 'center', margin: '20px 0' }}>
            {description}
          </Typography>
          <Typography variant="h5" sx={{ textAlign: 'center', margin: '10px 0' }}>
            History{' '}
            <span style={{ fontSize: themeProps.textSize.small }}>
              ({sensorsById.get(sensorId)?.name ?? sensorId})
            </span>
          </Typography>
          <Box>
            <DateRangePicker showHoursSelection={false} />
          </Box>
          {sensorData && (
            <div className={globalClasses.sensorGraph}>
              <ColouredLinePlot data={sensorData} handleRelayout={(e) => handleRelayout(e)} />
            </div>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                marginTop: '2rem',
                borderRadius: '20px',
                background: theme.palette.primary.light,
                border: 'none',
              }}
              onClick={() => setShowFeedbackPage(true)}
            >
              How can we help you today?
            </Button>
          </Box>
        </Box>
      )}
      {showFeedbackPage && (
        <Feedback sensorValue={selectedSensorValue} reset={() => setShowFeedbackPage(false)} />
      )}
    </div>
  );
}

export default AppDashboard;
