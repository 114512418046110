import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { SensorHistoryPlotItem, SensorMetricPlotItem } from '../Widgets/Plots/plotCommon';
import { VarName } from '../services/api';
import { varNameDetails } from './varNames';
import { GhostParams, HoursSelection } from '../state/types';

// compare page CSV
function history2CSV(data: SensorHistoryPlotItem): string {
  const { label, metric } = { ...varNameDetails[data.varName] };
  let CSV = `#time(ISO8601),${label}(${metric})\r\n`;

  for (let index = 0; index < data.history.time.length; index++) {
    const time = data.history.time[index];
    const date = new Date(time * 1000);
    const value = data.history.value[index];
    CSV += `${date.toISOString().replace(/.\d+Z$/g, 'Z')},${value}\r\n`;
  }

  return CSV;
}

export function prepareCSVzip(sensorData: SensorHistoryPlotItem[]): void {
  const zip = new JSZip();
  const folders: Map<VarName, JSZip> = new Map();
  sensorData.forEach((item) => {
    let folder;
    if (folders.has(item.varName)) {
      folder = folders.get(item.varName);
    } else {
      folder = zip.folder(`${item.varName}`) ?? undefined;
      if (folder) folders.set(item.varName, folder);
    }

    const startM = dayjs.unix(item.history.time[0]);
    const endM = dayjs.unix(item.history.time[item.history.time.length - 1]);
    const fileName = `${item.sensorId}_${item.sensorName}_${item.varName}_${startM.format(
      'YY-MM-DD'
    )}to${endM.format('YY-MM-DD')}.csv`;

    if (folder) folder.file(fileName, history2CSV(item));
  });

  zip.generateAsync({ type: 'blob' }).then((content) => {
    // see FileSaver.js
    saveAs(content, 'sensordata.zip');
  });
}

export const timeLabel = (hour: number) => {
  if (hour === 12) {
    return `${hour}pm`;
  }
  if (hour < 12) {
    return `${hour}am`;
  }
  return `${hour - 12}pm`;
};

// Metric page CSV
function metricHistory2CSV(
  sensorData: SensorMetricPlotItem[],
  activeVars: VarName[],
  ghostParams: GhostParams,
  selectedHours?: HoursSelection
): string {
  const { addGhosts, weekCount } = ghostParams;
  // Are we doing daily data with restricted hours?
  // (checking for `hours` prop is shortcut to indicate daily data)
  let hoursLabel = '';
  if (selectedHours !== undefined && sensorData[0].metrics[0].hours !== undefined) {
    const { startHour, endHour } = selectedHours;
    hoursLabel = ` (Analysis restricted to: ${timeLabel(startHour)} - ${timeLabel(endHour)})`;
  }

  let CSV = `Date${hoursLabel}${addGhosts ? ` (GHOST: -${weekCount} weeks)` : ''},`;

  // get header texts with sensor Id and metric format for each varName
  activeVars.forEach((varName) => {
    const varData = sensorData.filter((item) => item.varName === varName);
    const { label, metric } = { ...varNameDetails[varName] };
    const varMetricLabel = `${label}${metric ? `(${metric})` : ''}`;

    const energyTotMetricLabel = (item: SensorMetricPlotItem) =>
      `[Total ${varMetricLabel}],${item.sensorName ?? ''} (${
        item.sensorId
      })[Meter Reading ${varMetricLabel}]`;

    const namedIds = varData.map(
      (i) =>
        `${i.sensorName ?? ''} (${i.sensorId})${
          varName === VarName.EnergyInkWh ? energyTotMetricLabel(i) : ` [${varMetricLabel}]` // Add extra reading for energy data
        }`
    );

    CSV += `${namedIds.join(',')}${varName === activeVars[activeVars.length - 1] ? '\r\n' : ','}`;
  });

  // Get body content and values for dates with sensor value for each varname
  // Extract all available dates from history
  const dates = sensorData
    .flatMap((item) => item.metrics.map((day) => day.date))
    .filter((n) => n) as string[]; // remove undefined date for missing history
  // Remove duplicate date entries and sort
  const filteredDates = Array.from(new Set(dates)).sort((a, b) => a.localeCompare(b));

  for (let i = 0; i < filteredDates.length; i++) {
    CSV += `${filteredDates[i]},`;
    // eslint-disable-next-line no-loop-func
    activeVars.forEach((varName) => {
      const values: (number | null)[] = [];
      const varData = sensorData.filter((item) => item.varName === varName);
      varData.forEach((sensor) => {
        const dataItem = sensor.metrics.find((item) => item.date === filteredDates[i]);
        values.push(Number((dataItem?.tot ?? dataItem?.utl ?? dataItem?.avg)?.toFixed(2)) ?? null);
        if (varName === VarName.EnergyInkWh) values.push(Number(dataItem?.max?.toFixed(2)) ?? null);
      });

      CSV += `${values.join(',')}${varName === activeVars[activeVars.length - 1] ? '\r\n' : ','}`;
    });
  }

  return CSV;
}

// Metric Analysis Csv
export function prepareMetricCSV(
  sensorData: SensorMetricPlotItem[],
  activeVars: VarName[],
  ghostParams: GhostParams,
  selectedHours?: HoursSelection
): void {
  const csvData = new Blob(
    [metricHistory2CSV(sensorData, activeVars, ghostParams, selectedHours)],
    { type: 'text/csv' }
  );
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = 'MetricData.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
