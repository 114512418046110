import { createStore, applyMiddleware, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import rootEpic from './epics';
import { ActionTypes } from './actionTypes';
import { RootState } from './types';

const epicMiddleware = createEpicMiddleware();

export default function configureStore(initialState?: RootState): Store<RootState, ActionTypes> {
  const store = createStore(
    rootReducer,
    initialState || {},
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
}
