import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SortAlphaDescIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M120.777 202.315H210.376V231.734H75V210.25L162.891 83.663H75.1221V54H208.423V74.996L120.777 202.315Z" />
      <path d="M175.356 410.113H111.147L98.9404 446.734H60L126.162 269H160.097L226.625 446.734H187.685L175.356 410.113ZM121.035 380.45H165.468L143.129 313.922L121.035 380.45Z" />
      <path d="M427.875 246.5V381H376.125V246.5H333L402 112L471 246.5H427.875Z" />
    </SvgIcon>
  );
}

export default SortAlphaDescIcon;
