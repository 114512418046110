import React, { useState, useMemo, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import useStyles from '../styles/index';
import ChandePassWordDialog from '../Widgets/UserProfile/ChangePasswordDialog';
import { TempUser } from '../state/types';
import { getTempUser, getUserDetails } from '../state/selectors';
import { UserPublic } from '../services/api';
import MfaTotpDialog from '../Widgets/Dialog/MfaTotpDialog';
import DefaultMobileHeader from '../components/DefaultMobileHeader';
import { themeProps } from '../styles/theme';

dayjs.extend(localizedFormat);

enum MFAType {
  totp = 'SOFTWARE_TOKEN_MFA',
  sms = 'SMS_MFA',
  noMfa = 'NOMFA',
}

function UserProfile(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuthenticator((context) => [context.user]);
  const [showPassChangeDialog, setShowPassChangeDialog] = useState(false);
  const [showMfaTotpDialog, setShowMfaTotpDialog] = useState(false);
  // const [showMfaSmsDialog, setShowMfaSmsDialog] = useState(false);
  const [mfaStatus, setMfaStatus] = useState<string>();
  const tempUser: TempUser | null = useSelector(getTempUser);
  const userDetails: UserPublic | null = useSelector(getUserDetails);

  const expiryTime: number | null = useMemo(() => {
    let time = null;
    if (tempUser && tempUser.expiry) {
      time = tempUser.expiry;
    } else if (userDetails && userDetails.expiry) time = userDetails.expiry;
    return time;
  }, [tempUser, userDetails]);

  useEffect(() => {
    if (user) Auth.getPreferredMFA(user).then((data) => setMfaStatus(data));
  }, [user]);

  return (
    <>
      {userDetails && (
        <ChandePassWordDialog
          showPassChangeDialog={showPassChangeDialog}
          closeDialog={() => setShowPassChangeDialog(false)}
        />
      )}
      {showMfaTotpDialog && (
        <MfaTotpDialog
          openDialog={showMfaTotpDialog}
          closeDialog={() => setShowMfaTotpDialog(false)}
        />
      )}
      {/* Hide SMS MFA for now, when implementing look around for phone number validation */}
      {/* {showMfaSmsDialog && (
        <MfaSmsDialog
          openDialog={showMfaSmsDialog}
          closeDialog={() => setShowMfaSmsDialog(false)}
        />
      )} */}
      <div className={classes.bodyContent}>
        <DefaultMobileHeader />
        <Grid container spacing={2} style={{ margin: '10px 0', justifyContent: 'center' }}>
          <Grid item sm={12} md={6}>
            <Paper elevation={0} sx={{ padding: '10px' }}>
              <Box sx={{ display: 'inline-flex', marginTop: '1em' }}>
                <AccountCircleIcon color="secondary" style={{ alignSelf: 'center' }} />
                <Typography variant="h5" style={{ marginLeft: '10px' }}>
                  User Profile
                </Typography>
              </Box>
              <List>
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="User Id" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <ListItemText
                      primary={`${tempUser ? 'Temporary User' : user.attributes?.email}`}
                    />
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="Access Level" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary={!tempUser ? userDetails?.access_level : 'Read Only'} />
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="Expiry Time" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <ListItemText
                      primary={
                        expiryTime ? dayjs(expiryTime * 1000).format('DD MMM YYYY hh:mm:ss') : '-'
                      }
                    />
                  </Grid>
                </ListItem>
                {!tempUser && (
                  <>
                    <ListItem>
                      <Grid item sm={6} xs={6}>
                        <ListItemText primary="Preferred MFA" />
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <ListItemText primary={mfaStatus} />
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <Grid item sm={6} xs={6}>
                        <ListItemText primary="TOTP MFA" />
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <Button
                          color="primary"
                          variant="text"
                          style={{
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            padding: '0px',
                          }}
                          onClick={() => setShowMfaTotpDialog(true)}
                        >
                          {mfaStatus && mfaStatus === MFAType.totp
                            ? 'Change TOTP MFA'
                            : 'Add TOTP MFA'}
                        </Button>
                        {mfaStatus && mfaStatus === MFAType.totp && (
                          <Button
                            color="primary"
                            variant="text"
                            style={{ textTransform: 'capitalize', textDecoration: 'underline' }}
                            onClick={() => Auth.setPreferredMFA(user, MFAType.noMfa)}
                          >
                            Disable MFA
                          </Button>
                        )}
                      </Grid>
                    </ListItem>
                  </>
                )}
                {/* SMS feature to be implemented in future
                {(mfaStatus === MFAType.totp || mfaStatus === MFAType.sms) && (
                  <ListItem>
                    <Grid item sm={6} xs={6}>
                      <ListItemText primary="Backup SMS MFA" />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Button
                        color="primary"
                        variant="text"
                        style={{ textTransform: 'capitalize', textDecoration: 'underline' }}
                        onClick={() => setShowMfaSmsDialog(true)}
                      >
                        {mfaStatus && mfaStatus === MFAType.sms ? 'Change SMS MFA' : 'Add SMS MFA'}
                      </Button>
                      {mfaStatus && mfaStatus === MFAType.sms && (
                        <Button
                          color="primary"
                          variant="text"
                          style={{ textTransform: 'capitalize', textDecoration: 'underline' }}
                          onClick={() => Auth.setPreferredMFA(user, MFAType.noMfa)}
                        >
                          Disable MFA
                        </Button>
                      )}
                    </Grid>
                  </ListItem>
                )} */}
                {!tempUser && (
                  <ListItem>
                    <Grid item sm={6} xs={6}>
                      <ListItemText primary="Password" />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Button
                        startIcon={<KeyIcon />}
                        variant={themeProps.btnVariant.default}
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => setShowPassChangeDialog(true)}
                      >
                        Change Password
                      </Button>
                    </Grid>
                  </ListItem>
                )}
                <ListItem>
                  <Grid item sm={6} xs={6}>
                    <ListItemText primary="User Privacy Policy" />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.lightfi.io/privacy-policy/"
                      style={{ color: theme.palette.text.primary }}
                    >
                      User Privacy Policy
                    </a>
                  </Grid>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default UserProfile;
