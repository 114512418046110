import React from 'react';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { VarName, varNameDetails } from '../utils/varNames';
import { getDataBandParams, varNameBandParams } from '../utils/dataBandParams';
import {
  isHistoryItem,
  isMetricItem,
  SensorHistoryPlotItem,
  SensorMetricPlotItem,
} from '../Widgets/Plots/plotCommon';
import varNameDescriptions, { BandParamDescription } from './varNameDescriptions';

import useStyles from '../styles/report';
import whiteLogo from '../logo_512x512_white.png';
import greenLogo from '../logo_512x512_teal.png';
import '../utils/print.css';
import { getCurrentLocation, getLocationsById, getThemeMode } from '../state/selectors';
import { ThemeMode } from '../state/types';
import {
  SensorStatsSummary,
  MaxMinAvg,
  calculateStats,
  calculateMetricStats,
} from '../utils/statistics';

dayjs.extend(advancedFormat);

export interface ComparePageReportProps {
  stats: Map<VarName, SensorStatsSummary[]>;
  dateRange: [Date, Date];
  selectedHours: [number, number];
  includeWeekends: boolean;
  onClose: () => void;
  fetchingSensorData: boolean;
}

interface AnalysisData {
  varName: VarName;
  statsPerSensor: SensorStatsSummary[] | undefined;
}
function AnalysisSection({ varName, statsPerSensor }: AnalysisData): JSX.Element {
  const classes = useStyles();

  if (!statsPerSensor?.length) {
    return <h3>No data</h3>;
  }

  const bandDetails = varNameBandParams[varName];

  let max = -Infinity;
  let min = Infinity;
  const means: number[] = [];

  if (bandDetails) {
    const bandAnalysis = bandDetails.map((band) => {
      let totalTime = 0;
      let totalValue = 0;

      statsPerSensor.forEach(({ stats }) => {
        totalTime += stats.totalTime;
        totalValue += stats.bandTimes.get(band.label) ?? 0;
        max = Math.max(max, stats.maxMinAvg.max);
        min = Math.min(min, stats.maxMinAvg.min);
        means.push(stats.maxMinAvg.mean);
      });

      return {
        band,
        totalValue,
        totalTime,
        pct: (100 * totalValue) / totalTime,
      };
    });
    const sum = means?.reduce((a, b) => a + b, 0) ?? 0;
    const mean = sum / Math.max(means.length, 1);
    const maxMinAvg: MaxMinAvg = { max, min, mean };

    return (
      <>
        <h3>Analysis</h3>
        {/* <h4>Worst</h4>
        The worst performing space was *** which spent *** hours (***%) in band ***
        <h4>Best</h4>
        The best performing space was *** which spent *** hours (***%) in band *** */}
        <h4>Summary</h4>
        Table (below): Minimum, average (mean) and maximum values per sensor.
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-vals-header`}>
              <th> </th>
              <th>Minimum</th>
              <th>Average</th>
              <th>Maximum</th>
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.min).color }}
              >
                {`${maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.mean).color }}
              >
                {`${maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.max).color }}
              >
                {`${maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
              </td>
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.min).color }}
                  >
                    {`${s.stats.maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.mean).color }}
                  >
                    {`${s.stats.maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.max).color }}
                  >
                    {`${s.stats.maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <p />
        Table (below): Percentage of time measured in each band range. (For example if the sensor
        spends 1 of every 4 hours within a particular range and 3 of 4 in another those bands will
        show 25% and 75%.)
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-header`}>
              <th> </th>
              {bandDetails.map((b) => (
                <th key={`${varName}-band-${b.label}`}>{b.label}</th>
              ))}
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              {bandAnalysis.map((b) => (
                <td
                  key={`${varName}-bandAnalysis-${b.band.label}`}
                  className={classes.summaryTableBandCell}
                  style={{ background: b.band.color }}
                >{`${b.pct.toFixed(1)}%`}</td>
              ))}
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  {bandDetails.map((b) => {
                    const pct = (100 * (s.stats.bandTimes.get(b.label) ?? 0)) / s.stats.totalTime;
                    return (
                      <td
                        key={`${varName}-${s.sensorId}-band-${b.label}`}
                        className={classes.summaryTableBandCell}
                        style={pct > 0 ? { background: b.color } : { background: 'lightgrey' }}
                      >
                        {pct.toFixed(1)}%
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }

  // Cumulative data calculate the total
  if (varName === VarName.EnergyInkWh) {
    const totals: number[] = [];
    statsPerSensor.forEach(({ stats }) => {
      totals.push(stats.totalAdded ?? 0);
    });

    const sum = totals.reduce((a, b) => a + b);
    return (
      <>
        <h3>Analysis</h3>
        <h4>Summary</h4>
        Table (below): Total increase in recorded energy per sensor.
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-vals-header`}>
              <th> </th>
              <th>Total</th>
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, sum).color }}
              >
                {`${sum.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
              </td>
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                    {`${s.stats.totalAdded?.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }

  // No band details, do a basic summary
  statsPerSensor.forEach(({ stats }) => {
    max = Math.max(max, stats.maxMinAvg.max);
    min = Math.min(min, stats.maxMinAvg.min);
    means.push(stats.maxMinAvg.mean);
  });

  const sum = means.reduce((a, b) => a + b);
  const mean = sum / means.length;
  const maxMinAvg: MaxMinAvg = { max, min, mean };
  return (
    <>
      <h3>Analysis</h3>
      <h4>Summary</h4>
      Table (below): Minimum, average (mean) and maximum values per sensor.
      <table className={classes.summaryTable}>
        <tbody>
          <tr key={`${varName}-vals-header`}>
            <th> </th>
            <th>Minimum</th>
            <th>Average</th>
            <th>Maximum</th>
          </tr>
          <tr key={`${varName}-AllSensors`}>
            <td>
              <b>All sensors</b>
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.min).color }}
            >
              {`${maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.mean).color }}
            >
              {`${maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.max).color }}
            >
              {`${maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
            </td>
          </tr>
          {statsPerSensor
            .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
            .map((s) => (
              <tr key={`${varName}-${s.sensorId}`}>
                <td>{s.sensorName}</td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                </td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
                </td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <p>No band details</p>
    </>
  );
}

export function statsPerVarNamePerSensor(
  data: SensorHistoryPlotItem[] | SensorMetricPlotItem[],
  selectedHours: [number, number],
  includeWeekends: boolean
) {
  const stats = new Map<VarName, SensorStatsSummary[]>();
  data.forEach((item) => {
    if ((isHistoryItem(item) && item.history.time.length > 0) || isMetricItem(item)) {
      let sensors = stats.get(item.varName);
      const sensorStats = isHistoryItem(item)
        ? calculateStats(item.varName, item.history, selectedHours, includeWeekends)
        : calculateMetricStats(item.varName, item.metrics, selectedHours, includeWeekends);
      const sensor = {
        sensorId: item.sensorId,
        sensorName: item.sensorName,
        stats: sensorStats,
      };
      if (!sensors) {
        sensors = [sensor];
        stats.set(item.varName, sensors);
      } else {
        sensors.push(sensor);
      }
    }
  });

  return stats;
}

function ComparePageReport({
  stats,
  dateRange,
  selectedHours,
  includeWeekends,
  onClose,
  fetchingSensorData,
}: ComparePageReportProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useSelector(getThemeMode);
  const currentLocation = useSelector(getCurrentLocation);
  const locationsById = useSelector(getLocationsById);

  const print = () => {
    window.print();
  };

  // ***********************************************************************************
  // NOTE: It is IMPORTANT that we only use HTML elements within the <main> content
  // so that we can apply the CSS print styles effectively when the user wants to print.
  // Using Typography from Material or other such components will complicate things.
  // ***********************************************************************************
  return (
    <div className={`${classes.report} print`}>
      <AppBar className={`${classes.header} no-print`}>
        <Toolbar>
          <img
            src={themeMode === ThemeMode.dark ? whiteLogo : greenLogo}
            alt="logo"
            height="35"
            width="35"
          />
          <div style={{ flex: '1 1 auto' }} />
          {fetchingSensorData && <CircularProgress style={{ color: 'white' }} size={20} />}
          <Button onClick={print} className={classes.headerButton} startIcon={<PrintIcon />}>
            Print
          </Button>
          <Button onClick={onClose} className={classes.headerButton} startIcon={<CloseIcon />}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <h1>Report for {locationsById.get(currentLocation)?.name ?? currentLocation}</h1>
        <p>Date of report: {dayjs().format('dddd MMMM Do YYYY, h:mm a')}</p>
        <p>
          Reporting for period {dayjs(dateRange[0]).format('dddd MMMM Do YYYY, h:mm a')} to{' '}
          {dayjs(dateRange[1]).format('dddd MMMM Do YYYY, h:mm a')}.
        </p>
        <p>
          Hours analysed are between {selectedHours[0]}:00 and {selectedHours[1]}:00. Weekends{' '}
          <b>are {!includeWeekends && 'not '}</b>included in the analysis.
        </p>
        <section>
          <h2>About this report</h2>
          <p>
            Using continuous monitoring data from multiple sensors distributed throughout occupied
            areas, we can assess the performance of building systems and policy. This analysis can
            be used ensure the right environment is being provided to occupants in all areas and
            under all conditions of use, insight that cannot be gained from spot checks alone. The
            factors measured can have a dramatic effect on comfort, productivity and the risk of
            viral transmission, this analysis can demonstrate the building health or, if necessary,
            identify areas that can be improved.
          </p>
          <p>The following analysis is split to consider each environmental factor.</p>
        </section>
        {Array.from(stats.keys())
          .sort((a, b) => {
            const pa = varNameDetails[a].reportSortOrder ?? Infinity;
            const pb = varNameDetails[b].reportSortOrder ?? Infinity;
            if (pa < pb) return -1;
            if (pa > pb) return 1;

            const la = varNameDetails[a].label;
            const lb = varNameDetails[b].label;
            if (la < lb) return -1;
            if (la > lb) return 1;

            return 0;
          })
          .map((varName) => (
            <section key={`reportSection-${varName}`}>
              <h2>{varNameDetails[varName].label}</h2>
              {varNameDescriptions[varName].description}
              {varNameBandParams[varName] && <BandParamDescription varName={varName} />}

              <AnalysisSection varName={varName} statsPerSensor={stats.get(varName)} />

              {varNameDescriptions[varName].footnotes && (
                <aside>
                  <h3>References</h3>
                  <ol>
                    {varNameDescriptions[varName].footnotes?.map((footnote) => (
                      <li key={`reportSection-reference-${footnote.id}`}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footnote.url}
                          style={{ color: theme.palette.text.primary }}
                        >
                          {footnote.text}
                        </a>
                        ,
                      </li>
                    ))}
                  </ol>
                </aside>
              )}
            </section>
          ))}
      </main>
    </div>
  );
}

export default ComparePageReport;
