import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from '../../styles';
import { themeProps } from '../../styles/theme';

import { sensorProperties, PropSensorItemCreateIndexed } from '../../utils/sensorProperties';
import { Position, SubSensorType } from '../../services/api';

interface CreateSensorFormProps {
  sensorId: string;
  sensorPayload: PropSensorItemCreateIndexed | undefined;
  position: Position;
  setPosition: (value: Position) => void;
  positionValid: boolean;
  formPositionChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  textFieldChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, param: string) => void;
}

function CreateSensorForm({
  sensorId,
  sensorPayload,
  position,
  setPosition,
  positionValid,
  formPositionChangeHandler,
  textFieldChange,
}: CreateSensorFormProps): JSX.Element {
  const classes = useStyles();
  const isDesktopView = useMediaQuery(useTheme().breakpoints.up('md'));
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    if (isDesktopView) setExpand(true);
    else setExpand(false);
  }, [isDesktopView]);

  const blacklist = ['name', 'metadata', 'id', 'data', 'latest', 'port', 'position', 'fwVersion'];
  return (
    <Grid item md={6} sm={12} xs={12}>
      <Typography variant="h6">About {sensorId} </Typography>
      <TextField
        type="text"
        label="Name (of sensor location/coverage)"
        value={sensorPayload?.name ?? ''}
        error={!positionValid}
        helperText={!positionValid ? 'Incorrect position entry' : undefined}
        onChange={(e) => textFieldChange(e, 'name')}
        style={{ width: '100%', padding: '10px' }}
        variant="standard"
      />
      <span
        style={{
          fontSize: themeProps.textSize.h6,
          color: themeProps.colors.warningRed,
          marginBottom: '5px',
        }}
      >
        Place pin on map where sensor is installed
      </span>
      <Accordion expanded={expand} className={classes.createSensorAccordion}>
        <AccordionSummary
          expandIcon={
            <IconButton size="large">
              <ExpandMoreIcon />
            </IconButton>
          }
          onClick={() => setExpand(!expand)}
        >
          <Typography variant="h6">Sensor Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {sensorPayload &&
            Object.entries(sensorProperties)
              .filter(([sensorProps]) => !blacklist.includes(sensorProps))
              .map((sensorProps) => (
                <TextField
                  key={sensorProps[0]}
                  type="text"
                  required={!sensorProps[1].autofilled}
                  disabled={sensorProps[1].autofilled}
                  label={sensorProps[1].label}
                  onChange={(e) => textFieldChange(e, sensorProps[0])}
                  style={{ width: '50%', padding: '10px' }}
                  value={sensorPayload[sensorProps[0]] ?? ''}
                  variant="standard"
                />
              ))}
          <TextField
            type="text"
            label="Position"
            value={`${position.lat ? `${position.lat},${position.lng}` : ''} `}
            error={!positionValid}
            helperText={!positionValid ? 'Incorrect position entry' : undefined}
            onChange={formPositionChangeHandler}
            style={{ width: '100%', padding: '10px' }}
            variant="standard"
          />
          <TextField
            type="text"
            label="Polygon"
            value={position?.polygon ?? ''}
            onChange={(e) =>
              setPosition({
                ...position,
                polygon: e.target.value,
              })
            }
            style={{ width: '100%', padding: '10px' }}
            variant="standard"
          />
          {sensorPayload?.type === SubSensorType.OccSignatures && (
            <>
              <TextField
                type="number"
                label="Azimuth"
                value={position.azimuth ?? ''}
                onChange={(e) =>
                  setPosition({
                    ...position,
                    azimuth: parseInt(e.target.value, 10),
                  })
                }
                style={{ width: '100%', padding: '10px' }}
                variant="standard"
              />
              <TextField
                type="number"
                label="Position Height"
                value={position.height ?? ''}
                onChange={(e) =>
                  setPosition({
                    ...position,
                    height: parseFloat(e.target.value),
                  })
                }
                style={{ width: '100%', padding: '10px' }}
                variant="standard"
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default CreateSensorForm;
