import { combineEpics } from 'redux-observable';
import {
  restoreCurrentLocationEpic,
  restoreSelectedVarsEpic,
  currentLocationChildrenEpic,
  currentLocationFloorplanEpic,
  fetchLocationFloorplanEpic,
  restoreSelectedPlotVarsEpic,
} from './location';
import {
  fetchLatestVarNameDataEpic,
  fetchSensorLatestDetailsEpic,
  selectedSensorsEpic,
  updateFilterDataEpic,
  updateActivePlotVarsEpic,
} from './sensors';

export default combineEpics(
  currentLocationChildrenEpic,
  currentLocationFloorplanEpic,
  fetchLocationFloorplanEpic,
  restoreSelectedVarsEpic,
  restoreSelectedPlotVarsEpic,
  restoreCurrentLocationEpic,
  updateFilterDataEpic,
  fetchLatestVarNameDataEpic,
  fetchSensorLatestDetailsEpic,

  selectedSensorsEpic,
  updateActivePlotVarsEpic
);
