import { Store } from 'redux';
import dayjs from 'dayjs';
import { VarName } from '../utils/varNames';
import { DailyMetricItem, HistoricData } from './api';
import { fetchSensorHistory, getLocationDailyData, getSensorDailyData } from './apiService';
import { THREE_DAYS } from '../utils/functions';
import { ActionTypes } from '../state/actionTypes';
import { RootState } from '../state/types';
import { goFetchRefresh } from '../state/actions';
import { transformHistoricMotionOccupancy } from '../utils/motionEvents';

export type SensorDetails = {
  id: string;
  name?: string;
};

export type SensorReading = {
  value: number;
  time: number;
};

type ContiguousRange = {
  start: number;
  end: number;
};

export type SensorHistory = {
  values: SensorReading[];
  timeEntries: Map<number, SensorReading>;
  lastUpdated: number;
  contiguous: ContiguousRange[];
};

export type SensorData = {
  details: SensorDetails;
  latestData?: SensorReading;
  history?: Map<VarName, SensorHistory>;
};

export type DailyMetricHistory = {
  date: string;
  metric: DailyMetricItem;
};

export type MetricData = {
  metricHistory: Map<string, DailyMetricHistory[]>; // string defined as sensorId
};

const filterTimes = (values: SensorReading[], startTime: number, endTime: number) =>
  values.filter(({ time }) => time >= startTime && time <= endTime) ?? [];

class DataStore {
  private static UPDATE_INTERVAL = 600000; // 10 minutes in milliseconds

  private static instance: DataStore;

  private data = new Map<string, SensorData>();

  // store daily data each sensor in VarName record
  private metricData = new Map<VarName, MetricData>();

  private nameIndex = new Map<string, string>(); // name to id ref

  private refreshTimer: NodeJS.Timeout;

  private reduxStore: Store<RootState, ActionTypes> | undefined = undefined;

  get store(): Store<RootState, ActionTypes> | undefined {
    return this.reduxStore;
  }

  set store(s: Store<RootState, ActionTypes> | undefined) {
    if (!this.reduxStore) {
      // Don't change stores
      this.reduxStore = s;
    } else {
      throw new Error('Redux store already set in DataStore');
    }
  }

  private constructor() {
    // Start timer
    this.refreshTimer = setTimeout(
      () => this.refreshAllData(), // beware of binding this, hence the arrow function
      DataStore.UPDATE_INTERVAL
    );
  }

  static getInstance(): DataStore {
    if (!DataStore.instance) {
      DataStore.instance = new DataStore();
    }

    return DataStore.instance;
  }

  public getSensorData(id: string): SensorData | undefined {
    return this.data.get(id);
  }

  public getVarNameMetricData(varName: VarName): MetricData | undefined {
    return this.metricData.get(varName);
  }

  public getSensorDataByName(name: string): SensorData | undefined {
    return this.data.get(this.nameIndex.get(name) ?? '');
  }

  public getDetails(id: string): SensorDetails | undefined {
    return this.getSensorData(id)?.details;
  }

  public getDetailsByname(name: string): SensorDetails | undefined {
    return this.getSensorDataByName(name)?.details;
  }

  public getLatestData(id: string): SensorReading | undefined {
    return this.getSensorData(id)?.latestData;
  }

  public getLatestDataByname(name: string): SensorReading | undefined {
    return this.getSensorDataByName(name)?.latestData;
  }

  private static addMetricDataToStore(
    sensorHistory: DailyMetricHistory[] | undefined,
    dataItems: DailyMetricItem[]
  ): void {
    if (!sensorHistory || !dataItems) {
      return;
    }

    if (sensorHistory.length === 0) {
      dataItems.map((item) => item.date && sensorHistory.push({ date: item.date, metric: item }));
    } else {
      dataItems.forEach((item) => {
        // check and update metric item
        const itemIndex = sensorHistory.findIndex((el) => el.date === item.date);
        if (itemIndex === -1 && item.date) sensorHistory.push({ date: item.date, metric: item });
        // eslint-disable-next-line no-param-reassign
        else sensorHistory[itemIndex].metric = item;
      });
    }
    sensorHistory.sort((a, b) => {
      const aT = a.date;
      const bT = b.date;

      if (aT < bT) return -1;
      if (aT > bT) return 1;
      return 0;
    });
  }

  private static addDataToStore(varHistory: SensorHistory | undefined, data: HistoricData): void {
    if (!varHistory || !data) {
      return;
    }

    // Is new data contiguous with data in store?
    const first = data.time[0];
    const last = data.time[data.time.length - 1];
    const inContiguous = [] as ContiguousRange[];
    varHistory.contiguous.forEach((range) => {
      if (first <= range.start && last >= range.start) {
        // New contiguous data before existing data
        inContiguous.push(range);
      } else if (first <= range.end && last >= range.end) {
        // New contiguous data after existing data
        inContiguous.push(range);
      } else if (first >= range.start && last <= range.end) {
        // Data wholly within existing data (ideally shouldn't happen)
        inContiguous.push(range);
      }
    });

    if (inContiguous.length === 0) {
      // No overlap with existing data
      varHistory.contiguous.push({ start: first, end: last });
    } else {
      // Extend contiguous data range (and merge ranges if necessary)

      // Remove old contiguous ranges we are replacing
      inContiguous.forEach((range) => {
        const idx = varHistory.contiguous.indexOf(range);
        if (idx !== -1) {
          varHistory.contiguous.splice(idx, 1);
        }
      });
      // Find the new biggest contiguous range in our stored data
      const starts = inContiguous.map((range) => range.start);
      const ends = inContiguous.map((range) => range.end);
      const contigStart = Math.min(...starts, first);
      const contigEnd = Math.max(...ends, last);
      // Add new bigger contiguous range
      varHistory.contiguous.push({ start: contigStart, end: contigEnd });
    }

    for (let i = 0; i < data.time.length; i++) {
      const t = data.time[i];
      const v = data.value[i];
      const r = { time: t, value: v };

      if (!varHistory.timeEntries.get(t)) {
        varHistory.values.push(r);
        varHistory.timeEntries.set(t, r);
      }
    }

    varHistory.values.sort((a, b) => {
      const aT = a.time;
      const bT = b.time;

      if (aT < bT) return -1;
      if (aT > bT) return 1;
      return 0;
    });
  }

  public getSensorDailyMetricHistory(
    id: string, // sensorId
    varName: VarName,
    start: number,
    end: number
  ): Promise<DailyMetricItem[]> {
    return new Promise((resolve, reject) => {
      let s = this.getVarNameMetricData(varName);
      if (!s) {
        s = { metricHistory: new Map() };
        this.metricData.set(varName, s);
      }

      if (!s.metricHistory) {
        s.metricHistory = new Map();
      }

      const { metricHistory } = s;

      let sensorHistory = metricHistory.get(id);
      const fetchDailyData = () => {
        // Used to see which dates are missing
        const allDates: string[] = [];
        let current = dayjs(start);
        while (current.isBefore(dayjs(end)) || current.isSame(dayjs(end))) {
          allDates.push(current.format('YYYY-MM-DD'));
          current = current.add(1, 'day');
        }

        getSensorDailyData(
          varName,
          id,
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).format('YYYY-MM-DD')
        )
          .then((data) => {
            // Check if there are missing items (meaning no data) and add blank item (so we don't
            // fetch again)
            let addData = data;
            const gotDates = data.map((item) => item.date);
            const missingDates = allDates.filter((d) => !gotDates.includes(d));
            if (missingDates.length > 0) {
              const emptyData = missingDates.map(
                (date) => ({ date, id, hours: [], varName } as DailyMetricItem)
              );
              addData = [...addData, ...emptyData];
            }
            DataStore.addMetricDataToStore(sensorHistory, addData);

            resolve(data);
          })
          .catch((err) => reject(err));
      };

      // check if history exists
      if (!sensorHistory) {
        sensorHistory = [{ date: dayjs().format('YYYY-MM-DD'), metric: { id } }];
        metricHistory.set(id, sensorHistory);
        fetchDailyData();
      } else {
        // if history exists check if range data is found
        const totalDays = dayjs(end).diff(start, 'day') + 1;
        const rangeData = sensorHistory?.filter(
          (data) =>
            data.date >= dayjs(start).format('YYYY-MM-DD') &&
            data.date <= dayjs(end).format('YYYY-MM-DD')
        );
        if (rangeData.length === totalDays) resolve(rangeData.map((item) => item.metric));
        else fetchDailyData();
      }
    });
  }

  // returns history for multiple sensors, currently not being used, might be handy for future cases
  // discuss and remove if not necessary
  public async getSensorsCombinedHistory(
    sensorIds: string[],
    varName: VarName,
    start: number,
    end: number
  ): Promise<DailyMetricItem[]> {
    return new Promise((resolve, reject) => {
      const newItems: Promise<DailyMetricItem[]>[] = [];
      sensorIds.forEach((sensorId) => {
        const promisedItem = this.getSensorDailyMetricHistory(sensorId, varName, start, end)
          .then((history) => history)
          .catch(() => []);
        newItems.push(promisedItem as Promise<DailyMetricItem[]>);
      });

      // Wait for the data and update
      Promise.all(newItems)
        .then((values) => {
          resolve(values.flat(1));
        })
        .catch((err) => reject(err));
    });
  }

  public getLocDailyMetricHistory(
    locId: string, // locId
    varName: VarName,
    start: number,
    end: number
  ): Promise<DailyMetricItem[]> {
    // Make sure the store is properly initialised for this varName
    let s = this.getVarNameMetricData(varName);
    if (!s) {
      s = { metricHistory: new Map() };
      this.metricData.set(varName, s);
    }
    if (!s.metricHistory) {
      s.metricHistory = new Map();
    }
    const { metricHistory } = s;

    // Get list of sensors with varName in location
    const allSensorsInLoc =
      this.reduxStore?.getState().sensors.sensorsByLocationId.get(locId) || [];
    const sensorsWithVar = this.reduxStore?.getState().sensors.sensorsByVarName.get(varName) || [];
    const sensorIds = allSensorsInLoc.filter((sensor) => sensorsWithVar.includes(sensor));

    // Work out if we have data for every sensor
    const storeData: DailyMetricHistory[] = []; // Data to output if we have it all
    const fetchData = sensorIds.some((id) => {
      const sensorHistory = metricHistory.get(id);
      // If we don't have store data for a sensor we need to fetch
      if (sensorHistory === undefined) return true;

      // We have store data check if we have all of it?
      const totalDays = dayjs(end).diff(start, 'day') + 1;
      const rangeData = sensorHistory.filter(
        (data) =>
          data.date >= dayjs(start).format('YYYY-MM-DD') &&
          data.date <= dayjs(end).format('YYYY-MM-DD')
      );
      // if we don't have all data we need to fetch
      if (rangeData.length !== totalDays) return true;
      // We have the data, add it to possible output and check next sensors
      storeData.push(...rangeData);
      return false;
    });

    // We've already got all data, return it
    if (!fetchData) {
      return new Promise((resolve) => {
        resolve(storeData.map((item) => item.metric));
      });
    }

    // Used to see which dates are missing
    const allDates: string[] = [];
    let current = dayjs(start);
    while (current.isBefore(dayjs(end)) || current.isSame(dayjs(end))) {
      allDates.push(current.format('YYYY-MM-DD'));
      current = current.add(1, 'day');
    }
    // we need to fetch data from the API
    return new Promise((resolve, reject) => {
      getLocationDailyData(
        varName,
        locId,
        dayjs(start).format('YYYY-MM-DD'),
        dayjs(end).format('YYYY-MM-DD')
      )
        .then((data) => {
          // create an array of sensorIds from received dataItem
          const locSensors = data.map((item) => item.id);
          // remove duplicate sensor Id's
          const filteredLocSensors = locSensors.filter(
            (item, index) => locSensors.indexOf(item) === index
          );
          filteredLocSensors.forEach((id) => {
            // get exisiting history
            let sensorStoreHistory = metricHistory.get(id);
            // if the store is uninitialised for this sensor then add an empty item
            if (!sensorStoreHistory) {
              sensorStoreHistory = [
                { date: dayjs().format('YYYY-MM-DD'), metric: { id } },
              ] as DailyMetricHistory[];
              metricHistory.set(id, sensorStoreHistory);
            }
            // filter all history received from API
            let sensorDataItem = data.filter((item) => item.id === id);
            // Check if there are missing items (meaning no data) and add blank item (so we don't
            // fetch again)
            const gotDates = sensorDataItem.map((item) => item.date);
            const missingDates = allDates.filter((d) => !gotDates.includes(d));
            if (missingDates.length > 0) {
              const emptyData = missingDates.map((date) => ({ date, id } as DailyMetricItem));
              sensorDataItem = [...sensorDataItem, ...emptyData];
            }

            DataStore.addMetricDataToStore(sensorStoreHistory, sensorDataItem);
          });
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  private pendingRequests: Map<string, Promise<SensorReading[]>> = new Map();

  private static generateRequestKey(
    id: string,
    varName: VarName,
    start?: number,
    end?: number
  ): string {
    return `${id}-${varName}-${start}-${end}`;
  }

  public getHistory(
    id: string,
    varName: VarName,
    start?: number,
    end?: number,
    force?: boolean // Request new data even if just done a request
  ): Promise<SensorReading[]> {
    // Try to avoid fetching the same data again while request in progress
    const cacheKey = DataStore.generateRequestKey(id, varName, start, end);
    if (!force) {
      // Check if there is an ongoing request for the same data
      const cacheRequest = this.pendingRequests.get(cacheKey);
      if (cacheRequest) return cacheRequest;
    }

    const promise: Promise<SensorReading[]> = new Promise((resolve, reject) => {
      let s = this.getSensorData(id);
      if (!s) {
        s = { details: { id } };
        this.data.set(id, { details: { id } });
      }

      if (!s.history) {
        s.history = new Map();
      }
      const { history } = s;

      let varHistory = history.get(varName);

      const now = Date.now();
      const sT = Math.round((start ?? now - THREE_DAYS) / 1000);
      const eT = Math.round(Math.min(end ?? Infinity, now) / 1000);

      if (!varHistory) {
        // Have no data at all
        varHistory = {
          values: [],
          timeEntries: new Map(),
          lastUpdated: now,
          contiguous: [],
        };
        history.set(varName, varHistory);

        fetchSensorHistory(varName, id ?? '', start ?? now - THREE_DAYS, end ?? now)
          .then((data) => {
            DataStore.addDataToStore(varHistory, data);
            // Remove the request from the pending requests map
            this.pendingRequests.delete(cacheKey);
            resolve(this.transformData(varName, filterTimes(varHistory?.values ?? [], sT, eT), eT));
          })
          .catch((err) => {
            // Remove the request from the pending requests map
            this.pendingRequests.delete(cacheKey);
            reject(err);
          });
      } else if (!force && varHistory.lastUpdated >= now - 500) {
        // To reduce API calls from multiple repeated requests (e.g from mouse hover)
        // Remove the request from the pending requests map
        this.pendingRequests.delete(cacheKey);
        resolve(this.transformData(varName, filterTimes(varHistory?.values ?? [], sT, eT), eT));
      } else {
        varHistory.lastUpdated = now;
        // Have some data, need to see what more we need to get
        const tolerance = DataStore.UPDATE_INTERVAL / 1000; // In seconds

        // Have we got any data contiguous with request?
        const range: ContiguousRange | null =
          varHistory.contiguous.filter(
            (r) =>
              (sT + tolerance >= r.start && sT <= r.end) ||
              (eT >= r.start && (eT <= r.end + tolerance || now / 1000 <= r.end + tolerance))
          )[0] ?? null; // For now we just use the first range rather than the 'best' range

        let getStart = start ?? now - THREE_DAYS + tolerance * 1000;
        let getEnd = end ?? now;

        if (range) {
          // Got end of data?
          if (eT <= range.end + tolerance) {
            getEnd = range.start * 1000;
          }
          // Got start of data?
          if (sT + tolerance >= range.start) {
            getStart = range.end * 1000;
          }
          // Got both? getStart >= getEnd  === true
        }

        if (getStart >= getEnd) {
          // Got all data already
          // Remove the request from the pending requests map
          this.pendingRequests.delete(cacheKey);
          resolve(this.transformData(varName, filterTimes(varHistory?.values ?? [], sT, eT), eT));
        } else {
          // Fetch the missing data
          fetchSensorHistory(varName, id ?? '', getStart, getEnd)
            .then((data) => {
              DataStore.addDataToStore(varHistory, data);
              // Remove the request from the pending requests map
              this.pendingRequests.delete(cacheKey);
              resolve(
                this.transformData(varName, filterTimes(varHistory?.values ?? [], sT, eT), eT)
              );
            })
            .catch((err) => {
              // Remove the request from the pending requests map
              this.pendingRequests.delete(cacheKey);
              reject(err);
            });
        }
      }
    });
    // Store the request promise in the pending requests map
    this.pendingRequests.set(cacheKey, promise);
    return promise;
  }

  public getHistoryByName(
    name: string,
    varName: VarName,
    start?: number,
    end?: number
  ): Promise<SensorReading[]> {
    return this.getHistory(this.nameIndex.get(name) ?? '', varName, start, end);
  }

  public setSensorName(id: string, name: string): void {
    const s = this.data.get(id);
    if (s) {
      s.details.name = name;
      this.nameIndex.set(name, id);
    } else {
      this.data.set(id, { details: { id, name } });
    }
  }

  public refreshAllData(): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }

    // Refresh the data
    this.reduxStore?.dispatch(goFetchRefresh());
    this.refreshTimer = setTimeout(
      () => this.refreshAllData(), // beware of binding this, hence the arrow function
      DataStore.UPDATE_INTERVAL
    );
  }

  public transformMotionOccupancy(values: SensorReading[], endTime: number): SensorReading[] {
    const motionRange = this.reduxStore?.getState().uiSettings.motionThreshold ?? [2, 5];
    return transformHistoricMotionOccupancy(values, motionRange, endTime);
  }

  public transformData(
    varName: VarName,
    values: SensorReading[],
    endTime?: number
  ): SensorReading[] {
    switch (varName) {
      case VarName.MotionEvent:
        return this.transformMotionOccupancy(values, endTime ?? Date.now() / 1000);
      default:
        return values;
    }
  }

  public getTempUserAccessToken(): string | null {
    return this.reduxStore?.getState().userAuth.tempUser?.accessToken ?? null;
  }
}

export default DataStore;
