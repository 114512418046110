import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Alert from '@mui/material/Alert';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/location';
import { PropLocationItem } from '../../services/api';
import { AlertMsg } from './LocationProperties';
import CreateLocation from './CreateLocation';
import { setCurrentLocation } from '../../state/actions';
import Loading from '../../components/Loading';

interface SubLocationsListProps {
  locationDetails: PropLocationItem;
  sortedChildLocations: PropLocationItem[] | undefined;
  updateLocationChildren: () => void;
  loadingLocations: boolean;
}

function SubLocationsList({
  locationDetails,
  sortedChildLocations,
  updateLocationChildren,
  loadingLocations,
}: SubLocationsListProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [showCreateLocationForm, setShowCreateLocationForm] = useState<boolean>(false);

  useEffect(() => setAlertMsg(undefined), [locationDetails]);

  return (
    <div>
      <List>
        <ListItem>
          <Grid item sm={8} xs={8} style={{ display: 'flex' }}>
            <LocationOnIcon className={classes.icon} style={{ alignSelf: 'center' }} />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>
              Sub Locations
            </Typography>
          </Grid>

          <Tooltip title="Add Location">
            <Grid item sm={4} xs={4} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={() => setShowCreateLocationForm(!showCreateLocationForm)}
                size="large"
              >
                {showCreateLocationForm ? (
                  <CancelIcon className={globalClasses.closeBtnIcon} />
                ) : (
                  <AddLocationIcon className={globalClasses.icon} />
                )}
              </IconButton>
            </Grid>
          </Tooltip>
        </ListItem>
      </List>
      <div style={{ padding: '0 1rem' }}>
        {alertMsg?.msg && (
          <Alert
            severity={alertMsg.alertType ?? 'info'}
            onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
            className={globalClasses.alertMsg}
          >
            {alertMsg?.msg}
          </Alert>
        )}
        {loadingLocations ? (
          <Loading />
        ) : (
          !showCreateLocationForm && (
            <List>
              {sortedChildLocations &&
                Object.keys(sortedChildLocations).length > 0 &&
                Object.entries(sortedChildLocations).map(([, locationDetail]) => (
                  <Button
                    key={locationDetail.id}
                    onClick={() => dispatch(setCurrentLocation(locationDetail.id))}
                    className={classes.childLocationListItem}
                    startIcon={<LocationOnIcon />}
                  >
                    {locationDetail.name}
                  </Button>
                ))}
            </List>
          )
        )}

        {showCreateLocationForm && (
          <CreateLocation
            locationDetails={locationDetails}
            setAlertMsg={setAlertMsg}
            updateLocationChildren={updateLocationChildren}
            setShowCreateLocationForm={setShowCreateLocationForm}
          />
        )}
      </div>
    </div>
  );
}

export default SubLocationsList;
