import SvgIcon from '@mui/material/SvgIcon';
import LanguageIcon from '@mui/icons-material/Language';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface LocationProperty {
  label: string;
  editable: boolean;
  autofilled: boolean;
}

export interface AlertMsg {
  success: boolean;
  msg: string | undefined;
  alertType: 'success' | 'error' | 'info' | 'warning' | undefined;
}

export interface KeyAny {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface LocationType {
  label: string;
  code: string;
  icon: typeof SvgIcon;
}

export interface UserStatus {
  label: string;
  icon: typeof SvgIcon;
  color: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | undefined;
}

export interface RemoveUserProps {
  userId: string;
  locationId: string;
  dialogVisibility: boolean;
}

export const locationProperties: Record<string, LocationProperty> = {
  id: {
    label: 'Id',
    editable: false,
    autofilled: false,
  },
  name: {
    label: 'Name',
    editable: true,
    autofilled: false,
  },
  shortName: {
    label: 'Short Name',
    editable: true,
    autofilled: false,
  },
  type: {
    label: 'Type',
    editable: true,
    autofilled: true,
  },
  location: {
    label: 'Location',
    editable: false,
    autofilled: true,
  },
  position: {
    label: 'Position',
    editable: true,
    autofilled: false,
  },
  timeZone: {
    label: 'Time Zone',
    editable: true,
    autofilled: true,
  },
  latest: {
    label: 'Properties Version',
    editable: false,
    autofilled: false,
  },
  metadata: {
    label: 'Metadata',
    editable: true,
    autofilled: false,
  },
};

export const locationTypeDetail: Record<string, LocationType> = {
  client: {
    label: 'Client',
    code: 'CL',
    icon: LanguageIcon,
  },
  zone: {
    label: 'Zone',
    code: 'ZN',
    icon: HomeWorkIcon,
  },
  building: {
    label: 'Building',
    code: 'BLD',
    icon: ApartmentIcon,
  },
  floorLevel: {
    label: 'Floor Level',
    code: 'FL',
    icon: VerticalAlignCenterIcon,
  },
  area: {
    label: 'Area',
    code: 'AR',
    icon: FormatShapesIcon,
  },
};

export enum AccessLevelType {
  ReadOnly = 'readOnly',
  Demo = 'demo',
  Installer = 'installer',
  Admin = 'admin',
  Owner = 'owner',
}

export const userStatus: Record<string, UserStatus> = {
  active: {
    label: 'Active',
    icon: FiberManualRecordIcon,
    color: 'primary',
  },
  pending: {
    label: 'Pending',
    icon: HourglassFullIcon,
    color: 'disabled',
  },
  access_downgraded: {
    label: 'Access Downgraded',
    icon: ArrowDropDownIcon,
    color: 'error',
  },
  temporary_access: {
    label: 'Temporary Access',
    icon: AccessTimeIcon,
    color: 'inherit',
  },
};

export enum LocationAction {
  locationInfo = 'locationInfo',
  createNewLocation = 'createNewLocation',
  removeLocation = 'removeLocation',
}

export enum LocationContent {
  info = 'info',
  users = 'users',
  subLocations = 'subLocations',
  subSensors = 'subSensors',
}
