import circle from '@turf/circle';
import { PolygonLayer, PolygonLayerProps } from '@deck.gl/layers';
import { Units } from '@turf/helpers';
// eslint-disable-next-line import/no-unresolved
import { Position } from '@deck.gl/core/utils/positions';
import { Position as ApiPosition, VarName } from '../../../services/api';
import { PolygonMarker } from '../mapHelpers';

const getSummaryCoverageLayer = (
  targetPosition: ApiPosition
): PolygonLayer<unknown, PolygonLayerProps<unknown>> => {
  const center = [targetPosition.lng ?? 0, targetPosition.lat ?? 0];
  const radius = 20;
  const options = { steps: 50, units: 'meters' as Units };
  const circleData = circle(center, radius, options);

  // as we only need circular polygon from position, set default value to other params
  const polygonData: PolygonMarker[] = [
    {
      id: '1',
      contours: circleData.geometry.coordinates[0] as Position[],
      name: 'firstPolygon',
      value: 0,
      time: 0,
      varName: VarName.Unknown,
    },
  ];

  return new PolygonLayer({
    id: 'poly-layers',
    data: polygonData,
    stroked: true,
    filled: true,
    extruded: false,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: () => circleData.geometry.coordinates[0] as Position[],
    getLineColor: [56, 180, 160, 38],
    getFillColor: [56, 180, 160, 38],
    getLineWidth: 0,
  });
};

export default getSummaryCoverageLayer;
