import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataTreeItem } from '../../services/api';
import { getSearchTerm, getSortBy, getSensorsById, getLocationsById } from '../../state/selectors';

export default function useDataFiltering(data: DataTreeItem[]): DataTreeItem[] {
  const currentSearchTerm = useSelector(getSearchTerm);
  const [d, setD] = useState<DataTreeItem[]>([]);
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const sortBy = useSelector(getSortBy);

  const searchPropF = useMemo(
    () =>
      (function searchInitialiser(): (param: string | number) => string | number {
        const { property } = sortBy;
        switch (property) {
          case 'name':
            return (param: string | number) =>
              (sensorsById.get(param as string)?.name ?? (param as string)).toLowerCase();
          case 'location':
            return (param: string | number) =>
              locationsById.get(param as string)?.name ?? ''.toLowerCase();
          case 'time':
          case 'value':
          default:
            return (param: string | number) => param;
        }
      })(),
    [sortBy, sensorsById, locationsById]
  );

  useEffect(() => {
    const { property, ascending } = sortBy;
    const propToSearch = property === 'name' ? 'id' : property;
    const multiplier = ascending ? 1 : -1;

    setD(
      data
        .filter(({ id, location }: DataTreeItem) => {
          const { term } = currentSearchTerm;
          if (term === '') {
            return true;
          }

          const name = sensorsById.get(id)?.name ?? id.toLowerCase();
          if (name.indexOf(term) !== -1) {
            return true;
          }
          const locationName = locationsById.get(location ?? '')?.name ?? ''.toLowerCase();
          if (locationName.indexOf(term) !== -1) {
            return true;
          }

          return false;
        })
        .sort((a: DataTreeItem, b: DataTreeItem) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pa = searchPropF(a[propToSearch]);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pb = searchPropF(b[propToSearch]);

          if (pa < pb) return -multiplier;
          if (pa > pb) return multiplier;
          // pa === pb
          if (propToSearch === 'location') {
            // Sort by sensor name within each location
            const pa2 = (sensorsById.get(a.id)?.name ?? (a.id)).toLowerCase();
            const pb2 = (sensorsById.get(b.id)?.name ?? (b.id)).toLowerCase();
            if (pa2 < pb2) return -multiplier;
            if (pa2 > pb2) return multiplier;
          }
          return 0;
        })
    );
  }, [data, currentSearchTerm, searchPropF, sortBy, sensorsById, locationsById]);

  return d;
}
