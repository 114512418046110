import React, { ChangeEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PropLocationItem } from '../../services/api';
import { locationProperties } from './LocationProperties';
import { themeProps } from '../../styles/theme';
import useGlobalStyles from '../../styles';
import { getShortTimeStr } from '../../utils/functions';

interface LocationDetailsItemProps {
  locationDetails: PropLocationItem;
  displayItems: string[];
  isEditing: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => void;
}

function LocationDetailsItem({
  locationDetails,
  displayItems,
  isEditing,
  onChange,
}: LocationDetailsItemProps): JSX.Element {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();

  const metadata = locationDetails?.metadata as { [unit: string]: number };
  return (
    <>
      {Object.entries(locationDetails)
        .filter(([locationProps]) => displayItems.includes(locationProps))
        .map((locationDetail) => {
          const data = locationProperties[locationDetail[0]];
          return (
            <ListItem key={locationDetail[0]}>
              <Grid item sm={6} xs={6}>
                <ListItemText primary={data?.label} />
              </Grid>
              <Grid item sm={6} xs={6}>
                {data?.editable && isEditing ? (
                  <>
                    <TextField
                      type="text"
                      defaultValue={locationDetail[1] ?? ''}
                      onChange={(e) => onChange(e, locationDetail[0])}
                      style={{ width: '100%' }}
                      variant="standard"
                    />
                    {data.label === 'Time Zone' && (
                      <span style={{ fontSize: '0.75rem', color: themeProps.colors.lightGrey }}>
                        Timezone must comply to one from the{' '}
                        <a
                          href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: theme.palette.primary.main }}
                        >
                          TZ Timezone List
                        </a>
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <ListItemText primary={locationDetail[1] === '' ? '-' : locationDetail[1]} />
                    {!isEditing && metadata && (
                      <p className={globalClasses.updatedTime}>
                        {metadata[locationDetail[0]]
                          ? getShortTimeStr(metadata[locationDetail[0]])
                          : ''}
                      </p>
                    )}
                  </>
                )}
              </Grid>
            </ListItem>
          );
        })}
    </>
  );
}

export default LocationDetailsItem;
