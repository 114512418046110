import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { varNameDetails } from '../../utils/varNames';
import useStyles from '../../styles';
import BaseArrayRow from './BaseArrayRow';
import BaseArraySummary from './BaseArraySummary';
import { DashSummary } from './base';
import { getDashboardPanels, getSensorsById } from '../../state/selectors';
import { dataColours } from '../../utils/dataBandParams';
import useDataFiltering from './useDataFiltering';
import LocationIdentifier from './LocationIdentifier';
import { getShortTimeStr } from '../../utils/functions';

function ValueSensor({ data, varName, firstItem, children }: DashSummary): JSX.Element {
  const classes = useStyles();
  const sensorsById = useSelector(getSensorsById);
  const openPanels = useSelector(getDashboardPanels);
  const expanded = openPanels.indexOf(varName) !== -1;
  const dataToList = useDataFiltering(data);

  const total = useMemo(() => data.reduce((acc, val) => acc + (val.value ?? 0), 0), [data]);

  return (
    <div>
      <BaseArraySummary
        details={varNameDetails[varName]}
        expanding
        dataAvailable={data.length > 0}
        firstItem={firstItem}
      >
        <span>Total:</span>
        <span
          className={classes.pillLabel}
          style={{
            background: dataColours.blue,
            borderColor: dataColours.blue,
          }}
        >
          {Math.round(total * 100) / 100} {varNameDetails[varName].metric ?? ''}
        </span>
      </BaseArraySummary>
      {expanded && children && (
        <div className={classes.sensorArraySensorSummaryOptionalRow}>{children}</div>
      )}
      {expanded && data.length > 0 && (
        <ul className={classes.sensorArraySensorList}>
          {dataToList.map(({ id, time, value, location, position }) => (
            <BaseArrayRow
              id={id}
              key={`sensorarray-${varName}-${id}`}
              position={position}
              varName={varName}
            >
              <span
                className={classes.pillLabel}
                style={{
                  background: dataColours.blue,
                  borderColor: dataColours.blue,
                }}
              >
                {Math.round((value ?? 0) * 100) / 100} {varNameDetails[varName].metric ?? ''}
              </span>
              <span>{time && getShortTimeStr(time)}</span>
              <span>{sensorsById.get(id)?.name ?? id}</span>
              {location && <LocationIdentifier location={location} />}
            </BaseArrayRow>
          ))}
        </ul>
      )}
    </div>
  );
}

ValueSensor.defaultProps = {
  children: null,
};

export default ValueSensor;
