import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { setBleLocSwitchStatus, setCurrentLocation } from '../state/actions';
import { persistState, StorageTypes } from '../utils/persistentState';
import useStyles from '../styles/index';
import { themeProps } from '../styles/theme';
import { PropLocationItem } from '../services/api';

export interface LocationBreadcrumbItem {
  location: PropLocationItem | null;
  siblings: PropLocationItem[];
  shortName?: boolean;
}

function LocationBreadcrumb({
  location,
  siblings,
  shortName,
}: LocationBreadcrumbItem): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuItems, setMenuItems] = useState<Array<PropLocationItem>>([]);

  useEffect(() => {
    if (siblings) {
      setMenuItems(
        Array.from(siblings.values()).sort((a: PropLocationItem, b: PropLocationItem) => {
          const nameA = a.shortName ?? '';
          const nameB = b.shortName ?? '';

          if (nameA > nameB) {
            return 1;
          }

          if (nameA < nameB) {
            return -1;
          }

          return 0;
        })
      );
    } else {
      setMenuItems([]);
    }
  }, [siblings]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(setBleLocSwitchStatus(false));
    setAnchorEl(event.currentTarget);
    dispatch(setBleLocSwitchStatus(false));
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const locationClicked = (locationId: string) => {
    persistState(locationId, StorageTypes.CurrentLocation);
    dispatch(setCurrentLocation(locationId));
    closeMenu();
  };

  if (menuItems.length === 0) {
    return <div />;
  }
  if (location === null) {
    return (
      <>
        <IconButton
          aria-controls="breadcrumb-menu-NEXTITEMS"
          aria-haspopup="true"
          onClick={openMenu}
          className={classes.breadCrumbMenuBtn}
          size="small"
          color="inherit"
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <Menu
          id="breadcrumb-menu-NEXTITEMS"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {menuItems.map((item: PropLocationItem) => (
            <MenuItem
              key={`breadcrumb-menu-item-${item.id}`}
              onClick={() => locationClicked(item.id)}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  if (menuItems.length === 1) {
    return (
      <Button
        className={classes.breadCrumbMenuBtn}
        onClick={() => locationClicked(location.id)}
        style={{
          fontWeight: themeProps.fontWeight.semiBold,
        }}
      >
        <span className={classes.menuLabel}>{shortName ? location.shortName : location.name}</span>
      </Button>
    );
  }
  return (
    <>
      <Button
        aria-controls={`breadcrumb-menu-${location?.id ?? 'NEXTITEMS'}`}
        aria-haspopup="true"
        onClick={openMenu}
        endIcon={<ExpandMoreIcon className={classes.menuExpandIcon} />}
        className={classes.breadCrumbMenuBtn}
        size="small"
        color="inherit"
      >
        <span className={classes.menuLabel}>{shortName ? location.shortName : location.name}</span>
      </Button>
      <Menu
        id={`breadcrumb-menu-${location?.id ?? 'NEXTITEMS'}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          key={`breadcrumb-menu-item-head-${location.id}`}
          onClick={() => locationClicked(location.id)}
        >
          {location.name}
        </MenuItem>
        <Divider sx={{ borderColor: 'primary.contrastText' }} />
        {menuItems.map((item: PropLocationItem) => (
          <MenuItem
            key={`breadcrumb-menu-item-${item.id}`}
            onClick={() => locationClicked(item.id)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

LocationBreadcrumb.defaultProps = {
  shortName: false,
};

export default LocationBreadcrumb;
