import {
  VarName,
  BandPct,
  ReportUtlSensorSummary,
  ReportSensorSummary,
} from '../../../services/api';
import { varNameBandParams, BandParamsType } from '../../../utils/dataBandParams';
import { BarChartProperties, reportColors } from '../constants';

const getSensorBarChartProp = (
  source: VarName,
  sensorReadings: (ReportSensorSummary | ReportUtlSensorSummary)[],
  xAxisVisibility?: boolean,
  showLegend?: boolean
) => {
  const clientsWiFiBand: BandParamsType[] = [
    {
      upperBound: 50,
      color: reportColors.blue,
      text: 'Utl',
      label: 'Utilisation',
    },
    {
      upperBound: Infinity,
      color: reportColors.green,
      text: 'Utl',
      label: 'Relative maximum occupancy',
    },
  ];

  const traces = [];
  const isUtlSource = source === VarName.ClientsWiFi;
  const sensorBands = isUtlSource ? clientsWiFiBand : varNameBandParams[source];

  const getTraceValue = (index: number) => {
    let value: number[] = [];

    const getBandSum = (bandPct: BandPct[]) =>
      bandPct.slice(0, index + 1).reduce((p: number, c: BandPct) => p + c.pct, 0);

    if (index === 0) {
      value = sensorReadings
        ?.map((x) =>
          isUtlSource
            ? (x as ReportUtlSensorSummary).utl * (x as ReportUtlSensorSummary).relativeMax * 100
            : (x as ReportSensorSummary).bands[index]?.pct ?? 0
        )
        .flat(2);
    } else {
      value = sensorReadings
        .map((x) =>
          isUtlSource
            ? (x as ReportUtlSensorSummary).relativeMax * 100
            : getBandSum((x as ReportSensorSummary).bands)
        )
        .flat(2);
    }
    return value;
  };

  if (sensorBands && sensorReadings) {
    for (let i = 0; i < sensorBands.length; i++) {
      traces.unshift({
        name: sensorBands[i].label,
        value: getTraceValue(i),
        color: sensorBands[i].color,
        orientation: 'h',
      });
    }
  }

  const data: BarChartProperties = {
    traces,
    values: sensorReadings?.map((x) => x.name).flat(2),
    source,
    range: [0, 100],
    xAxisVisibility: xAxisVisibility ?? true,
    showLegend,
  };
  return data;
};

export default getSensorBarChartProp;
