import React, { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import { removeSensorBacnetConfig } from '../../../services/apiService';
import { AlertMsg } from '../../LocationConfig/LocationProperties';
import { GatewaySensorType, SensorLatest } from '../../../services/api';
import { themeProps } from '../../../styles/theme';

interface RemoveBacnetDialogProps {
  showDialog: boolean;
  sensorDetails: SensorLatest;
  hideDialog: () => void;
  refreshConfig: () => void;
  setSuccessAlert: () => void;
}

function RemoveBacnetDialog({
  showDialog,
  sensorDetails,
  hideDialog,
  refreshConfig,
  setSuccessAlert,
}: RemoveBacnetDialogProps): JSX.Element {
  const [removeGateway, setRemoveGateway] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<AlertMsg | undefined>();

  const sensorId = sensorDetails.id;

  const sensorIsGateway = useMemo(() => {
    const isGateway = Object.values(GatewaySensorType).includes(
      sensorDetails?.type as GatewaySensorType
    );
    if (isGateway) return true;
    return false;
  }, [sensorDetails]);

  const handleRemove = () => {
    setErrMsg(undefined);
    removeSensorBacnetConfig(sensorId, true, removeGateway)
      .then(() => {
        hideDialog();
        setSuccessAlert();
        refreshConfig();
      })
      .catch((err) => {
        setErrMsg({
          success: true,
          msg: err.cause,
          alertType: 'error',
        });
      });
  };

  return (
    <Dialog
      open={showDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Remove Bacnet config for {sensorId}</DialogTitle>
      <DialogContent>
        {errMsg && (
          <Alert severity={errMsg.alertType} style={{ marginBottom: '20px' }}>
            {errMsg.msg}
          </Alert>
        )}
        <Typography variant="body1">Are you sure, you want to delete {sensorId}?</Typography>
        {sensorIsGateway && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={removeGateway}
                onChange={() => setRemoveGateway(!removeGateway)}
              />
            }
            label="Remove gateway for this sensor?"
            style={{ marginTop: '15px' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant={themeProps.btnVariant.default} onClick={handleRemove}>
          Remove
        </Button>
        <Button color="secondary" variant={themeProps.btnVariant.default} onClick={hideDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveBacnetDialog;
