/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { IconLayer, IconLayerProps } from '@deck.gl/layers';
import { DataItem, Position as ApiPosition } from '../../../services/api';
import { Marker, ICON_ATLAS, ICON_MAPPING, MAX_FLOOR_OBJECT_ELEVATION } from '../mapHelpers';
import { VarName } from '../../../utils/varNames';

export enum TargetPosIconType {
  person = 'person',
  sensor = 'sensor',
}

const getTargetPosLayer = (
  targetPosition: ApiPosition,
  iconType?: TargetPosIconType
): IconLayer<unknown, IconLayerProps<unknown>> => {
  const data: DataItem[] = [{ varName: VarName.OccSignatures }];
  const posMarker: Marker[] = [
    {
      id: `target-pos-${iconType}`,
      name: `target-pos-${iconType}`,
      location: '',
      coordinates: [targetPosition.lng ?? 0, targetPosition.lat ?? 0],
      availableData: data,
    },
  ];
  return new IconLayer({
    id: `target-pos-layer-${iconType}`,
    data: posMarker,
    pickable: true,
    iconAtlas: ICON_ATLAS,
    iconMapping: ICON_MAPPING,
    getIcon: () => iconType || 'person', // default to person
    sizeScale: 15,
    // @ts-ignore
    getPosition: () => [
      targetPosition.lng ?? 0,
      targetPosition.lat ?? 0,
      MAX_FLOOR_OBJECT_ELEVATION,
    ],
    getSize: () => 4,
    getColor: () => [56, 180, 160, 255],
  });
};

export default getTargetPosLayer;
