import { GatewaySensorType, SensorLatest, VisibleSubSensor } from '../../services/api';
import { fetchVisibleItems, fetchSensorLatest } from '../../services/apiService';
import { isDataExpired } from '../../utils/functions';

export interface GatewayDataType {
  gatewayId: string;
  RSSI: number;
}

export interface GatewayVisibleSubsensors {
  gatewayId: string;
  visibleSubsensors: VisibleSubSensor[];
}

export const getAllGatewayVisibleSubsensors = async (
  locationId: string,
  sensorsById: Map<string, SensorLatest>,
  sensorsByLocId: Map<string, string[]>
): Promise<GatewayVisibleSubsensors[]> => {
  const alllocGatewayIds: string[] = [];
  const allLocSensors = sensorsByLocId.get(locationId) ?? [];
  allLocSensors.forEach((sensorId) => {
    const isGateway = Object.values(GatewaySensorType).includes(
      sensorsById.get(sensorId)?.type as GatewaySensorType
    );
    if (isGateway) alllocGatewayIds.push(sensorId);
  });
  const visibleSubSensors: GatewayVisibleSubsensors[] = [];
  await Promise.all(
    alllocGatewayIds.map(async (gatewayId) => {
      // return undefined for error when fetching visible items
      const visibleItems = await fetchVisibleItems(gatewayId).catch(() => undefined);
      const subSensors = visibleItems?.subsensors;
      if (subSensors) {
        const item = { gatewayId, visibleSubsensors: subSensors };
        visibleSubSensors.push(item);
      }
    })
  );
  return visibleSubSensors;
};

export const getAllowedGateways = async (
  allGatewayVisibleSubSensors: GatewayVisibleSubsensors[],
  newSensorId: string
): Promise<GatewayDataType[]> => {
  const filterGatewayData = await Promise.all(
    allGatewayVisibleSubSensors.map((gateway) => {
      const subSensors = gateway.visibleSubsensors;
      const sensorData = subSensors?.find(
        (subsensor: VisibleSubSensor) =>
          subsensor.suggested_id === newSensorId && !isDataExpired(subsensor.last_seen)
      );
      const data: GatewayDataType[] = [];
      if (sensorData) {
        const item: GatewayDataType = { gatewayId: gateway.gatewayId, RSSI: sensorData.rssi };
        data.push(item);
      }
      return data;
    })
  );
  // combine and sort filtered gateway based on signal strength into one array
  return filterGatewayData
    ?.reduce((prev, current) => prev.concat(current))
    .sort((a, b) => b.RSSI - a.RSSI);
};

export const filterHighStrengthGateway = async (
  allGatewayVisibleSubSensors: GatewayVisibleSubsensors[],
  newSensorId: string
): Promise<SensorLatest | undefined> => {
  let highStrengthGatewayDetail: SensorLatest | undefined;
  if (allGatewayVisibleSubSensors.length > 0) {
    // filter gateways that has sensor in visible list
    const filteredGatewayData = await getAllowedGateways(allGatewayVisibleSubSensors, newSensorId);

    if (filteredGatewayData && filteredGatewayData.length > 0) {
      highStrengthGatewayDetail = await fetchSensorLatest(filteredGatewayData[0].gatewayId).catch(
        () => undefined
      );
    } else {
      highStrengthGatewayDetail = { id: '' };
    }
  } else {
    highStrengthGatewayDetail = { id: '' };
  }
  return highStrengthGatewayDetail;
};

export const filterNewSensorsInLocation = async (
  gatewayVisibleSubSensors: GatewayVisibleSubsensors[],
  allLocSensorIds: string[]
): Promise<VisibleSubSensor[] | undefined> => {
  const flattenAllSubSensors = gatewayVisibleSubSensors
    .reduce((prev, current) => prev.concat(current.visibleSubsensors), [] as VisibleSubSensor[])
    .filter((sensor) => !isDataExpired(sensor.last_seen));

  // sort sensors based on signal strength,
  flattenAllSubSensors?.sort((a, b) => {
    const va = a.rssi;
    const vb = b.rssi;
    if (va > vb) return -1;
    if (va < vb) return 1;
    return 0;
  });

  // some sensors might be visible to multiple gateways
  const filteredDuplicateAddress: VisibleSubSensor[] = Object.values(
    flattenAllSubSensors.reduce((acc, cur) => Object.assign(acc, { [cur.address]: cur }), {})
  );

  // check if sensorId exists in direct children sensors list from visible items list, if not they are new unconfigured sensors
  const unconfiguredSensors =
    filteredDuplicateAddress &&
    filteredDuplicateAddress.filter((sensor) => !allLocSensorIds.includes(sensor?.suggested_id));

  return unconfiguredSensors;
};
