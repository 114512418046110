import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { VarName, varNameDetails } from '../../utils/varNames';
import {
  getActiveMarker,
  getSelectedLocationVarValues,
  getSelectedVars,
} from '../../state/selectors';
import { DataTreeItem } from '../../services/api';
import useStyles from '../../styles/dashboard';
import DoughnutPlot from '../Plots/DoughnutPlot';
import { setActiveMarker, setSelectedBand, setSensorVars } from '../../state/actions';
import SourcesMenu from '../../components/SourcesMenu';
import { themeProps } from '../../styles/theme';
import SensorSunburst from '../Plots/SensorSunburst';
import MotionRingPlot from './MotionRingPlot';
import { StorageTypes, persistState } from '../../utils/persistentState';
import SensorIcon from '../../styles/icons/SensorIcon';

function SummaryCardContainer(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedVars = useSelector(getSelectedVars);
  const activeMarker = useSelector(getActiveMarker);
  const selectedVarValues = useSelector(getSelectedLocationVarValues);
  // update state and pass to ref for outside click events to change active marker card
  const [selectedVarName, setSelectedVarName] = useState<VarName>();

  const handleSource = (varName: VarName) => {
    dispatch(setActiveMarker(varName));
    navigate('/activeSource');
    dispatch(setSelectedBand(null));
  };

  const outSideClick = () => {
    if (selectedVarName && selectedVarName !== activeMarker) {
      dispatch(setActiveMarker(selectedVarName));
      // reset selected varname afterwards
      setSelectedVarName(undefined);
    }
  };

  const useOutsideClick = (callback: () => void) => {
    const ref = React.useRef();
    React.useEffect(() => {
      const handleClick = () => {
        callback();
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVarName]);
    return ref;
  };

  const ref = useOutsideClick(outSideClick);

  const varNamePlotList = useMemo(() => {
    let varList: VarName[] = [];
    // take first 4 source from selected vars list
    if (selectedVars.length >= 4) varList = selectedVars.slice(0, 4);
    else {
      // fill with default varname if selected vars is less than 4
      const vars: VarName[] = [];
      for (let i = 0; i < 4; i++) {
        vars.push(selectedVars[i] ?? VarName.Unknown);
      }
      if (vars.length === 4) varList = vars;
    }
    return varList;
  }, [selectedVars]);

  // check and update activemarker if source has been removed from the list
  useEffect(() => {
    const sourceExists = selectedVars.includes(activeMarker);
    if (!sourceExists && varNamePlotList[0] !== VarName.Unknown) {
      dispatch(setActiveMarker(varNamePlotList[0]));
    }
  }, [activeMarker, dispatch, selectedVars, varNamePlotList]);

  const onCardCloseHandler = (varName: VarName) => {
    const newVars = selectedVars.filter((item) => item !== varName);
    dispatch(setSensorVars(newVars));
    persistState(newVars, StorageTypes.SelectedVars);
  };

  return (
    <>
      {varNamePlotList.slice(0, 4).map((varName) => {
        const Icon = varNameDetails[varName].icon ?? SensorIcon;
        const data: DataTreeItem[] = selectedVarValues.get(varName) ?? [];

        function showRingPlots() {
          switch (varName) {
            case VarName.ClientsBle:
            case VarName.ClientsWiFi:
            case VarName.EnergyInkWh:
              return <SensorSunburst data={data} varName={varName} />;
            case VarName.MotionEvent:
              return <MotionRingPlot />;
            default:
              return <DoughnutPlot varName={varName} />;
          }
        }

        return (
          <Grid item xs={12} md={6} lg={3} key={`${Math.random()}-${varName}`}>
            <Card
              className={classes.dashboardCard}
              style={{
                background: varName === activeMarker ? themeProps.colors.activeSummaryCard : '',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedVarName(varName)}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                  {Icon && <Icon />}
                  <Typography variant="h5" style={{ marginLeft: '5px' }}>
                    {varName === VarName.Unknown ? 'Add Source' : varNameDetails[varName].label}
                  </Typography>
                </Box>
                {varName !== VarName.Unknown && (
                  <Box ref={ref}>
                    <CloseIcon
                      style={{ color: themeProps.colors.lightGrey, cursor: 'pointer' }}
                      onClick={() => onCardCloseHandler(varName)}
                    />
                  </Box>
                )}
              </Box>
              <Box className={classes.dashboardRingPlot} ref={ref}>
                <Box sx={{ alignSelf: 'center' }}>
                  {varName === VarName.Unknown ? (
                    <SourcesMenu
                      iconOnlyBtn
                      customIcon={<AddIcon style={{ fontSize: '70px' }} />}
                    />
                  ) : (
                    showRingPlots()
                  )}
                </Box>
              </Box>
              <Box sx={{ marginTop: '-40px', cursor: 'pointer', textAlign: 'end' }} ref={ref}>
                {varName !== VarName.Unknown && (
                  <Button onClick={() => handleSource(varName)} endIcon={<ArrowForwardIcon />}>
                    Detail
                  </Button>
                )}
              </Box>
            </Card>
          </Grid>
        );
      })}
    </>
  );
}

export default SummaryCardContainer;
