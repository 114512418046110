import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function InstallSuccessIcon(props: SvgIconProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="232" height="232" viewBox="0 0 232 232" fill="none" {...props}>
      <circle cx="116" cy="116" r="116" fill="#10CDAE" fillOpacity="0.16" />
      <path
        d="M127 117.573C127 123.648 122.075 128.573 116 128.573C109.925 128.573 105 123.648 105 117.573C105 111.498 109.925 106.573 116 106.573C122.075 106.573 127 111.498 127 117.573Z"
        stroke="white"
        strokeWidth="6"
        fill="none"
      />
      <path
        d="M94.8104 138.862C83.1077 127.04 83.1077 107.874 94.8104 96.0524M137.19 96.0524C148.892 107.874 148.892 127.04 137.19 138.862"
        stroke="white"
        strokeWidth="6"
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M82.702 151.936C64.3121 132.958 64.3121 102.188 82.702 83.2095M149.298 83.2095C167.688 102.188 167.688 132.958 149.298 151.936"
        stroke="white"
        strokeWidth="6"
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M116 128.159V149.564"
        stroke="white"
        strokeWidth="6"
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M95.2858 68.4178L115.936 88.7522L154.943 50.3428"
        stroke="#10CDAE"
        strokeWidth="6"
        fill="none"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default InstallSuccessIcon;
