/* eslint-disable react/no-danger */
import React from 'react';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { BasicSensorType, GatewaySensorType, SubSensorType } from '../../services/api';
import { sensorTypeDetails } from '../../utils/sensorProperties';

interface SensorInfoDialogProps {
  sensorType: SubSensorType | GatewaySensorType | BasicSensorType;
  showSensorInfo: boolean;
  closeDialog: () => void;
}

function SensorInfoDialog({
  sensorType,
  showSensorInfo,
  closeDialog,
}: SensorInfoDialogProps): JSX.Element {
  const theme = useTheme();
  return (
    <Dialog
      open={showSensorInfo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ textAlign: 'center' }}
      onClose={closeDialog}
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px',
        }}
      >
        <Typography variant="h5" style={{ width: '100%' }}>
          {sensorTypeDetails[sensorType].brandName}
        </Typography>
        <IconButton onClick={closeDialog} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <hr style={{ border: 'solid 1px #f1f1f1' }} />
        <img src={sensorTypeDetails[sensorType].image} alt="sensor" />
        <div
          dangerouslySetInnerHTML={{ __html: sensorTypeDetails[sensorType]?.desc ?? '' }}
          style={{ color: theme.palette.text.primary }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default SensorInfoDialog;
