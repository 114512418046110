import { PropLocationItem } from './api';

const UPDATE_INTERVAL_CURRENT = 4 * 3600 * 1000; // 4 hours in ms
const UPDATE_INTERVAL_HISTORY = 24 * 3600 * 1000; // 24 hours in ms

const API_URL = process.env.REACT_APP_OPENWEATHER_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;

/* eslint-disable camelcase */
export type WeatherMain = {
  temp?: number;
  feels_like?: number;
  pressure?: number;
  humidity?: number;
  temp_min?: number;
  temp_max?: number;
  sea_level?: number;
  grnd_level?: number;
};
/* eslint-enable camelcase */

export type WeatherCondition = {
  id: string;
  main: string;
  description?: string;
  icon?: string;
};

export type WeatherWind = {
  speed: number;
  deg: number;
  gust: number;
};

export type WeatherClouds = {
  all: number;
};

export type WeatherData = {
  id: string;
  name?: string;
  coord?: { lon: number; lat: number };
  weather?: WeatherCondition[];
  main?: WeatherMain;
  visibility?: number;
  timezone?: number;
  wind?: WeatherWind;
  clouds?: WeatherClouds;
};

export type WeatherHistory = {
  id: string;
};

class WeatherService {
  private static instance: WeatherService;

  private weatherData: Map<string, WeatherData>;

  private weatherHistory: Map<string, WeatherHistory>;

  private lastUpdateTime: number;

  private constructor() {
    this.weatherData = new Map();
    this.weatherHistory = new Map();
    this.lastUpdateTime = 0;
  }

  static getInstance(): WeatherService {
    if (!WeatherService.instance) {
      WeatherService.instance = new WeatherService();
    }

    return WeatherService.instance;
  }

  public getCurrentWeather(locationId: string, lat: number, lng: number): Promise<WeatherData> {
    return new Promise((resolve, reject) => {
      if (!locationId || !lat || !lng) {
        reject(new Error('No location id or position supplied when trying to fetch weather'));
        return;
      }

      const data = this.weatherData.get(locationId);
      if (data) {
        if (Date.now() - UPDATE_INTERVAL_CURRENT < this.lastUpdateTime) {
          resolve(data);
          return;
        }
      }

      if (lat && lng) {
        this.lastUpdateTime = Date.now();

        const url = `${API_URL}weather?lat=${lat}&lon=${lng}&units=metric&appid=${API_KEY}`;
        fetch(url)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }

            reject(new Error(`Weather API returned ${res.status}, ${res.statusText}`));
            return false;
          })
          .then((responseData) => {
            if (responseData) {
              this.weatherData.set(locationId, responseData);
              resolve(responseData);
            }
          });
      } else {
        reject(
          new Error(
            `Attempting to fetch current weather when no coordinates available: ${locationId}`
          )
        );
      }
    });
  }

  public getWeatherHistory(location: PropLocationItem, date: number): Promise<WeatherHistory> {
    return new Promise((resolve, reject) => {
      if (!location.id) {
        reject(new Error('No location id supplied when trying to fetch weather history'));
        return;
      }

      const data = this.weatherHistory.get(location.id);
      if (data) {
        if (Date.now() - UPDATE_INTERVAL_HISTORY < this.lastUpdateTime) {
          resolve(data);
          return;
        }
      }

      const { lat, lng } = location.position ?? { lat: undefined, lng: undefined };
      if (lat && lng) {
        this.lastUpdateTime = Date.now();
        const dt = Math.round(date / 1000);
        const url = `${API_URL}onecall/timemachine?lat=${lat}&lon=${lng}&units=metric&dt=${dt}&appid=${API_KEY}`;
        fetch(url)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }

            reject(new Error(`Weather API returned ${res.status}, ${res.statusText}`));
            return false;
          })
          .then((responseData) => {
            if (responseData) {
              this.weatherHistory.set(location.id, responseData);
              resolve(responseData);
            }
          });
      } else {
        reject(
          new Error(
            `Attempting to fetch weather history when no coordinates available: ${location}`
          )
        );
      }
    });
  }
}

export default WeatherService;
