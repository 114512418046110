import Plotly from 'plotly.js/lib/core';
import createPlotlyComponent from 'react-plotly.js/factory';

// import the necessary trace type
Plotly.register([
  /* eslint-disable global-require */
  require('plotly.js/lib/scatter'),
  require('plotly.js/lib/pie'),
  require('plotly.js/lib/sunburst'),
  require('plotly.js/lib/heatmap'),
  require('plotly.js/lib/bar'),
  require('plotly.js/lib/histogram'),
  /* eslint-enable global-require */
]);

export default createPlotlyComponent(Plotly);
