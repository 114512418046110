import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Authenticator } from '@aws-amplify/ui-react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import AuthShell from './Shell/AuthShell';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as serviceWorker from './serviceWorker';
import configureStore from './state/store';
import DataStore from './services/dataStore';
import { getTheme } from './styles/theme';
import { ThemeMode } from './state/types';

posthog.init('phc_2I07hBNQikMyYHwlQ0vYHkWPAIN6DmQr4zsiGFOK8bg', {
  api_host: 'https://eu.posthog.com',
  persistence: 'memory',
});

const store = configureStore();
DataStore.getInstance().store = store;

function App() {
  const [themeType, setThemeType] = useState<ThemeMode>(ThemeMode.dark);
  const theme = React.useMemo(() => getTheme(themeType), [themeType]);

  return (
    <div className="lf-dashboard">
      <Authenticator.Provider>
        <Provider store={store}>
          <PostHogProvider client={posthog}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthShell setThemeType={setThemeType} />
              </ThemeProvider>
            </StyledEngineProvider>
          </PostHogProvider>
        </Provider>
      </Authenticator.Provider>
    </div>
  );
}

const container = document.getElementById('root');
// method returns null if provided ID is not found in the container. Thus to verify the ID exists
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
