import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BarChartProperties } from './constants';
import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import { VarName } from '../../services/api';
import Plot from '../Plots/PlotlyCustom';

interface BarChartPlotProps {
  barProps: BarChartProperties;
}

function BarChartPlot({ barProps }: BarChartPlotProps): JSX.Element {
  const isMobileView = useMediaQuery(useTheme().breakpoints.down('sm'));

  const { traces, range, xAxisVisibility, source, values, yAxisTitle, showLegend } = barProps;
  const tracedata = [];
  if (traces && traces.some((item) => item.name === 'min')) {
    const minValues = traces.find((trace) => trace.name === 'min')?.value ?? [];
    const maxValues = traces.find((trace) => trace.name === 'max')?.value ?? [];
    const avgValues = traces.find((trace) => trace.name === 'avg')?.value ?? [];
    const dataBands = varNameBandParams[source];

    if (dataBands && values) {
      for (let i = 0; i < dataBands.length; i++) {
        const value = [];
        const baseValue = [];
        for (let j = 0; j < values.length; j++) {
          if (i !== dataBands.length) {
            if (i === 0) {
              // adding base value
              let base = dataBands[i].upperBound;
              if (minValues[j] < dataBands[i].upperBound) base = minValues[j];
              // adding value
              if (maxValues[j] > dataBands[i].upperBound && base !== undefined)
                value.push(dataBands[i].upperBound - base);
              else if (maxValues[j] < dataBands[i].upperBound && maxValues[j] > minValues[j])
                value.push(maxValues[j] - minValues[j]);
              else value.push(null);
              baseValue.push(base);
            } else {
              let base = null;
              if (maxValues[j] < dataBands[i - 1].upperBound) base = maxValues[j];
              else if (minValues[j] <= dataBands[i - 1].upperBound)
                base = dataBands[i - 1].upperBound;
              else if (minValues[j] <= dataBands[i].upperBound) base = minValues[j];
              else base = dataBands[i].upperBound;

              if (maxValues[j] > dataBands[i].upperBound && base !== undefined)
                value.push(dataBands[i].upperBound - base);
              else if (
                maxValues[j] <= dataBands[i].upperBound &&
                maxValues[j] > dataBands[i - 1].upperBound &&
                base
              ) {
                value.push(maxValues[j] - base);
              } else value.push(null);

              baseValue.push(base);
            }
          } else {
            let base = null;
            if (minValues[j] < dataBands[i - 1].upperBound) base = dataBands[i - 1].upperBound;
            else base = minValues[j];

            if (maxValues[j] > dataBands[i - 1].upperBound) value.push(maxValues[j]);
            else value.push(null);
            baseValue.push(base);
          }
        }
        tracedata.push({
          x: values,
          y: value,
          name: dataBands[i].label,
          base: baseValue,
          type: 'bar',
          marker: {
            color: `${dataBands[i].color}95`,
          },
        });
      }

      const getScatterData = (dataValues: number[]) => ({
        x: values,
        y: dataValues,
        mode: 'markers',
        marker: { size: 5, color: dataValues.map((num) => getDataBandParams(source, num).color) },
        showlegend: false,
        orientation: 'h',
      });

      tracedata.push(getScatterData(minValues));
      tracedata.push(getScatterData(avgValues));
      tracedata.push(getScatterData(maxValues));
    }
  } else {
    for (let i = 0; i < traces.length; i++) {
      tracedata.push({
        x: traces[i].orientation === 'v' ? values : traces[i].value,
        y: traces[i].orientation === 'v' ? traces[i].value : values,
        name: traces[i].name,
        base: source === VarName.Co2ppm && traces[i].orientation === 'v' ? 400 : 0,
        type: 'bar',
        orientation: traces[i].orientation,
        marker: {
          color: traces[i].color,
          pattern: {
            shape: traces[i]?.shape ?? '',
          },
        },
        width: traces[i].orientation === 'h' ? 0.5 : 0.8,
      });
    }
  }

  const layout = {
    showlegend: showLegend ?? true,
    // legend: { orientation: 'h', xanchor: 'center', y: -0.15, x: 0.5 },
    legend: { orientation: 'h', xanchor: 'center', y: -0.15, x: 0.5 },
    xaxis: {
      ticksuffix: tracedata[0]?.orientation === 'h' ? '%' : '',
      range: range ?? null,
      visible: xAxisVisibility ?? true,
      position: 0,
      zeroline: !range,
      showgrid: false,
    },
    yaxis: {
      automargin: true,
      title: {
        text: yAxisTitle ?? '',
      },
    },
    barmode: 'overlay',
    bargroupgap: 0.1,
    height: range ? 150 : 250,
    width: isMobileView ? 350 : 500,
    margin: {
      l: 50,
      r: 50,
      b: 50,
      t: range ? 0 : 50,
      pad: range ? 20 : 0,
    },
    font: {
      size: 10,
      family: "'Poppins-Medium', sans-serif",
      color: '#979BA6',
    },
  } as Partial<Plotly.Layout>;

  return <Plot data={tracedata as Plotly.Data[]} layout={layout} config={{ staticPlot: true }} />;
}

export default BarChartPlot;
