import makeStyles from '@mui/styles/makeStyles';
import { lighten } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  invalidDate: {
    opacity: '0 !important',
    cursor: 'none',
    background: 'none',
  },

  disabledDate: {
    opacity: '0.4',
  },

  selectedDate: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.text.secondary,
    borderRadius: '50%',
  },

  today: {
    border: `solid 1px ${theme.palette.text.primary}`,
  },

  inRange: {
    backgroundColor: lighten(theme.palette.text.primary, 0.2),
    color: theme.palette.text.secondary,
    borderRadius: 0,
  },

  willBeInRange: {
    borderTop: `dashed 1px ${theme.palette.text.primary}`,
    borderBottom: `dashed 1px ${theme.palette.text.primary}`,
    borderRadius: 0,
  },

  textStyle: {
    fontSize: themeProps.textSize.small,
    lineHeight: '1rem',
    textAlign: 'center',
  },

  monthBtn: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2rem 1fr 2rem',
    marginBottom: '0.5rem',
  },

  weekDaysContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    rowGap: '0.5rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
    height: '2rem',
  },

  monthBtnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    rowGap: '0.5rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
    height: '2rem',
  },

  daysCol: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    rowGap: '0.5rem',
  },
}));

export default useStyles;
