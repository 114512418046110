import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { SensorLatest } from '../../services/api/api';
import { varNameDetails } from '../../utils/varNames';
import useStyles from '../../styles';
import { getShortTimeStr } from '../../utils/functions';
import DataValueString from '../HelperComponents/DataValueString';

interface SensorDataProps {
  sensorDetails: SensorLatest;
}

function SensorData({ sensorDetails }: SensorDataProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <List>
        <ListItem>
          <AssessmentIcon className={classes.icon} style={{ alignSelf: 'center' }} />
          <Typography variant="h6" style={{ marginLeft: '10px' }}>
            Sensor Data
          </Typography>
        </ListItem>
        <Divider />
        {sensorDetails?.data?.map((item) => {
          const { id, icon, label } = varNameDetails[item.varName];
          const { time, value, varName } = item;
          const Icon = icon;

          return Icon ? (
            <ListItem key={id}>
              <Icon className={classes.icon} />
              <Grid item sm={5} xs={8} style={{ marginLeft: '10px' }}>
                <ListItemText primary={label} />
              </Grid>
              <Grid item sm={7} xs={4} className={classes.sensorDataGrid}>
                <p>
                  {value !== undefined && (
                    <DataValueString value={value} varName={varName} showMetric time={time} />
                  )}
                </p>
                <p className={classes.unixTime}>{time && getShortTimeStr(time)}</p>
              </Grid>
            </ListItem>
          ) : (
            ''
          );
        })}
      </List>
    </Paper>
  );
}

export default SensorData;
